import { ChangeEvent, useState, useRef, useEffect } from 'react';

import { Box, Button, Typography, useTheme, TextField, CircularProgress } from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { useDependencies } from 'hooks/queriesGraphQL/useDependencies';
import { useFullDependencies } from 'hooks/queriesGraphQL/useFullDependencies';
import useDebounce from 'hooks/useDebounce';

import { openMonitoringPage } from '../..';
import { Filters, PageFilters } from '../DependenciesFilters';

import { useStyles } from './styles';
import { useDependenciesListColumns } from './useDependenciesListColumns';

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export const DependenciesList = ({ applicationId }: { applicationId: number }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | ResourceStatusEnum | MultiFlowStatusEnum>('all');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const [dependencies, setDependencies] = useState<any>([]);
  const [fullDependencies, setFullDependencies] = useState<any>([]);

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [{ fetching, data, error }] = useDependencies({
    applicationId,
    page,
    perPage,
    query: buildElasticQuery({
      search,
      status: status === 'all' ? '' : status
    })
  });

  const columns = useDependenciesListColumns();

  const [{ fetching: fullDependenciesFetching, data: fullDependenciesData }] = useFullDependencies({
    fullDependencies: dependencies
  });
  useEffect(() => {
    if (data?.dependenciesV2?.data) {
      setDependencies(data?.dependenciesV2.data);
    }
  }, [data?.dependenciesV2.data]);

  useEffect(() => {
    if (fullDependenciesData?.fullDependencies) {
      setFullDependencies(fullDependenciesData?.fullDependencies);
    }
  }, [fullDependenciesData?.fullDependencies]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);

  const handleApplyFilters = (filters: PageFilters) => {
    setStatus(filters.status);
  };

  const handleClearFilters = () => {
    setStatus('all');
  };

  const handleCloseFilters = () => setIsFiltersOpen(false);

  const dataToDisplay = () => {
    if (!dependencies.length && !fetching) return [];

    if (!fullDependencies.length || fullDependenciesFetching) return dependencies;

    return fullDependencies;
  };

  const DependenciesResult = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    return (
      <DataTable
        columns={columns}
        data={dataToDisplay()}
        noDataComponent="No data"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
        paginationPerPage={perPage}
        paginationTotalRows={data?.dependenciesV2?.total}
        paginationServer
        pagination
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={fetching}
        highlightOnHover
        striped
        pointerOnHover={true}
        onRowClicked={dependency =>
          history.push(
            openMonitoringPage({ entity: dependency.entity!, entityUid: dependency.entityUid! })
          )
        }
      />
    );
  };

  return (
    <Box>
      <Typography variant="h3" color="secondary" className={classes.monitoringDependenciesTitle}>
        Monitoring dependencies
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={4}
        mt={5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchContainerRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Try to search by name"
          InputProps={{
            endAdornment: <Search />,
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          fullWidth
        />

        <Button
          className={classes.filterButton}
          variant="outlined"
          color="primary"
          onClick={handleToggleFilters}>
          <FilterList />
        </Button>
      </Box>

      {isFiltersOpen && (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      )}

      {DependenciesResult()}
    </Box>
  );
};
