import { makeStyles } from '@material-ui/core';
import { ExternalIntegrationStatusEnum } from 'types/external/ExternalService';

import { Theme } from 'theme/v4';

import { StatusChipProps } from '.';

type StatusChipStyleProps = {
  status: StatusChipProps['status'];
};

function getChipColorByStatus(status: ExternalIntegrationStatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [ExternalIntegrationStatusEnum.Outage]: palette.elvenRed[500],
    [ExternalIntegrationStatusEnum.Pending]: palette.elvenGray[200],
    [ExternalIntegrationStatusEnum.Available]: palette.elvenGreen[400]
  };

  return statusColorMap[status];
}

export const useStyles = makeStyles<Theme, StatusChipStyleProps>(theme => ({
  chip: {
    backgroundColor: props => getChipColorByStatus(props.status, theme.palette),
    color: theme.palette.common.white
  }
}));
