import { MouseEvent, useCallback, useEffect, useState } from 'react';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Link,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { formatDistanceStrict } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Application, ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceOrigin, ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useMutation, useQuery } from 'urql';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { DeleteDialog } from 'componentsV4/DeleteDialog';
import { DeployEnum, FilterTypeEnum } from 'componentsV4/Filters';
import { getPermission } from 'helpers';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import { StatusChip as ResourceStatusChip } from 'views/Monitoring/List/components/StatusChip';
import { ErrorBudget } from 'views/Monitoring/View/components/ErrorBudget';
import { Uptime } from 'views/Monitoring/View/components/Uptime';
import { useStyles } from 'views/Monitoring/View/styles';
import { StatusChip as MultiFlowStatusChip } from 'views/MultiFlow/List/components/StatusChip';

import { DependenciesList } from './components/DependenciesList';
import { eventHistoryCustomElasticQuery, EventsHistory } from './components/EventsHistory';
import { FrequenciesCards } from './components/FrequenciesCards';
import { LeadTimeCard } from './components/LeadTimeCard';
import { MttsCard } from './components/MttsCard';
import { LoadingSkeleton } from './LoadingSkeleton';
import { RegisterDeploy } from './RegisterDeploy';
import { ApplicationData } from './types';

const GetResourceQuery = `#graphql
  query GetApplication($uid: String!) {
    applicationV2(applicationUid: $uid) {
      id
      entityUid
      entity
      name
      productId

      main {
        ... on Resource {
          name
          interval
          serviceId
          origin
          domainSettings

          resourceStatus: status {
            status
            lastCheck
          }

          environment {
            name
          }
        }
        ... on MultiFlowOutput {
          name
          environment {
            name
          }

          status
        }
      }
    }
  }
`;

const DeleteApplicationMutation = `#graphql
  mutation DeleteApplication($uid: String!) {
    deleteApplicationV2(applicationUid: $uid)
  }
`;

const eventsHistoryQuery = `#graphql
    query (
        $entityUid: String!
        $type: String!
        $searchArgs: SearchArgs!
    ) {
        applicationEvents(
            entityUid: $entityUid
            type: $type
            searchArgs: $searchArgs
        ) {
          data {
            id
            incidentId
            eventId
            content {
                name
                version
                createdAt
                description
                severity
                private
            }
            createdAt
            originEntity
            originUid
            type
            eventType
          }
          total
        }
    }
`;

export const openMonitoringPage = ({
  entity,
  entityUid
}: {
  entity: ApplicationManagerEntityType;
  entityUid: string;
}) => {
  if (entity === ApplicationManagerEntityType.Resource) return `/monitoring/${entityUid}`;

  return `/synthetic/${entityUid}`;
};

const PER_PAGE = 10;

export function ApplicationView() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const { uid } = useParams<{ uid: string }>();

  const { data: user } = useUser({});

  const shouldUseEventsHistory = useFeatureFlagUnleash('useEventsHistory');

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | StatusEnum>('all');
  const [severity, setSeverity] = useState<'all' | SeverityStatusEnum>('all');
  const [betweenValues, setBetweenValues] = useState('now-1y,now');
  const [deploy, setDeploy] = useState<'all' | DeployEnum>('all');
  const [type, setType] = useState(FilterTypeEnum.Incident);
  const [page, setPage] = useState(1);

  const [{ fetching: getApplicationLoading, data }, reexecuteQuery] = useQuery<{
    applicationV2: ApplicationData;
  }>({
    query: GetResourceQuery,
    variables: {
      uid
    }
  });

  const application = data?.applicationV2;

  const [resultEventsQuery, reexecuteEventsQuery] = useQuery({
    query: eventsHistoryQuery,
    pause: !application?.entityUid || !uid,
    variables: {
      entityUid: application?.entityUid,
      type,
      searchArgs: {
        query: eventHistoryCustomElasticQuery({
          queryFilters: [
            {
              key: type === FilterTypeEnum.Incident ? 'title' : 'content.description',
              value: search,
              isRegex: true
            },
            {
              key: 'event_type',
              value: deploy,
              isRegex: false
            },
            {
              key: 'status',
              value: status,
              isRegex: false
            },
            {
              key: 'severity',
              value: severity,
              isRegex: false
            }
          ]
        }),
        termValues: uid,
        from: Math.max(page - 1, 0) * PER_PAGE,
        size: PER_PAGE,
        betweenKey: 'created_at',
        betweenValues
      }
    }
  });

  const { fetching } = resultEventsQuery;

  const dataFromGraphQL = resultEventsQuery.data?.applicationEvents || [];
  const events = dataFromGraphQL || [];

  useEffect(() => {
    if (getApplicationLoading) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 10 /* 10 seconds */);

    return () => clearInterval(intervalId);
  }, [getApplicationLoading, reexecuteQuery]);

  const [{ fetching: deleteApplicationLoading }, deleteApplicationMutation] = useMutation<{
    applicationV2: Application;
  }>(DeleteApplicationMutation);

  const monitoringIsActive = useCallback(() => {
    if (!application) return false;

    if (application.entity === ApplicationManagerEntityType.Resource) {
      return (
        application.main.resourceStatus.status === ResourceStatusEnum.Available ||
        application.main.resourceStatus.status === ResourceStatusEnum.Pending ||
        application.main.resourceStatus.status === ResourceStatusEnum.Unavailable
      );
    }

    return (
      application.main.status === MultiFlowStatusEnum.Success ||
      application.main.status === MultiFlowStatusEnum.Pending ||
      application.main.status === MultiFlowStatusEnum.Error
    );
  }, [application]);

  const canDeploy = true;

  const isResource = application?.entity === ApplicationManagerEntityType.Resource;

  const isMultiFlow = application?.entity === ApplicationManagerEntityType.Multiflow;

  const previousDayDate = new Date();
  previousDayDate.setDate(previousDayDate.getDate() - 1);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleToggleMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(openDeleteDialog => !openDeleteDialog);
  };

  const deleteApplication = () => {
    deleteApplicationMutation({ uid }).then(() => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application deleted successfully'
      });

      history.push('/applications');
    });
  };

  const hasPermissionEdit = getPermission(user, 'ApplicationController-put-/applications');
  const hasPermissionDelete = getPermission(
    user,
    'ApplicationController-delete-/applications/:uid'
  );

  const [open, setOpenDeployDialog] = useState(false);

  const handleOpenDeployDialog = () => {
    setOpenDeployDialog(true);
  };

  const handleCloseDeployDialog = () => {
    setOpenDeployDialog(false);
  };

  if (getApplicationLoading) {
    return <LoadingSkeleton />;
  }

  if (!application) {
    return <Redirect to="/applications" />;
  }

  const renderStatusMessage = (message: string) => (
    <Box mt={1}>
      <Typography className={classes.paperTextMiddle}>{message}</Typography>
    </Box>
  );

  const showUptime = () => {
    if (!monitoringIsActive()) {
      return renderStatusMessage('Monitoring inactive');
    }

    if (isResource) {
      return (
        <Box mt={1}>
          <Uptime serviceId={application.main.serviceId} isMonitoring={monitoringIsActive()} />
        </Box>
      );
    }

    return renderStatusMessage('No data');
  };

  const showErrorBudget = () => {
    if (!monitoringIsActive()) {
      return renderStatusMessage('Monitoring inactive');
    }

    if (isResource) {
      return (
        <ErrorBudget
          serviceId={application.main.serviceId}
          isMonitoring={monitoringIsActive()}
          interval={application.main.interval}
        />
      );
    }

    return renderStatusMessage('No data');
  };

  const isAPI = application.main.origin === ResourceOrigin['API'];
  const isPool = application.main.origin === ResourceOrigin['Pool'];
  const isStatic = application.main.origin === ResourceOrigin['Static'];

  const checkpointName = () => {
    if (isAPI) {
      return ' API';
    }

    if (isPool) {
      return ' Pool - N. Virginia';
    }

    if (isStatic) {
      return ' Static';
    }

    return ` Agent - ${application.main.environment?.name}`;
  };

  return (
    <>
      <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
        <Backdrop open={deleteApplicationLoading} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>

        <Breadcrumb
          items={[
            { label: 'Monitoring Aggregator' },
            { label: 'Application Center', link: '/applications' },
            { label: application.name }
          ]}
        />

        <Box display="flex" mt={6} alignItems="center">
          <Typography variant="h4" color="secondary" className={globalClasses.title}>
            {application.name}
          </Typography>

          <Box ml="auto">
            <Tooltip
              title="Register Deploy"
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
              }}>
              <Button
                className={classes.icons}
                variant="outlined"
                disabled={!canDeploy}
                onClick={handleOpenDeployDialog}>
                <Icon fontSize="small">cloud_upload</Icon>
              </Button>
            </Tooltip>

            <RegisterDeploy
              handleCloseDeployDialog={handleCloseDeployDialog}
              open={open}
              application={application}
              applicationUid={uid}
            />

            <Tooltip
              title="More Options"
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
              }}>
              <Button className={classes.icons} variant="outlined" onClick={handleToggleMenu}>
                <MoreVert />
              </Button>
            </Tooltip>
          </Box>

          <Menu
            id="simple-menu"
            open={isMenuOpen}
            elevation={1}
            anchorEl={menuAnchorEl}
            getContentAnchorEl={null}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            classes={{ paper: classes.paperProps }}>
            <MenuItem className={classes.menuItem} disabled={!hasPermissionEdit}>
              <Link component={RouterLink} to={`/services-hub/edit/${uid}?type=application`}>
                Edit application
              </Link>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={handleDeleteDialog}
              disabled={!hasPermissionDelete}>
              Delete application
            </MenuItem>
          </Menu>

          <DeleteDialog
            open={openDeleteDialog}
            title={`Delete ${application.name}`}
            handleClose={handleDeleteDialog}
            handleRemove={deleteApplication}>
            Are you sure you want to delete your <strong>{application.name}</strong>? This action
            may have an impact on your Deploys and Lead Times integrations.
          </DeleteDialog>
        </Box>

        <Box display="flex" mt={5} alignItems="center" className={classes.topBox} gridGap="140px">
          <Box display="flex" alignItems="center" gridGap="1.5rem">
            {isResource && <ResourceStatusChip status={application.main.resourceStatus.status} />}
            {isMultiFlow && <MultiFlowStatusChip status={application.main.status} />}

            <Typography
              variant="body1"
              style={{ display: 'flex' }}
              className={classes.textMainName}>
              <strong>Monitoring main:&nbsp;</strong>
              <Link
                component={RouterLink}
                className={classes.link}
                to={openMonitoringPage({
                  entity: application.entity,
                  entityUid: application.entityUid
                })}>
                <Typography>{application.main.name}</Typography>
              </Link>
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="body1" className={classes.textTitle}>
              <strong>Checkpoint:</strong> {checkpointName()}
            </Typography>
          </Box>

          {isResource && !isAPI && (
            <Box display="flex" alignItems="center">
              <Typography variant="body1" className={classes.textTitle}>
                <strong>Last Check:</strong>{' '}
                {new Date(application.main.resourceStatus.lastCheck).getTime() >
                previousDayDate.getTime()
                  ? formatDistanceStrict(
                      new Date(application.main.resourceStatus.lastCheck),
                      new Date()
                    )
                  : 'No data'}
              </Typography>
            </Box>
          )}
        </Box>

        <Box display="flex" mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MttsCard
                name="MTBF"
                originUid={application.entityUid}
                entity={application.entity}
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
            <Grid item xs={2}>
              <MttsCard
                name="MTTA"
                originUid={application.entityUid}
                entity={application.entity}
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
            <Grid item xs={2}>
              <MttsCard
                name="MTTRecovery"
                originUid={application.entityUid}
                entity={application.entity}
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
            <Grid item xs={4}>
              <FrequenciesCards applicationUid={uid} />
            </Grid>
            <Grid item xs={2}>
              <LeadTimeCard applicationUid={uid} productId={application.productId} />
            </Grid>
          </Grid>
        </Box>

        {!isMultiFlow && (
          <Box display="flex" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box component={Paper} className={classes.paperMiddle}>
                  <Typography variant="subtitle2" color="secondary" className={classes.paperTitle}>
                    SRE Metrics | Uptime
                  </Typography>

                  {showUptime()}
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box component={Paper} className={classes.paperMiddle}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      className={classes.paperTitle}>
                      SRE Metrics | Error Budget
                    </Typography>
                    <Tooltip title={'Metric used to display your error budget burned.'}>
                      <Icon className={classes.descriptionIcon}>help</Icon>
                    </Tooltip>
                  </Box>
                  <Box>{showErrorBudget()}</Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box mt={8}>
          <DependenciesList applicationId={application.id} />
        </Box>

        {shouldUseEventsHistory ? (
          <Box mt={2}>
            <EventsHistory
              page={page}
              setPage={setPage}
              setSearch={setSearch}
              setStatus={setStatus}
              setSeverity={setSeverity}
              setBetweenValues={setBetweenValues}
              setDeploy={setDeploy}
              setType={setType}
              fetching={fetching}
              reexecuteQuery={reexecuteEventsQuery}
              events={events}
            />
          </Box>
        ) : null}
      </PageContainer>
    </>
  );
}
