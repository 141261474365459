import React from 'react';

import Form from '../components/PermissionForm';

class RolesPermissionForm extends React.Component {
  render() {
    const { formState, features, permissions, save, remove } = this.props;
    return (
      <Form
        formState={formState}
        features={features}
        permissions={permissions}
        save={save}
        remove={remove}
      />
    );
  }
}

export default RolesPermissionForm;
