import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    width: '100%',
    '& h1': {
      fontSize: '12.5rem',
      lineHeight: '100%'
    },
    '& h2': {
      fontSize: '3rem',
      lineHeight: '100%'
    },
    '& img': {
      marginTop: theme.spacing(2)
    }
  }
}));

export function NotFoundView() {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h1">404</Typography>
      <Typography variant="h2" color="primary">
        Page not found
      </Typography>
      <img src="/images/not-found.svg" alt="Not found" />
    </Box>
  );
}
