import React from 'react';

import Form from '../components/Form';

class FeaturesEditForm extends React.Component {
  render() {
    const { formState, onChange, save } = this.props;
    return <Form title="Edit Feature" formState={formState} onChange={onChange} save={save} />;
  }
}

export default FeaturesEditForm;
