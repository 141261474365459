import { useEffect } from 'react';

import { makeStyles, Box, CssBaseline } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import Footer from 'layouts/V4/components/Footer';
import Toolbar from 'layouts/V4/components/Toolbar';
import { withThemeV4, Theme } from 'theme/v4';

type StyleProps = {
  useDefaultWidth?: boolean;
};

interface ComponentProps extends StyleProps {
  children: React.ReactNode;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 74px)',
    minHeight: '100%',
    paddingTop: theme.spacing(8),
    justifyContent: 'center'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: ({ useDefaultWidth }) => (useDefaultWidth ? '30.375rem' : '100%'),
    height: '100%'
  }
}));

const Minimal = (props: ComponentProps) => {
  const { children } = props;

  const location = useLocation();

  const routesWithDifferentWidth = ['/terms', '/not-found', '/plans', '/login-error'];

  const classes = useStyles({
    useDefaultWidth: routesWithDifferentWidth.includes(location.pathname) ? false : true
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CssBaseline />

      <Toolbar />

      <Box display="flex" className={classes.root}>
        <Box className={classes.main}>
          <Box>{children}</Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default withThemeV4(Minimal);
