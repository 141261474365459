import { Typography, useTheme } from '@material-ui/core';

import { IntegrationsList } from './List';

export function TabIntegrations() {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h3" color="secondary" style={{ marginBottom: theme.spacing(4) }}>
        Integrations
      </Typography>

      <IntegrationsList />
    </>
  );
}
