import { useEffect, useRef, useState } from 'react';

import {
  Badge,
  Box,
  Divider,
  IconButton,
  Link,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import {
  Close,
  NotificationImportantOutlined as NotificationImportantIcon
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import dayjs from 'dayjs';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import { Link as RouterLink } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useSubscription } from 'urql';
import { v4 as uuid } from 'uuid';

import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';
import { useIncidents } from 'views/IncidentCenter/useQuery/useIncidents';

import { useStylesV2 } from './styles';

dayjs.extend(dayjsRelativeTimePlugin);

const QUERY_SIZE = 5;
const CRITICAL_EVENTS_LIST_RENDER_POOL = 1000 * 30; // 30s

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function CriticalEventsV2() {
  const classes = useStylesV2();

  const [isCriticalEventsOpen, setIsCriticalEventsOpen] = useState(false);
  const [pageIncidents, setPageIncidents] = useState(1);
  const [pageAlerts, setPageAlerts] = useState(1);
  const [tab, setTab] = useState(0);
  const [criticalEventsListKeyIncidents, setIsCriticalEventsKeyIncidents] = useState(uuid());
  const [criticalEventsListKeyAlerts, setIsCriticalEventsKeyAlerts] = useState(uuid());

  const criticalEventsContainerRef = useRef<HTMLDivElement>(null);

  const { data: dataIncidents, reexecuteQuery: reexecuteQueryIncidents } = useIncidents({
    perPage: QUERY_SIZE,
    page: pageIncidents,
    period: 0,
    severity: 'all',
    type: 'all',
    status: StatusEnum.Alarmed,
    query: ''
  });

  const { data: dataAlerts, reexecuteQuery: reexecuteQueryAlerts } = useAlertsState({
    from: pageAlerts,
    size: QUERY_SIZE,
    query: `(status: ${StatusEnum.Alarmed})`
  });

  useSubscription<void>(
    {
      query: `
        subscription {
          incidentsCreated {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryIncidents();
    }
  );

  useSubscription<void>(
    {
      query: `
        subscription {
          alertsState {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQueryAlerts();
    }
  );

  const hasDataAlerts = Boolean(dataAlerts?.alertsState?.total);
  const hasDataIncidents = Boolean(dataIncidents?.total);

  const totalAlerts = dataAlerts?.alertsState?.total ?? 0;
  const totalIncidents = dataIncidents?.total ?? 0;
  const totalEvents = totalAlerts + totalIncidents;

  useEffect(() => {
    if (!isCriticalEventsOpen) return;

    const criticalEventsKeyUpdateTimeoutId = setInterval(() => {
      setIsCriticalEventsKeyIncidents(uuid());
      setIsCriticalEventsKeyAlerts(uuid());
    }, CRITICAL_EVENTS_LIST_RENDER_POOL);

    return () => clearTimeout(criticalEventsKeyUpdateTimeoutId);
  }, [isCriticalEventsOpen]);

  return (
    <>
      <Box
        {...{ ref: criticalEventsContainerRef }}
        display="flex"
        gridGap="0.5rem"
        alignItems="center">
        Critical Events
        <IconButton onClick={() => setIsCriticalEventsOpen(isOpen => !isOpen)}>
          <Badge badgeContent={totalEvents} color="error">
            <NotificationImportantIcon htmlColor="white" />
          </Badge>
        </IconButton>
      </Box>

      <Popper
        open={isCriticalEventsOpen}
        anchorEl={criticalEventsContainerRef?.current}
        placement="bottom"
        className={classes.popper}>
        <Paper className={classes.paper}>
          <Box className={classes.content}>
            <Box display="flex" justifyContent="space-between" className={classes.dialogTitle}>
              <Typography variant="h3" color="secondary">
                Critical Events
              </Typography>

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setIsCriticalEventsOpen(false)}>
                <Close />
              </IconButton>
            </Box>

            <Box sx={{ borderBottom: 2, borderColor: 'silver' }}>
              <Tabs
                className={classes.tabs}
                classes={{
                  flexContainer: classes.tabsFlexContainer
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                value={tab}
                onChange={(_, value) => setTab(value)}>
                <Tab className={classes.tab} label={`Incidents (${totalIncidents})`} />
                <Tab className={classes.tab} label={`Alerts (${totalAlerts})`} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
              <Box
                key={criticalEventsListKeyIncidents}
                mt={3}
                display="flex"
                flexDirection="column"
                gridGap="1rem">
                {dataIncidents?.incidents?.map(incident => (
                  <Link
                    key={incident.incidentId}
                    component={RouterLink}
                    className={classes.criticalEventLink}
                    to={`/incidents/${incident.incidentId}`}
                    title="View incident">
                    <>
                      <Box display="flex" gridGap="0.5rem" flexDirection="column">
                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                          <StatusChip
                            type="Event"
                            status={incident.status as StatusEnum}
                            isAlertStatus
                          />
                          <StatusChip
                            type="Severity"
                            severityStatus={incident.severity as SeverityStatusEnum}
                            isAlertStatus={false}
                          />{' '}
                          <Typography className="criticalEventTitle" variant="h5">
                            {incident.title.length > 80
                              ? `${incident.title.substring(0, 80)}...`
                              : incident.title}
                          </Typography>
                        </Box>
                        <Typography variant="caption">
                          {dayjs(incident.happenedAt).fromNow()}
                        </Typography>
                      </Box>
                      <Divider className={classes.divider} />
                    </>
                  </Link>
                ))}
              </Box>
              {hasDataIncidents && (
                <Box className={classes.buttonsContainer} mt={2}>
                  <Pagination
                    page={pageIncidents}
                    count={Math.ceil(totalIncidents / QUERY_SIZE)}
                    defaultPage={pageIncidents}
                    size="large"
                    color="primary"
                    onChange={(_, value) => setPageIncidents(value)}
                  />
                </Box>
              )}

              {!hasDataIncidents && (
                <Typography variant="h5" color="secondary">
                  No critical events
                </Typography>
              )}
            </CustomTabPanel>

            <CustomTabPanel value={tab} index={1}>
              <Box
                key={criticalEventsListKeyAlerts}
                mt={3}
                display="flex"
                flexDirection="column"
                gridGap="1rem">
                {dataAlerts?.alertsState?.data?.map(alert => (
                  <Link
                    key={alert.uid}
                    component={RouterLink}
                    className={classes.criticalEventLink}
                    to={`/alert-center?uid=${alert.uid}`}
                    title="View Alert">
                    <>
                      <Box display="flex" gridGap="0.5rem" flexDirection="column">
                        <Box display="flex" alignItems="center" gridGap="0.5rem">
                          <StatusChip
                            type="Event"
                            status={alert.status as StatusEnum}
                            isAlertStatus
                          />
                          <StatusChip
                            type="Severity"
                            severityStatus={alert.severity as SeverityStatusEnum}
                            isAlertStatus={false}
                          />{' '}
                          <Typography className="criticalEventTitle" variant="h5">
                            {alert.title.length > 80
                              ? `${alert.title.substring(0, 80)}...`
                              : alert.title}
                          </Typography>
                        </Box>
                        <Typography variant="caption">
                          {dayjs(alert.alertHappenedAt).fromNow()}
                        </Typography>
                      </Box>
                      <Divider className={classes.divider} />
                    </>
                  </Link>
                ))}
              </Box>
              {hasDataAlerts && (
                <Box className={classes.buttonsContainer} mt={2}>
                  <Pagination
                    page={pageAlerts}
                    count={Math.ceil(totalAlerts / QUERY_SIZE)}
                    defaultPage={pageAlerts}
                    size="large"
                    color="primary"
                    onChange={(_, value) => setPageAlerts(value)}
                  />
                </Box>
              )}

              {!hasDataAlerts && (
                <Typography variant="h5" color="secondary">
                  No critical events
                </Typography>
              )}
            </CustomTabPanel>
          </Box>
        </Paper>
      </Popper>
    </>
  );
}
