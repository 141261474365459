import { severityOptions } from 'constants/severityOptions';

import { Box, Button, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';

import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';
import { TeamsSelect } from 'views/ServicesHub/components/TeamsSelect';

import { FormProps } from '../..';

export type TimeoutRuleFormData = {
  timeout: {
    severity: string;
    timeout: number;
    teams: { value: string; label: string }[];
    enable: boolean;
    type: string;
  };
};

export function TimeoutRuleForm({ form }: FormProps) {
  const { register, control, errors: formErrors } = form as UseFormMethods<{
    rules: TimeoutRuleFormData;
  }>;

  const isEdit = window.location.pathname.includes('edit');

  const isIncidentEnabled = form.watch('rules.timeout.enable');

  const errors = formErrors?.rules?.timeout
    ? Object.entries(formErrors?.rules?.timeout).reduce((_errors, [key, value]) => {
        _errors[`rules.timeout.${key}`] = value;

        return _errors;
      }, {} as Record<string, any>)
    : {};

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Select
        options={severityOptions}
        control={control}
        name="rules.timeout.severity"
        label="Severity"
        required={isIncidentEnabled}
      />
      <Input
        errors={errors}
        label="Timeout in seconds"
        type="number"
        name="rules.timeout.timeout"
        inputRef={register}
        required={isIncidentEnabled}
      />

      <TeamsSelect
        control={control}
        name="rules.timeout.teams"
        required={false}
        multiSelect={true}
      />

      <Controller
        control={control}
        type="checkbox"
        name="rules.timeout.enable"
        render={({ value, onChange }) => {
          if (isEdit && value) {
            return (
              <Button
                variant="outlined"
                startIcon={<Delete />}
                onClick={() => onChange(false)}
                fullWidth>
                Remove current settings
              </Button>
            );
          }

          return (
            <FormGroup>
              <FormControlLabel
                label="Enable to set up automatic alert opening"
                control={
                  <Switch
                    color="primary"
                    value={value}
                    checked={value}
                    onChange={(_, val) => onChange(val)}
                  />
                }
              />
            </FormGroup>
          );
        }}
      />

      <input type="hidden" name="rules.timeout.type" value="alert" ref={register} />
    </Box>
  );
}
