import React, { ReactNode } from 'react';

import { Dialog } from '@material-ui/core';

import { DialogContent, DialogTitle } from 'components/LegacyDialog';

type BrowsersRequestFullscreen = HTMLElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
};

type BrowsersExitFullscreen = Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};

type FullscreenProps = {
  title: string;
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
};

export const useFullscreenActions = () => {
  const [open, setOpen] = React.useState('');

  const handleClickOpen = (id: string = 'OPEN') => {
    setOpen(id);

    const documentElement = document.documentElement as BrowsersRequestFullscreen;

    if (documentElement.requestFullscreen) {
      documentElement.requestFullscreen();
    } else if (documentElement?.mozRequestFullScreen) {
      /* Firefox */
      documentElement.mozRequestFullScreen();
    } else if (documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      documentElement.webkitRequestFullscreen();
    } else if (documentElement.msRequestFullscreen) {
      /* IE/Edge */
      documentElement.msRequestFullscreen();
    }
  };

  const handleClose = () => {
    setOpen('');

    const documentWithBrowserExitFullscreen = document as BrowsersExitFullscreen;

    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen();
    } else if (documentWithBrowserExitFullscreen.mozCancelFullScreen) {
      /* Firefox */
      documentWithBrowserExitFullscreen.mozCancelFullScreen();
    } else if (documentWithBrowserExitFullscreen.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      documentWithBrowserExitFullscreen.webkitExitFullscreen();
    } else if (documentWithBrowserExitFullscreen.msExitFullscreen) {
      /* IE/Edge */
      documentWithBrowserExitFullscreen.msExitFullscreen();
    }
  };

  return { handleClickOpen, handleClose, open };
};

function Fullscreen({ title, children, open, handleClose }: FullscreenProps) {
  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby="fullscreen-dialog"
      open={open}
      style={{ zIndex: 9999 }}>
      <DialogTitle id="fullscreen-dialog" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

export default Fullscreen;
