const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Description',
    selector: 'description'
  },
  {
    name: 'Type',
    selector: feature => (feature.requiresDependent ? 'Team' : 'Organization')
  },
  {
    name: 'Target',
    selector: 'target',
    sortable: true
  }
];

export default columns;
