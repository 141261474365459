import React from 'react';
import { useState } from 'react';

import { Typography, Box, Tab, Tabs } from '@material-ui/core';

import LatencyGraph from '../Latencies/index';
import PercentilesGraph from '../Percentiles/index';

import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface LatencyTabsProps {
  uid: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LatencyTabs(props: LatencyTabsProps) {
  const uid = props.uid;

  const classes = useStyles();

  const [dashboardIndex, setDashboardIndex] = useState(1);

  const tabs = [
    {
      label: 'Latencies Percentiles'
    },
    {
      label: 'Realtime Latencies 🔥'
    }
  ];

  const onChangeDashboard = function(value: number) {
    setDashboardIndex(value);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Tabs
          className={classes.tabs}
          classes={{
            flexContainer: classes.tabsFlexContainer
          }}
          indicatorColor="secondary"
          textColor="secondary"
          value={dashboardIndex}
          onChange={(_, value) => onChangeDashboard(value)}>
          {tabs.map((tab, index) => (
            <Tab className={classes.tab} key={tab.label} label={tab.label} value={index} />
          ))}
        </Tabs>
      </Box>

      <CustomTabPanel value={dashboardIndex} index={0}>
        <PercentilesGraph uid={uid} entity="resource" />
      </CustomTabPanel>
      <CustomTabPanel value={dashboardIndex} index={1}>
        <LatencyGraph uid={uid} entity="resource" />
      </CustomTabPanel>
    </Box>
  );
}

export default LatencyTabs;
