import { Typography, Link, MenuItem, Divider } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Button from 'componentsV4/Button';
import Select from 'componentsV4/Select';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useGetAccounts } from 'hooks/useGetAccounts';
import WithThemeV4Hoc from 'theme/v4/withTheme';

import useStyles from './styles';
import useAppActions from './useAppActions';
import useForm from './useForm';

const SelectOrg = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      orgId: '0'
    }
  });

  const { data: user } = useUser({});

  const { data: accounts } = useGetAccounts();

  const newAccountsList = useFeatureFlagUnleash('newAccountsList', {
    queryString: true
  });

  const options = user.accounts
    .filter((account: any) => Boolean(account.org))
    .map((account: any) => {
      return (
        <MenuItem key={`option-org-${account.org.id}`} value={Number(account.org.id)}>
          {account.org.name}
        </MenuItem>
      );
    });

  const newOptions = accounts?.map(account => {
    return (
      <MenuItem key={`option-org-${account.org.id}`} value={account.org.id}>
        {account.org.name}
      </MenuItem>
    );
  });

  const { handleSelectAccount, isLoading } = useAppActions();

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        Which organization would you like to access?
      </Typography>
      <form name="signUpForm" onSubmit={handleSubmit(handleSelectAccount)}>
        <Controller
          name="orgId"
          control={control}
          render={({ onChange, ...props }) => {
            return (
              <Select
                fullWidth
                className={classes.select}
                onChange={event => onChange(event)}
                {...props}>
                <MenuItem value="0" disabled>
                  Select organization
                </MenuItem>
                {!newAccountsList ? options : newOptions}
              </Select>
            );
          }}
        />

        <Button
          type="submit"
          fullWidth
          text="Login"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!isValid}
        />
      </form>

      <Divider />

      <Typography variant="body1" className={classes.question}>
        Haven't created an organization yet?
      </Typography>

      <Link
        title="Create organization"
        to="/new_org"
        component={RouterLink}
        className={classes.link}>
        Create organization
      </Link>

      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default WithThemeV4Hoc(SelectOrg);
