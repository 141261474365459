import { ChangeEvent, useState, useEffect } from 'react';

import { MenuItem, TextField, Box } from '@material-ui/core';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { FiltersPopper, FiltersPopperProps } from 'components/FiltersPopper';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';

export type PageFilters = {
  alertStatus: StatusEnum | string;
  alertSeverity: SeverityStatusEnum | string;
  alertPeriod: 'All' | string;
  alertUid: string;
};

type DefaultValues = {
  status?: string;
  severity?: string;
  period?: string;
  uid?: string;
};

type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  defaultValues: DefaultValues;
} & Pick<FiltersPopperProps, 'anchorEl' | 'handleClose'>;

export function Filters({
  anchorEl,
  onApplyFilters,
  onClearFilters,
  handleClose,
  defaultValues
}: FiltersProps) {
  const globalClasses = useGlobalStyles();

  const [alertStatus, setAlertStatus] = useState<StatusEnum | string>('All');
  const [alertSeverity, setAlertSeverity] = useState<SeverityStatusEnum | string>('All');
  const [alertPeriod, setAlertPeriod] = useState<'All' | string>('All');
  const [alertUid, setAlertUid] = useState<string>('');

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setAlertStatus(event.target.value as StatusEnum);
  };

  const handleChangeSeverity = (event: ChangeEvent<HTMLInputElement>) => {
    setAlertSeverity(event.target.value as SeverityStatusEnum);
  };

  const handleChangePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    setAlertPeriod(event.target.value as string);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ alertStatus, alertSeverity, alertPeriod, alertUid });
  };

  const handleChangeUid = (event: ChangeEvent<HTMLInputElement>) => {
    setAlertUid(event.target.value as string);
  };

  useEffect(() => {
    defaultValues.status && setAlertStatus(defaultValues.status as StatusEnum);
    defaultValues.severity && setAlertSeverity(defaultValues.severity as SeverityStatusEnum);
    defaultValues.period && setAlertPeriod(defaultValues.period);
    defaultValues.uid && setAlertUid(defaultValues.uid);
  }, [defaultValues, setAlertStatus, setAlertSeverity, setAlertPeriod, setAlertUid]);

  const handleClearFilters = () => {
    setAlertStatus('All');
    setAlertSeverity('All');
    setAlertPeriod('All');
    setAlertUid('');
    onClearFilters();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <Box display="flex" justifyContent="space-between" gridGap={12}>
        <Box width="480px">
          <TextField
            name="uidFilter"
            label="UID"
            variant="outlined"
            onChange={handleChangeUid}
            value={alertUid}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            fullWidth></TextField>
        </Box>

        <Box width="480px">
          <TextField
            name="statusFilter"
            label="Status"
            variant="outlined"
            onChange={handleChangeStatus}
            value={alertStatus}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={StatusEnum.Alarmed}>Alarmed</MenuItem>
            <MenuItem value={StatusEnum.Resolved}>Resolved</MenuItem>
            <MenuItem value={StatusEnum.Acknowledged}>Acknowledged</MenuItem>
          </TextField>
        </Box>

        <Box width="480px">
          <TextField
            name="severityFilter"
            label="Severity"
            variant="outlined"
            onChange={handleChangeSeverity}
            value={alertSeverity}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={SeverityStatusEnum.NotClassified}>Not Classified</MenuItem>
            <MenuItem value={SeverityStatusEnum.Critical}>SEV 1 - Critical</MenuItem>
            <MenuItem value={SeverityStatusEnum.High}>SEV 2 - High</MenuItem>
            <MenuItem value={SeverityStatusEnum.Moderate}>SEV 3 - Moderate</MenuItem>
            <MenuItem value={SeverityStatusEnum.Low}>SEV 4 - Low</MenuItem>
            <MenuItem value={SeverityStatusEnum.Informational}>SEV 5 - Informational</MenuItem>
          </TextField>
        </Box>

        <Box width="480px">
          <TextField
            name="periodFilter"
            label="Period"
            variant="outlined"
            onChange={handleChangePeriod}
            value={alertPeriod}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="1">Last 24 hours</MenuItem>
            <MenuItem value="7">Last 7 days</MenuItem>
            <MenuItem value="30">Last 30 days</MenuItem>
            <MenuItem value="0">Last 12 months</MenuItem>
          </TextField>
        </Box>
      </Box>
    </FiltersPopper>
  );
}
