import React from 'react';

import { Grid, TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import CrudForm from '../../../../components/Crud/Form';

const Field = ({ label, name, handleChange, hasError, value, errors, type, ...otherProps }) => {
  return (
    <Grid item md={4} xs={12}>
      <TextField
        fullWidth
        margin="dense"
        label={label}
        name={name}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={value !== undefined ? value : ''}
        error={hasError(name)}
        helperText={hasError(name) ? errors[0] : null}
        variant="outlined"
        type={type || undefined}
        {...otherProps}
      />
    </Grid>
  );
};

const Form = ({ formState, menuItems, onChange, title }) => {
  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  const makeFieldProps = name => ({
    name,
    handleChange,
    hasError,
    value: formState.values[name],
    errors: formState.errors[name]
  });

  return (
    <CrudForm entity="menuItems" title={title} formState={formState}>
      <Grid container spacing={3}>
        <Field label="MUI Icon name" {...makeFieldProps('icon')} />
        <Field key="title" label="Title" {...makeFieldProps('title')} required />
        <Field label="HREF" {...makeFieldProps('href')} required />
        <Field label="Order" {...makeFieldProps('order')} type="number" />

        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="parentId-label">
              Parent ID
            </InputLabel>
            <Select
              labelId="parentId-label"
              name="parent"
              value={formState.values.parent ? formState.values.parent.id : ''}
              displayEmpty
              onChange={event => {
                event.target.value = menuItems.find(({ id }) => event.target.value === id) || null;
                handleChange(event);
              }}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {menuItems &&
                menuItems.map(({ id, title }) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CrudForm>
  );
};

export default Form;
