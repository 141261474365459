import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStylesMaterial = makeStyles((theme: Theme) => ({
  pageContainerList: {
    padding: theme.spacing(6, 10),
    margin: 0
  },
  titleList: {
    fontSize: '2.5rem',
    lineHeight: '123.5%',
    fontWeight: theme.typography.fontWeightRegular
  }
}));
