import React from 'react';

import {
  DialogContentText,
  Link,
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DialogContent } from 'components/LegacyDialog';
import useCopy from 'hooks/useCopy';

import { instructions } from './CommandTexts';

const useStyles = makeStyles(theme => ({
  text: { paddingBottom: theme.spacing(1), color: '#546e7a' }
}));

const KubernetesTab = ({ environment, updateType, setUpdateType }) => {
  const classes = useStyles();

  const { copiedSuccessfullyMessage, handleCopy } = useCopy();

  const handleChange = event => {
    setUpdateType(event.target.value);
  };

  return (
    <DialogContent>
      <DialogContentText id={'customAgent-dialog-kubernetesText'}>
        Please install the Agent on your cloud using the Kubernetes manifest below to be able to
        monitor applications there. We will automatically update your Agent Status when you install.
      </DialogContentText>

      <Typography className={classes.text}>Select update type:</Typography>
      <FormControl fullWidth size="small" variant="outlined" margin="dense">
        <Select id="updateType" onChange={handleChange} value={updateType}>
          <MenuItem value={'autoUpdate'}>{'Auto update'}</MenuItem>
          <MenuItem value={'noAutoUpdate'}>{'Manual update'}</MenuItem>
        </Select>
      </FormControl>

      {updateType && (
        <>
          <Box paddingTop={2}>
            <DialogContentText>
              <Link
                id={'customAgent-copy-kubernetes-commands'}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  handleCopy(instructions(environment.id, environment.agentToken, updateType));
                }}>
                Copy to clipboard
              </Link>{' '}
              {copiedSuccessfullyMessage}
            </DialogContentText>
          </Box>
          <Box maxHeight={'240px'}>
            <pre
              id={'customAgent-codeBox-kubernetes'}
              style={{
                overflowX: 'auto',
                padding: 8,
                border: '1px solid rgba(29,28,29,.04)',
                borderRadius: 4,
                background: 'rgba(29, 28, 29, 0.04)'
              }}
              onClick={event => {
                const range = document.createRange();
                range.selectNodeContents(event.target);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
              }}>
              {instructions(environment.id, environment.agentToken, updateType)}
            </pre>
          </Box>
        </>
      )}
    </DialogContent>
  );
};

export default KubernetesTab;
