import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { makeStyles, Box, Typography, CircularProgress } from '@material-ui/core';
import { useMutation } from 'urql';

import { EnvironmentSelect } from 'components/Inputs';
import InputsGroup from 'components/MonitoringInputs/InputsGroup';
import { ButtonSimple } from 'componentsV4/Button';

import { setOneTeamForResource } from '../queries';

import HTTPStep from './HttpStep';
import KeysForm from './KeysComplete/KeysForm';
import useForm from './useForm';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  },
  invisible: {
    display: 'none'
  }
}));

const defaultValues = {
  pre_environmentId: '',
  pre_healthcheckUrl: '',
  pre_method: 'GET',
  pre_headers: [{ key: '', value: '' }],
  pre_post_body_default: [{ key: '', value: '' }],
  pre_post_body_urlencoded: [{ key: '', value: '' }],
  pre_post_body_raw: '',
  pre_post_body_type: postBodyTypeValues.raw,
  pre_post_body_raw_type: postBodyRawTypeValues.json,
  pre_tls_renegotiation: 0,

  pos_environmentId: '',
  pos_pos_healthcheckUrl: '',
  pos_method: 'GET',
  pos_headers: [{ key: '', value: '' }],
  pos_post_body_default: [{ key: '', value: '' }],
  pos_post_body_urlencoded: [{ key: '', value: '' }],
  pos_post_body_raw: '',
  pos_post_body_type: postBodyTypeValues.raw,
  pos_post_body_raw_type: postBodyRawTypeValues.json,
  pos_tls_renegotiation: 0,
  teamId: '',
  openIncidentWithSeverity: 'not-classified'
};

const MultiHttpForm = ({
  pos_teamId,
  onClose,
  startValues,
  submitMutation,
  errorUseMutation,
  isLoading
}) => {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    register,
    errors,
    watch,
    setValue,
    pre_headersFields,
    pre_headersAppend,
    pre_headersRemove,
    pre_postBodyXFormUrlEncodedFields,
    pre_postBodyXFormUrlEncodedAppend,
    pre_postBodyXFormUrlEncodedRemove,
    pos_headersFields,
    pos_headersAppend,
    pos_headersRemove,
    pos_postBodyXFormUrlEncodedFields,
    pos_postBodyXFormUrlEncodedAppend,
    formState: { isValid }
  } = useForm({
    defaultValues: startValues
      ? {
          ...startValues,
          pos_teamId: pos_teamId || null,
          pre_post_body_type:
            startValues.pre_post_body_type === postBodyTypeValues.urlencoded
              ? startValues.pre_post_body_type
              : postBodyTypeValues.raw,
          pre_post_body_raw_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded ||
            !startValues.post_body_type
              ? postBodyRawTypeValues.json
              : startValues.post_body_type,
          pos_post_body_type:
            startValues.pos_post_body_type === postBodyTypeValues.urlencoded
              ? startValues.pos_post_body_type
              : postBodyTypeValues.raw,
          pos_post_body_raw_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded ||
            !startValues.post_body_type
              ? postBodyRawTypeValues.json
              : startValues.post_body_type,
          pos_tls_renegotiation:
            startValues.pos_domain_settings !== '' && startValues.pos_domain_settings !== undefined
              ? JSON.parse(startValues?.pos_domain_settings)?.tls_renegotiation
              : 0,
          pre_tls_renegotiation:
            startValues.pre_domain_settings !== '' && startValues.pre_domain_settings !== undefined
              ? JSON.parse(startValues?.pre_domain_settings)?.tls_renegotiation
              : 0
        }
      : defaultValues
  });

  const [, addResource] = useMutation(setOneTeamForResource);

  const onSubmit = async data => {
    const submitResponse = await submitMutation(data);

    const resourceId =
      submitResponse?.data?.data?.id || submitResponse.data.updateMultiHttpApplication.id;

    const teamId = data?.pos_teamId;

    if (resourceId) {
      await addResource({
        teamId: Number(teamId),
        resourceId: Number(resourceId)
      });
    }
  };

  const pre_selectedMethod = watch('pre_method');
  const pre_selectedPostBodyType = watch('pre_post_body_type');

  const pos_selectedMethod = watch('pos_method');
  const pos_selectedPostBodyType = watch('pos_post_body_type');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EnvironmentSelect className={classes.formControl} errors={errors} control={control} />
      <Typography className={classes.formControl} variant="h3">
        Pre Request
      </Typography>

      <HTTPStep
        type="pre"
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
        headersFields={pre_headersFields}
        headersAppend={pre_headersAppend}
        headersRemove={pre_headersRemove}
        defaultValues={{
          headers: startValues ? startValues?.pre_headers : defaultValues?.pre_headers,
          skip_ssl_validation: startValues
            ? startValues?.pre_skip_ssl_validation
            : defaultValues?.pre_skip_ssl_validation
        }}
        postBodyXFormUrlEncodedFields={pre_postBodyXFormUrlEncodedFields}
        postBodyXFormUrlEncodedAppend={pre_postBodyXFormUrlEncodedAppend}
        postBodyXFormUrlEncodedRemove={pre_postBodyXFormUrlEncodedRemove}
        selectedMethod={pre_selectedMethod}
        selectedPostBodyType={pre_selectedPostBodyType}
      />

      <KeysForm />

      <Typography className={classes.formControl} variant="h3">
        Post Request
      </Typography>
      <HTTPStep
        type="pos"
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        watch={watch}
        headersFields={pos_headersFields}
        headersAppend={pos_headersAppend}
        headersRemove={pos_headersRemove}
        postBodyXFormUrlEncodedFields={pos_postBodyXFormUrlEncodedFields}
        postBodyXFormUrlEncodedAppend={pos_postBodyXFormUrlEncodedAppend}
        postBodyXFormUrlEncodedRemove={pos_postBodyXFormUrlEncodedAppend}
        defaultValues={{
          headers: startValues ? startValues.pos_headers : defaultValues.pos_headers,
          skip_ssl_validation: startValues
            ? startValues.pos_skip_ssl_validation
            : defaultValues.pos_skip_ssl_validation
        }}
        startValues={{
          headers: startValues ? startValues.pos_headers : defaultValues.pos_headers
        }}
        selectedMethod={pos_selectedMethod}
        selectedPostBodyType={pos_selectedPostBodyType}>
        <InputsGroup
          className={classes.formControl}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
        />
      </HTTPStep>

      {errorUseMutation && (
        <Typography color="error" gutterBottom>
          {errorUseMutation.response?.data?.message}
        </Typography>
      )}

      <Box display="flex" justifyContent="flex-end">
        <ButtonSimple
          className={classes.cancelButton}
          variant="outlined"
          onClick={onClose}
          text="Cancel"
          color="primary"
        />
        <ButtonSimple
          disableRipple
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          disabled={!isValid}
          text="Save"
        />
      </Box>

      {isLoading && (
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgb(255 255 255 / 66%)',
            zIndex: 1000
          }}>
          <CircularProgress />
        </Box>
      )}
    </form>
  );
};

export default MultiHttpForm;
