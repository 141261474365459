import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud/';

import schema from '../components/schema';

import RolesEditForm from './RolesEditForm';

class RolesEdit extends React.Component {
  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { roles_edit: formState, onChange, save } = this.props;

    return <RolesEditForm formState={formState} onChange={onChange} save={save} />;
  }
}

export default connect(
  ({ roles_edit }) => ({ roles_edit }),
  dispatch => ({
    fetch: id =>
      dispatch({
        type: actions.EDIT_ENTITY,
        meta: {
          entity: 'roles',
          id: id
        }
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'roles_edit'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'roles_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    },
    save: payload =>
      dispatch({
        type: actions.SAVE_ENTITY,
        payload,
        meta: { entity: 'roles', url: '/roles' }
      })
  })
)(RolesEdit);
