import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Dialog } from 'design-system';
import { AddSecretMutation } from 'graphqlQueries/addSecret';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import * as z from 'zod';

import { ButtonSimple } from 'componentsV4/Button';
import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import slugify from 'helpers/slugify';

const useStyles = makeStyles(theme => {
  return {
    visibilityButton: {
      color: blueGrey[200],
      '&:hover': {
        color: theme.palette.icon,
        backgroundColor: '#00000000'
      }
    }
  };
});

const schema = z.object({
  name: z.string().nonempty({ message: 'Required' }),
  value: z.string().nonempty({ message: 'Required' })
});

const NewSecretForm = ({ open, handleClose, onSecretAdd }) => {
  const classes = useStyles();

  const [valueVisible, setValueVisible] = React.useState(false);

  const toggleValueVisibility = () => {
    setValueVisible(current => !current);
  };

  const [addedSecret, addSecretMutation] = useMutation(AddSecretMutation);

  const { fetching, error } = addedSecret;

  const errorMessage = getErrorMessageFromUrql(error);

  const handleAddSecret = formData => {
    return addSecretMutation(formData).then(data => {
      onSecretAdd();

      if (!data.error) {
        handleClose();
      }
    });
  };

  const { register, control: controlSecret, handleSubmit, errors } = useForm({
    defaultValues: {
      name: '',
      value: ''
    },
    resolver: zodResolver(schema)
  });

  return (
    <Dialog
      open={open}
      aria-labelledby="new-secret-variable-dialog"
      onClose={handleClose}
      title="New secret variable"
      fullWidth
      maxWidth="xs">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={controlSecret}
              rules={{ required: true }}
              render={({ onChange, ...otherProps }) => {
                return (
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Name"
                    margin="dense"
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                    onChange={event => {
                      onChange(slugify(event.target.value, '_'));
                    }}
                    required
                    variant="outlined"
                    fullWidth
                    {...otherProps}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="new-password"
              error={Boolean(errors?.value)}
              fullWidth
              helperText={errors?.value?.message}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.visibilityButton}
                      onClick={toggleValueVisibility}
                      title="Show value"
                      aria-label="show value">
                      {valueVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputRef={register({ required: true })}
              label="Value"
              margin="dense"
              name="value"
              required
              type={valueVisible ? 'text' : 'password'}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={3}>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}

          <Box ml={1} clone>
            <ButtonSimple
              onClick={handleSubmit(handleAddSecret)}
              variant="contained"
              color="primary"
              type="submit"
              text="Save"
            />
          </Box>
          <Box ml={1} clone>
            <ButtonSimple variant="outlined" color="primary" onClick={handleClose} text="Cancel" />
          </Box>
        </Box>

        {fetching && (
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default NewSecretForm;
