import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import SignUpForm from './Form';
import useStyles from './style';

const SignUp = props => {
  const classes = useStyles();

  const { signUpGuestError } = props;

  const formView =
    signUpGuestError && signUpGuestError.length ? (
      <div className={classes.form}>
        <Typography color="error" gutterBottom>
          {signUpGuestError}
        </Typography>
      </div>
    ) : (
      <SignUpForm {...props} classes={classes} />
    );

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>{formView}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
