import { Box, Typography, useTheme } from '@material-ui/core';

import Dialog from 'components/Dialog';
import LoadingOverlay from 'componentsV3/LoadingOverlay';

import IntegrationEditTable from './EditTable';

function IntegrationEditDialog({
  open,
  onClose,
  onPrimaryAction,
  isLoading,
  connectedApplications,
  accountName,
  repositoryAccess
}: {
  open: boolean;
  onClose: () => void;
  onPrimaryAction: (applicationUid: string) => void;
  isLoading: boolean;
  connectedApplications: {
    id: number;
    uid: string;
    name: string;
    productName: string;
  }[];
  accountName: string;
  repositoryAccess: string;
}) {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      title="Edit integration"
      showPrimaryAction={false}
      onPrimaryAction={() => {}}
      showCloseButton={true}
      onClose={onClose}>
      <Box maxHeight={480} maxWidth={750}>
        <Box display="flex" gridGap={theme.spacing(3)}>
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            Account:{' '}
            <Typography variant="body2" display="inline">
              {accountName}
            </Typography>
          </Typography>

          <Typography variant="body2" style={{ fontWeight: 500 }}>
            Repositories Access:{' '}
            <Typography variant="body2" display="inline">
              {repositoryAccess}
            </Typography>
          </Typography>
        </Box>

        <Typography
          variant="body2"
          color="secondary"
          style={{ fontWeight: 500, marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
          Connected Applications
        </Typography>

        <Box display="flex">
          <IntegrationEditTable data={connectedApplications} onDelete={onPrimaryAction} />
        </Box>

        {isLoading && <LoadingOverlay />}
      </Box>
    </Dialog>
  );
}

export default IntegrationEditDialog;
