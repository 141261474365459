import { useState, useEffect } from 'react';

import { Box, Typography, MenuItem, InputAdornment, IconButton, Fade } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'clipboard-copy';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useApiKey from 'hooks/queriesGraphQL/useApiKey';
import { useAppTokens } from 'hooks/queriesGraphQL/useApiToken';
import { Input } from 'views/ServicesHub/components/Input';

type Props = {
  url?: string;
  onChangeToken?: (token: string) => void;
};

export const ApiToken = ({ url, onChangeToken = (token: string) => token }: Props) => {
  const [value, setValue] = useState('');
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);

  const [consumerId, setConsumerId] = useState('');
  const [apiKeyId, setApiKeyId] = useState('');

  useEffect(() => {
    if (copiedSuccessfully) {
      setTimeout(() => {
        setCopiedSuccessfully(false);
      }, 300);
    }
  }, [copiedSuccessfully, setCopiedSuccessfully]);

  const [{ fetching, data }] = useAppTokens({
    from: 0,
    size: 500
  });

  const apiTokens = data?.tokens?.data;

  const [{ data: apiKeyData, fetching: isLoadingApiKey }] = useApiKey({
    apiKeyId,
    consumerId
  });

  const apiKey = apiKeyData?.apiKey;

  const options = data?.tokens?.data?.map(token => ({ title: token.name, value: token.uid })) ?? [];

  const handleChange = (event: string) => {
    const tokenFound = apiTokens?.find(findToken => findToken.uid === event);

    setConsumerId(tokenFound?.consumer?.kongConsumerId!);
    setApiKeyId(tokenFound?.kongApiKeyId!);

    setValue(event);
  };

  useEffect(() => {
    if (apiKey) {
      onChangeToken(apiKey?.key);
    }
  }, [apiKey, onChangeToken]);

  return (
    <Box mb={5}>
      <Box mb={4}>
        <Typography variant="subtitle1" color="secondary">
          External service URL:
        </Typography>
      </Box>

      <Box position="relative" display="flex" width={1000}>
        {fetching && <LoadingOverlay size="2rem" />}

        <Input
          select
          value={value}
          required={false}
          label="API Token"
          onChange={event => handleChange(event.target.value)}
          InputLabelProps={{
            shrink: true,
            style: {
              color: '#286A6B'
            }
          }}>
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Input>
      </Box>

      {url && (
        <Box width={1000} mt={4}>
          <Box position="relative" display="flex" gridGap="1rem">
            {isLoadingApiKey && <LoadingOverlay isRelative size="2rem" />}
            <Input
              label="URL"
              color="secondary"
              disabled={true}
              required={false}
              value={url}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: '#286A6B'
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ paddingRight: 0 }}>
                    <Fade in={copiedSuccessfully} timeout={{ enter: 500, exit: 2000 }}>
                      <small style={{ color: 'green' }}>Copied</small>
                    </Fade>

                    <IconButton
                      onClick={event => {
                        event.preventDefault();
                        copy(String(url)).then(() => {
                          setCopiedSuccessfully(true);
                        });
                      }}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  paddingRight: 0
                }
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
