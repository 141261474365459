import MainLayoutV4 from 'layouts/V4/Main';
import MinimalLayoutV4 from 'layouts/V4/Minimal/Minimal';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import OrganizationView from 'views/Organization';
import { OrganizationNewSSOIntegration } from 'views/Organization/SSOIntegrations/New';
import OrgsNewV4 from 'views/V4/Orgs';

export const orgsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={OrgsNewV4}
    exact
    layout={MinimalLayoutV4}
    path="/new_org"
    key="/new_org"
  />,
  <AuthorizedRoute
    component={OrganizationView}
    exact
    layout={MainLayoutV4}
    key="/organization"
    path="/organization"
    featureTarget="OrganizationController-get-/org"
  />,
  <AuthorizedRoute
    component={OrganizationNewSSOIntegration}
    exact
    layout={MainLayoutV4}
    key="/organization/single-sign-on/new/:type"
    path="/organization/single-sign-on/new/:type"
    planFeature="SSOIntegrations"
    featureTarget="SSOIntegrationController-post-/sso-integrations"
  />
];
