import { useGetProducTeams } from './useGetProductTeams';

export function useGetProductTeamsForPermission(productId?: number) {
  const [productTeams] = useGetProducTeams({ productId });

  if (!productTeams.data?.getTeamsByResourceId) {
    return null;
  }

  // Convert from:
  // {
  //   name: string;
  //   resourceTeams: {
  //     teamId: number;
  //   }[];
  // }[]
  //
  // to:
  //
  // {
  //   id: number;
  // }[]
  const teams = productTeams.data.getTeamsByResourceId.reduce<{ id: number }[]>((final, item) => {
    return final.concat(
      item.resourceTeams.map(item => {
        return { id: item.teamId };
      })
    );
  }, []);

  return teams;
}
