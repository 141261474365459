import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function getParamsData() {
  const data = new Date();

  const year = data.getFullYear();
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const day = String(data.getDate()).padStart(2, '0');
  const formatedData = `${year}-${month}-${day}`;

  const hourEnd = data.getHours();
  const hourIni = hourEnd - 4;

  return {
    data: formatedData,
    hourEnd: hourEnd,
    hourIni: hourIni
  };
}

function Resources() {
  const dashboardName = MetabaseDashboards.CheckExplorerHitsOrFailures;

  const { serviceId } = useParams<{
    serviceId: string;
  }>();

  const filters = getParamsData();

  const params = {
    service: serviceId,
    single_date: filters['data'],
    hour: [filters['hourIni'], filters['hourEnd']]
  };

  const hideParams = ['service'];

  return (
    <MetabaseDashboard
      title="Check Explorer"
      subtitle="Checks details"
      dashboardName={dashboardName}
      dashboardParams={params}
      hideParams={hideParams}
      iframeTitle={false}
      showSubtitle={false}
    />
  );
}

export default withThemeV4(Resources);
