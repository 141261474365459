import React from 'react';

import { Grid } from '@material-ui/core';

import SecretsField from 'components/SecretsField';

const CloudAzureFields = ({ register, control, errors, watch, setValue }: any) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Client ID',
            name: `clientId`,
            required: true,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Client Secret',
            name: `clientSecret`,
            required: true,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Tenant ID',
            name: `tenantId`,
            required: true,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Subscription ID',
            name: `subscriptionId`,
            required: true,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
    </>
  );
};

export default CloudAzureFields;
