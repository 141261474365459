import { ChangeEvent, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';

import { FiltersPopper, FiltersPopperProps } from 'components/FiltersPopper';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';

export type StatusFilters = {
  status: MultiFlowStatusEnum | null;
};

export type FiltersProps = {
  onApplyFilters: (filters: StatusFilters) => void;
  onClearFilters: () => void;
} & Pick<FiltersPopperProps, 'anchorEl' | 'handleClose'>;

export function Filters({ anchorEl, onApplyFilters, onClearFilters, handleClose }: FiltersProps) {
  const classes = useGlobalStyles();

  const [status, setStatus] = useState<MultiFlowStatusEnum>(MultiFlowStatusEnum.Success);

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as MultiFlowStatusEnum);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ status });
  };

  const handleClearFilters = () => {
    setStatus(MultiFlowStatusEnum.Success);
    onClearFilters();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <TextField
        name="statusFilter"
        label="Status"
        variant="outlined"
        onChange={handleChangeStatus}
        value={status}
        InputProps={{
          className: classes.input
        }}
        InputLabelProps={{
          shrink: true,
          color: 'secondary'
        }}
        select
        fullWidth>
        <MenuItem value={MultiFlowStatusEnum.Off}>Inactive</MenuItem>
        <MenuItem value={MultiFlowStatusEnum.Success}>Success</MenuItem>
        <MenuItem value={MultiFlowStatusEnum.Pending}>Pending</MenuItem>
        <MenuItem value={MultiFlowStatusEnum.Maintenance}>Maintenance</MenuItem>
        <MenuItem value={MultiFlowStatusEnum.Error}>Error</MenuItem>
      </TextField>
    </FiltersPopper>
  );
}
