import React from 'react';

import { Grid, Icon, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import { TextField } from 'components/Inputs';

const ValidationStringField = ({
  className,
  register,
  disabled,
  name = 'validationString',
  required
}) => {
  return (
    <Grid container spacing={1} className={className}>
      <Grid item xs={11}>
        <TextField
          label="Validation string"
          name={name}
          register={register}
          disabled={disabled}
          required={required}
        />
      </Grid>
      <Grid item sm={1} container alignItems="center">
        <Tooltip title="Will check if the healthcheck response body contains this string. Leave empty to check status code only.">
          <Icon style={{ color: grey[500], margin: 12 }}>help</Icon>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ValidationStringField;
