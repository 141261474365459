import { Box, Icon, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { directus, directusV2 } from 'services/directus';

import useStyles from './styles';

const Loading = () => (
  <Box padding={4} overflow="hidden">
    <LoadingOverlay isBlock isRelative={false} size="3rem" />
  </Box>
);

type ToolbarMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
};

export function ToolbarMenu({ anchorEl, open, handleClose }: ToolbarMenuProps) {
  const classes = useStyles();

  const shouldCmsWithPlugin = useFeatureFlagUnleash('cmsWithPlugin', { queryString: true });

  const { data, isLoading } = useQuery('menu_toolbar', async () => {
    return !shouldCmsWithPlugin
      ? (await directus.items('menu_toolbar').readByQuery({})).data
      : (await directusV2.items('menu_toolbar').readByQuery({})).data;
  });

  const checkURL = (url: string) => {
    // ! The following regex is capturing any sequence that starts with some protocol
    // ! EX: https://example.com, http://example.com, ftp://user:password@ftp.example.com
    const regex = /((\w+:\/\/)[-a-zA-Z0-9@:%_+.~#?&//=;\\]+)/g;

    const match = url.match(regex);

    return Boolean(match);
  };

  const buttonLink = (item: any) =>
    (() => {
      if (checkURL(item.link ?? '')) {
        return {
          pathname: item.link
        };
      }

      return item.link;
    })();

  return (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      open={open}
      onClose={handleClose}
      disableScrollLock
      classes={{ paper: classes.paper, list: classes.list }}>
      {isLoading && <Loading />}
      {!isLoading &&
        data?.map(item => (
          <MenuItem
            className={clsx(
              classes.listItem,
              item.link === window.location.pathname && classes.listItemActive
            )}
            {...{
              target: checkURL(item.link ?? '') ? '_blank' : '_self',
              component: NavLink,
              to: buttonLink(item),
              rel: 'noopener noreferrer'
            }}>
            {item.icon && <Icon>{item.icon}</Icon>}
            {item.title}
          </MenuItem>
        ))}
    </Menu>
  );
}
