import { AlertCenterType } from 'types/external/AlertCenter';
import { useQuery } from 'urql';

type UseAlertCenterParams = {
  page: number;
  perPage: number;
  query?: string;
  aggregationKeys: string;
  aggregationRefs?: string;
  aggregationValues?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
  pause?: boolean;
};

type UseAlertCenterData = {
  data: AlertCenterType[];
  total: number;
};

const GetAlertsQuery = `
    query(
      $from: Int!
      $size: Int!
      $query: String!
      $aggregationRefs: String
      $aggregationKeys: String!
      $aggregationValues: String
      $termKey: String
      $termValue: [String!]
      $betweenKey: String
      $betweenValues: String
    ) {
        alertList(
          from: $from
          size: $size
          query: $query
          aggregationRefs: $aggregationRefs
          aggregationKeys: $aggregationKeys
          aggregationValues: $aggregationValues
          termKey: $termKey
          termValue: $termValue
          betweenKey: $betweenKey
          betweenValues: $betweenValues
        ) {
            data {
                id
                createdAt
                updatedAt
                deletedAt
                uid
                orgUid
                ruleId
                title
                cause
                origin
                severity
                status
                assignedId
                entityUid
                entity
                externalAggregateKey
                integrationUid
            }
            total
        }
    }
`;

type Variables = {
  from: number;
  size: number;
  query?: string;
  aggregationKeys: string;
  aggregationRefs?: string;
  aggregationValues?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
};

export function useAlerts({
  page,
  perPage,
  query,
  aggregationKeys,
  aggregationRefs,
  aggregationValues,
  termKey,
  termValue,
  betweenKey,
  betweenValues,
  pause = false
}: UseAlertCenterParams) {
  const from = Math.max(page - 1, 0) * perPage;

  const [{ fetching, data }, reexecuteQuery] = useQuery<
    { alertList: UseAlertCenterData },
    Variables
  >({
    query: GetAlertsQuery,
    variables: {
      from,
      size: perPage,
      query,
      aggregationKeys,
      aggregationRefs,
      aggregationValues,
      termKey,
      termValue,
      betweenKey,
      betweenValues
    },
    pause
  });

  return {
    fetching,
    data: { alerts: data?.alertList?.data || [], total: data?.alertList?.total },
    reexecuteQuery
  };
}
