import { MetabaseDashboards } from 'constants/metabaseDashboards';

import dayjs from 'dayjs';

import MetabaseDashboard from 'components/MetabaseDashboard';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

function Resources() {
  const queryParams = useQueryParams();

  const useTabDoraMetrics = useFeatureFlagUnleash('tabDoraMetrics', {
    queryString: true
  });

  const useTabRealTimeMonitoring = useFeatureFlagUnleash('tabRealTimeMonitoring', {
    queryString: true
  });

  return (
    <MetabaseDashboard
      title="Insights"
      subtitle="Insights dashboards with their metrics"
      tabs={[
        {
          label: 'Incidents',
          slug: 'incidents',
          dashboardName: MetabaseDashboards.InsightsIncidents,
          pageSubtitle:
            'Get insights into all incidents occurring within your organization, with metrics calculated up to yesterday.'
        },
        {
          label: 'Responders',
          slug: 'responders',
          dashboardName: MetabaseDashboards.InsightsResponders,
          pageSubtitle:
            'In-depth insights into the impact of incidents on responders, with metrics calculated up to yesterday.'
        },
        {
          label: 'Monitoring Performance',
          slug: 'monitoring-performance',
          dashboardName: MetabaseDashboards.InsightsMonitoringPerformance,
          dashboardParams: {
            start_date: dayjs()
              .subtract(1, 'day')
              .format('YYYY-MM-DD'),
            end_date: dayjs().format('YYYY-MM-DD'),
            resource: queryParams.get('resource') ?? ''
          },
          pageSubtitle:
            'Insights to track the performance of your organization`s monitoring, with real-time and long-term data.'
        },
        {
          label: 'DORA Metrics',
          slug: 'dora-metrics',
          dashboardName: MetabaseDashboards.InsightsDoraMetrics,
          hide: !useTabDoraMetrics,
          pageSubtitle:
            "Track your team's performance with the four DORA Metrics, calculated up to yesterday."
        },
        {
          label: 'Business Impact',
          slug: 'business-impact',
          dashboardName: MetabaseDashboards.InsightsBusinessImpact,
          pageSubtitle: 'Analysis of the financial impact caused by incidents on systems. '
        },
        {
          label: 'Real-Time Explorer',
          slug: 'real-time-monitoring',
          dashboardName: MetabaseDashboards.InsightsRealTimeMonitoring,
          hide: !useTabRealTimeMonitoring,
          dashboardParams: {
            single_date: dayjs().format('YYYY-MM-DD')
          },
          pageSubtitle:
            'Learn more details, in real-time, about the hit and failure events of the agents installed in your environments.'
        }
      ]}
    />
  );
}

export default Resources;
