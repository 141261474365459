import React from 'react';

import { Box, Divider, Typography, IconButton, List, ListItem, Collapse } from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  BorderColor as BorderColorIcon,
  ChevronRight as ChevronRightIcon
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ButtonSimple } from 'componentsV4/Button';
import ChangeOrg from 'helpers/changeOrg';
import SidebarList from 'layouts/V4/components/Sidebar/components/SidebarList';
import { withThemeV4 } from 'theme/v4';

import useStyles from './styles';
import { State } from './types';

const Drawer = () => {
  const classes = useStyles();

  const user = useSelector((state: State) => state.user);

  const [open, setOpen] = React.useState(false);

  const { options, handleLogout, handleChangeOrg } = ChangeOrg();

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Box pt={1} pb={2}>
      <Box>
        <SidebarList />
      </Box>

      <Divider className={classes.divider} />

      <Box ml={4} maxHeight={45}>
        <Typography variant="subtitle1" className={classes.simpleText}>
          ORGANIZATION
        </Typography>

        <Box display="flex" alignItems="center" mt={-1}>
          <Box flexGrow={1}>
            <Typography variant="h5" className={classes.orgName}>
              {user.logged?.org?.name}
            </Typography>
          </Box>

          <IconButton className={classes.iconButton} onClick={handleToggle}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="nav" disablePadding>
          <Box ml={4}>
            <Divider className={classes.menuOrgDivider} />
          </Box>

          <Box display="block">
            <Box display="flex" alignItems="center" ml={4}>
              <Box flexGrow={1}>
                <Typography variant="body2">Configure Org</Typography>
              </Box>

              <IconButton
                component={Link}
                to="/organization"
                title="configure org"
                className={classes.iconButton}>
                <BorderColorIcon fontSize="small" />
              </IconButton>
            </Box>

            <Box mt={2} ml={4}>
              <Typography variant="subtitle1">CHANGE ORGANIZATION</Typography>
            </Box>

            <List component="nav">
              {options?.map(option => (
                <ListItem
                  key={option.props.value}
                  button
                  onClick={() => handleChangeOrg(option.props.value)}>
                  <Box flexGrow={1} mb={1} className={classes.item}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Typography variant="body2" className={classes.menuOrgName}>
                        {option.props.children}
                      </Typography>
                    </Box>

                    <ChevronRightIcon color="primary" />
                  </Box>
                </ListItem>
              ))}
              <Box display="flex" justifyContent="center" mt={2}>
                <Link to="/new_org">
                  <ButtonSimple
                    text="New organization"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                  />
                </Link>
              </Box>
            </List>
          </Box>
        </List>
      </Collapse>

      <Divider className={classes.divider} />

      <Box display="flex" alignItems="center" ml={4}>
        <Box display="block" flexGrow={1}>
          <Typography variant="h5" className={classes.simpleText}>
            {user.displayName}
          </Typography>

          <Box className={classes.email}>
            <Typography variant="caption" className={classes.simpleText}>
              {user.email}
            </Typography>
          </Box>
        </Box>

        <IconButton title="sign out" className={classes.iconButton} onClick={handleLogout}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export const SidebarDrawer = withThemeV4(Drawer);
