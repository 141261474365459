import React from 'react';

import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Dialog } from 'design-system';

import { ButtonSimple } from 'componentsV4/Button';
import useQueryParams from 'helpers/useQueryParams';
import { useProductPermission } from 'hooks/useProductPermission';
import withTheme from 'theme/v2/withTheme';

import CardsList from './CardsList';

const NewResourcesDialog = ({ productId }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const newResourceQueryParams = useQueryParams().get('newresource') === '';

  React.useEffect(() => {
    if (newResourceQueryParams) {
      setOpen(true);
    }
  }, [newResourceQueryParams, setOpen]);

  const permissions = {
    new: useProductPermission({
      productId,
      target: `ApplicationController-post-/products/:productId/applications`
    })
  };

  return (
    <>
      <ButtonSimple
        onClick={handleOpen}
        color="primary"
        variant="contained"
        disabled={!permissions.new}
        id="newResource"
        text="New Resource"
        style={{
          width: 'initial'
        }}
      />
      <Dialog open={open} onClose={handleClose} title="New Resource" maxWidth="md" fullWidth>
        <Typography color={theme.palette.text.primary} gutterBottom>
          Select the type of resource
        </Typography>
        <CardsList productId={productId} />
      </Dialog>
    </>
  );
};

export default withTheme(NewResourcesDialog);
