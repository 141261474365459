import React, { useState } from 'react';

import { makeStyles, Box, SvgIcon, CircularProgress } from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SimpleButton from 'componentsV4/Button/SimpleButton';
import actions from 'redux/actions';
import axios from 'redux/axios';

const useStyles = makeStyles(theme => ({
  signInButton: {
    margin: '16px 0px!important'
  },
  loadingContainer: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(255 255 255 / 66%)',
    zIndex: 1000
  }
}));

const GoogleOAuth = ({ text }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [googleError, setGoogleError] = useState(false);

  const showError = () => {
    dispatch({
      type: actions.ENTITY_ERROR,
      payload: new Error("Cookies are disabled, can't login with Google")
    });
  };

  const [googleOAuthValidate, { isLoading }] = useMutation(
    data => axios.post('/auth/googleLogin', data).then(response => response.data),
    {
      onSuccess: data => {
        dispatch({
          type: actions.LOGGED_USER,
          data
        });
        history.push('/');
      }
    }
  );

  const responseGoogle = response => {
    const tokenId = response.tokenId;
    googleOAuthValidate({
      tokenId
    });
  };
  const renderButton = renderProps => (
    <SimpleButton
      variant="outlined"
      color="secondary"
      className={classes.signInButton}
      startIcon={<SvgGoogleIcon />}
      fullWidth
      size="large"
      type="submit"
      onClick={googleError ? showError : renderProps.onClick}>
      {text}
    </SimpleButton>
  );

  return (
    <>
      <GoogleLogin
        clientId={process.env.REACT_APP_DEV_GOOGLE_LOGIN_CLIENT_ID}
        render={renderProps => renderButton(renderProps)}
        onSuccess={responseGoogle}
        onFailure={response => {
          if (response.error === 'popup_closed_by_user') {
            return;
          }
          if (response.error === 'idpiframe_initialization_failed') {
            setGoogleError(true);

            return;
          }
          responseGoogle(response);
        }}
        cookiePolicy={'single_host_origin'}
      />
      {isLoading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const SvgGoogleIcon = () => {
  return (
    <SvgIcon viewBox="-3 -3 24 24">
      <g fill="#000" fillRule="evenodd">
        <path
          d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
          fill="#EA4335"
        />
        <path
          d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
          fill="#4285F4"
        />
        <path
          d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
          fill="#FBBC05"
        />
        <path
          d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
          fill="#34A853"
        />
        <path fill="none" d="M0 0h18v18H0z" />
      </g>
    </SvgIcon>
  );
};

export default GoogleOAuth;
