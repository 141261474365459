import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { MultiFlowList } from 'views/MultiFlow/List';
import { MultiFlowMonitoring } from 'views/MultiFlow/View';

const RouteV4WithMainLayout = MainLayoutV4;

export const multiFlowRoutes = [
  <AuthorizedRoute
    component={MultiFlowList}
    exact
    layout={RouteV4WithMainLayout}
    path="/synthetic"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={MultiFlowMonitoring}
    exact
    layout={RouteV4WithMainLayout}
    path="/synthetic/:multiFlowUid"
    planFeature="Monitoring"
  />
];
