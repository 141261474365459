import { useQuery as useQueryUrql } from 'urql';

import { getProviders } from '../query';

const UseProvidersFromMsTeam = ({ teamId, orgUid }: { teamId: string; orgUid: string }) => {
  const [resultQuery, reexecuteQuery] = useQueryUrql({
    query: getProviders,
    variables: { orgUid: orgUid, teamId: Number(teamId) }
  });

  return { ...resultQuery, reexecuteQuery };
};

export default UseProvidersFromMsTeam;
