import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud';

import schema from '../components/schema';

import MenuItemsEditForm from './MenuItemsEditForm';

class MenuItemsEdit extends React.Component {
  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { menuItems_edit: formState, menuItems, onChange } = this.props;

    return <MenuItemsEditForm formState={formState} menuItems={menuItems} onChange={onChange} />;
  }
}

export default connect(
  ({ menuItems_edit, menuItems: { data: menuItems } }) => ({
    menuItems_edit,
    menuItems
  }),
  dispatch => ({
    fetch: id => {
      dispatch({
        type: actions.EDIT_ENTITY,
        meta: {
          entity: 'menuItems',
          id: id
        }
      });
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'menuItems',
          pagination: false
        }
      });
    },
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'menuItems_edit'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'menuItems_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(MenuItemsEdit);
