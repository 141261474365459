import { makeStyles } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';
import { SeverityStatusEnum } from 'types/external/Severity';

import { Theme } from 'theme/v4';

type TypeChipStyleProps = {
  event: EventType;
};

function getBackgroundColorBySeverity(severity: SeverityStatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [SeverityStatusEnum.NotClassified]: palette.elvenGray[500],
    [SeverityStatusEnum.Critical]: palette.elvenRed[500],
    [SeverityStatusEnum.High]: palette.elvenOrange[400],
    [SeverityStatusEnum.Moderate]: palette.elvenYellow[400],
    [SeverityStatusEnum.Low]: palette.elvenGreen[500],
    [SeverityStatusEnum.Informational]: palette.elvenBlue[400]
  };

  return statusColorMap[severity];
}

function getBackgroundColorChip(event: EventType, palette: Theme['palette']) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: getBackgroundColorBySeverity(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified,
      palette
    ),
    [EventTypeEnum.Acknowledged]: getBackgroundColorBySeverity(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified,
      palette
    ),
    [EventTypeEnum.Resolved]: getBackgroundColorBySeverity(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified,
      palette
    ),
    [EventTypeEnum.PostMortem]: palette.elvenGray[500],
    [EventTypeEnum.Deploy]: palette.elvenBlue[400],
    [EventTypeEnum.HotFix]: palette.elvenOrange[400],
    [EventTypeEnum.Rollback]: palette.elvenRed[500],
    [EventTypeEnum.IncidentStatusUpdate]: palette.elvenGray[500]
  };

  return labelMap[event?.type];
}

export const useStyles = makeStyles<Theme, TypeChipStyleProps>(theme => ({
  ellipse: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.elvenGray[700],
    borderRadius: '50%'
  },
  icon: {
    color: theme.palette.elvenGray[700],
    marginRight: theme.spacing(1)
  },
  chip: {
    color: 'white',
    borderColor: props => getBackgroundColorChip(props.event, theme.palette),
    backgroundColor: props => getBackgroundColorChip(props.event, theme.palette),
    marginRight: theme.spacing(1)
  }
}));
