import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { push } from 'connected-react-router';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation as useMutationURQL, useQuery as useQueryFromURQL } from 'urql';

import { usePermission } from 'hooks/usePermission';
import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

import DataGridFromTeams from '../../../../../components/Crud/DataGrid/v2/DataGridProductsUseMSTeams';
import { queryDeleteRelationshipWithTeams, queryGetResource } from '../queries';
import SearchInput from '../Toolbar/searchInput';

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  }
}));

const createResourceTeamMutation = `#graphql
mutation ($teamIds: Float!, $resourceId: Float!){
  createResourceTeam(
    teamIds: [$teamIds],
    resourceId: $resourceId,
    resourceType: "Product"
  ){
    teamId
    resourceId
    resourceType
  }
}
`;

const List = ({ cols, entity, view }) => {
  const [, addResourceAndTeam] = useMutationURQL(createResourceTeamMutation);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState('resourceId,ASC');
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  const { id: teamId } = useParams();

  const [{ data, fetching }, reexecuteQuery] = useQueryFromURQL({
    query: queryGetResource,
    variables: {
      page,
      per_page: perPage,
      sort,
      query: '',
      teamId: Number(teamId)
    }
  });

  const dispatch = useDispatch();

  const searchTextKey = ['products', searchText];

  const products = useQuery(searchTextKey, () =>
    axios
      .get(`/products`, {
        params: {
          sort: 'name',
          q: searchText,
          page: '',
          per_page: ''
        }
      })
      .then(response => response.data)
  );

  const autoCompleteHandleChange = searchText => {
    setSearchText(searchText);
  };

  const [addHandler] = useMutation(
    async value => {
      setIsLoading(true);
      await addResourceAndTeam({ teamIds: Number(teamId), resourceId: Number(value) });
      setIsLoading(false);
    },
    {
      onSuccess: async () => {
        reexecuteQuery();

        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Product successfully associated with team'
        });
        setSearchText('');
        setIsLoading(false);
      },
      onError: error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: error,
          ga: { category: 'ERROR' }
        });
        setSearchText('');
        setIsLoading(false);
      }
    }
  );

  const permissions = {
    update: usePermission('TeamController-put-/teams/:teamId/members/:memberId'),
    remove: usePermission('TeamController-delete-/teams/members/:id'),
    unlinkProduct: useProductPermission({
      productId: String(view.id),
      target: 'ProductController-delete-/products/:id/teams/:teamId'
    })
  };

  if (isLoading) {
    return (
      <CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
    );
  }

  return (
    <div>
      <div className={classes.row}>
        <SearchInput
          entity={entity}
          placeholder="Associate product with this team"
          view={view}
          addHandler={addHandler}
          products={products}
          autoCompleteHandleChange={autoCompleteHandleChange}
          setOpen={setOpen}
          open={open}
          searchText={searchText}
        />
      </div>
      <DataGridFromTeams
        fetchQuery={queryGetResource}
        deleteQuery={queryDeleteRelationshipWithTeams}
        query={''}
        cols={cols}
        sortProp="resourceId,ASC"
        permissions={{ remove: permissions.unlinkProduct, update: true, new: true }}
        params={{ teamId: Number(teamId) }}
        argsDelete={['id']}
        deleteParams={{ teamId: Number(teamId), resourceType: 'Product' }}
        editPath="products"
        entity={entity}
        page={page}
        perPage={perPage}
        sort={sort}
        resultQuery={{
          data: Object.values(data || { data: [] })[0]?.data,
          total: Object.values(data || { total: 0 })[0]?.total,
          isFetching: fetching
        }}
        refetchResult={reexecuteQuery}
        setPage={setPage}
        setPerPage={setPerPage}
        setSort={setSort}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchEntity: entity =>
    dispatch({
      type: actions.TEAMS_FETCH_ENTITY,
      meta: {
        entity
      }
    }),
  editHandler: route => dispatch(push(route))
});

const mapStateToProps = ({ teams_view, user }) => {
  return {
    teams_view: teams_view
  };
};

const ConnectedList = connect(mapStateToProps)(List, mapDispatchToProps);

export default ConnectedList;
