import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'components/MetabaseDashboard';

interface ResourcesProps {
  uid: string;
  entity: string;
}

function getParamsData() {
  const data = new Date();

  const year = data.getFullYear();
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const day = String(data.getDate()).padStart(2, '0');
  const formatedData = `${year}-${month}-${day}`;

  const hourEnd = data.getHours();
  const hourIni = hourEnd - 1;

  return {
    data: formatedData,
    hourEnd: hourEnd,
    hourIni: hourIni
  };
}

function Latencies(props: ResourcesProps) {
  const uid = props.uid;
  const entity = props.entity;

  const dashboardName = MetabaseDashboards.RealtimeLatenciesResources;

  const filters = getParamsData();

  const hideParams = ['type', 'uid', 'entity'];

  const params = {
    uid: uid,
    entity: entity,
    single_date: filters['data'],
    hour: [filters['hourIni'], filters['hourEnd']]
  };

  return (
    <MetabaseDashboard
      title="Latencies checks"
      subtitle="List of checks"
      dashboardName={dashboardName}
      dashboardParams={params}
      header={false}
      hideParams={hideParams}
      iframeHeight={500}
      iframePadding={false}
      iframeRefresh={60}
      iframeTitle={false}
    />
  );
}

export default Latencies;
