import { Grid } from '@material-ui/core';

import FailuresToIncident from 'components/MonitoringInputs/FailuresToIncident';
import IntervalField from 'components/MonitoringInputs/IntervalField';
import TimeoutField from 'components/MonitoringInputs/TimeoutField';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import OpenIncidentField from './OpenIncidentField';

const InputsGroup = ({ className = '', register, disabled, errors, control }) => {
  const useSeverityOnApplication = useFeatureFlagUnleash('chooseSeverityOnApplication', {
    queryString: true
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={!useSeverityOnApplication ? 4 : 2}>
        <IntervalField
          className={className}
          register={register}
          errors={errors}
          disabled={disabled}
          name="check_interval"
        />
      </Grid>
      <Grid item xs={!useSeverityOnApplication ? 4 : 2}>
        <TimeoutField
          className={className}
          register={register}
          errors={errors}
          disabled={disabled}
          name="timeout"
        />
      </Grid>
      <Grid item xs={4}>
        <FailuresToIncident
          className={className}
          register={register}
          errors={errors}
          disabled={disabled}
          name="failuresToIncident"
        />
      </Grid>
      {useSeverityOnApplication && (
        <Grid item xs={4}>
          <OpenIncidentField
            className={className}
            register={register}
            control={control}
            disabled={disabled}
            errors={errors}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InputsGroup;
