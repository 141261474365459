import { useEffect } from 'react';

import { Box, Link } from '@material-ui/core';
import slugify from 'slugify';
import { Service, ServiceItem } from 'types/cms/ServicesHub';

import { Typography } from 'components/Typography';
import { getFeatureFlagServiceItem } from 'helpers/getFeatureFlagServiceItem';

import useStyles from '../styles';

import { ServiceHubCard } from './Card';

type ComponentProps = {
  services: Service[];
};

export const ServicesHubList = ({ services }: ComponentProps) => {
  const classes = useStyles();

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const targetElement = document.querySelector('#' + slugify(hash, { lower: true }));

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const filterItem = (item: ServiceItem, featureFlag: boolean): boolean => {
    if (!item.hidden && (!item.featureFlag || featureFlag)) {
      return true;
    }

    return false;
  };

  const featureFlags = getFeatureFlagServiceItem(services);

  const filteredServices = services
    .filter(service => !service.hidden)
    .map((service, key) => {
      const filteredItems = service.items
        ? service.items.filter((item, itemKey) => filterItem(item, featureFlags[key][itemKey]))
        : [];

      return {
        ...service,
        items: filteredItems
      };
    })
    .filter(service => service.items.length > 0);

  return (
    <>
      {filteredServices.map((service, key) => (
        <div key={`${key} ${service.title}`}>
          <Link
            id={`${slugify(service.title, { lower: true })}`}
            key={service.title}
            underline="none">
            <Typography className={classes.ServiceTitle} variant="body1">
              {service.title}
            </Typography>
          </Link>

          <Box className={classes.boxServiceCard}>
            {service.items &&
              service.items.map((item, key) => (
                <ServiceHubCard
                  key={`${key} ${service.title}`}
                  serviceTitle={service.title}
                  item={item}
                />
              ))}
          </Box>
        </div>
      ))}
    </>
  );
};
