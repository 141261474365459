import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import DataGrid from '../../../components/Crud/DataGrid/v1';
import Toolbar from '../../../components/Crud/Toolbar';

import columns from './columns';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
});

class MenuItemsList extends React.Component {
  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        <Toolbar
          buttonNewName="New"
          entity="menuItems"
          history={history}
          searchPlaceholder="Search menu items"
        />
        <div className={classes.content}>
          <DataGrid cols={columns} entity="menuItems" />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(MenuItemsList);
