import { Box, CardContent, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useImpactedOrigins } from 'hooks/queriesGraphQL/useImpactedOrigins';

import { useStyles } from './styles';

type ImpactedOriginsProps = {
  incidentId: number;
};

type Origin = {
  uid: string;
  name: string;
  origin: string;
};

function ImpactedOrigins({ incidentId }: ImpactedOriginsProps) {
  const classes = useStyles();
  const theme = useTheme();

  const { data: ImpactedOrigins, isLoading } = useImpactedOrigins(incidentId);

  const hasData = ImpactedOrigins.length > 0;

  const getLinkTo = (origin: Origin): string => {
    return origin.origin === 'resource'
      ? `/monitoring/${origin.uid}`
      : origin.origin === 'multiflow'
      ? `/synthetic/${origin.uid}`
      : origin.origin === 'integration'
      ? `/external-services`
      : '#';
  };

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} color="secondary" variant="h6">
          Impacted origins
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />
        <Box
          className={clsx(classes.scrollBar, !hasData && classes.noData)}
          position="relative"
          gridGap={theme.spacing()}>
          {ImpactedOrigins.map(origin => (
            <Box display="flex" gridGap={theme.spacing()}>
              <Typography variant="h6" style={{ lineHeight: 'inherit' }}>
                {origin.name}
                <Typography display="block" variant="caption" color="primary">
                  {origin.origin}
                </Typography>
              </Typography>
              <Link to={getLinkTo(origin)} className={classes.link}>
                <Launch />
              </Link>
            </Box>
          ))}

          {!hasData && !isLoading && (
            <Typography color="textPrimary" variant="h6">
              No Linked Impacted Origins
            </Typography>
          )}

          {isLoading && <LoadingOverlay size={theme.spacing(4)} />}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default ImpactedOrigins;
