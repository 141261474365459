import checkTypes from 'constants/checkTypes';

import { MenuItem } from '@material-ui/core';

import { Select } from 'components/Inputs';

const Methods = ({ selectedCheckType, control, errors, disabled }) => {
  if (!selectedCheckType) {
    return (
      <Select control={control} required={true} label="Method" name="method" errors={errors} />
    );
  }

  const checkType = checkTypes.find(method => method.value === selectedCheckType);

  const options = checkType.options.map(method => (
    <MenuItem key={method.value} value={method.value}>
      {method?.label}
    </MenuItem>
  ));

  return (
    <Select
      control={control}
      required={true}
      label="Method"
      name="method"
      errors={errors}
      disabled={disabled}>
      {options}
      {selectedCheckType === 'queue' && (
        <MenuItem key="sidekiq" value="sidekiq">
          Sidekiq
        </MenuItem>
      )}
      {selectedCheckType === 'others' && (
        <MenuItem key="webhook" value="webhook">
          Webhook
        </MenuItem>
      )}
      {selectedCheckType === 'lambda' && [
        <MenuItem key="lambdaaws" value="lambdaaws">
          Lambda (AWS) (Deprecated)
        </MenuItem>,

        <MenuItem key="lambdaawsv2" value="lambdaawsv2">
          Lambda (AWS)
        </MenuItem>
      ]}
      {selectedCheckType === 'lambda' && [
        <MenuItem key="functions" value="functions">
          Functions (GCP)
        </MenuItem>,

        <MenuItem key="functionsgen2" value="functionsgen2">
          Functions (GCP) 2nd Gen
        </MenuItem>
      ]}
      {selectedCheckType === 'cache' && [
        <MenuItem key="redisV2" value="redisV2">
          Redis
        </MenuItem>,

        <MenuItem key="redis" value="redis">
          Redis (Deprecated)
        </MenuItem>
      ]}
      {selectedCheckType === 'others' && (
        <MenuItem key="grpc" value="grpc">
          gRPC
        </MenuItem>
      )}
    </Select>
  );
};

export default Methods;
