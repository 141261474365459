import React from 'react';

import { MenuItem } from '@material-ui/core';

import { Select } from 'components/Inputs';

const OpenIncidentField = ({ className = '', errors, register, control, disabled }) => {
  return (
    <Select
      label="Severity"
      name="openIncidentWithSeverity"
      className={className}
      control={control}
      register={register}
      errors={errors}
      disabled={disabled}>
      <MenuItem value="sev-1-critical">SEV-1 - Critical</MenuItem>
      <MenuItem value="sev-2-high">SEV-2 - High</MenuItem>
      <MenuItem value="sev-3-moderate">SEV-3 - Moderate</MenuItem>
      <MenuItem value="sev-4-low">SEV-4 - Low</MenuItem>
      <MenuItem value="sev-5-informational">SEV-5 - Informational</MenuItem>
      <MenuItem value="not-classified">Not Classified</MenuItem>
    </Select>
  );
};

export default OpenIncidentField;
