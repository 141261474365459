import { useEffect } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import { Service } from 'types/cms/ServicesHub';

import PageContainer from 'components/PageContainer';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useCms } from 'hooks/queriesGraphQL/useCms';

import { useStyles } from '../formStyles';
import { useLayoutFromServiceType } from '../lib/useLayoutFromServiceType';

function ServicesHubNewInner({ servicesDefinitions }: { servicesDefinitions: Service[] }) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const {
    Layout,
    title,
    description,
    breadcrumb,
    baseBreadcrumb,
    backLabel,
    backLink
  } = useLayoutFromServiceType(servicesDefinitions);

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={
          baseBreadcrumb
            ? [
                { label: baseBreadcrumb || 'Services Hub', link: backLink || '/services-hub' },
                { label: breadcrumb }
              ]
            : [
                { label: backLabel || 'Services Hub', link: backLink || '/services-hub' },
                { label: breadcrumb }
              ]
        }
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          New {title}
        </Typography>
      </Box>

      <Typography className={classes.description} variant="body1">
        {description}
      </Typography>

      <Divider className={classes.divider} />

      <Layout />
    </PageContainer>
  );
}

export function ServicesHubNew() {
  const globalClasses = useGlobalStyles();

  const [{ fetching, data }] = useCms({
    page: 'servicesHub'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (fetching) {
    return (
      <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
        <LoadingOverlay />
      </PageContainer>
    );
  }

  return <ServicesHubNewInner servicesDefinitions={data?.cms.content.services} />;
}
