import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DataTable from 'react-data-table-component';

import DeleteEntity from 'components/DeleteEntityDialog';

import columns from './columns';

const DataGrid = ({ data, removeHandler }) => {
  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, entity: null });

  const dataGridTheme = {
    title: {
      height: 0
    }
  };

  const gridColumns = [
    ...columns,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          onClick={() => setDeleteModalState({ isOpen: true, entity: row })}
          title="Remove"
          variant="outlined">
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  return (
    <>
      <DataTable
        clicked
        columns={gridColumns}
        customTheme={dataGridTheme}
        data={data}
        highlightOnHover
        noDataComponent="No data"
        striped
      />

      {deleteModalState.isOpen && (
        <DeleteEntity
          open={deleteModalState.isOpen}
          entityName="Team"
          handleClose={() => setDeleteModalState({ isOpen: false, entity: null })}
          handleRemove={() => {
            const { entity } = deleteModalState;
            removeHandler(entity);
            setDeleteModalState({ isOpen: false, entity: null });
          }}
        />
      )}
    </>
  );
};

export default DataGrid;
