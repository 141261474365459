import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';

import DeleteEntity from 'components/DeleteEntityDialog';
import actions from 'redux/actions';

import useTeams from './useTeams';

const DataGrid = ({ entity, cols, title, mapEntity, permissions, query }) => {
  const dispatch = useDispatch();
  const editHandler = ({ id }) => dispatch(push(`/${entity}/${id}/`));

  const [fetchParams, setFetchParams] = React.useState({
    page: 1,
    sort: 'name,ASC',
    per_page: 10
  });

  const queryKey = ['teams', fetchParams.page, fetchParams.sort, fetchParams.per_page, query];

  const { data, isLoading: isLoadingTeams, refetch: refetchTeams } = useTeams(queryKey, {
    ...fetchParams,
    query
  });

  const removeHandler = payload => {
    dispatch({
      type: actions.DELETE_ENTITY,
      payload,
      meta: {
        entity
      }
    });
    setTimeout(() => {
      refetchTeams();
    }, 100);
  };

  const handlePerRowsChange = per_page =>
    setFetchParams(prevState => ({
      ...prevState,
      per_page
    }));

  const handlePageChange = page =>
    setFetchParams(prevState => ({
      ...prevState,
      page
    }));

  const handleSort = ({ selector }, sortDirection) =>
    setFetchParams(prevState => ({
      ...prevState,
      sort: `${selector},${sortDirection.toUpperCase()}`
    }));

  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, id: null });

  const deleteIcon = (row, permissions) => {
    const button = (
      <IconButton disabled={true}>
        <DeleteIcon />
      </IconButton>
    );

    if (!permissions?.remove) {
      return <div title="You are not allowed to remove">{button}</div>;
    }

    if (row.addons?.length) {
      return <div title="Can't remove because there are addons set for this Service">{button}</div>;
    }

    if (row.products?.length || row.members?.length) {
      return (
        <div title="Can't remove because there are products or members set for this Team">
          {button}
        </div>
      );
    }

    return (
      <IconButton
        title="Remove"
        data-user={row.user?.email}
        data-name={row.name}
        onClick={() => setDeleteModalState({ isOpen: true, id: row })}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const columns = [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          title="Edit"
          data-user={row.user?.email}
          data-name={row?.name}
          data-isactive={row?.isActive}
          onClick={event => {
            event.preventDefault();
            const dto = mapEntity ? mapEntity(row) : row;
            editHandler(dto);
          }}
          variant="outlined"
          disabled={permissions && !permissions.update}>
          <EditIcon />
        </IconButton>
      )
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => deleteIcon(row, permissions)
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false
  };

  const dataGridTheme = {
    tableWrapper: {
      style: {
        display: 'block'
      }
    },
    title: {
      height: title ? 56 : 0
    }
  };

  return data?.data?.length || isLoadingTeams ? (
    <>
      <DataTable
        clicked
        columns={columns}
        customTheme={dataGridTheme}
        data={data?.data}
        highlightOnHover
        noDataComponent="No data"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={data?.total || 0}
        sortServer
        striped
        title={title}
      />
      <DeleteEntity
        open={deleteModalState.isOpen}
        handleClose={() => setDeleteModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          removeHandler(deleteModalState.id);
          setDeleteModalState({ isOpen: false, id: null });
        }}
        entityName="Team"
        disabled={!permissions?.remove}
      />
    </>
  ) : null;
};

export default DataGrid;
