import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import EnvironmentsEditView from 'views/Environments/Edit';
import EnvironmentsListView from 'views/Environments/List';
import EnvironmentsNewView from 'views/Environments/New';
import EnvironmentsView from 'views/Environments/View';

export const environmentsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={EnvironmentsListView}
    exact
    layout={MainLayoutV4}
    key="/environments"
    path="/environments"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={EnvironmentsNewView}
    exact
    layout={MainLayoutV4}
    key="/environments/new"
    path="/environments/new"
    planFeature="Monitoring"
    featureTarget="EnvironmentController-post-/environments"
  />,
  <AuthorizedRoute
    component={EnvironmentsEditView}
    exact
    layout={MainLayoutV4}
    key="/environments/:id/edit"
    path="/environments/:id/edit"
    planFeature="Monitoring"
    featureTarget="EnvironmentController-put-/environments/:id"
  />,
  <AuthorizedRoute
    component={EnvironmentsView}
    exact
    layout={MainLayoutV4}
    key="/environments/:id"
    path="/environments/:id"
    planFeature="Monitoring"
  />
];
