import React, { useEffect } from 'react';

import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Box, CircularProgress, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery as useQueryUrql } from 'urql';

import {
  EnvironmentSelect,
  KeyValueFields,
  RadioButtons,
  RawJsonField,
  Select,
  TextField
} from 'components/Inputs';
import InputsGroup from 'components/MonitoringInputs/InputsGroup';
import ValidationStringField from 'components/MonitoringInputs/ValidationStringField';
import SkipSSLValidation from 'components/ResourceInputs/SkipSSLValidation';
import SecretsField from 'components/SecretsField';
import { ButtonSimple } from 'componentsV4/Button';
import { useMsTeamsQuery } from 'views/V3/Products/View/Product/TeamsAssign';

import { getTeamsByResourceId } from '../../../../View/queries/getTeamsByResourceId';

import { setOneTeamForResource } from './../queries';
import useForm from './useForm';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  },
  invisible: {
    display: 'none'
  }
}));

const defaultValues = {
  environmentId: '',
  healthcheckUrl: '',
  method: 'GET',
  headers: [{ key: '', value: '' }],
  post_body_default: [{ key: '', value: '' }],
  post_body_urlencoded: [{ key: '', value: '' }],
  post_body_raw: '',
  tls_renegotiation: 0,
  post_body_type: postBodyTypeValues.raw,
  post_body_raw_type: postBodyRawTypeValues.json,
  openIncidentWithSeverity: 'not-classified'
};

const MonitoringForm = ({
  onClose,
  startValues,
  teamId,
  submitMutation,
  errorUseMutation,
  application,
  isLoading
}) => {
  const classes = useStyles();

  const { applicationId } = useParams();

  const [getQueryFromMsTeams, reexecuteQueryFromMsTeams] = useQueryUrql({
    query: getTeamsByResourceId,
    variables: {
      resourceId: Number(applicationId)
    }
  });

  const team = {
    name: getQueryFromMsTeams?.data?.getTeamsByResourceId[0]?.name,
    id: getQueryFromMsTeams?.data?.getTeamsByResourceId[0]?.resourceTeams[0]?.teamId
  };

  const {
    control,
    handleSubmit,
    register,
    errors,
    watch,
    setValue,
    headersFields,
    headersAppend,
    headersRemove,
    postBodyXFormUrlEncodedFields,
    postBodyXFormUrlEncodedAppend,
    postBodyXFormUrlEncodedRemove,
    formState: { isValid }
  } = useForm({
    defaultValues: startValues
      ? {
          ...startValues,
          teamId: team?.id || null,
          post_body_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded
              ? startValues.post_body_type
              : postBodyTypeValues.raw,
          post_body_raw_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded ||
            !startValues.post_body_type
              ? postBodyRawTypeValues.json
              : startValues.post_body_type,
          tls_renegotiation:
            startValues.domain_settings !== '' && startValues.domain_settings === undefined
              ? 0
              : JSON.parse(startValues?.domain_settings)?.tls_renegotiation
        }
      : defaultValues
  });
  const [, addResource] = useMutation(setOneTeamForResource);

  const useQuery = useMsTeamsQuery;

  const [teamsQueryResult] = useQuery();

  const onSubmit = async data => {
    if (isLoading) {
      return;
    }
    const {
      data: { data: application }
    } = await submitMutation(data);

    await addResource({
      teamId: Number(data?.teamId),
      resourceId: Number(application.id)
    });
  };
  const selectedMethod = watch('method');
  const selectedPostBodyType = watch('post_body_type');

  const resultData =
    teamsQueryResult?.data?.teams?.data?.map(team => ({
      label: team.name,
      value: String(team.id)
    })) || [];

  useEffect(() => {
    reexecuteQueryFromMsTeams();
  }, [applicationId, reexecuteQueryFromMsTeams]);

  const postBodyField = (
    <React.Fragment>
      <Grid item xs={10}>
        <RawJsonField
          className={clsx(
            classes.formControl,
            selectedPostBodyType === postBodyTypeValues.urlencoded ? classes.invisible : null
          )}
          register={register}
          errors={errors}
          name="post_body_raw"
          label="Post body"
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValueFields
          className={clsx(
            classes.formControl,
            selectedPostBodyType === postBodyTypeValues.raw ? classes.invisible : null
          )}
          name="post_body_urlencoded"
          label="Post body"
          register={register}
          fields={postBodyXFormUrlEncodedFields}
          fieldAppend={postBodyXFormUrlEncodedAppend}
          fieldRemove={postBodyXFormUrlEncodedRemove}
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
    </React.Fragment>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EnvironmentSelect className={classes.formControl} errors={errors} control={control} />

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              className={classes.formControl}
              errors={errors}
              register={register}
              label="Application Name"
              name="name"
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              className={classes.formControl}
              label="Team"
              name="teamId"
              control={control}
              setValue={setValue}
              watch={watch}>
              <MenuItem value={null}>No Team</MenuItem>
              {resultData.map(team => (
                <MenuItem value={`${team.value}`}>{team.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Box display="flex">
              <SecretsField
                TextFieldProps={{
                  label: 'Healthcheck URL',
                  name: 'healthcheckUrl',
                  required: true
                }}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Select className={classes.formControl} label="Method" name="method" control={control}>
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
            </Select>
          </Grid>
          <Grid style={{ marginTop: '7px' }} item xs={3}>
            <SkipSSLValidation
              register={register}
              defaultChecked={startValues?.skip_ssl_validation}
            />
          </Grid>
          <Grid item md={2}>
            <Select
              errors={errors}
              name="tls_renegotiation"
              control={control}
              label="TLS Renegotiation">
              <MenuItem value={0}>Never</MenuItem>
              <MenuItem value={1}>One Time</MenuItem>
              <MenuItem value={2}>Always</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <KeyValueFields
          className={classes.formControl}
          defaultValues={startValues?.headers || defaultValues.headers}
          name="headers"
          label="Header"
          register={register}
          fields={headersFields}
          fieldAppend={headersAppend}
          fieldRemove={headersRemove}
          control={control}
          watch={watch}
          setValue={setValue}
        />

        {selectedMethod === 'POST' && (
          <Box mb={3} display="flex" alignItems="flex-end">
            <RadioButtons
              label="Post-body type"
              name="post_body_type"
              control={control}
              options={[
                { value: postBodyTypeValues.raw, label: 'raw' },
                { value: postBodyTypeValues.urlencoded, label: postBodyTypeValues.urlencoded }
              ]}
            />
            {selectedPostBodyType === postBodyTypeValues.raw && (
              <Box ml={2} clone>
                <Select
                  style={{ minWidth: 120 }}
                  label="Raw Type"
                  name="post_body_raw_type"
                  control={control}
                  fullWidth={false}>
                  <MenuItem value={postBodyRawTypeValues.json}>JSON</MenuItem>
                  <MenuItem value={postBodyRawTypeValues.text}>Text</MenuItem>
                  <MenuItem value={postBodyRawTypeValues.javaScript}>JavaScript</MenuItem>
                  <MenuItem value={postBodyRawTypeValues.xml}>XML</MenuItem>
                  <MenuItem value={postBodyRawTypeValues.html}>HTML</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
        )}

        {selectedMethod === 'POST' && postBodyField}

        <ValidationStringField
          className={classes.formControl}
          register={register}
          required={false}
        />

        <InputsGroup
          className={classes.formControl}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
        />

        {errorUseMutation && (
          <Typography color="error" gutterBottom>
            {errorUseMutation.response?.data?.message}
          </Typography>
        )}
        <Box display="flex" justifyContent="flex-end">
          <ButtonSimple
            className={classes.cancelButton}
            variant="outlined"
            color="primary"
            onClick={onClose}
            text="Cancel"
          />
          <ButtonSimple
            disableRipple
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            disabled={!isValid}
            text="Save"
          />
        </Box>

        {isLoading && (
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </>
  );
};

export default MonitoringForm;
