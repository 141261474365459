import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Toolbar as ToolbarMui,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ExitToApp as ExitToAppIcon, Menu as MenuIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ImportantUpdates from 'components/ImportantUpdates';
import PlansMessage from 'components/PlansMessage';
import ChangeOrg from 'helpers/changeOrg';
import usePlans from 'hooks/queriesGraphQL/usePlans';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import GlobalMessage from 'layouts/MainV1/components/Topbar/GlobalMessage';
import actions from 'redux/actions';
import { withThemeV4 } from 'theme/v4';

import { CriticalEvents } from './CriticalEvents';
import { CriticalEventsV2 } from './CriticalEvents/V2';
import { ToolbarMenu } from './Menu';
import useStyles from './styles';
import { GlobalMessages, ToolbarProps } from './types';

function ToolbarContainer({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();

  const { orgStatus, showMessage } = usePlans();

  return (
    <AppBar elevation={0} position="sticky" className={`${classes.root} toolbar`}>
      {showMessage && <PlansMessage message={orgStatus?.message} />}
      <ToolbarMui>
        <Box display="flex" justifyContent="space-between" alignItems="center" flex="1">
          <Box>
            <RouterLink to={'/'}>
              <img
                alt="One Platform"
                className={classes.logo}
                src={`/images/logos/elven_logo_horizontal-v2-white.svg`}
              />
            </RouterLink>
          </Box>

          {children}
        </Box>
      </ToolbarMui>
      <ImportantUpdates />
    </AppBar>
  );
}

const Toolbar = ({ onSidebarOpen }: ToolbarProps) => {
  const { data: user } = useUser({});

  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const { globalErrors, globalSuccess, globalWarning } = useSelector(
    (state: GlobalMessages) => state
  );

  const reset = useCallback(() => dispatch({ type: actions.CLEAR_ERROR }), [dispatch]);

  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const { handleLogout } = ChangeOrg();

  const dividerRef = useRef<HTMLDivElement>(null);

  const anchorEl = dividerRef.current;

  const handleToggle = () => {
    setOpen(open => !open);
  };

  const shouldUseCriticalEvents = useFeatureFlagUnleash('toolbarCriticalEvents');
  const shouldUseCriticalEventsAlerts = useFeatureFlagUnleash('useCriticalEventsAlerts');

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <ToolbarContainer />;
  }

  const isOrgBlocked = user?.logged?.org?.signatureStatus === 'blocked';
  const displayName = user?.displayName;
  const orgName = user?.logged?.org?.name;
  const email = user?.email;

  return (
    <ToolbarContainer>
      <Box display="flex" gridGap="2rem" alignItems="center">
        {displayName && email && isDesktop && (
          <>
            {orgName && !isOrgBlocked && (
              <>
                <Box display="flex" alignItems="center" className="toolbarMenu">
                  <Box>
                    <Typography variant="caption" style={{ fontWeight: 300 }}>
                      ORGANIZATION
                    </Typography>
                    <Typography variant="h5" className={classes.orgName}>
                      {orgName}
                    </Typography>
                  </Box>
                  <ToolbarMenu anchorEl={anchorEl} handleClose={() => setOpen(false)} open={open} />
                </Box>

                {shouldUseCriticalEvents && (
                  <>
                    <Divider orientation="vertical" flexItem className={classes.divider} />
                    {shouldUseCriticalEventsAlerts ? <CriticalEventsV2 /> : <CriticalEvents />}{' '}
                  </>
                )}

                <Box {...{ ref: dividerRef }}>
                  <Divider orientation="vertical" flexItem className={classes.divider} />
                </Box>
              </>
            )}
            <IconButton title="Open menu" className={classes.iconButton} onClick={handleToggle}>
              <Avatar
                alt={user?.displayName}
                src={user?.photoURL ?? 'without_photo'}
                className={classes.avatar}
              />
            </IconButton>
            <IconButton title="sign out" className={classes.iconButton} onClick={handleLogout}>
              <ExitToAppIcon></ExitToAppIcon>
            </IconButton>
          </>
        )}
        {!isDesktop && (
          <IconButton title="Menu" onClick={onSidebarOpen} className={classes.iconButton}>
            <MenuIcon />
          </IconButton>
        )}
        <GlobalMessage
          globalErrors={globalErrors}
          globalSuccess={globalSuccess}
          globalWarning={globalWarning}
          reset={reset}
        />
      </Box>
    </ToolbarContainer>
  );
};

export default withThemeV4(Toolbar);
