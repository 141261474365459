import { useEffect, useState } from 'react';

import axios from 'redux/axios';

export type Role = {
  id: number;
  name: string;
};

type Response = {
  organizationRoles: Role[] | null;
  defaultRole: Role | null;
  defaultUser: {
    id: string;
    name: string;
    email: string;
    role: number;
    status: string;
  };
};

export const useDefaultUser = (): Response => {
  const [roles, setRoles] = useState<Role[] | null>(null);

  async function getRoles() {
    const {
      data: { data: roles }
    }: any = await axios.get('/roles/org?page=1&per_page=10000&sort=name,ASC');
    setRoles(roles);
    return roles;
  }
  useEffect(() => {
    getRoles();
  }, []);

  const defaultRole = roles?.filter(role => role.name === 'member') || [];

  return {
    organizationRoles: roles,
    defaultRole: defaultRole?.[0],
    defaultUser: {
      id: '',
      name: '',
      email: '',
      role: defaultRole?.[0]?.id,
      status: 'active'
    }
  };
};
