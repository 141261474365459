import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import TimelineItem from 'components/TimelineItem';
import { getDescription } from 'helpers/formatDescription';
import getTimelineDescription from 'helpers/getTimelineDescription';
import { useTimeline } from 'hooks/queriesGraphQL/useTimeline';

import getCreatedAt from '../../View/BottomSection/Timeline/getCreatedAt';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5)
  }
}));

export default function CustomizedTimeline({ productId, applications, environment }) {
  const classes = useStyles();

  const [size, setSize] = React.useState(5);

  const appIds = applications?.map(app => String(app.id));

  const { timeline: timelineData } = useTimeline({
    applicationIds: appIds,
    from: 0,
    size
  });

  if (timelineData?.length > 0) {
    return (
      <div className={classes.root}>
        <Typography variant="h3" gutterBottom>
          Timeline
        </Typography>
        {timelineData?.map(item => {
          const app = applications.find(application => application.id === item.applicationId);
          return (
            <TimelineItem
              description={getTimelineDescription(item)}
              version={item?.content?.version || ''}
              key={`timeline_item_${item.id}`}
              type={item.type}
              createdAt={getCreatedAt(item)}
              incidentId={item.incidentId}
              title={getDescription(
                item.applicationName || item.content?.name || app?.name,
                item.type.toLowerCase()
              )}
              applicationId={item.applicationId}
              environment={environment}
              productId={productId}
            />
          );
        })}
        <Button
          variant="outlined"
          onClick={() => {
            setSize(size + 5);
          }}>
          load more
        </Button>
      </div>
    );
  }

  return null;
}
