import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import Toolbar from 'components/Crud/ToolbarQueryProps';
import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';
import axios from 'redux/axios';
import uploadReducer from 'redux/reducer/upload';

import { AccountProfile, AccountDetails } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const queryCache = useQueryCache();

  const { data: account_edit } = useUser({
    refetchOnWindowFocus: false
  });

  const { upload, user: account } = useSelector(state => state);

  const saveHandler = (file, contentType, name) => {
    dispatch({
      type: actions.UPLOAD_AVATAR,
      meta: { reducer: uploadReducer },
      payload: { file, contentType, name }
    });
  };

  const [saveProfileHandler] = useMutation(
    data => axios.put(`users/${account.id}`, data).then(response => response.data),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('auth/logged');

        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Updated successfully'
        });
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  return (
    <div className={classes.root}>
      <Toolbar title="Profile" noSearch={true} noButton={true} />
      {account_edit && (
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <AccountProfile account={account} saveHandler={saveHandler} upload={upload} />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <AccountDetails account_edit={account_edit} saveHandler={saveProfileHandler} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Account;
