import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import useQueryParams from 'helpers/useQueryParams';
import { withThemeV4 } from 'theme/v4';

function Metrics() {
  const { serviceId } = useParams<{
    serviceId: string;
  }>();

  const queryParams = useQueryParams();

  const name = queryParams.get('name')!;
  const applicationId = queryParams.get('application_id')!;

  const isApplication = Boolean(applicationId);

  return (
    <MetabaseDashboard
      tabs={[
        {
          label: 'System health monitoring',
          slug: 'metrics-uptime',
          dashboardName: MetabaseDashboards.MetricsUptime
        },
        {
          label: 'DORA Metrics',
          slug: 'metrics-dora',
          dashboardName: MetabaseDashboards.MetricsDora,
          hide: !isApplication,
          dashboardParams: {
            application_id: `${applicationId} - ${name}`
          }
        },
        {
          label: 'MTTs graphics',
          slug: 'metrics-mtts',
          dashboardName: MetabaseDashboards.MetricsMtts
        }
      ]}
      title="Metrics details"
      subtitle="Metrics refer"
      dashboardParams={{ service_id: `${serviceId} - ${name}` }}
    />
  );
}

export default withThemeV4(Metrics);
