import { PropsWithChildren, useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import Toolbar from 'layouts/V4/components/Toolbar';
import { withThemeV4 } from 'theme/v4';

import SidebarV4 from '../components/Sidebar';

import { Container } from './Content';

const Main = ({ children }: PropsWithChildren<{}>) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarToggle = () => {
    setOpenSidebar(!openSidebar);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateAreas: '"topbar" "content"',
        gridTemplateRows: 'auto 1fr',
        height: '100%'
      }}>
      <Toolbar onSidebarOpen={handleSidebarToggle} />
      <Box display="flex">
        <SidebarV4
          open={shouldOpenSidebar}
          onClose={handleSidebarToggle}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <Container>{children}</Container>
      </Box>
    </div>
  );
};

export default withThemeV4(Main);
