import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useQuery } from 'urql';

const GetAlertsStateQuery = `#graphql
query alertsState(
  $from: Float!
  $size: Float!
  $sortKey: String
  $sortValue: String
  $query: String
){
  alertsState(
    from: $from
    size: $size 
    sortKey: $sortKey
    sortValue: $sortValue
    query: $query
  ){
    data {
      id
      uid
      title
      status
      severity
      alertHappenedAt
      createdAt
    }
    total
  }
}
`;

export const useAlertsState = ({
  pause = false,
  from = 0,
  size = 500,
  sortKey = 'created_at',
  sortValue = 'DESC',
  query = ''
}: {
  pause?: boolean;
  from?: number;
  size?: number;
  sortKey?: string;
  sortValue?: string;
  query?: string;
}) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery<{
    alertsState: {
      data: {
        id: number;
        uid: string;
        title: string;
        status: StatusEnum;
        severity: SeverityStatusEnum;
        alertHappenedAt: string;
        createdAt: string;
      }[];
      total: number;
    };
  }>({
    query: GetAlertsStateQuery,
    variables: {
      from: Math.max(from - 1, 0) * size,
      size,
      sortKey,
      sortValue,
      query: query
    },
    pause
  });

  return {
    fetching,
    reexecuteQuery,
    data
  };
};
