import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

export const General = ({ org }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <form autoComplete="off" noValidate>
        <CardHeader title="My Organization" titleTypographyProps={{ variant: 'h4' }} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">{org.name}</Typography>

              <Typography variant="subtitle1">{org.email}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </form>
    </Card>
  );
};
