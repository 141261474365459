import { useState, useEffect } from 'react';

import ApplicationType from 'constants/ApplicationType';

import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import MaintenanceMessage from 'components/MaintenanceMessage';
import Title from 'components/Title';
import { useLatencies } from 'hooks/queriesGraphQL/useLatencies';
import useMaintenanceWindow from 'hooks/queriesGraphQL/useMaintenanceWindow';
import useNewAddons from 'hooks/queriesGraphQL/useNewAddons';
import { useServiceStatus } from 'hooks/queriesGraphQL/useServiceStatus';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';
import reducer from 'redux/reducer/availability';

import Timeline from './BottomSection/Timeline';
import MiddleSection from './MiddleSection';
import Toolbar from './MiddleSection/Toolbar';
import useGetApplication from './queries/useGetApplication';
import TopSection from './TopSection';

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  incidentsList: {
    marginTop: theme.spacing(4)
  }
});

const Applications = ({
  fetch,
  reset,
  stopTimer,
  startTimer,
  classes,
  application_availability: data,
  user
}) => {
  const [highlightAddonItem, setHighlightAddonItem] = useState(null);

  const {
    dataFromGraphQL: applicationStatusGraphQL,
    reexecuteQuery: reexecuteQueryUniqueResourceStatus
  } = useServiceStatus({
    useResource: data.application?.useResource,
    resourceUid: data.application?.resourceUid,
    serviceId: Number(data.application?.sterasId),
    applicationId: Number(data.application?.id),
    applicationDependencyId: null,
    addonId: null,
    resourceType: 'Application'
  });

  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });
  const useLatencyByMSFlag = useFeatureFlagUnleash('useLatencyByMS', { queryString: true });

  const shouldUseResource = Boolean(useResourceFlag && data?.application?.useResource);

  const activeResourceStatus = ['available', 'unavailable', 'pending'];

  const monitoringActiveStatus = activeResourceStatus.includes(applicationStatusGraphQL?.status);

  const oldMonitoringActiveStatus = Boolean(
    applicationStatusGraphQL?.monitoringStatus === 'Stale' ||
      applicationStatusGraphQL?.isMonitoringActive
  );

  const monitoringActive = shouldUseResource ? monitoringActiveStatus : oldMonitoringActiveStatus;

  const product = data.application?.product;

  const { productId, applicationId } = useParams();

  const { result, reexecuteQuery } = useGetApplication({ applicationId: Number(applicationId) });

  const monitoringStatus = result.data?.application.monitoringStatusV1;

  const dataFromGraphQl = result.data?.application;

  const latencyGraphQL = useLatencies({
    applicationId: data.application?.id,
    isMonitoringActive: useLatencyByMSFlag && monitoringActive
  });

  const canAddAddon = data.application?.applicationsSettings?.type !== ApplicationType.Webhook.type;

  const permissions = {
    addAddon: useProductPermission({
      productId: String(productId),
      target: 'AddonController-post-/products/:productId/addons'
    }),
    editAddon: useProductPermission({
      productId: String(productId),
      target: 'AddonController-put-/products/:productId/addons/:id'
    }),
    deleteAddon: useProductPermission({
      productId: String(productId),
      target: 'AddonController-delete-/products/:productId/addons/:id'
    }),
    viewDeploy: useProductPermission({
      productId: String(productId),
      target: 'TimelineController-post-/products/:productId/applications/:applicationId/timeline'
    })
  };

  const cantHaveAddons =
    data.application?.applicationsSettings?.type === ApplicationType.Webhook.type ||
    data.application?.applicationsSettings?.type === ApplicationType.ExternalWithoutAddons.type;

  const { result: dependencies } = useNewAddons({ applicationId: Number(applicationId) });

  useEffect(() => {
    fetch({ productId, applicationId });
    startTimer({ productId, applicationId });
    return () => {
      reset();
      stopTimer();
    };
  }, [productId, applicationId, fetch, reset, startTimer, stopTimer]);

  const resetHighlightAddonItem = () => {
    setHighlightAddonItem(null);
  };

  const {
    maintenance,
    onForceFinish,
    inMaintenance,
    maintenanceMessage,
    errorCreateMessage,
    onDelete
  } = useMaintenanceWindow({
    serviceId: data?.application?.sterasId ? data.application.sterasId : null,
    applicationId: data?.application?.id,
    reexecuteEntityQuery: reexecuteQuery,
    uid: ''
  });

  const applicationName = data.application?.name || '';

  const addons = dependencies;

  const addonHttpFlow = addons?.filter(addon => addon.multiFlow === true);

  return (
    <div>
      <Title title={applicationName} />

      {inMaintenance && (
        <MaintenanceMessage
          maintenance={maintenance}
          message={maintenanceMessage}
          onForceFinish={onForceFinish}
          user={user}
        />
      )}
      <div className={classes.root}>
        {/* Application info section */}
        <TopSection
          application={data.application}
          resourceLastCheck={applicationStatusGraphQL?.lastCheck || ''}
          addons={addons}
          productId={productId}
          productName={product?.name}
          applicationId={applicationId}
          applicationName={applicationName}
          applicationUid={data?.application?.uid}
          addonHttpFlow={addonHttpFlow}
          monitoringActive={monitoringActive}
          latenciesGraphData={latencyGraphQL}
          onUpdateAddon={() => {}}
          maintenance={maintenance}
          inMaintenance={inMaintenance}
          submitDeleteMaintenance={onDelete}
          applicationStatusUniqueResource={applicationStatusGraphQL}
          reexecuteQueryUniqueResourceStatus={reexecuteQueryUniqueResourceStatus}
          monitoringStatus={monitoringStatus}
        />

        {errorCreateMessage}

        <Divider />

        {/* Addons section */}
        {data.application.applicationsSettings && !cantHaveAddons && (
          <MiddleSection
            toolbar={
              <Toolbar
                title="External Dependencies"
                productId={productId}
                applicationId={applicationId}
                application={data?.application}
                canAddAddon={canAddAddon && permissions.addAddon}
                isHttpFlow={
                  data.application.applicationsSettings?.type === 'internalMultiHttp' ? true : false
                }
                onAddAddon={addonId => {
                  setHighlightAddonItem(addonId);
                }}
              />
            }
            application={data.application}
            permissions={permissions}
            addons={addons}
            newAddons={addons}
            monitoringActive={monitoringActive}
            highlightAddonItem={highlightAddonItem}
            onDeleteAddon={() => {
              resetHighlightAddonItem();
            }}
            applicationLatenciesGraphData={latencyGraphQL}
            onUpdateAddon={() => {
              reexecuteQueryUniqueResourceStatus();
            }}
            applicationParentMonitoringActive={monitoringActive}
            dataFromGraphQl={dataFromGraphQl}
          />
        )}

        {/* Timeline section */}
        <Timeline
          applicationId={applicationId}
          productId={productId}
          appName={data.application?.name}
          environment={data.application.environment}
        />
      </div>
    </div>
  );
};

export default connect(
  ({ application_availability, user, products_view }) => ({
    application_availability,
    user,
    product: products_view
  }),

  dispatch => ({
    reset: () =>
      dispatch({
        type: actions.APPLICATION_AVAILABILITY_VIEW_RESET,
        meta: {
          reducer
        }
      }),
    fetch: ({ productId, applicationId }) =>
      dispatch({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId }
      }),
    startTimer: ({ productId, applicationId }) =>
      dispatch({
        type: actions.APPLICATION_AVAILABILITY_STATUS_TIMER,
        params: { productId, applicationId }
      }),
    stopTimer: () =>
      dispatch({
        type: actions.APPLICATION_AVAILABILITY_STATUS_TIMER_STOP
      })
  })
)(withStyles(styles)(Applications));
