import React from 'react';

import { Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { ButtonSimple } from 'componentsV4/Button';
import actions from 'redux/actions';
import reducer from 'redux/reducer/users';
import reducerReset from 'redux/reducer/users/reset';

import Guest from './Guest';

const createAccountMutation = `#graphql
  mutation($createAccountInputs: [CreateAccountInputs!]!) {
    createAccounts(createAccountInputs: $createAccountInputs){
      message
    }
  }
`;

const UsersForm = ({
  users_new: formState,
  onChange,
  addGuest,
  removeGuest,
  onSave,
  reset,
  fetchEntity
}) => {
  const createAccountsMutation = useMutation(createAccountMutation)[1];

  const history = useHistory();

  React.useEffect(() => {
    fetchEntity();
    return () => {
      reset();
    };
  }, [fetchEntity, reset]);

  const items = formState.data.map(item => (
    <Guest
      formState={item}
      key={`guest-${item.uid}`}
      onChange={onChange}
      removeGuest={removeGuest}
      roles={formState.roles}
    />
  ));

  const graphqlSaveHander = event => {
    event.preventDefault();
    const createAccountInputs = formState.data.map(item => ({
      email: item.email,
      displayName: item.displayName,
      roleId: item.role
    }));
    createAccountsMutation({ createAccountInputs }).then(() => {
      history.push('/users');
    });
  };

  return (
    <>
      <Breadcrumb items={[{ link: `/users`, label: 'Users' }, { label: 'New' }]} />
      <Card>
        <form name="CrudForm">
          <CardHeader title="Organization guests" />
          <Divider />
          <CardContent>
            {items}

            <ButtonSimple
              title="New User"
              color="primary"
              onClick={addGuest}
              startIcon={<AddIcon />}
              variant="contained"
              text="New User"
            />
          </CardContent>
          <Divider />
          <CardActions>
            <ButtonSimple
              color="primary"
              disabled={!formState.isValid}
              onClick={graphqlSaveHander}
              type="submit"
              variant="contained"
              text="Save"
            />
          </CardActions>
        </form>
      </Card>
    </>
  );
};

UsersForm.propTypes = {
  fetchEntity: PropTypes.func.isRequired
};

export default connect(
  ({ users_new }) => ({ users_new }),
  dispatch => ({
    reset: () => {
      dispatch({
        type: actions.RESET_FORM_USER,
        meta: { reducer: reducerReset }
      });
    },
    onSave: payload => {
      dispatch({
        type: actions.SAVE_ENTITY,
        meta: {
          entity: 'users',
          url: '/users'
        },
        payload
      });
    },
    onChange: (name, value, uid) => {
      dispatch({
        type: actions.USERS_POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'users_new',
          uid,
          touched: {
            [name]: true
          },
          reducer
        }
      });
    },
    fetchEntity: () => {
      dispatch({
        type: actions.USERS_FETCH
      });
    },
    addGuest: () => {
      dispatch({
        type: actions.USERS_ADD,
        meta: {
          reducer
        }
      });
    },
    removeGuest: uid => {
      dispatch({
        type: actions.USERS_REMOVE,
        meta: {
          uid,
          reducer
        }
      });
    }
  })
)(UsersForm);
