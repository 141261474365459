import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'components/MetabaseDashboard';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { withThemeV4 } from 'theme/v4';

function getDashboardName({ newApplications, newInsightsIncidents }: { newApplications: boolean, newInsightsIncidents: boolean }) {
  if (newInsightsIncidents) {
    return MetabaseDashboards.InsightsIncidents
  }
  if (newApplications) {
    return MetabaseDashboards.ResourcesIncidentsByOrg
  }
  return MetabaseDashboards.IncidentsByOrg
}

function IncidentsMetrics() {
  const useNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const useNewInsightsIncidents = useFeatureFlagUnleash('newInsightsIncidents', {
    queryString: true
  })

  const dashboardName = getDashboardName({ newApplications: useNewApplications, newInsightsIncidents: useNewInsightsIncidents })

  const title = useNewApplications ? 'Incidents metrics' : 'Organization incidents metrics';

  const hideParams = ['source'];

  return (
    <MetabaseDashboard
      title={title}
      subtitle="List of resources with their incidents number"
      dashboardName={dashboardName}
      hideParams={hideParams}
      iframeTitle={!useNewApplications}
    />
  );
}

export default withThemeV4(IncidentsMetrics);
