import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  alert: {
    display: 'flex',
    justifyContent: 'center',
    top: 60,
    height: 120,
    width: '100%',
    background: '#0E3C3D'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20
  },
  iconCloseButton: {
    left: theme.spacing(12),
    color: 'white',
    fontSize: 16
  },
  iconClose: {
    fontSize: 20,
    marginLeft: theme.spacing(2)
  }
}));
