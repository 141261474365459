import { RefObject } from 'react';

import { Button, ButtonGroup, CircularProgress, Grid } from '@material-ui/core';
import { FormatAlignLeft } from '@material-ui/icons';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import { Link } from 'react-router-dom';

import { ButtonDefault } from 'components/Button';
import Dialog from 'components/Dialog';
import { Typography } from 'components/Typography';
import SelectIntegrationV1 from 'componentsV4/SelectIntegration/V1';
import SelectIntegrationV3 from 'componentsV4/SelectIntegration/V3';
import useAcknowledgeIncident from 'hooks/queriesGraphQL/useAcknowledgeIncident';
import useResolveIncident from 'hooks/queriesGraphQL/useResolveIncident';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import Checklist from 'icons/Checklist';

import { SlackCreateChannelV2 } from '../../SlackCreateChannel/V2';

import useStyles from './styles';

const Actions = ({
  incident,
  onAcknowledgeOrResolved,
  incidentInfoRef,
  incidentUpdateRef,
  postMortemRef
}: {
  incident: any;
  onAcknowledgeOrResolved: () => void;
  incidentInfoRef: RefObject<HTMLDivElement>;
  incidentUpdateRef: RefObject<HTMLDivElement>;
  postMortemRef: RefObject<HTMLDivElement>;
}) => {
  const classes = useStyles();

  const shouldUseITSMIntegration = useFeatureFlagUnleash('useItsmIntegration', {
    queryString: true
  });

  const scrollToIncidentInfo = () => {
    const position = incidentInfoRef?.current?.getBoundingClientRect();
    window.scrollTo({ top: Number(position?.y) - 15, behavior: 'smooth' });
  };

  const scrollToIncidentUpdate = () => {
    const position = incidentUpdateRef?.current?.getBoundingClientRect();
    window.scrollTo({ top: Number(position?.y) - 75, behavior: 'smooth' });
  };

  const scrollToPostMortem = () => {
    const position = postMortemRef?.current?.getBoundingClientRect();
    window.scrollTo({ top: Number(position?.y) - 15, behavior: 'smooth' });
  };

  const isResolved = incident.status === 'resolved';
  const isAcknowledged = incident.status === 'acknowledged' || incident.acknowledged;
  const disableResolvedButton = incident.type === 'hit/fail';

  const {
    handleAcknowledge,
    isFetching: acknowledgeIsFetching,
    canAcknowledge
  } = useAcknowledgeIncident({
    incident,
    isResolved,
    isAcknowledged,
    onAcknowledge: onAcknowledgeOrResolved
  });

  const {
    handleResolve,
    isFetching: resolveIsFetching,
    canResolve,
    showResolveDialog,
    handleOpenResolveDialog,
    handleClose
  } = useResolveIncident({
    incident,
    isResolved,
    onResolve: onAcknowledgeOrResolved
  });

  const shouldUseNewIncidentActionsButtons = useFeatureFlagUnleash('newIncidentActionsButtons', {
    queryString: true
  });

  const shouldUsePostmortemJourney = useFeatureFlagUnleash('postmortemJourney', {
    queryString: true
  });

  const hasPermissionAcknowledge = usePermission(
    'IncidentController-post-/incidents/:incidentId/acknowledge'
  );
  const hasPermissionResolved = usePermission(
    'IncidentController-post-/incidents/incidents_managed/resolved'
  );

  if (shouldUseNewIncidentActionsButtons) {
    return (
      <Grid container spacing={3} className={classes.root}>
        <Grid item sm={4} xl={3}>
          <ButtonGroup fullWidth>
            <Button
              className={classes.acknowledgeButton}
              fullWidth
              onClick={handleAcknowledge}
              variant="contained"
              disabled={!canAcknowledge || !hasPermissionAcknowledge}>
              {acknowledgeIsFetching ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                'Acknowledge'
              )}
            </Button>

            <Button
              fullWidth
              color="primary"
              onClick={handleOpenResolveDialog}
              variant="contained"
              disabled={disableResolvedButton || !canResolve || !hasPermissionResolved}>
              {resolveIsFetching ? <CircularProgress color="primary" size={24} /> : 'Resolve'}
            </Button>
          </ButtonGroup>

          <Dialog
            open={showResolveDialog}
            onClose={handleClose}
            showCloseButton
            title="Resolve incident?"
            onPrimaryAction={handleResolve}
            primaryActionLabel="Resolve"
            showSecondaryAction>
            <Typography variant="body2">
              Are you sure you want to proceed with the operation and confirm the incident has been
              resolved?
            </Typography>
          </Dialog>
        </Grid>

        {shouldUseITSMIntegration && (
          <SelectIntegrationV3
            incidentId={incident.incidentId}
            title={incident.title}
            description={incident.description}
            stateIncident={isAcknowledged ? 'Acknowledge' : incident.status}
            severity={incident.severity}
            isResolved={isResolved}
          />
        )}

        <Grid item sm={4} xl={3}>
          <SlackCreateChannelV2
            channelNameSuggestion={`incident-${incident.incidentId}`}
            incidentId={incident.incidentId}
            warRoomProvider={incident.warRoomProvider}
            warRoomIntegrationId={incident.warRoomIntegrationId}
          />
        </Grid>

        {shouldUsePostmortemJourney && incident?.postMortem?.uid && (
          <Grid item sm={4} xl={3}>
            <Button
              component={Link}
              fullWidth
              variant="outlined"
              title="View post mortem"
              to={`/postmortems/${incident.postMortem.uid}`}>
              <PostAddRoundedIcon style={{ marginRight: '1rem' }} />
              Post mortem
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item md={8}>
        <ButtonDefault
          size="small"
          variant="text"
          title={'Incident info'}
          onClick={scrollToIncidentInfo}>
          <FormatAlignLeft className={classes.iconFooter} />
          <Typography variant="body2" className={classes.textFooter}>
            Incident info
          </Typography>
        </ButtonDefault>
        <ButtonDefault
          size="small"
          variant="text"
          title={'Incident status update'}
          onClick={scrollToIncidentUpdate}>
          <Checklist className={classes.iconFooter} />
          <Typography variant="body2" className={classes.textFooter}>
            Incident status update
          </Typography>
        </ButtonDefault>
        {shouldUsePostmortemJourney && incident?.postMortem?.uid ? (
          <Button
            component={Link}
            variant="text"
            title="View post mortem"
            to={`/postmortems/${incident.postMortem.uid}`}>
            <PostAddRoundedIcon className={classes.iconFooter} />
            <Typography variant="body2" className={classes.textFooter}>
              Post mortem
            </Typography>
          </Button>
        ) : (
          <ButtonDefault
            size="small"
            variant="text"
            title="Post Mortem"
            onClick={scrollToPostMortem}>
            <PostAddRoundedIcon className={classes.iconFooter} />
            <Typography variant="body2" className={classes.textFooter}>
              Post mortem
            </Typography>
          </ButtonDefault>
        )}

        <SlackCreateChannelV2
          channelNameSuggestion={`incident-${incident.incidentId}`}
          incidentId={incident.incidentId}
          warRoomProvider={incident.warRoomProvider}
          warRoomIntegrationId={incident.warRoomIntegrationId}
        />
      </Grid>

      <Grid item md={2}>
        <ButtonDefault
          className={classes.acknowledgeButton}
          fullWidth
          size="small"
          onClick={handleAcknowledge}
          variant="contained"
          disabled={!canAcknowledge}
          isLoading={acknowledgeIsFetching}>
          Acknowledge
        </ButtonDefault>
      </Grid>
      <Grid item md={2}>
        <ButtonDefault
          fullWidth
          size="small"
          onClick={handleOpenResolveDialog}
          variant="contained"
          disabled={disableResolvedButton || !canResolve}
          isLoading={resolveIsFetching}>
          Resolve
        </ButtonDefault>

        <Dialog
          open={showResolveDialog}
          onClose={handleClose}
          showCloseButton
          title="Resolve incident?"
          onPrimaryAction={handleResolve}
          primaryActionLabel="Resolve"
          showSecondaryAction>
          <Typography variant="body2">
            Are you sure you want to proceed with the operation and confirm the incident has been
            resolved?
          </Typography>
        </Dialog>
      </Grid>

      {shouldUseITSMIntegration && (
        <Grid item md={2}>
          <SelectIntegrationV1 />
        </Grid>
      )}
    </Grid>
  );
};

export default Actions;
