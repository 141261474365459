import React from 'react';

import { Divider, Box, Typography, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useQuery } from 'urql';

import TooltipButton from 'components/TooltipButton';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import NewSecretForm from './NewSecretForm';
import SecretsList from './SecretsList';

const SecretsQuery = `
  query {
    secrets {
      name
    }
  }
`;

const Secrets = ({ permissions }) => {
  const [result, reexecuteQuery] = useQuery({
    query: SecretsQuery,
    pause: !permissions.list
  });

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addButton = (
    <IconButton disabled={!permissions.new} onClick={handleOpen} size="small" color="primary">
      <AddCircleIcon />
    </IconButton>
  );

  const newSecret = (
    <>
      <Box display="flex" alignItems="center" mb={4}>
        <Box mr={2}>
          <Typography variant="h3" color="secondary">
            Secrets Variables
          </Typography>
        </Box>

        {!permissions.new ? (
          <TooltipButton title="You don't have permission to add a secret variable">
            {addButton}
          </TooltipButton>
        ) : (
          addButton
        )}

        <NewSecretForm
          open={open}
          handleClose={handleClose}
          onSecretAdd={() => {
            reexecuteQuery({ requestPolicy: 'network-only' });
          }}
        />
      </Box>

      <Typography>
        This is where you should store all access keys and secrets that will be used in your Clouds
        and Resources.
      </Typography>
    </>
  );

  const shouldUseTabs = useFeatureFlagUnleash('useTabsInOrganizationSettings', {
    queryString: true
  });

  if (shouldUseTabs) {
    return (
      <>
        {newSecret}

        <Box mt={2}>
          <SecretsList permissions={permissions} result={result} reexecuteQuery={reexecuteQuery} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Divider />

      <Box marginTop={2}>{newSecret}</Box>

      <Box mt={2}>
        <SecretsList permissions={permissions} result={result} reexecuteQuery={reexecuteQuery} />
      </Box>
    </>
  );
};

export default Secrets;
