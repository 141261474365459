import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@material-ui/core';

import { useStyles } from './styles';

export function DeleteDialog({
  open,
  title,
  children,
  handleRemove,
  handleClose,
  deleteText = 'Delete'
}: {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  handleRemove: () => void;
  handleClose: () => void;
  deleteText?: string;
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box p={3}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h4" color="secondary">
            {title}
          </Typography>

          <Divider className={classes.divider} />
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {children}
          <Box mt={5} display="flex" justifyContent="flex-end" className={classes.buttons}>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className={classes.cancelButton}>
              CANCEL
            </Button>
            <Button color="primary" variant="contained" onClick={handleRemove}>
              {deleteText}
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
