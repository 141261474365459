import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {
  isDesktop: boolean;
  hasChildren: boolean;
}

export default makeStyles<Theme, StyleProps>(theme => ({
  button: {
    backgroundColor: props => (props.isDesktop ? theme.palette.background.default : 'white'),
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(),
    justifyContent: 'flex-start',
    width: '100%',
    '& .material-icons': {
      marginRight: theme.spacing(),
      color: theme.palette.elvenGray[700]
    }
  },
  buttonDisabled: {
    cursor: 'not-allowed'
  },
  buttonText: {
    color: theme.palette.elvenGray[700],
    fontWeight: 300,
    textTransform: 'none',
    whiteSpace: 'nowrap'
  },
  activeIcon: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0 2px 2px 0',
    width: 6,
    top: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    zIndex: 100
  },
  expandIcon: {
    marginLeft: 'auto',
    color: theme.palette.grey[800]
  },
  childrenActiveIcon: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0 2px 2px 0',
    width: 6,
    top: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    zIndex: 100
  },
  listItem: {
    padding: theme.spacing(0)
  },
  childrenButton: {
    backgroundColor: props => (props.isDesktop ? theme.palette.background.default : 'white'),
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    justifyContent: 'flex-start',
    width: '100%',
    '& .material-icons': {
      marginRight: theme.spacing(),
      color: theme.palette.elvenGray[700]
    }
  }
}));
