import { useEffect } from 'react';

import dayjs from 'dayjs';
import { CreateManualIncident, UpdateManualIncident } from 'graphqlQueries/createManualIncident';
import {
  CreateManualIncidentWithAlerts,
  UpdateManualIncidentWithAlerts
} from 'graphqlQueries/createManualIncidentWithAlerts';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import { updateManualIncidentAdapter } from 'views/ServicesHub/adapters/edit/incidentManual';
import { updateManualIncidentWithAlertsAdapter } from 'views/ServicesHub/adapters/edit/incidentManualWithAlerts';
import { incidentManualAdapter } from 'views/ServicesHub/adapters/new/incident/incidentManual';
import { incidentManualWithAlertsAdapter } from 'views/ServicesHub/adapters/new/incident/incidentManualWithAlerts';
import { LayoutType } from 'views/ServicesHub/forms';

import { BaseLayout } from '../components/BaseLayout';

import { useStartValues } from './useStartValues';

export type IncidentManualData = {
  incidentId: number;
  incidentName: string;
  cause: string;
  incidentStatus: string;
  severity: string;
  startedAt: string;
  origins?: { value: string; label: string; type: string; linkedOriginId?: number }[];
  teams?: { value: string; label: string; linkedTeamId?: number }[];
  alerts?: { value: string; label: string; linkedAlertId?: number }[];
};

export function IncidentManualLayout({ Form, isEdit }: LayoutType) {
  const shouldUseIncidentManualWithAlerts = useFeatureFlagUnleash('useIncidentManualWithAlerts', {
    queryString: true
  });

  const {
    startValues,
    fetchingGetIncident,
    fetchingGetTeams,
    fetchingGetOrigins
  } = useStartValues();

  const form = useForm<any>({
    defaultValues: shouldUseIncidentManualWithAlerts
      ? {
          severity: '',
          teams: [],
          origins: [],
          alerts: [],
          startedAt: dayjs(new Date()).format('YYYY-MM-DDTHH:mm')
        }
      : {
          severity: '',
          teams: [],
          origins: [],
          startedAt: dayjs(new Date()).format('YYYY-MM-DDTHH:mm')
        }
  });

  const { reset } = form;

  useEffect(() => {
    reset(startValues);
  }, [reset, startValues]);

  const dispatch = useDispatch();

  const history = useHistory();

  const [{ fetching: fetchingCreateIncident }, createManualIncident] = useMutation(
    shouldUseIncidentManualWithAlerts ? CreateManualIncidentWithAlerts : CreateManualIncident
  );

  const [{ fetching: fethcinUpdateIncident }, updateManualIncident] = useMutation(
    shouldUseIncidentManualWithAlerts ? UpdateManualIncidentWithAlerts : UpdateManualIncident
  );
  const handleSubmit = async (data: IncidentManualData) => {
    if (isEdit && startValues) {
      const payload = shouldUseIncidentManualWithAlerts
        ? updateManualIncidentWithAlertsAdapter(data, startValues.incidentId)
        : updateManualIncidentAdapter(data, startValues.incidentId);

      const response = await updateManualIncident({
        incidentManual: payload
      });

      if (response.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update manual incident' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Manual incident updated successfully'
      });

      history.push(`/incidents/${startValues.incidentId}`);
      return;
    }

    if (!isEdit) {
      const payload = shouldUseIncidentManualWithAlerts
        ? incidentManualWithAlertsAdapter(data)
        : incidentManualAdapter(data);

      const response = await createManualIncident({ incidentManual: payload });

      if (response.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create manual incident' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Manual incident created successfully'
      });

      history.push(
        `/incidents/${response.data?.createManualIncident?.id ||
          response.data?.createIncidentManualV2?.id}`
      );
      return;
    }
  };

  const fetching =
    fetchingCreateIncident ||
    fetchingGetIncident ||
    fethcinUpdateIncident ||
    fetchingGetTeams ||
    fetchingGetOrigins;

  return (
    <BaseLayout
      formComponent={<Form form={form} />}
      onSubmit={form.handleSubmit(handleSubmit)}
      isLoading={fetching}
      saveButtonLabel={!isEdit ? ' Create new incident' : 'Update incident'}
      infoTitle="Manual Incident"
      infoDescription="In these settings, choose the name of the incident you are opening, report the cause for others to be aware of what is happening, provide the time and day when the incident started, and its status. As soon as you create this incident, the team will be notified."
    />
  );
}
