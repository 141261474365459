import { ChangeEvent, useEffect, useRef, useState } from 'react';

import {
  Badge,
  Box,
  Divider,
  IconButton,
  Link,
  Paper,
  Popper,
  Typography
} from '@material-ui/core';
import {
  Close,
  NotificationImportantOutlined as NotificationImportantIcon
} from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import dayjs from 'dayjs';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import { Link as RouterLink } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useSubscription } from 'urql';
import { v4 as uuid } from 'uuid';

import { StatusChip } from 'views/AlertCenter/components/StatusChip';
import { useIncidents } from 'views/IncidentCenter/useQuery/useIncidents';

import { useStyles } from './styles';

dayjs.extend(dayjsRelativeTimePlugin);

const INCIDENTS_QUERY_SIZE = 5;
const CRITICAL_EVENTS_LIST_RENDER_POOL = 1000 * 30; // 30s

export function CriticalEvents() {
  const classes = useStyles();

  const [isCriticalEventsOpen, setIsCriticalEventsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [criticalEventsListKey, setIsCriticalEventsKey] = useState(uuid());

  const criticalEventsContainerRef = useRef<HTMLDivElement>(null);

  const handlePageChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const { data, reexecuteQuery } = useIncidents({
    perPage: INCIDENTS_QUERY_SIZE,
    page,
    period: 0,
    severity: 'all',
    type: 'all',
    status: StatusEnum.Alarmed,
    query: ''
  });

  useSubscription<void>(
    {
      query: `
        subscription {
          incidentsCreated {
            __typename
          }
        }
      `
    },
    () => {
      reexecuteQuery();
    }
  );

  const hasData = Boolean(data?.total);

  const total = data?.total ?? 0;

  useEffect(() => {
    if (!isCriticalEventsOpen) return;

    const criticalEventsKeyUpdateTimeoutId = setInterval(() => {
      setIsCriticalEventsKey(uuid());
    }, CRITICAL_EVENTS_LIST_RENDER_POOL);

    return () => clearTimeout(criticalEventsKeyUpdateTimeoutId);
  }, [isCriticalEventsOpen]);

  return (
    <>
      <Box
        {...{ ref: criticalEventsContainerRef }}
        display="flex"
        gridGap="0.5rem"
        alignItems="center">
        Critical Events
        <IconButton onClick={() => setIsCriticalEventsOpen(isOpen => !isOpen)}>
          <Badge badgeContent={total} color="error">
            <NotificationImportantIcon htmlColor="white" />
          </Badge>
        </IconButton>
      </Box>

      <Popper
        open={isCriticalEventsOpen}
        anchorEl={criticalEventsContainerRef?.current}
        placement="bottom"
        className={classes.popper}>
        <Paper className={classes.paper}>
          <Box className={classes.content}>
            <Box display="flex" justifyContent="space-between" className={classes.dialogTitle}>
              <Typography variant="h3" color="secondary">
                Critical Events
              </Typography>

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setIsCriticalEventsOpen(false)}>
                <Close />
              </IconButton>
            </Box>
            <Divider className={classes.divider} />
            <Box
              key={criticalEventsListKey}
              mt={3}
              display="flex"
              flexDirection="column"
              gridGap="1rem">
              {data?.incidents?.map(incidents => (
                <Link
                  key={incidents.incidentId}
                  component={RouterLink}
                  className={classes.criticalEventLink}
                  to={`/incidents/${incidents.incidentId}`}
                  title="View incident">
                  <>
                    <Box display="flex" gridGap="0.5rem" flexDirection="column">
                      <Box display="flex" alignItems="center" gridGap="0.5rem">
                        <StatusChip
                          type="Event"
                          status={incidents.status as StatusEnum}
                          isAlertStatus
                        />
                        <StatusChip
                          type="Severity"
                          severityStatus={incidents.severity as SeverityStatusEnum}
                          isAlertStatus={false}
                        />{' '}
                        <Typography className="criticalEventTitle" variant="h5">
                          {incidents.title}
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                        {dayjs(incidents.happenedAt).fromNow()}
                      </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                  </>
                </Link>
              ))}
            </Box>
            {hasData && (
              <Box className={classes.buttonsContainer} mt={2}>
                <Pagination
                  page={page}
                  count={Math.ceil(total / INCIDENTS_QUERY_SIZE)}
                  defaultPage={page}
                  size="large"
                  color="primary"
                  onChange={handlePageChange}
                />
              </Box>
            )}

            {!hasData && (
              <Typography variant="h5" color="secondary">
                No critical events
              </Typography>
            )}
          </Box>
        </Paper>
      </Popper>
    </>
  );
}
