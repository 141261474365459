import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

type StylesProps = {
  showPlansMessage: boolean;
};

export default makeStyles<Theme, StylesProps>(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: 240
  },
  drawer: {
    minWidth: 240,
    paddingTop: ({ showPlansMessage }) => theme.spacing(7) + (showPlansMessage ? 30 : 0),
    [theme.breakpoints.up('sm')]: {
      paddingTop: ({ showPlansMessage }) => theme.spacing(8) + (showPlansMessage ? 30 : 0)
    }
  }
}));
