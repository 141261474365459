import React from 'react';

import Form from '../components/Form';

class RolesEditForm extends React.Component {
  render() {
    const { formState, onChange, save } = this.props;
    return <Form formState={formState} onChange={onChange} save={save} title="Edit Role" />;
  }
}

export default RolesEditForm;
