import { ChangeEvent, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Add, FilterList, Search } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { useStyles } from 'helpers/globalStyles';
import { useProducts } from 'hooks/queriesGraphQL/useProductsV2';
import useDebounce from 'hooks/useDebounce';
import { usePermission } from 'hooks/usePermission';

import { COLUMNS } from '../columns';
import { Filters, PageFilters } from '../components/Filters';

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export function ProductsList() {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const [{ fetching, data, error }] = useProducts({
    page,
    perPage,
    query: buildElasticQuery({
      search,
      use_new_application: 'true'
    })
  });

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const handleApplyFilters = (filters: PageFilters) => {};
  const handleClearFilters = () => {};

  const hasPermissionToCreateProduct = usePermission('ProductController-post-/products');

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const ProductsResult = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    return (
      <DataTable
        columns={COLUMNS}
        data={data?.getProductsFromIndexer?.data ?? []}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
        paginationPerPage={perPage}
        paginationTotalRows={data?.getProductsFromIndexer?.total}
        paginationServer
        pagination
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={fetching}
        highlightOnHover
        striped
        pointerOnHover={true}
        onRowClicked={product => history.push(`products/${product.id}`)}
      />
    );
  };

  return (
    <PageContainer className={classes.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={[{ label: 'Monitoring Aggregator' }, { label: 'Product Center', link: '/products' }]}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2.5}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          Products
        </Typography>
        <Box>
          <Link to="/services-hub/new/?type=product">
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              disabled={!hasPermissionToCreateProduct}>
              Product
            </Button>
          </Link>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box
        display="flex"
        justifyContent="space-between"
        mb={7.5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchContainerRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Search by name"
          InputProps={{
            endAdornment: <Search />,
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          fullWidth
        />

        <Button
          className={classes.filterButton}
          variant="outlined"
          color="primary"
          onClick={handleToggleFilters}
          disabled>
          <FilterList />
        </Button>
      </Box>

      {isFiltersOpen && (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      )}

      {ProductsResult()}
    </PageContainer>
  );
}
