import { init as initApm } from '@elastic/apm-rum';

export default function initElasticApm() {
  const isProductionEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';

  if (!isProductionEnvironment) return;

  const serverUrl = process.env['REACT_APP_ELASTIC_APM_SERVER_URL'];
  const serviceName = process.env['REACT_APP_ELASTIC_APM_SERVICE_NAME'];

  initApm({
    serviceName,
    serverUrl,
    serviceVersion: '0.0.1',
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}
