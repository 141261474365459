import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import ProviderIcon from './ProviderIcon';

function Title({ provider, isLoading, isFetching }) {
  return (
    <Box display="flex" alignItems="center">
      <ProviderIcon provider={provider} />

      {provider === 'custom' && (
        <Typography component="h3" variant="h3" style={{ textTransform: 'capitalize' }}>
          {provider}
        </Typography>
      )}

      {!isLoading && isFetching && (
        <CircularProgress color="primary" size="1.2rem" style={{ marginLeft: '1rem' }} />
      )}
    </Box>
  );
}

export default Title;
