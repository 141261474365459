import { useRef, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles, Box, Typography, Link, FormHelperText } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Recaptcha, { ReCAPTCHA } from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import DividerWithText from 'components/DividerWithText/DividerWithText';
import { ButtonSimple } from 'componentsV4/Button';
import GoogleButton from 'componentsV4/GoogleButton';
import SimpleInput from 'componentsV4/Input';
import encrypt from 'helpers/encrypt';
import { useSignIn } from 'hooks/queriesRest/useSignIn';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { Theme, withThemeV4 } from 'theme/v4';

import schema, { FormData } from './schema';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: theme.typography.pxToRem(438),
    width: '100%'
  },
  headerText: {
    paddingTop: theme.spacing(6),
    color: theme.palette.secondary.dark
  },
  content: {
    '& > div:not(:first-child)': {
      marginTop: theme.spacing(3)
    }
  },
  orText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignSelf: 'center',
    color: grey[500]
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(4)
    }
  },
  button: {
    height: '100px'
  },
  captchaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(0.4)
  }
}));

const SignUpV4 = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { handleSignIn, isLoading: isLoginLoading } = useSignIn();

  const reRef = useRef<ReCAPTCHA>(null);

  const [isChecked, setIsChecked] = useState(false);
  const [inUseEmail, setInUseEmail] = useState(false);

  const onChange = () => {
    setIsChecked(true);
  };
  const onExpired = () => {
    setIsChecked(false);
  };

  const [signUpMutation, { isLoading }] = useMutation(
    (data: string | null) =>
      axios.post('/auth/signup', { data }).then(response => response.data.data),
    {
      onSuccess: data => {
        const { email } = data;
        handleSignIn({ email, password });
      },
      onError: (e: any) => {
        if (e.response.status === 422) {
          setInUseEmail(true);
        }

        if (e.response.status !== 422) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: 'The request failed. Please try again.',
            ga: { category: 'ERROR' }
          });
        }
      }
    }
  );

  const handleSignUp = (formData: FormData) => {
    const { email, name, password } = formData;
    const token = reRef.current ? reRef.current.getValue() : null;

    if (reRef.current) {
      reRef.current.reset();
    }

    const form = JSON.stringify({ email, password, displayName: name, token });

    const hash = encrypt(form);

    signUpMutation(hash);

    setIsChecked(false);

    return;
  };

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      passwordConfirmation: ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const password = watch('password');
  const passwordConfirmation = watch('passwordConfirmation');

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Box>
          <img src="/images/logos/logo_by_elven_works.min.svg" alt="One Platform by Elven Works" />

          <Typography variant="h3" className={classes.headerText}>
            Create a new account
          </Typography>

          <GoogleButton text="Sign up with your Google account" />

          <DividerWithText>
            <Typography className={classes.orText} variant="body2">
              or
            </Typography>
          </DividerWithText>
        </Box>

        <form onSubmit={handleSubmit(handleSignUp)}>
          <Box className={classes.content}>
            <SimpleInput
              fullWidth
              name="email"
              id="input-email"
              inputRef={register()}
              required={true}
              placeholder={'Ex.: email@email.com'}
              defaultLabel={'Email'}
              error={errors['email']?.message ? true : false || inUseEmail}
            />

            <FormHelperText error>{errors['email']?.message}</FormHelperText>
            <FormHelperText error>{inUseEmail ? 'email already in use' : null}</FormHelperText>

            <SimpleInput
              name="name"
              id="input-name"
              inputRef={register()}
              required={true}
              placeholder="What name do you prefer to be called?"
              defaultLabel="Name"
              error={errors['name']?.message ? true : false}
            />

            <FormHelperText error>{errors['name']?.message}</FormHelperText>

            <SimpleInput
              name="password"
              id="input-password"
              inputRef={register()}
              required={true}
              placeholder="At least six characters, including letters, numbers."
              defaultLabel="Create a password"
              type="password"
              error={errors['password']?.message ? true : false}
            />

            <FormHelperText error>{errors['password']?.message}</FormHelperText>

            <SimpleInput
              name="passwordConfirmation"
              id="input-confirm-password"
              inputRef={register()}
              required={true}
              placeholder="Same as the password defined above."
              defaultLabel="Confirm your password"
              error={errors['passwordConfirmation']?.message ? true : false}
              type="password"
            />

            <FormHelperText error>
              {password !== passwordConfirmation ? "Passwords don't match" : ''}
            </FormHelperText>

            <Box className={classes.captchaContainer}>
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                ref={reRef}
                onChange={onChange}
                onExpired={onExpired}
              />
            </Box>

            <Box>
              <ButtonSimple
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                text="Sign up"
                isLoading={isLoading || isLoginLoading}
                disabled={!isValid || password !== passwordConfirmation || !isChecked}
              />
            </Box>
          </Box>
        </form>
        <Box className={classes.footer}>
          <Typography color="textPrimary" variant="body2">
            Already have an account?
            <Link component={RouterLink} to="/sign-in" variant="body2" className={classes.link}>
              Sign In
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withThemeV4(SignUpV4);
