import { useMemo } from 'react';

import locations from 'constants/locations';

import { MenuItem } from '@material-ui/core';

import { Select } from 'components/Inputs';

const Locations = ({ control, errors, disabled = false }) => {
  const locationsOptions = useMemo(() => {
    const options = [
      <MenuItem key={'locations-blank'} value=""></MenuItem>,
      ...locations.map(locations => (
        <MenuItem key={locations.value} value={locations.value}>
          {locations.label}
        </MenuItem>
      ))
    ];

    return options;
  }, []);

  return (
    <Select
      control={control}
      required={true}
      label="Region"
      name="locations"
      errors={errors}
      disabled={disabled}>
      {locationsOptions}
    </Select>
  );
};

export default Locations;
