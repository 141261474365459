import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';

import MenuItemsList from './MenuItemsList';

class MenuItems extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { history } = this.props;
    return <MenuItemsList history={history} />;
  }
}

export default connect(null, dispatch => ({
  fetch: () =>
    dispatch({
      type: actions.FETCH_ENTITY,
      meta: {
        entity: 'menuItems'
      }
    })
}))(MenuItems);
