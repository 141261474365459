import { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Divider } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import { Typography } from 'components/Typography';
import { useStyles } from 'helpers/globalStyles';
import useUser from 'hooks/queriesRest/useUser';

import ProductsList from '../../V3/Products/List/ProductList';

const Products = ({ showEmptyHome: boolean }) => {
  const classes = useStyles();
  const [firstAccess, setFirstAccess] = useState(false);
  const [
    shouldUpdateHighlightNewResourcesCard,
    setShouldUpdateHighlightNewResourcesCard
  ] = useState(false);

  const { data: user, isLoading } = useUser({});

  useEffect(() => {
    if (shouldUpdateHighlightNewResourcesCard) {
      setShouldUpdateHighlightNewResourcesCard(false);
    }
  }, [shouldUpdateHighlightNewResourcesCard]);

  return isLoading ? (
    <PageContainer className={classes.pageContainer} maxWidth="xl">
      <CircularProgress />
    </PageContainer>
  ) : (
    <PageContainer className={classes.pageContainer} maxWidth="xl">
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2.5}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          Welcome, {user?.displayName}
        </Typography>
        <Box>
          <Link to="/services-hub">
            <Button size="large" variant="contained" color="primary" startIcon={<Add />}>
              New resource
            </Button>
          </Link>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box marginTop={3} marginBottom={3}>
        <ProductsList
          showEmptyHome={true}
          firstAccess={firstAccess}
          setFirstAccess={setFirstAccess}
        />
      </Box>
    </PageContainer>
  );
};

export default Products;
