import MainLayoutV4 from 'layouts/V4/Main';
import FeaturesEditView from 'views/Features/Edit';
import FeaturesListView from 'views/Features/List';
import FeaturesNewView from 'views/Features/New';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const featuresRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={FeaturesListView}
    exact
    layout={MainLayoutV4}
    key="/features"
    path="/features"
  />,
  <RouteWithLayoutV2
    component={FeaturesNewView}
    exact
    layout={MainLayoutV4}
    key="/features/new"
    path="/features/new"
  />,
  <RouteWithLayoutV2
    component={FeaturesEditView}
    exact
    layout={MainLayoutV4}
    key="/features/:id/edit"
    path="/features/:id/edit"
  />
];
