import { useState } from 'react';

import { IconButton, Link, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

import { usePermission } from 'hooks/usePermission';

import { PostmortemDeleteDialog } from '../components/DeleteDialog';
import { useStyles } from '../styles';

type MenuProps = {
  postmortemUid: string;
  postmortemTitle: string;
  refetch: () => void;
};

export function MenuActions({ postmortemUid, postmortemTitle, refetch }: MenuProps) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePostmortem = () => {
    handleClose();
    setDeleteDialogOpen(true);
  };

  const hasDeletePostmortemPermission = usePermission('PostMortemController-delete-/post-mortem');
  const hasUpdatePostmortemPermission = usePermission('PostMortemController-put-/post-mortem');

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          onClick={handleClose}
          disabled={!hasUpdatePostmortemPermission}>
          <Link component={RouterLink} to={`/postmortems/edit/${postmortemUid}`}>
            Edit postmortem
          </Link>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeletePostmortem}
          disabled={!hasDeletePostmortemPermission}>
          Delete postmortem
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <Link component={RouterLink} to={`/postmortems/${postmortemUid}?print=true`}>
            Export to PDF
          </Link>
        </MenuItem>
      </Menu>

      <PostmortemDeleteDialog
        open={deleteDialogOpen}
        postmortemTitle={postmortemTitle}
        postmortemUid={postmortemUid}
        handleClose={() => setDeleteDialogOpen(false)}
        onDelete={() => refetch()}
      />
    </>
  );
}
