import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  simpleText: {
    color: theme.palette.secondary.main,
    maxWidth: '130px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  email: {
    maxWidth: '130px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  orgName: {
    color: theme.palette.secondary.main,
    maxWidth: '130px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexGrow: 1
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  menuOrgDivider: {
    backgroundColor: theme.palette.grey[300],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '85%'
  },
  iconButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2)
  },
  menuOrgName: {
    maxWidth: '110px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  button: {
    width: 180,
    height: 45,
    borderRadius: 7,
    whiteSpace: 'nowrap'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 8
  }
}));
