import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    color: theme.palette.elvenTeal[400],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(34),
    lineHeight: theme.typography.pxToRem(41.99),
    marginTop: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(8)
  },
  description: {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(25)
  },
  divider: {
    border: `${theme.palette.grey[300]} 1px solid`
  },
  boxServiceCard: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: theme.typography.pxToRem(65),
    rowGap: theme.typography.pxToRem(32)
  },
  ServiceTitle: {
    color: theme.palette.elvenTeal[400],
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(32),
    marginTop: theme.typography.pxToRem(60),
    marginBottom: theme.typography.pxToRem(32)
  },
  card: {
    width: theme.typography.pxToRem(252),
    height: theme.typography.pxToRem(180)
  },
  cardTop: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(8)
  },
  cardMedia: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48)
  },
  cardTitle: {
    color: theme.palette.elvenTeal[400],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(32),
    marginLeft: theme.typography.pxToRem(16),
    alignSelf: 'center'
  },
  cardDescription: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  cardLocked: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.typography.pxToRem(20),
    marginLeft: 'auto'
  }
}));

export default useStyles;
