import MainLayoutV4 from 'layouts/V4/Main';
import MenuItemsEditView from 'views/MenuItems/Edit';
import MenuItemsListView from 'views/MenuItems/List';
import MenuItemsNewView from 'views/MenuItems/New';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const menuItemsRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={MenuItemsListView}
    exact
    layout={MainLayoutV4}
    key="/menuItems"
    path="/menuItems"
  />,
  <RouteWithLayoutV2
    component={MenuItemsNewView}
    exact
    layout={MainLayoutV4}
    key="/menuItems/new"
    path="/menuItems/new"
  />,
  <RouteWithLayoutV2
    component={MenuItemsEditView}
    exact
    layout={MainLayoutV4}
    key="/menuItems/:id/edit"
    path="/menuItems/:id/edit"
  />
];
