import { Dialog } from 'design-system';

const MaintenanceDialog = ({
  formComponent: FormComponent,
  title,
  startValues,
  applicationName,
  inMaintenance,
  fetchingCreate,
  onForceFinish,
  isOpen,
  handleClose
}) => {
  return (
    <Dialog disableEnforceFocus open={isOpen} onClose={handleClose} title={title} maxWidth="xs">
      <FormComponent
        onClose={handleClose}
        startValues={startValues}
        applicationName={applicationName}
        inMaintenance={inMaintenance}
        fetchingCreate={fetchingCreate}
        onForceFinish={onForceFinish}
      />
    </Dialog>
  );
};

export default MaintenanceDialog;
