import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function MetricsProduct() {
  const { productId } = useParams<{
    productId: string;
  }>();

  const dashboardName = MetabaseDashboards.ListByProduct;

  const params = {
    product_id: productId
  };

  return (
    <MetabaseDashboard
      title="Organization product metrics"
      subtitle="List of products with their metrics"
      dashboardName={dashboardName}
      dashboardParams={params}
    />
  );
}

export default withThemeV4(MetricsProduct);
