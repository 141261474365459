import { useEffect } from 'react';

import ApplicationType from 'constants/ApplicationType';

import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

import ApplicationTab from '../View/Application';

import WithAddons from './WithAddons';

function ExternalWithAddons() {
  const firstAccess = useQueryParams().has('firstAccess');

  useEffect(
    () => {
      analyticsEvent(firstAccess ? 'fa_new_eaea_screen' : 'new_eaea_screen');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return (
    <WithAddons
      applicationType={ApplicationType.ExternalWithAddons}
      renderApplicationTab={({
        firstAccess,
        environmentId,
        productId,
        applicationType
      }: {
        firstAccess: boolean;
        environmentId: number;
        productId: number;
        applicationType: string;
      }) => (
        <ApplicationTab
          firstAccess={firstAccess}
          environmentId={environmentId}
          productId={productId}
          applicationType={applicationType}
        />
      )}
    />
  );
}

export default withThemeV3Hoc(ExternalWithAddons);
