import React from 'react';

import { Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';

import { ButtonSimple } from 'componentsV4/Button';

import Form from './Form';

const Form1 = ({ title, formState, onChange, save }) => {
  const saveHandler = event => {
    event.preventDefault();
    delete formState.values.key;
    delete formState.values.value;
    save(formState.values);
  };

  return (
    <Card>
      <form name="RolesForm">
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Form formState={formState} onChange={onChange} />
        </CardContent>
        <Divider />
        <CardActions>
          <ButtonSimple
            color="primary"
            disabled={!formState.isValid}
            onClick={saveHandler}
            type="submit"
            variant="contained"
            text="Save"
          />
        </CardActions>
      </form>
    </Card>
  );
};

// FIXME: identifier "Form" has already been declared or isn't helpful, rename this please!
export default Form1;
