const schema = {
  title: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },

  href: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  }
};

export default schema;
