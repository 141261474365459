import { Component } from 'react';

import { FlagProvider } from '@unleash/proxy-client-react';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { PageGuideProvider } from 'contexts/PageGuideContext';
import { createClient as createWSClient } from 'graphql-ws';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Provider, useDispatch } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import {
  cacheExchange,
  createClient,
  dedupExchange,
  Provider as UrqlProvider,
  subscriptionExchange
} from 'urql';

import Title from 'components/Title';
import { applyMiddleware, createStore } from 'redux';
import { Auth0ProviderWrapper } from 'services/auth0';
import initElasticApm from 'services/elastic-apm';
import { PosthogSetup } from 'services/posthog';

import './assets/scss/index.scss';

import actions from './redux/actions';
import createReducer from './redux/reducer';
import sagas from './redux/sagas';
import Routes from './routes';

const browserHistory = createBrowserHistory({
  basename: process.env.REACT_APP_BRANCH ? `/${process.env.REACT_APP_BRANCH}` : undefined
});
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(browserHistory), sagaMiddleware];
const composeEnhancers = composeWithDevTools({});
const composed = composeEnhancers(applyMiddleware(...middlewares));
const reducer = createReducer(browserHistory);
const store = createStore(reducer, composed);
window.store = store;
sagaMiddleware.run(sagas);

const AppWithReactQuery = () => {
  const dispatch = useDispatch();

  const queryCache = new QueryCache({
    defaultConfig: {
      queries: {
        onError: err => {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: err,
            ga: { category: 'ERROR' }
          });
        }
      }
    }
  });

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ConnectedRouter history={browserHistory}>
        <PosthogSetup>
          <PageGuideProvider>
            <Routes />
          </PageGuideProvider>
        </PosthogSetup>
      </ConnectedRouter>
    </ReactQueryCacheProvider>
  );
};

/**
 * @type { import("@unleash/proxy-client-react").IConfig } - Unleash configuration
 */
const unleashProxyOptions = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  disableRefresh: process.env.REACT_APP_ENVIRONMENT === 'local',
  appName: process.env.REACT_APP_UNLEASH_APP_NAME,
  environment: 'production',
  fetch: (url, options) => {
    return window.fetch(url, { ...options, credentials: 'include' });
  }
};
const wsClient = createWSClient({
  url: `${process.env.REACT_APP_GRAPHQL_WS_URL}/graphql-ws`
});

const urqlClient = createClient({
  url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
  requestPolicy: 'cache-and-network',
  fetchOptions: {
    credentials: 'include'
  },
  // default exchanges: https://formidable.com/open-source/urql/docs/architecture/#the-exchanges
  exchanges: [
    dedupExchange,
    cacheExchange,
    multipartFetchExchange,
    subscriptionExchange({
      forwardSubscription: operation => ({
        subscribe: sink => ({
          unsubscribe: wsClient.subscribe(operation, sink)
        })
      })
    })
  ]
});

// Inits Elastic APM Agent
initElasticApm();

class App extends Component {
  componentDidMount() {
    process.env.REACT_APP_ENVIRONMENT !== 'local' &&
      // eslint-disable-next-line no-console
      console.log(`
█▀█ █▄░█ █▀▀
█▄█ █░▀█ ██▄

█▀█ █░░ ▄▀█ ▀█▀ █▀▀ █▀█ █▀█ █▀▄▀█
█▀▀ █▄▄ █▀█ ░█░ █▀░ █▄█ █▀▄ █░▀░█

The one platform to rule them all
`);
  }

  render() {
    return (
      <Provider store={store}>
        <UrqlProvider value={urqlClient}>
          <Auth0ProviderWrapper>
            <FlagProvider config={unleashProxyOptions} startClient={false}>
              <HelmetProvider>
                <Title />

                <AppWithReactQuery />
              </HelmetProvider>
            </FlagProvider>
          </Auth0ProviderWrapper>
        </UrqlProvider>
      </Provider>
    );
  }
}

export default App;
