import { useQuery } from 'urql';

import useFeatureFlagUnleash from './useFeatureFlagUnleash';

export const getTeamsByResourceId = `#graphql
    query($resourceId: Float!) {
      getTeamsByResourceId(resourceId: $resourceId, resourceType: "Product") {
        name
        resourceTeams {
          teamId
        }
      }
    }
  `;

type TeamWithResourceTeam = {
  name: string;
  resourceTeams: {
    teamId: number;
  }[];
}[];

type TeamWithResourceTeamVariables = {
  resourceId?: number;
};

export function useGetProducTeams({ productId }: { productId?: number }) {
  const useNewPermission = useFeatureFlagUnleash('useNewPermission', { queryString: true });

  return useQuery<{ getTeamsByResourceId: TeamWithResourceTeam }, TeamWithResourceTeamVariables>({
    query: getTeamsByResourceId,
    variables: { resourceId: productId },
    pause: !productId || !useNewPermission
  });
}
