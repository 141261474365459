import { Box, Divider, Link, Typography, capitalize } from '@material-ui/core';
import { Redirect, useHistory, useParams } from 'react-router';
import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';

import PageContainer from 'components/PageContainer';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useSSOIntegrations } from 'hooks/queriesGraphQL/useSSOIntegrations';
import { useStyles } from 'views/ServicesHub/formStyles';

import { SSOIntegrationForm } from './Form';

const organizationSettingsSSOPath = '/organization?tab=sso';

export function OrganizationNewSSOIntegration() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  const { type } = useParams<{ type: SSOIntegration['type'] }>();

  const [{ data, fetching }] = useSSOIntegrations();
  const hasConnectedIntegrations = Boolean(data?.ssoIntegrations?.length ?? false);

  if (!fetching && hasConnectedIntegrations) {
    return <Redirect to="/organization?tab=sso" />;
  }

  const goToOrganizationPage = () => history.push(organizationSettingsSSOPath);

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      {fetching && <LoadingOverlay />}

      <Breadcrumb
        items={[
          { label: 'Settings Organization', link: organizationSettingsSSOPath },
          { label: 'Single Sign-On' },
          { label: `New SAML ${capitalize(type)}` }
        ]}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          New SAML {capitalize(type)}
        </Typography>
      </Box>

      <Typography className={classes.description} variant="body1">
        {capitalize(type)} SSO is a secure authentication method that allows a user to log in to a
        session granting access to various related applications and services.
      </Typography>

      <Divider className={classes.divider} />

      <Box>
        <Box display="flex" gridGap="12rem">
          <Box flex={1}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Typography variant="h3" color="secondary">
                Login Authentication
              </Typography>

              <SSOIntegrationForm
                type={type}
                onSubmit={goToOrganizationPage}
                onCancel={goToOrganizationPage}
              />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2" color="secondary">
              <b>Support configuration</b>
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              To perform the integration, carefully follow the step-by-step instructions in the
              form. In case of doubts,{' '}
              <Link href="https://help.elven.works/" target="_blank" rel="noopener noreferrer">
                click here
              </Link>{' '}
              to access the documentation.
            </Typography>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
}
