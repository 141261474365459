import React from 'react';

import Breadcrumb from 'components/Breadcrumb';

import ServicesNewForm from './ServicesNewForm';

function ServicesNew() {
  return (
    <>
      <Breadcrumb items={[{ link: `/services`, label: 'Services' }, { label: 'New' }]} />
      <ServicesNewForm />
    </>
  );
}

export default ServicesNew;
