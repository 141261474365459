import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  popper: {
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(),
    zIndex: theme.zIndex.appBar,
    borderRadius: 8,
    minWidth: 930
  },
  content: {
    padding: theme.spacing(3)
  },
  paper: {
    borderRadius: 8,
    boxShadow:
      '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20);'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(0.5)
  },
  divider: {
    backgroundColor: theme.palette.elvenGray[100]
  },
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    alignItems: 'center'
  },
  closeButton: {
    color: theme.palette.elvenGray[500]
  },
  criticalEventLink: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    color: 'inherit',

    '&:hover': {
      textDecoration: 'none'
    },

    '&:hover .criticalEventTitle': {
      color: theme.palette.elvenGreen[500],
      fontWeight: 'bold'
    }
  }
}));
