import { z } from 'zod';

import { makeMonitoringSchema } from '../makeMonitoringSchema';

export const postSchema = makeMonitoringSchema({
  url: z.string().url({ message: 'Invalid url. E.g.: http://host' }),
  skipSslValidation: z.boolean().optional(),
  tlsRenegotiation: z.union([z.string().nonempty({ message: 'Required' }), z.number()]),
  headers: z.array(z.object({ key: z.string(), value: z.string() })).optional(),
  postBodyUrlencoded: z.array(z.object({ key: z.string(), value: z.string() })).optional(),
  postBodyRaw: z.string().optional(),
  postBodyType: z.string().optional(),
  rawType: z.string().optional(),
  elvenAgentRegion: z.string().optional(),
  elvenAgentCloud: z.string().optional(),
  validationString: z
    .string()
    .nullable()
    .optional()
});
