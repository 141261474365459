import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function DeploymentsApplicationMetrics() {
  const dashboardName = MetabaseDashboards.DeploymentsByApplication;

  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const params = {
    application_id: applicationId
  };

  return (
    <MetabaseDashboard
      title="Application deployments metrics"
      subtitle="Application Deployments metrics"
      dashboardName={dashboardName}
      dashboardParams={params}
    />
  );
}

export default withThemeV4(DeploymentsApplicationMetrics);
