import React from 'react';

import Form from '../components/Form';

class MenuItemsEditForm extends React.Component {
  render() {
    const { formState, menuItems, onChange } = this.props;

    return (
      <Form
        formState={formState}
        menuItems={menuItems}
        onChange={onChange}
        title="Edit Menu Item"
      />
    );
  }
}

export default MenuItemsEditForm;
