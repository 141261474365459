import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialogTitle: {
    marginTop: theme.spacing(1),
    padding: 0
  },
  dialogContent: {
    padding: 0,
    marginTop: theme.spacing(5)
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.elvenGray[100]
  },
  buttons: {
    float: 'right',
    minWidth: 240
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));
