export const GetIncidentRuleToResourceQuery = `#graphql
  query($resourceUid: String!) {
    getIncidentRuleByEntityUid(
      entityUid: $resourceUid
    ) {
      uid
      severity
      actions {
        id
        action
        type
        operation
        occurrence
        interval
      }
      teams {
        teamId
      }
    }
  }
`;
