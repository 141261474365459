import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginLeft: 0,
      marginTop: theme.spacing(10),
      paddingBottom: theme.spacing(5),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15)
      }
    }
  };
});

const PageContainer = ({ children, className, ...props }: ContainerProps) => {
  const classes = useStyles();

  return (
    <Container className={clsx(classes.container, className)} {...props}>
      {children}
    </Container>
  );
};

export default PageContainer;
