import { useEffect, useState } from 'react';

import { Container, Typography, CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'urql';

import useQueryParams from 'helpers/useQueryParams';

const saveSlackIntegrationQuery = `
  mutation($integrationData: SaveSlackIntegrationInput!) {
    saveSlackIntegration(integrationData: $integrationData ) 
  }
`;

type SaveSlackIntegrationVariables = {
  integrationData: {
    slackRedirectUri: string;
    slackTemporaryCode: string;
  };
};

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SlackConfirmation = () => {
  const classes = useStyles();

  const [result, validateCode] = useMutation<true, SaveSlackIntegrationVariables>(
    saveSlackIntegrationQuery
  );

  const { fetching, error } = result;

  const queryParams = useQueryParams();

  const slackTemporaryCode = queryParams.get('code') || '';
  const slackRedirectUri = window.location.href.split('?')[0];

  const [integrationCompleted, setIntegrationCompleted] = useState(false);

  useEffect(() => {
    validateCode({
      integrationData: {
        slackRedirectUri,
        slackTemporaryCode
      }
    }).then(() => {
      setIntegrationCompleted(true);
    });
  }, [validateCode, slackTemporaryCode, slackRedirectUri]);

  return (
    <Container className={classes.container}>
      {fetching && (
        <>
          <Typography variant="h3">Integrating slack...</Typography>
          <Box marginTop={2} component={CircularProgress} />
        </>
      )}

      {!fetching && error && (
        <>
          <Typography variant="h3" gutterBottom>
            Oops! Integration failed
          </Typography>
          <Typography variant="subtitle1">
            server response: {error.response?.data?.message}
          </Typography>
        </>
      )}

      {integrationCompleted && !fetching && !error && (
        <>
          <Typography variant="h3" gutterBottom>
            Integrated successfully!
          </Typography>
          <Typography variant="subtitle1">You can close this window now</Typography>
          {window.close()}
        </>
      )}
    </Container>
  );
};

export default SlackConfirmation;
