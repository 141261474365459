import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import { ListPostmortemsQuery } from 'graphqlQueries/listPostmortems';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Postmortem } from 'types/external/Postmortem';
import { useQuery } from 'urql';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import useDebounce from 'hooks/useDebounce';
import { usePermission } from 'hooks/usePermission';

import { MenuActions } from './MenuActions';

const PER_PAGE = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export function PostmortemList() {
  const classes = useGlobalStyles();
  const theme = useTheme();

  const history = useHistory();

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE[0]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const searchElementRef = useRef<HTMLDivElement>(null);

  const [{ fetching, data }, refetch] = useQuery<
    {
      postmortems: {
        data: Postmortem[];
        total: number;
      };
    },
    {
      query: string;
      size: number;
      from: number;
    }
  >({
    query: ListPostmortemsQuery,
    variables: {
      size: perPage,
      query: search,
      from: Math.max(currentPage - 1, 0) * perPage
    }
  });

  const refresh = useCallback(() => {
    setTimeout(() => {
      refetch({ requestPolicy: 'network-only' });
    }, 1000);
  }, [refetch]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const COLUMNS: TableColumn<Postmortem>[] = useMemo(
    () => [
      {
        name: 'Title',
        selector: row => row.title
      },
      {
        name: 'Actions',
        right: true,
        cell: row => (
          <MenuActions
            postmortemUid={row.uid}
            postmortemTitle={row.title}
            refetch={() => refresh()}
          />
        )
      }
    ],
    [refresh]
  );

  const hasCreatePostmortemPermission = usePermission('PostMortemController-post-/post-mortem');

  return (
    <PageContainer className={classes.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={[
          { label: 'Incident Management' },
          { label: 'Postmortem Center', link: '/postmortems' },
        ]}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2.5}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          Postmortem center
        </Typography>

        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => history.push(`/postmortems/new`)}
          disabled={!hasCreatePostmortemPermission}>
          New Postmortem
        </Button>
      </Box>

      <Divider className={classes.divider} />

      <Box
        display="flex"
        justifyContent="space-between"
        mb={7.5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchElementRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Try to search by postmortem title"
          InputProps={{
            endAdornment: <Search />,
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          fullWidth
        />
      </Box>

      <DataTable
        columns={COLUMNS}
        data={data?.postmortems.data ?? []}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={data?.postmortems.total}
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={fetching}
        highlightOnHover
        pagination
        striped
        pointerOnHover={true}
        onRowClicked={(postmortem, e) => {
          if (e.ctrlKey) {
            return window.open(`/postmortems/${postmortem.uid}`, '_blank');
          }
          history.push(`/postmortems/${postmortem.uid}`);
        }}
      />
    </PageContainer>
  );
}
