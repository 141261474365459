import { Tabs } from 'components/Tabs';
import useQueryParams from 'helpers/useQueryParams';
import { withThemeV4 } from 'theme/v4';

import MetabaseDashboardContainer from './Container';
import { Iframe } from './Iframe';
import { MetabaseDashboardProps } from './types';

function MetabaseDashboard({
  title,
  subtitle,
  dashboardParams = {},
  dashboardName,
  hideParams,
  header = true,
  iframeTitle = true,
  iframeHeight,
  iframePadding = true,
  showSubtitle = true,
  iframeRefresh,
  tabs
}: MetabaseDashboardProps) {
  const queryParams = useQueryParams();

  if (!tabs || tabs.length === 0) {
    return (
      <MetabaseDashboardContainer
        title={title}
        subtitle={subtitle}
        header={header}
        iframePadding={iframePadding}
        showSubtitle={showSubtitle}>
        <Iframe
          title={title}
          dashboardName={dashboardName}
          dashboardParams={dashboardParams}
          hideParams={hideParams}
          iframeHeight={iframeHeight}
          iframeRefresh={iframeRefresh}
          iframeTitle={iframeTitle}
        />
      </MetabaseDashboardContainer>
    );
  }

  const tabsWithIframeComponent = tabs
    .filter(tab => !tab.hide)
    .map(({ dashboardName, dashboardParams: tabDashboardParams, ...tab }) => ({
      ...tab,
      component: (
        <Iframe
          title={title}
          dashboardName={dashboardName}
          dashboardParams={tabDashboardParams || dashboardParams}
          hideParams={hideParams}
          iframeHeight={iframeHeight}
          iframeRefresh={iframeRefresh}
          iframeTitle={iframeTitle}
        />
      )
    }));

  const pageSubtitle = (() => {
    const tabSubtitle = tabs.find(tab => tab.slug === queryParams.get('tab'))?.pageSubtitle;

    if (tabSubtitle) return tabSubtitle;

    return `${subtitle} up to yesterday`;
  })();

  return (
    <MetabaseDashboardContainer
      title={title}
      subtitle={pageSubtitle}
      header={header}
      iframePadding={iframePadding}
      showSubtitle={showSubtitle}>
      <Tabs tabs={tabsWithIframeComponent} />
    </MetabaseDashboardContainer>
  );
}

export default withThemeV4(MetabaseDashboard);
