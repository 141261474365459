import dayjs from 'dayjs';
import {
  IncidentManualWithAlerts,
  UpdateManualIncidentWithAlertsPayload
} from 'types/external/IncidentManualWithAlerts';

import { IncidentManualData } from 'views/ServicesHub/layouts/Incident';

export function incidentManualWithAlertsStartValuesAdapter({
  incident,
  teamsFieldOptions,
  monitorsOptions,
  alertsOptions
}: {
  incident: IncidentManualWithAlerts;
  teamsFieldOptions: {
    value: string;
    label: string;
  }[];
  monitorsOptions: {
    value: string;
    label: string;
    type: string;
  }[];
  alertsOptions: {
    value: string;
    label: string;
  }[];
}): IncidentManualData {
  const date = dayjs(incident.failureHappenedAt);

  const getLabelForTeams = ({
    team,
    options
  }: {
    team: number;
    options: { value: string; label: string }[];
  }) => {
    const selected = options.find(option => {
      return Number(option.value) === team;
    });
    return selected ? selected.label : '';
  };

  const getLabelForAlerts = ({
    alert,
    options
  }: {
    alert: string;
    options: { value: string; label: string }[];
  }) => {
    const selected = options.find(option => {
      return option.value === alert;
    });
    return selected ? selected.label : '';
  };

  const getMonitorsData = ({
    origin,
    options
  }: {
    origin: string;
    options: { value: string; label: string; type: string }[];
  }) => {
    const selected = options.find(option => {
      return option.value === origin;
    });
    return selected ? { label: selected.label, type: selected.type } : { label: '', type: '' };
  };

  const teams = !incident.teams
    ? []
    : incident.teams.map(team => {
        return {
          value: String(team.teamId),
          linkedTeamId: team.id,
          label: teamsFieldOptions
            ? getLabelForTeams({ team: team.teamId, options: teamsFieldOptions })
            : ''
        };
      });

  const alerts = !incident.alerts
    ? []
    : incident.alerts.map(alert => {
        return {
          value: alert.alertUid,
          linkedAlertId: alert.id,
          label: alertsOptions
            ? getLabelForAlerts({ alert: alert.alertUid, options: alertsOptions })
            : ''
        };
      });

  const origins = !incident.origins
    ? []
    : incident.origins.map(origin => {
        const originData = monitorsOptions
          ? getMonitorsData({ origin: origin.originUid, options: monitorsOptions })
          : { label: '', type: '' };
        return {
          value: origin.originUid,
          label: originData.label,
          type: originData.type,
          linkedOriginId: origin.id
        };
      });

  return {
    incidentId: incident.id,
    incidentName: incident.title,
    cause: incident.description,
    incidentStatus: incident.status,
    severity: incident.severity,
    startedAt: date.format('YYYY-MM-DDTHH:mm'),
    teams,
    origins,
    alerts
  };
}

export function updateManualIncidentWithAlertsAdapter(
  incident: IncidentManualData,
  incidentId: number
): UpdateManualIncidentWithAlertsPayload {
  return {
    id: incidentId,
    name: incident.incidentName,
    cause: incident.cause,
    status: incident.incidentStatus,
    severity: incident.severity,
    startedAt: dayjs(new Date(incident.startedAt)).format('YYYY-MM-DDTHH:mmZ'),
    teams: incident.teams
      ? incident.teams.map(team => ({
          teamId: Number(team.value),
          id: team.linkedTeamId ? Number(team.linkedTeamId) : 0
        }))
      : [],
    alerts: incident.alerts
      ? incident.alerts.map(alert => ({
          alertUid: alert.value,
          id: alert.linkedAlertId ? Number(alert.linkedAlertId) : 0
        }))
      : [],
    origins: incident.origins
      ? incident.origins.map(origin => ({
          originUid: origin.value,
          originEntity: origin.type,
          id: origin.linkedOriginId ? Number(origin.linkedOriginId) : 0
        }))
      : []
  };
}
