import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function MetricsLeadTimeForChanges() {
  const dashboardName = MetabaseDashboards.LeadTimeForChanges;

  return (
    <MetabaseDashboard
      title=" Lead Time for Changes"
      subtitle="Lead Time for Changes metrics of your applications"
      dashboardName={dashboardName}
    />
  );
}

export default withThemeV4(MetricsLeadTimeForChanges);
