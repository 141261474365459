import React from 'react';

import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

interface CreateManualIncidentButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

function CreateManualIncidentButton({ disabled }: CreateManualIncidentButtonProps) {
  const canShowMetricsButton = useFeaturePlan('Metrics');

  if (!canShowMetricsButton) {
    return null;
  }

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      startIcon={<Add />}
      disabled={disabled}
      href="/services-hub/new?type=incident-manual">
      New Incident
    </Button>
  );
}

export default CreateManualIncidentButton;
