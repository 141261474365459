import * as z from 'zod';

const schema = z.object({
  email: z
    .string()
    .email()
    .nonempty({ message: "Can't be empty" }),
  password: z.string().nonempty({ message: "Can't be empty" })
});

export default schema;
