import React from 'react';

import Form from '../components/Form';

class MenuItemsNewForm extends React.Component {
  render() {
    const { formState, menuItems, onChange } = this.props;

    return (
      <Form formState={formState} menuItems={menuItems} onChange={onChange} title="New Menu Item" />
    );
  }
}

export default MenuItemsNewForm;
