import dayjs from 'dayjs';

import { IncidentManualData } from 'views/ServicesHub/layouts/Incident';

export function incidentManualWithAlertsAdapter(data: IncidentManualData) {
  return {
    name: data.incidentName,
    cause: data.cause,
    status: data.incidentStatus,
    severity: !data.severity ? 'sev-1-critical' : data.severity,
    startedAt: dayjs(new Date(data.startedAt)).format('YYYY-MM-DDTHH:mmZ'),
    origins: data.origins
      ? data.origins.map(origin => ({
          originUid: origin.value,
          originEntity: origin.type
        }))
      : [],
    teams: data.teams ? data.teams.map(team => ({ teamId: Number(team.value) })) : [],
    alerts: data.alerts ? data.alerts.map(alert => ({ alertUid: alert.value })) : []
  };
}
