export const getPermissionForProduct = (
  user: {
    features: { target: string; requiresDependent: boolean }[];
    teamFeatures: { teamId: number; features: { target: string }[] }[];
  },
  product: { id: number; teams: { id: number }[] },
  target: string
) => {
  if (!user) return false;

  if (!product) {
    return false;
  }

  const orgPermission = user?.features?.find(permission => permission.target === target);

  if (orgPermission && !orgPermission.requiresDependent) {
    return true;
  }

  return (
    user?.teamFeatures &&
    user?.teamFeatures.reduce((permission, item) => {
      const found = product.teams && product.teams.find(team => team.id === item.teamId);
      if (found) {
        return !!item.features.find(f => f.target === target);
      }

      return permission;
    }, false)
  );
};

export const getPermissionForTeams = (
  user: { teamFeatures: { teamId: number; features: { target: string }[] }[] },
  teamsId: number | string,
  target: string
) => {
  if (!user) return false;

  const found = !!user?.teamFeatures?.find(item => item.teamId === Number(teamsId));

  if (found) {
    const permission = user?.teamFeatures.map(item => {
      return !!item.features.find(f => f.target === target);
    });

    return permission;
  } else {
    return found;
  }
};

const getPermission = (
  user: { features: { target: string; requiresDependent: boolean }[] },
  requestedFeature: string
) => {
  if (!user) return false;

  return (
    user?.features && Boolean(user.features.find(feature => feature.target === requestedFeature))
  );
};

export default getPermission;
