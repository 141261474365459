import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  makeStyles,
  Box,
  Typography,
  Link,
  FormHelperText,
  Divider,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import DividerWithText from 'components/DividerWithText/DividerWithText';
import { ButtonSimple } from 'componentsV4/Button';
import GoogleButton from 'componentsV4/GoogleButton';
import SimpleInput from 'componentsV4/Input';
import { useSignIn } from 'hooks/queriesRest/useSignIn';
import { Theme, withThemeV4 } from 'theme/v4';

import schema from './schema';

interface StyleProps {
  isSmallScreen: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: props => (props.isSmallScreen ? '5vh' : '')
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: theme.typography.pxToRem(438),
    width: '100%'
  },
  headerText: {
    color: theme.palette.secondary.dark
  },
  input: {
    marginTop: theme.spacing(3)
  },
  forgotPassword: {
    marginTop: theme.spacing(1.5)
  },
  orText: {
    alignSelf: 'center',
    color: grey[500]
  },
  footer: {
    marginTop: theme.spacing(4),
    maxWidth: theme.spacing(54),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(4)
  },
  divider: {
    marginTop: theme.spacing(8)
  },
  dontHaveAccount: {
    color: theme.palette.secondary.dark,
    paddingBottom: theme.spacing(1)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(0.4)
  }
}));

const SignUpV4 = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles({ isSmallScreen });

  const { handleSignIn, loginError, isLoading } = useSignIn();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Box className={classes.header}>
          <Typography variant="h3" className={classes.headerText}>
            Login
          </Typography>

          <GoogleButton text="Sign in with your Google account" />

          <DividerWithText>
            <Typography className={classes.orText} variant="body2">
              or
            </Typography>
          </DividerWithText>
        </Box>

        <form onSubmit={handleSubmit(handleSignIn)}>
          <Box>
            <Box className={classes.input}>
              <SimpleInput
                fullWidth
                name="email"
                id="input-email"
                inputRef={register()}
                required={true}
                placeholder="Ex.: email@email.com"
                defaultLabel="Email"
                error={errors['email']?.message ? true : false || loginError}
              />

              <FormHelperText error>{errors['email']?.message}</FormHelperText>
              <FormHelperText error>{loginError ? 'Wrong email or password' : null}</FormHelperText>
            </Box>

            <Box className={classes.input}>
              <SimpleInput
                name="password"
                id="input-password"
                inputRef={register()}
                required={true}
                defaultLabel="Password"
                type="password"
                placeholder="******"
                error={errors['password']?.message ? true : false || loginError}
              />

              <FormHelperText error>{errors['password']?.message}</FormHelperText>
              <FormHelperText error>{loginError ? 'Wrong email or password' : null}</FormHelperText>
            </Box>

            <Box className={classes.forgotPassword}>
              <Typography color="textPrimary" variant="body2">
                <Link component={RouterLink} to="/recovery" variant="body2">
                  <b>Forgot Password?</b>
                </Link>
              </Typography>
            </Box>

            <Box className={classes.button}>
              <ButtonSimple
                type="submit"
                fullWidth
                isLoading={isLoading}
                variant="contained"
                color="primary"
                text="Login"
                disabled={!isValid}
              />
            </Box>
          </Box>
        </form>
        <Divider className={classes.divider} />
        <Box className={classes.footer}>
          <Box>
            <Typography color="textPrimary" variant="h5" className={classes.dontHaveAccount}>
              Don't have an account yet?
            </Typography>
          </Box>
          <Box>
            <Typography color="textPrimary" variant="body2">
              Start monitoring the health of your technology infrastructure.
              <Link component={RouterLink} to="/sign-up" variant="body2" className={classes.link}>
                Sign up now
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withThemeV4(SignUpV4);
