import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.grey[300]
  }
}));

const AccountSecurity = props => {
  const { className, account_edit: formState, onChange, saveHandler, changeCEP, ...rest } = props;
  const values = formState.values;
  const [error, setError] = React.useState(null);
  const disabled = !values.password || !values.repeatPassword || error;

  const checkPassword = React.useCallback(() => {
    if (values.password !== values.repeatPassword) {
      setError('password does not match');
      return;
    }
    setError(null);
    return;
  }, [values, setError]);

  React.useEffect(() => {
    checkPassword();
  }, [values, checkPassword]);

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange(name, value);
  };

  const handleSave = event => {
    event.preventDefault();
    saveHandler();
  };

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)} variant="outlined">
      <form autoComplete="off" noValidate>
        <CardHeader title="Security" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Password"
                margin="dense"
                type="password"
                name="password"
                onChange={handleChange}
                required
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Repeat Password"
                margin="dense"
                type="password"
                name="repeatPassword"
                onChange={handleChange}
                required
                value={values.repeatPassword}
                variant="outlined"
                error={Boolean(error)}
                helperText={error}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ButtonSimple
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={disabled}
              text="Save"
            />
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

AccountSecurity.propTypes = {
  className: PropTypes.string
};

export default AccountSecurity;
