import { makeStyles } from '@material-ui/core';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { Theme } from 'theme/v4';

import { StatusChipProps } from '.';

type StatusChipStyleProps = {
  status: StatusChipProps['status'];
  severityStatus: StatusChipProps['severityStatus'];
};

function getChipColorByIncidentStatusUpdate(palette: Theme['palette']) {
  return palette.elvenGray[500];
}

function getChipColorByAlertStatus(status: StatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [StatusEnum.Alarmed]: palette.elvenRed[500],
    [StatusEnum.Resolved]: palette.elvenGreen[400],
    [StatusEnum.Acknowledged]: palette.elvenBlue[400],
    [StatusEnum.PostMortem]: palette.elvenYellow[400]
  };

  return statusColorMap[status];
}

function getChipColorBySeverityStatus(status: SeverityStatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [SeverityStatusEnum.NotClassified]: palette.elvenGray[500],
    [SeverityStatusEnum.Critical]: palette.elvenRed[500],
    [SeverityStatusEnum.High]: palette.elvenOrange[400],
    [SeverityStatusEnum.Moderate]: palette.elvenYellow[400],
    [SeverityStatusEnum.Low]: palette.elvenGreen[500],
    [SeverityStatusEnum.Informational]: palette.elvenBlue[400]
  };

  return statusColorMap[status];
}

export const useStyles = makeStyles<Theme, StatusChipStyleProps>(theme => ({
  chipAlertStatus: {
    backgroundColor: 'white',
    color: props => getChipColorByAlertStatus(props.status!, theme.palette),
    borderColor: props => getChipColorByAlertStatus(props.status!, theme.palette),
    marginRight: theme.spacing(1)
  },
  chipSeverityStatus: {
    color: 'white',
    backgroundColor: props =>
      getChipColorBySeverityStatus(
        props.severityStatus ? props.severityStatus : SeverityStatusEnum.NotClassified,
        theme.palette
      ),
    marginRight: theme.spacing(1)
  },
  chipIncidentStatusUpdateSeverityStatus: {
    color: 'white',
    backgroundColor: getChipColorByIncidentStatusUpdate(theme.palette),
    marginRight: theme.spacing(1)
  }
}));
