import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { apm } from '@elastic/apm-rum';
import { useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { useQueryCache } from 'react-query';
import { useMutation as UseMutationUrql } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useQueryParams from 'helpers/useQueryParams';
import useUser from 'hooks/queriesRest/useUser';
import WithThemeV4Hoc from 'theme/v4/withTheme';

import { RedirectComponent, RouteComponent } from './RouteComponent';

export interface RouteWithLayoutV2Props {
  layout: React.ElementType;
  component: React.ElementType;
  isPublicRoute?: boolean;
  location?: any;
  exact: boolean;
  path: string;
}

const CreateSessionMutation = `#graphql
  mutation{
    createSession {
      email
    }
  }
`;

const RouteWithLayoutV2 = (props: RouteWithLayoutV2Props) => {
  const {
    layout: Layout,
    component: Component,
    location: { pathname },
    isPublicRoute,
    ...rest
  } = props;

  const { data: user, isLoading: userLoading } = useUser({});

  const orgId = user?.logged?.org?.id;
  const orgUid = user?.logged?.org?.uid;
  const userId = user?.id;
  const userEmail = user?.email;

  useEffect(() => {
    apm.setUserContext({ id: userId, email: userEmail });
    apm.addLabels({
      orgUid
    });
  }, [userId, orgUid, userEmail]);

  const unleashClient = useUnleashClient();
  const updateUnleashContext = useUnleashContext();
  const unleashContext = useUnleashClient().getContext();

  const unleashContextOrgId = unleashContext.properties?.orgId;
  const unleashContextOrgUid = unleashContext.properties?.orgUid;
  const unleashContextUserId = unleashContext.userId;

  useEffect(() => {
    if (
      orgId !== unleashContextOrgId ||
      orgUid !== unleashContextOrgUid ||
      userId !== unleashContextUserId
    ) {
      const properties = {
        ...(orgId ? { orgId: String(orgId) } : {}),
        ...(orgUid ? { orgUid: String(orgUid) } : {})
      };

      updateUnleashContext({
        userId: userId ? String(userId) : undefined,
        properties
      }).then(() => {
        if (unleashContextOrgUid) {
          unleashClient.start();
        }
      });
    }
  }, [
    orgId,
    updateUnleashContext,
    userId,
    orgUid,
    unleashContextOrgId,
    unleashContextOrgUid,
    unleashContextUserId,
    unleashClient
  ]);

  const utmMedium = useQueryParams().get('utm_medium');
  const utmSource = useQueryParams().get('utm_source');

  const queryCache = useQueryCache();

  const {
    isAuthenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0();

  const [token, setToken] = React.useState('');

  const createSessionMutation = UseMutationUrql(CreateSessionMutation)[1];

  const isLoading = auth0Loading || userLoading;

  React.useEffect(() => {
    if (!token.length) {
      return;
    }

    createSessionMutation(undefined, {
      fetchOptions: {
        credentials: 'include',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    }).then(() => {
      queryCache.invalidateQueries('auth/logged');
    });

    return;
  }, [token, createSessionMutation, queryCache]);

  React.useEffect(() => {
    (async () => {
      if (isAuthenticated && !user && !token && !userLoading) {
        const receivedToken = await getAccessTokenSilently();

        setToken(receivedToken);
      }
    })();
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    user,
    isLoading,
    createSessionMutation,
    pathname,
    token,
    queryCache,
    userLoading
  ]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const pathnameFormated = pathname.replace('/', '');

  const authRoutes = ['sign-in', 'sign-up', 'recovery'];

  //PUBLIC ROUTES

  /*
    Condições para ser verdadeiro:

    1º - a rota é publica
    2º - a rota não existe no array de auth routes

    Exemplo de rotas: /terms, /not-found

    Quando as condições forem satisfeitas será apresentado a tela em questão pro usuário
  */

  if (isPublicRoute && !authRoutes.includes(pathnameFormated)) {
    return <RouteComponent {...rest} layout={Layout} component={Component} />;
  }

  /*
    Condições para ser verdadeiro:

    1 - a rota é publica
    2 - a rota é alguma do array de auth routes
    3 - o usuario não está autenticado no auth0

    Exemplo de rotas: /sign-in, /sign-up, /recovery

    Quando as condições forem satisfeitas o usuário será redirecionado para a rota em questão no auth0
  */

  if (isPublicRoute && authRoutes.includes(pathnameFormated) && !isAuthenticated) {
    pathnameFormated === 'sign-up'
      ? loginWithRedirect({
          fragment: pathnameFormated,
          utm_medium: utmMedium ? utmMedium : undefined,
          utm_source: utmSource ? utmSource : undefined
        })
      : loginWithRedirect({
          fragment: pathnameFormated
        });
  }

  /*
    Condições para ser verdadeiro:

    1 - a rota é publica
    2 - a rota é alguma do array de auth routes
    3 - o usuario não está autenticado no auth0

    Exemplo de rotas: /sign-in, /sign-up, /recovery

    Quando as condições forem satisfeitas o usuário será redirecionado para /
  */

  if (isPublicRoute && authRoutes.includes(pathnameFormated) && isAuthenticated) {
    return <RedirectComponent {...rest} path={'/'} />;
  }

  // PRIVATE ROUTES

  /*
    Condições para ser verdadeiro:

    1 - a rota é privada
    2 - o usuario não está autenticado no auth0

    Exemplo de rotas: todas as rotas que o usuário precisa estar autenticado para conseguir acessar
    Ex: /products, /select-org, /application, /organization

    Quando as condições forem satisfeitas o usuário será levado para o login do auth0
  */

  if (!isPublicRoute && !isAuthenticated) {
    loginWithRedirect();
  }

  const isBlocked = user?.logged?.org?.signatureStatus === 'blocked';

  /*
    Condições para ser verdadeiro:

    1 - o usuário está autenticado e logado
    2 - a organização que o usuário acessou está bloqueada

    Acontece quando o usuário acabou de logar em uma organização bloqueada

    Quando as condições forem satisfeitas o usuário será redirecionado para uma página de organização bloqueada
  */

  if (isBlocked && !['/blocked'].includes(pathname)) {
    return <RedirectComponent {...rest} path="/blocked" />;
  }

  const isAuthenticatedButNoLogged =
    !isPublicRoute && isAuthenticated && user?.email && !user?.logged;

  const isOrgRoute = ['/select-org', '/new_org'].includes(pathname);

  /*
    Condições para ser verdadeiro:

    1 - o usuário está autenticado
    2 - o usuário não está logado em nenhuma organização
    3 - o usuário tem organizações vinculadas a sua conta
    4 - o usuario não está na pagina de /select-org e nem na página de /new_org

    Se o usuário tentar acessar /products estando autenticado mas não logado em alguma organização e se na conta do usuário existe alguma organização vinculada ele será redirecinado para /select-org
  */

  if (isAuthenticatedButNoLogged && user?.accounts.length && !isOrgRoute) {
    return <RedirectComponent {...rest} path="/select-org" />;
  }

  /*
    Condições para ser verdadeiro:

    1 - o usuário está autenticado
    2 - o usuário não está logado em nenhuma organização
    3 - o usuário não tem organizações vinculadas a sua conta
    4 - o usuario não está na pagina de /select-org e nem na página de /new_org

    Se o usuário tentar acessar /products estando autenticado mas não logado em alguma organização e se na conta do usuário não existe alguma organização vinculada ele será redirecinado para /new_org
  */

  if (isAuthenticatedButNoLogged && !user?.accounts.length && !isOrgRoute) {
    return <RedirectComponent {...rest} path="/new_org" />;
  }

  /*
    Condições para ser verdadeiro:

    1 - o usuário está na página de /select-org ou na página de /new_org
    2 - o usuário está autenticado
    3 - o usuário não está logado em alguma organização

    Se o usuário estiver na página de /select-org ou na página de /new_org a página em questão será apresentada
  */

  if (isOrgRoute && isAuthenticated && user?.email && !user?.logged) {
    return <RouteComponent {...rest} layout={Layout} component={Component} />;
  }

  const isAuthenticatedAndLogged = isAuthenticated && user?.email && user?.logged;

  /*
    Condições para ser verdadeiro:

    1 - a rota é privada
    2 - o usuário está autenticado e logado em alguma organização

    Se o usuário estiver autenticado e logado a página em questão é apresentada ao usuário
  */

  if (!isPublicRoute && isAuthenticatedAndLogged) {
    return <RouteComponent {...rest} layout={Layout} component={Component} />;
  }

  return <LoadingOverlay />;
};

export default WithThemeV4Hoc(RouteWithLayoutV2);
