import { TextField } from 'components/Inputs';

const TimeoutField = ({ className, register, errors, disabled, name = 'timeout' }) => {
  return (
    <TextField
      errors={errors}
      className={className}
      register={register}
      label="Timeout (sec)"
      name={name}
      type="number"
      defaultValue="60"
      disabled={disabled}
      variant="outlined"
      InputLabelProps={{
        shrink: true
      }}
      required="true"
    />
  );
};

export default TimeoutField;
