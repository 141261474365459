import React from 'react';

import { useQuery } from 'react-query';

import Breadcrumb from 'components/Breadcrumb';
import axios from 'redux/axios';

import ServicesEditForm from './ServicesEditForm';

function ServicesEdit({ match }) {
  const { id: serviceId } = match.params;
  const { data: service } = useQuery(
    ['services', serviceId],
    async () => (await axios.get(`/services/${serviceId}`)).data.data
  );

  return (
    <>
      <Breadcrumb items={[{ link: `/services`, label: 'Services' }, { label: 'Edit' }]} />
      <ServicesEditForm service={service} />
    </>
  );
}

export default ServicesEdit;
