import { useFlagsStatus } from '@unleash/proxy-client-react';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ProductsCardsList from './V3';
import NewProductsCardsList from './V4';

const Home = () => {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  return newApplications ? <NewProductsCardsList /> : <ProductsCardsList />;
};

export default Home;
