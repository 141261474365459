import React from 'react';

import { Box } from '@material-ui/core';

import PageContainer from 'components/PageContainer';

import Toolbar from '../../../components/Crud/ToolbarQueryProps';
import { NewDataGrid as DataGrid } from '../components/Crud/NewDataGrid';

function EnvironmentsList({ history, permissions }) {
  const [query, setQuery] = React.useState('');

  return (
    <PageContainer maxWidth={false}>
      <Toolbar
        title="Environments"
        buttonNewName="New"
        entity="environments"
        history={history}
        searchPlaceholder="Search environments"
        disableNew={!permissions.new}
        query={query}
        setQuery={setQuery}
      />
      <Box marginTop={4}>
        <DataGrid entity="environments" permissions={permissions} provider="aws" query={query} />
        <DataGrid entity="environments" permissions={permissions} provider="azure" query={query} />
        <DataGrid entity="environments" permissions={permissions} provider="custom" query={query} />
      </Box>
    </PageContainer>
  );
}

export default EnvironmentsList;
