import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import CheckExplorer from 'views/Metrics/CheckExplorer';
import DeploymentsMetricsList from 'views/Metrics/Deployments/List';
import DeploymentsApplicationMetrics from 'views/Metrics/Deployments/View/Application';
import IncidentsMetricsPerDayOfWeek from 'views/Metrics/Incidents/ByDayOfWeek';
import IncidentsMetricsPerHour from 'views/Metrics/Incidents/ByHour';
import IncidentsMetrics from 'views/Metrics/Incidents/List';
import MetricsList from 'views/Metrics/List';
import MetricsLeadTimeForChanges from 'views/Metrics/MetricsLeadTimeForChanges/List';
import MetricsLeadTimeForChangesByApplication from 'views/Metrics/MetricsLeadTimeForChanges/View/Application';
import MetricsProductList from 'views/Metrics/MetricsProduct';
import UptimeMetrics from 'views/Metrics/Uptime/List';
import UptimeApplicationMetrics from 'views/Metrics/Uptime/View/Application';
import UptimeServiceMetrics from 'views/Metrics/Uptime/View/Service';
import MetricsView from 'views/Metrics/View';

const RouteV4WithMainLayout = MainLayoutV4;

export const metricsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsList}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics"
    key="/metrics"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={IncidentsMetrics}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/incidents"
    key="/metrics/incidents"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={IncidentsMetricsPerHour}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/incidents/by-hour"
    key="/metrics/incidents/by-hour"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={IncidentsMetricsPerDayOfWeek}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/incidents/by-day-of-week"
    key="/metrics/incidents/by-day-of-week"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={DeploymentsMetricsList}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/deployments"
    key="/metrics/deployments"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={DeploymentsApplicationMetrics}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/deployments/:applicationId"
    key="/metrics/deployments/:applicationId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={UptimeMetrics}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/uptime"
    key="/metrics/uptime"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsLeadTimeForChanges}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/lead-time-for-changes"
    key="/metrics/lead-time-for-changes"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={UptimeServiceMetrics}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/uptime/:serviceId"
    key="/metrics/uptime/:serviceId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsView}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/:serviceId"
    key="/metrics/:serviceId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsProductList}
    exact
    layout={RouteV4WithMainLayout}
    path="/products/:productId/metrics"
    key="/products/:productId/metrics"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsLeadTimeForChangesByApplication}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/lead-time-for-changes/application/:applicationId"
    key="/metrics/lead-time-for-changes/application/:applicationId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={UptimeApplicationMetrics}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/uptimes/application/:applicationId"
    key="/metrics/uptimes/application/:applicationId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={CheckExplorer}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/checks/service/:serviceId"
    key="/metrics/checks/service/:serviceId"
  />
];
