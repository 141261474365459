import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';

import RolesPermissionForm from './RolesPermissionForm';

class RolesEdit extends React.Component {
  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    const { roles_edit: formState, features, permissions, save, remove } = this.props;

    return (
      <RolesPermissionForm
        formState={formState}
        features={features}
        permissions={permissions}
        save={save}
        remove={remove}
      />
    );
  }
}

export default connect(
  ({ roles_edit, features, permissions }) => ({
    roles_edit,
    features,
    permissions
  }),
  dispatch => ({
    fetch: id => {
      dispatch({
        type: actions.EDIT_ENTITY,
        meta: {
          entity: 'roles',
          id
        }
      });
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'features',
          pagination: false
        }
      });
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'permissions',
          query: [{ key: 'roleId', value: id }]
        }
      });
    },
    save: payload =>
      dispatch({
        type: actions.SAVE_ENTITY,
        payload,
        meta: {
          entity: 'permissions',
          query: [{ key: 'roleId', value: payload.roleId }],
          stayHere: true,
          reload: true
        }
      }),
    remove: payload =>
      dispatch({
        type: actions.DELETE_ENTITY,
        payload,
        meta: {
          entity: 'permissions',
          query: [{ key: 'roleId', value: payload.roleId }],
          stayHere: true
        }
      })
  })
)(RolesEdit);
