import { Menu, MenuItem } from '@material-ui/core';

import { useStyles } from './styles';

export type PeriodProps = {
  onApplyPeriod: ({ period }: { period: string }) => void;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

export function Period({ anchorEl, onApplyPeriod, handleClose }: PeriodProps) {
  const classes = useStyles();

  const handleApplyPeriod = (period: string) => {
    onApplyPeriod({ period });
    handleClose();
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyPeriod('all')}>
        All
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyPeriod('1d')}>
        Last Day
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyPeriod('7d')}>
        Last Week
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyPeriod('30d')}>
        Last Month
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyPeriod('1y')}>
        Last Year
      </MenuItem>
    </Menu>
  );
}
