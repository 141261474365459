import { useEffect, useMemo, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import axios from 'redux/axios';
import { User } from 'views/ServicesHub/adapters/new/user';

import { startValuesAdapter } from './adapters/startValuesAdapter';

interface StartUser {
  id: number;
  name: string;
  role: string;
  email: string;
  status: string;
}
interface UseStartValuesResult {
  startValues?: StartUser;
  fetching: boolean;
}

export function useStartValues(): UseStartValuesResult {
  const { uid: userId } = useParams<{ uid: string }>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  const getUser = useCallback(async () => {
    if (!userId) return;
    setFetching(true);
    try {
      const {
        data: { data }
      } = await axios.get<{ data: User }>(`/users/${userId}/account`);
      setCurrentUser(data);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const startValues = useMemo(() => {
    if (!currentUser) return undefined;
    return startValuesAdapter(currentUser);
  }, [currentUser]);

  return { startValues: startValues, fetching };
}
