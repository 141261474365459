import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  descriptionEllipse: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    backgroundColor: theme.palette.elvenGray[300],
    marginRight: theme.spacing(1)
  },
  eventDescription: {
    marginRight: theme.spacing(1),
    fontSize: '16px'
  }
}));
