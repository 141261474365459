import React from 'react';

import { Button, Box, Icon, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import {
  ScheduledEventDialog,
  ScheduledEventProps
} from 'views/Products/View/Availability/components/ScheduledEvent/Dialog/ScheduledEventDialog';

import { useStyles } from '../../styles';

export function ScheduledEventButton({
  serviceId,
  uid,
  entity,
  disabled
}: {
  serviceId: number;
  uid: string;
  entity: string;
  disabled: boolean;
}) {
  const classes = useStyles();

  const [openingHoursActivated, setOpeningHoursActivated] = React.useState(false);

  const ScheduledEventButton = React.useMemo(
    () => (props: ScheduledEventProps) => {
      return (
        <Tooltip
          title="Operating Hours"
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.tooltipArrow
          }}>
          <Button
            className={classes.icons}
            variant="outlined"
            type="submit"
            {...props}
            disabled={disabled}>
            <Icon fontSize="small">edit_calendar</Icon>
            <Box className={clsx(openingHoursActivated && classes.openingHoursMarginPoint)} />
          </Button>
        </Tooltip>
      );
    },
    [
      disabled,
      openingHoursActivated,
      classes.openingHoursMarginPoint,
      classes.icons,
      classes.tooltip,
      classes.tooltipArrow
    ]
  );

  return (
    <ScheduledEventDialog
      key="edit_calendar"
      openerComponent={(props: ScheduledEventProps) => <ScheduledEventButton {...props} />}
      serviceId={serviceId}
      applicationId={0}
      uid={uid}
      entity={entity}
      setActivated={setOpeningHoursActivated}
    />
  );
}
