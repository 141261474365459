const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Oranization',
    selector: 'org.name',
    sortable: true
  },
  {
    name: 'Description',
    selector: 'description'
  }
];

export default columns;
