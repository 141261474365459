import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(6, 5),
    margin: 0
  },
  title: {
    fontSize: '2.5rem',
    lineHeight: '123.5%',
    fontWeight: theme.typography.fontWeightRegular
  },
  divider: {
    backgroundColor: theme.palette.elvenGray[100],
    margin: theme.spacing(2, 0, 6)
  },
  input: {
    height: theme.spacing(5.5),

    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.elvenGray[200],
      borderStyle: 'solid'
    }
  },
  filterButton: {
    minWidth: 50,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  }
}));
