import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { ServicesHubView } from 'views/ServicesHub';
import { ServicesHubEdit } from 'views/ServicesHub/Edit';
import { ServicesHubNew } from 'views/ServicesHub/New';

const RouteV4WithMainLayout = MainLayoutV4;

export const servicesHubRoutes = [
  <AuthorizedRoute
    component={ServicesHubView}
    exact
    layout={RouteV4WithMainLayout}
    path="/services-hub"
    featureTarget="ServicesHubController-get-/hub"
  />,
  <AuthorizedRoute
    component={ServicesHubNew}
    exact
    layout={RouteV4WithMainLayout}
    path="/services-hub/new"
    featureTarget="ServicesHubController-post-/services"
  />,
  <AuthorizedRoute
    component={ServicesHubEdit}
    exact
    layout={RouteV4WithMainLayout}
    path="/services-hub/edit/:uid"
    featureTarget="ServicesHubController-put-/services"
  />
];
