import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  boxSpacing: {
    marginTop: theme.spacing(1)
  },
  isPrivateIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(-0.8)
  },
  text: {
    fontSize: 14,
    minWidth: 80
  },
  textTime: {
    marginRight: theme.spacing(1),
    fontSize: 14
  },
  descriptionUpdateLine: {
    fontSize: 16,
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(0.5)
  },
  generatedUpdateLine: {
    fontSize: 12,
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(0.5)
  },
  editIcon: {
    color: theme.palette.elvenGreen[400],
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
    marginLeft: theme.spacing(1.8),
    marginRight: theme.spacing(2.3)
  },
  deleteIcon: {
    color: theme.palette.elvenGray[700],
    width: theme.spacing(1.8),
    height: theme.spacing(1.8)
  },
  toggleVisibilityIcon: {
    color: theme.palette.elvenGray[700],
    width: theme.spacing(1.8),
    height: theme.spacing(1.8)
  },
  line: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    height: theme.spacing(4),
    width: 2,
    backgroundColor: theme.palette.elvenGray[200],
    backgroundPosition: 'top',
    backgroundSize: '2px 8px',
    backgroundRepeat: 'repeat-y'
  },
  ellipseSeparator: {
    width: '3px',
    height: '3px',
    backgroundColor: theme.palette.grey[500],
    borderRadius: '50%'
  },
  ellipse: {
    marginRight: theme.spacing(1),
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.elvenGray[700],
    borderRadius: '50%'
  }
}));
