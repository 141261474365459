import { useEffect, useState } from 'react';

import applicationType from 'constants/ApplicationType';

import { Box, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect, useHistory } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Tabs from 'componentsV3/Tab';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';
import TabApplicationWebhook from 'views/V3/Products/View/TabSetup/Componets/TabApplicationWebhook';

import TabPanel from '../components/TabPanel';
import TabProduct from '../View/Product';
import TabSetup from '../View/TabSetup';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  container: {
    padding: spacing(5, 10)
  },
  title: {
    marginBottom: spacing(1)
  },
  subtitle: {
    fontSize: 18
  },
  divider: {
    background: palette.inputBorder.default,
    margin: spacing(4, 0),
    opacity: '30%'
  },
  tabs: {
    marginTop: spacing(4)
  }
}));

function WebhookApplication() {
  const classes = useStyles();

  const router = useHistory();

  const [tabIndex, setTabIndex] = useState(0);

  const [productId, setProductId] = useState<number | null>(null);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [sterasId, setSterasId] = useState<number | null>(null);

  const firstAccess = useQueryParams().has('firstAccess');

  useEffect(
    () => {
      analyticsEvent(firstAccess ? 'fa_view_product_pg_hook' : 'view_product_pg_hook');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        Webhook Application
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Monitor via API, which will run the script and report the status to the platform.
      </Typography>

      <Divider className={classes.divider} />

      <Tabs
        tabsList={[
          { label: 'Product', slug: 'product' },
          { label: 'Application', slug: 'application' },
          {
            label: 'Setup',
            slug: 'setup'
          }
        ]}
        config={{ navigation: process.env.REACT_APP_ENVIRONMENT !== 'production' }}
        tabIndex={tabIndex}
        onChange={(index: number) => setTabIndex(index)}
      />

      <Box marginTop={4}>
        <TabPanel value={tabIndex} index={0}>
          <TabProduct
            firstAccess={firstAccess}
            onFinish={(productId: number) => {
              setProductId(productId);
              setTabIndex(1);
            }}
            applicationType={applicationType.Webhook.type}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <TabApplicationWebhook
            productId={productId}
            onFinish={({ applicationId, sterasId }) => {
              setApplicationId(applicationId);
              setSterasId(sterasId);
              setTabIndex(2);

              sterasId
                ? analyticsEvent(firstAccess ? 'fa_success_monitor_hook' : 'success_monitor_hook')
                : analyticsEvent(firstAccess ? 'fa_fail_monitor_hook' : 'fa_fail_monitor_hook');
            }}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <TabSetup
            onFinish={() => {
              router.push(`/products/${productId}/availability/applications/${applicationId}`);
            }}
            sterasId={sterasId}
            firstAccess={firstAccess}
          />
        </TabPanel>
      </Box>
    </Container>
  );
}

export default withThemeV3Hoc(WebhookApplication);
