import { Box, BoxProps, Button, Typography, makeStyles } from '@material-ui/core';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  infoSectionDescription: {
    color: theme.palette.elvenGray[500]
  }
}));

type Props = BoxProps & {
  isLoading: boolean;
  formComponent: JSX.Element;
  saveButtonLabel: string;
  infoTitle: string;
  infoDescription: string;
};

export const BaseLayout = ({
  isLoading,
  formComponent,
  saveButtonLabel,
  infoTitle,
  infoDescription,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Box component="form" display="flex" gridGap="2.5rem" flexDirection="column" {...props}>
      <Box>
        <Box display="flex" gridGap="12rem">
          <Box flex={1}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              {isLoading && <LoadingOverlay size="2rem" />}
              {formComponent}
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2" color="secondary">
              {infoTitle}
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              {infoDescription}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gridGap="1rem" mt={5}>
          <Button variant="outlined" color="primary">
            Cancel
          </Button>

          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {saveButtonLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
