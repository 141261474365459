import MainLayoutV4 from 'layouts/V4/Main';
import MinimalLayoutV4 from 'layouts/V4/Minimal/Minimal';
import BlockedView from 'views/Blocked';
import { PlansView } from 'views/Plans';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const plansRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={BlockedView}
    exact
    layout={MinimalLayoutV4}
    key="/blocked"
    path="/blocked"
  />,
  <RouteWithLayoutV2 component={PlansView} exact layout={MainLayoutV4} key="/plans" path="/plans" />
];
