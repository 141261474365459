import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router';

import useSchedulesList from 'hooks/useSchedulesList';

import { Recurrence, EndOfRecurrence } from '../../../../constants/callRotationRecurrence';
import DialogEdit from '../CallRotation/components/DialogEdit';

import Calendar from './Calendar';
import DialogCreate from './components/DialogCreate';

const CallRotationV2 = ({ permissions }) => {
  const [createSchedule, setCreateSchedule] = React.useState(false);
  const [editSchedule, setEditSchedule] = React.useState(false);
  const [dataSelected, setDataSelected] = React.useState([]);
  const [events, setEvents] = React.useState([]);

  const [currentDates, setCurrentDates] = React.useState({ end: null, start: null });

  const { id: teamId } = useParams();

  const { data: schedulesList, reexecuteQuery } = useSchedulesList({ teamId, start: currentDates.start, end: currentDates.end });

  const [dataInitSelected, setDataInitSelected] = React.useState(false);
  const [dataEndSelected, setDataEndSelected] = React.useState(false);

  const [recurrence, setRecurrence] = React.useState(Recurrence.DoesNotRepeat);
  const [endOfRecurrence, setEndOfRecurrence] = React.useState(EndOfRecurrence.AfterAMonth);

  const calendarProps = {
    createEvent: createSchedule,
    setCreateEvent: value => {
      setCreateSchedule(value);
      setEditSchedule(!value);
    },
    editEvent: editSchedule,
    setEditEvent: value => {
      setEditSchedule(value);
      setCreateSchedule(!value);
    },
    setCurrentDates: (dates) => {
      setCurrentDates({ start: dates.start, end: dates.end });
      reexecuteQuery();
    },
    dataSelected,
    setDataSelected,
    events,
    setEvents,
    dataInitSelected,
    setDataInitSelected,
    dataEndSelected,
    setDataEndSelected,
    recurrence,
    endOfRecurrence
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} sm={5} item>
          {!createSchedule && !editSchedule && (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <Typography align="center" variant="subtitle2">
                Select one or more dates on calendar
              </Typography>
            </Box>
          )}

          {createSchedule && (
            <DialogCreate
              permissions={permissions}
              refetchSchedules={reexecuteQuery}
              dataSelected={dataSelected}
              setDataSelected={setDataSelected}
              dataInitSelected={dataInitSelected}
              dataEndSelected={dataEndSelected}
              recurrence={recurrence}
              endOfRecurrence={endOfRecurrence}
              setRecurrence={setRecurrence}
              setEndOfRecurrence={setEndOfRecurrence}
              onClose={() => {
                setCreateSchedule(false);
              }}
            />
          )}

          {editSchedule && (
            <DialogEdit
              permissions={permissions}
              eventId={editSchedule}
              refetchSchedules={reexecuteQuery}
              onClose={() => {
                reexecuteQuery();
                setEditSchedule(false);
              }}
            />
          )}
        </Grid>
        <Grid xs={12} sm={7} item>
          <Calendar schedulesList={schedulesList} {...calendarProps} />
        </Grid>
      </Grid>
    </>
  );
};

export default CallRotationV2;
