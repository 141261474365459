import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import DeleteEntity from 'components/DeleteEntityDialog';
import ShowDependencies from 'components/DeleteEntityDialog/components/ShowDependencies';
import actions from 'redux/actions';
import axios from 'redux/axios';

import useServices from './useServices';

const DataGrid = ({ entity, cols, permissions, query }) => {
  const [deleteDialogState, setDeleteDialogState] = React.useState({ isOpen: false, entity: null });
  const dispatch = useDispatch();

  const editHandler = ({ id }) => dispatch(push(`/${entity}/${id}/edit`));

  const [fetchParams, setFetchParams] = React.useState({
    page: 1,
    sort: 'name,ASC',
    per_page: 10
  });

  const queryKey = ['services', fetchParams.page, fetchParams.sort, fetchParams.per_page, query];

  const handlePerRowsChange = per_page =>
    setFetchParams(prevState => ({
      ...prevState,
      per_page
    }));

  const handlePageChange = page =>
    setFetchParams(prevState => ({
      ...prevState,
      page
    }));

  const handleSort = ({ selector }, sortDirection) =>
    setFetchParams(prevState => ({
      ...prevState,
      sort: `${selector},${sortDirection.toUpperCase()}`
    }));

  const removeHandler = payload =>
    dispatch({
      type: actions.DELETE_ENTITY,
      payload,
      meta: {
        entity
      }
    });

  const { data, isLoading: isLoadingServices } = useServices(queryKey, {
    ...fetchParams,
    query
  });

  const deleteIcon = row => {
    return (
      <IconButton
        title="Remove"
        data-name={row.name}
        onClick={() => {
          setDeleteDialogState({ isOpen: true, entity: row });
        }}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const editIcon = row => {
    if (!permissions?.update) {
      return (
        <div title="You are not allowed to update">
          <IconButton disabled={true}>
            <EditIcon />
          </IconButton>
        </div>
      );
    }

    return (
      <IconButton
        title="Edit"
        data-name={row?.name}
        data-isactive={row?.isActive}
        onClick={event => {
          event.preventDefault();
          editHandler(row);
        }}
        variant="outlined">
        <EditIcon />
      </IconButton>
    );
  };

  const { data: dependencies, isLoading } = useQuery(
    ['servicesDependencies', deleteDialogState.entity?.id],
    () =>
      axios
        .get(`services/${deleteDialogState.entity?.id}/dependencies`)
        .then(response => response.data.data),
    {
      enabled: deleteDialogState.isOpen
    }
  );

  const columns = [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => editIcon(row)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => deleteIcon(row)
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false
  };

  const dataGridTheme = {
    tableWrapper: {
      style: {
        display: 'block'
      }
    }
  };

  return data?.data?.length || isLoadingServices ? (
    <>
      <DataTable
        clicked
        columns={columns}
        customTheme={dataGridTheme}
        data={data?.data}
        highlightOnHover
        noDataComponent="No data"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={data?.total}
        sortServer
        striped
      />
      {deleteDialogState.isOpen && (
        <DeleteEntity
          open={true}
          handleClose={() => setDeleteDialogState({ isOpen: false, entity: null })}
          entityName="Service"
          isLoading={isLoading}
          disabled={!permissions.remove || dependencies?.length || isLoading}
          handleRemove={() => {
            const { entity } = deleteDialogState;
            removeHandler(entity);
            setDeleteDialogState({ isOpen: false, entity: null });
          }}>
          <ShowDependencies
            dependencies={[
              {
                items: dependencies || [],
                type: 'Application'
              }
            ]}
            title="Cannot remove because there are dependencies"
          />
        </DeleteEntity>
      )}
    </>
  ) : null;
};

export default DataGrid;
