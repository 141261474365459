import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import DeleteEntity from 'components/DeleteEntityDialog';
import actions from 'redux/actions';
import reducer from 'redux/reducer/crud';
import { Loading } from 'views';

import {
  Row,
  GridParams,
  getParams,
  getStyles,
  getColumns,
  deleteIcon
} from './utils/DataGridUtils';

const DataGridUseMSTeams = ({
  deleteQuery,
  entity,
  cols,
  title,
  mapEntity,
  permissions,
  argsDelete,
  deleteParams,
  setPage,
  setPerPage,
  setSort,
  resultQuery,
  refetchResult,
  editPath,
  setIsLoading
}: GridParams) => {
  const [data, setData] = useState(resultQuery?.isFetching ? [] : resultQuery?.data);
  const [total, setTotal] = useState(resultQuery?.isFetching ? 0 : resultQuery?.total);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(resultQuery?.isFetching ? [] : resultQuery?.data);
    setTotal(resultQuery?.isFetching ? 0 : resultQuery?.total);
  }, [resultQuery]);

  const editHandler = ({ id }: { id: number }) => dispatch(push(`/${editPath}/${id}/`));

  const [, deleteTeam] = useMutation(deleteQuery);

  const removeHandler = (payload: { id: number } | null) => {
    setIsLoading(true);
    const params = getParams(argsDelete, payload);

    return deleteTeam({ ...params, ...deleteParams }).then(({ error }) => {
      if (error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on remove Entity' }
        });
      } else {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Entity removed successfully'
        });
        refetchResult();
      }
      setIsLoading(false);
    });
  };

  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, id: null });

  const handlePerRowsChange = (per_page: number) => {
    setPerPage(per_page);
    dispatch({
      type: actions.PAGINATE_ENTITY,
      payload: { per_page },
      meta: {
        entity,
        reducer
      }
    });
  };
  const handlePageChange = (page: number) => {
    setPage(page);
    dispatch({
      type: actions.PAGINATE_ENTITY,
      payload: { page },
      meta: {
        entity,
        reducer
      }
    });
  };

  const handleSort = (row: TableColumn<Row>, sortDirection: string) => {
    const name = row.name as string;
    setSort(`${name.toLowerCase()},${sortDirection.toUpperCase()}`);
    dispatch({
      type: actions.PAGINATE_ENTITY,
      payload: {
        sort: `&sort=${name.toLowerCase()},${sortDirection.toUpperCase()}`
      },
      meta: {
        entity,
        reducer
      }
    });
  };

  const columns = getColumns({
    cols,
    permissions,
    deleteIcon,
    editHandler,
    mapEntity,
    setDeleteModalState
  });

  const { dataGridTheme, paginationComponentOptions } = getStyles(title);

  return (
    <>
      {resultQuery.isFetching ? (
        <Loading />
      ) : (
        <DataTable
          // @ts-ignore
          clicked
          // @ts-ignore: Unreachable code error//ts-ignore-next-line
          columns={columns}
          customTheme={dataGridTheme}
          data={data}
          highlightOnHover
          noDataComponent="No data"
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          onSort={handleSort}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationRowsPerPageOptions={[10, 25, 50]}
          paginationServer
          paginationTotalRows={total}
          sortServer
          striped
          title={title}
        />
      )}
      <DeleteEntity
        open={deleteModalState.isOpen}
        handleClose={() => setDeleteModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          removeHandler(deleteModalState.id);
          setDeleteModalState({ isOpen: false, id: null });
        }}
        entityName="Product"
        disabled={!permissions?.remove}
        isLoading={false}
        customTitle={''}
        agentInstalled={''}
        children={''}
      />
    </>
  );
};

export default DataGridUseMSTeams;
