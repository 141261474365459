import { ResourceOrigin, ResourceQuery, ResourceStatusEnum } from 'types/external/Resource';

export const resourceDefaultValue: ResourceQuery = {
  id: 0,
  name: 'Resource',
  domain: 'none',
  interval: 0,
  timeout: 0,
  origin: ResourceOrigin.Agent,
  type: 'none',
  method: 'none',
  serviceId: 0,
  domainSettings: {},
  status: {
    status: ResourceStatusEnum['Off'],
    lastCheck: 'No data'
  },
  environment: {
    name: 'none',
    statusCode: 0
  }
};

export const maintenanceDefaultValue = {
  id: 0,
  status: 'pending',
  applicationId: 0,
  dependencies: null,
  orgUid: '',
  serviceId: 0,
  start: '',
  userUid: '',
  finish: '',
  forceFinish: ''
};
