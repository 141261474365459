import { CardContent, Grid, Paper } from '@material-ui/core';
import dayjs from 'dayjs';

import { Typography } from 'components/Typography';

import useStyles from './styles';

const CardTimeInfo = ({ title, value, isTime }: { title: string; value: any; isTime: boolean }) => {
  const classes = useStyles();

  const getTime = (value: any): string => {
    let dayReturn = '';
    const newDate = new Date(2000, 0, 1, 0, 0, 0);
    const parsedDate = new Date(newDate.getTime() + 1000 * value);

    var days = parsedDate.getDate() - 1;
    var hours = parsedDate.getHours();
    var minutes = parsedDate.getMinutes();
    var seconds = parsedDate.getSeconds();

    if (value === 0) {
      return ' - ';
    }

    if (value < 60) {
      return seconds + ' seconds';
    }

    if (days >= 1) {
      dayReturn = days === 1 ? '1 day, ' : days + ' days, ';
    }

    if (hours >= 1) {
      const hourReturn = hours + (hours === 1 ? 'hour' : ' hours');

      if (minutes > 0) {
        if (seconds > 0) {
          return dayReturn + hourReturn + ', ' + minutes + ' min and  ' + seconds + ' secs';
        }

        return dayReturn + hourReturn + ' and ' + minutes + ' minutes';
      }

      return dayReturn + hourReturn;
    }

    if (minutes > 0) {
      if (seconds > 0) {
        return minutes + ' minutes and ' + seconds + ' seconds';
      }

      return minutes + ' minutes';
    }

    return seconds + ' seconds';
  };

  return (
    <Grid item md={3}>
      <CardContent className={classes.content}>
        <Paper elevation={1} className={classes.root}>
          <Typography className={classes.title} color="secondary" variant="h5">
            {title}
          </Typography>
          <Typography className={classes.body} variant="body2">
            {isTime
              ? getTime(value)
              : value
              ? dayjs(new Date(value)).format('MMM, D, HH:mm z')
              : ' - '}
          </Typography>
        </Paper>
      </CardContent>
    </Grid>
  );
};

export default CardTimeInfo;
