import React from 'react';

import { Typography, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Dialog } from 'design-system';

import { LoadingOverlay } from 'componentsV4/Loading';
import useNewGetDependencies from 'hooks/queriesGraphQL/useNewGetDependencies';

import { getStartValues, getStartValuesForHttpFlow } from '../../../View/TopSection/startValues';

const useStyles = makeStyles(theme => ({
  paragraphText: {
    paddingBottom: theme.spacing(3)
  }
}));

const GetDependencyQuery = `#graphql
  query($id: Int!) {
    dependencyWithOrgUid(id: $id) {
    	id
      service {
        name
        domain
        headers
        method
        post_body_type
        post_body
        domain_settings
      }
      
    }
  }
`;

const ResourceDialog = ({
  openerComponent: OpenerComponent,
  formComponent: FormComponent,
  title,
  description,
  onUpdateAddon,
  reexecuteQueryUniqueResourceStatus,
  application,
  maxWidth = 'md',
  addonHttpFlow
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const addonId = addonHttpFlow && addonHttpFlow.length ? addonHttpFlow[0].id : null;

  const { result: resultNewGetDependencies, fetching } = useNewGetDependencies({
    addonId: addonId,
    executeQuery: open,
    personalizedQuery: GetDependencyQuery
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const startValues = () => {
    if (addonId) {
      return resultNewGetDependencies.id && !fetching
        ? getStartValuesForHttpFlow(application, resultNewGetDependencies)
        : null;
    }
    if (application) {
      return getStartValues(application);
    }
    return null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <OpenerComponent onClick={handleOpen} />
      <Dialog
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        title={title}
        maxWidth={maxWidth}
        fullWidth>
        {fetching && <LoadingOverlay />}
        {!fetching && (
          <>
            <Typography className={classes.paragraphText} color={theme.palette.text.primary}>
              {description}
            </Typography>

            <FormComponent
              onClose={handleClose}
              startValues={startValues()}
              onUpdateAddon={onUpdateAddon}
              application={application}
              reexecuteQueryUniqueResourceStatus={reexecuteQueryUniqueResourceStatus}
            />
          </>
        )}
      </Dialog>
    </>
  );
};

export default ResourceDialog;
