export const GetAccountsQuery = `#graphql
  query($page: Float!, $per_page: Float! , $sort: String!, $query: String!) {
    accounts(page: $page, per_page: $per_page, sort: $sort, query: $query) {
      data {
        id
        isActive
        role {
          name
        }
        user {
          id
          email
          displayName
          photoURL
          profile {
            displayName
            phone
            verifiedPhone
            photoURL
          }
        }
      }
      total
    }
  }
`;

export const DeleteAccountMutation = `#graphql
  mutation($id: Float!) {
    deleteAccount(id: $id)
  }
`;
