import { default as axiosDefault } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';

import useSelectAccount from 'hooks/queriesGraphQL/useSelectAccount';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { planFeaturesQueryKey } from 'hooks/useFeaturePlan';

import { SelectAccountFormData } from './useForm/schema';

const useAppActions = () => {
  const queryCache = useQueryCache();
  const history = useHistory();

  const { selectUserAccount, isLoading } = useSelectAccount();

  const newSelectAccount = useFeatureFlagUnleash('newSelectAccount', {
    queryString: true
  });

  const [selectAccountOrg, { isLoading: isLoadingSelectAccountMutation }] = useMutation(
    async (orgId: number) => {
      return axiosDefault({
        url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
        method: 'post',
        withCredentials: true,
        data: {
          query: `
            query {
              selectAccount(orgId: ${orgId})
            }
          `
        }
      });
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(planFeaturesQueryKey);
        queryCache.invalidateQueries('auth/logged').then(() => {
          history.push('/');
        });
      }
    }
  );

  const handleSelectAccount = async (data: SelectAccountFormData) => {
    if (!newSelectAccount) {
      return selectAccountOrg(data.orgId);
    }

    return selectUserAccount({ orgId: data?.orgId }).then(async () => {
      await queryCache.invalidateQueries(planFeaturesQueryKey);
      queryCache.invalidateQueries('auth/logged').then(() => {
        history.push('/');
      });
    });
  };

  return {
    handleSelectAccount,
    isLoading: isLoadingSelectAccountMutation || isLoading
  };
};

export default useAppActions;
