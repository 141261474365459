import React from 'react';

import { Tabs, Tab, Typography, Box, Backdrop, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { useQuery as useQueryUrql } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import reducer from 'redux/reducer/teams/change';

import Toolbar from '../components/Toolbar';

import AdvancedNotifySettings from './AdvancedNotifySettings';
import CallRotationV2 from './CallRotationV2';
import columns from './components/DataGrid/columns';
import MembersList from './Members/List';
import NotificationForm from './Notifications';
import ProductsList from './Products/List';

const getTeamQuery = `#graphql
  query(
    $teamId: Int!
  ) {
    getTeam(
      teamId: $teamId
    ) {
      id
      name
      orgUid
    }
  }
`;

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  breadcrumb: {
    marginLeft: 0,
    marginTop: 0
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      id={`full-width-tabpanel-${index}`}
      component="div"
      hidden={value !== index}
      role="tabpanel"
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const TeamView = props => {
  const {
    classes,
    teams_view: view,
    handleTabChange,
    handleOpen,
    autoCompleteHandleChange
  } = props;
  const history = useHistory();
  const tabViewQuery = useQueryParams().get('tab');

  const permissions = {
    new: usePermission('TeamController-post-/teams/:teamId/add_members'),
    editNotification: usePermission('ProvidersController-put-/providers/update_providers'),
    editTeams: usePermission('TeamController-put-/teams/:id'),
    newCallRotation: usePermission('CallrotationController-post-/callrotation/stepGroups'),
    editCallRotation: usePermission('CallrotationController-put-/callrotation/schedule/:id'),
    deleteCallRotation: usePermission('CallrotationController-delete-/callrotation/stepGroups/:id'),
    editAdvancedNotify: usePermission('NotifySettingsController-post-/notify_settings/:teamId')
  };

  const tabsIndexes = {
    members: 0,
    products: 1,
    notifications: 2,
    'call-rotation': 3,
    'advanced-notify-settings': 4
  };

  const value = tabViewQuery && tabViewQuery in tabsIndexes ? tabsIndexes[tabViewQuery] : view.tab;

  const {
    match: {
      params: { id }
    }
  } = props;

  const { data: teams_edit } = useQuery('teams', async () => {
    return { data: {} };
  });

  const [result] = useQueryUrql({
    query: getTeamQuery,
    variables: { teamId: Number(id) }
  });

  const handleChange = (event, newValue) => {
    if (tabViewQuery) {
      history.replace(`/teams/${id}`);
    }
    handleTabChange(newValue);
  };

  const handleChangeIndex = index => {
    handleTabChange(index);
  };

  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const [teamData, setTeamData] = React.useState({});

  const canUseCallRotationFeaturePlan = useFeaturePlan('CallRotation');
  const canUseNotificationsFeaturePlan = useFeaturePlan('NotifyChannels');
  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  React.useEffect(() => {
    setTeamData(result.data?.getTeam);
  }, [result, teams_edit]);

  return (
    <>
      {teamData ? (
        <div key={teamData.id} className={classes.root}>
          <Breadcrumb
            className={classes.breadcrumb}
            items={[
              {
                link: `/teams`,
                label: 'Teams'
              },
              { label: teamData.name }
            ]}
          />
          <Toolbar
            autoCompleteHandleChange={autoCompleteHandleChange}
            entity="users"
            handleOpen={handleOpen}
            searchPlaceholder="Add a new user using the email account"
            view={teamData}
            newUserDisabled={!permissions.new}
            editDisabled={!permissions.editTeams}
          />
          <div className={classes.content}>
            <Tabs
              indicatorColor="primary"
              onChange={handleChange}
              textColor="primary"
              value={value}
              variant="fullWidth">
              <Tab label="Members" value={0} />
              {!shouldUseNewApplications && <Tab label="Products" value={1} />}
              {canUseNotificationsFeaturePlan && <Tab label="Notifications" value={2} />}
              {canUseCallRotationFeaturePlan && <Tab label="Call Rotation" value={3} />}
              {!shouldUseNewApplications && <Tab label="Advanced Notify Settings" value={4} />}
            </Tabs>
            <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
              <TabPanel index={0} value={value}>
                <MembersList
                  cols={columns.members}
                  entity="teams_members"
                  disabled={!permissions.new}
                  notificationSetup={teamData.notificationSetup || []}
                />
              </TabPanel>
              <TabPanel index={1} value={value}>
                <ProductsList view={view} cols={columns.products} entity="teams_products" />
              </TabPanel>
              <TabPanel index={2} value={value}>
                <NotificationForm
                  setShowBackdrop={setShowBackdrop}
                  canEdit={permissions.editNotification}
                />
              </TabPanel>

              <TabPanel index={3} value={value}>
                <CallRotationV2 permissions={permissions} />
              </TabPanel>

              <TabPanel index={4} value={value}>
                <AdvancedNotifySettings view={view} canEdit={permissions.editAdvancedNotify} />
              </TabPanel>
            </SwipeableViews>
          </div>
          {showBackdrop && (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress size={90} thickness={5} />
            </Backdrop>
          )}
        </div>
      ) : null}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEntity: id =>
      dispatch({
        type: actions.EDIT_ENTITY,
        meta: {
          entity: 'teams',
          id: id
        }
      }),
    handleTabChange: payload => {
      dispatch({
        type: actions.TEAMS_CHANGE_TAB,
        payload,
        meta: {
          reducer
        }
      });
    },
    handleOpen: payload => {
      dispatch({
        type: actions.TEAMS_AUTOCOMPLETE_OPEN,
        payload,
        meta: {
          reducer
        }
      });
    },
    autoCompleteHandleChange: payload => {
      dispatch({
        type: actions.TEAMS_AUTOCOMPLETE_CHANGE,
        payload,
        meta: {
          reducer
        }
      });
    }
  };
};

const mapStateToProps = ({ teams_view, teams_edit, user }) => ({
  teams_view: teams_view,
  teams_edit: teams_edit
});

const ConnectedTeamView = connect(mapStateToProps, mapDispatchToProps)(TeamView);

export default withStyles(styles)(ConnectedTeamView);
