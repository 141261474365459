import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'components/MetabaseDashboard';

interface ResourcesProps {
  application: number;
}

function Resources(props: ResourcesProps) {
  const application = props.application;

  const dashboardName = MetabaseDashboards.DeliveriesChecks;

  const hideParams = ['application'];

  const params = {
    application: application
  };

  return (
    <MetabaseDashboard
      title="Last Deployments"
      subtitle="List of deliveries"
      dashboardName={dashboardName}
      dashboardParams={params}
      header={false}
      hideParams={hideParams}
      iframeHeight={570}
      iframePadding={false}
      iframeTitle={false}
    />
  );
}

export default Resources;
