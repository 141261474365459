import { TextField } from 'components/Inputs';

const IntervalField = ({ className = '', register, errors, disabled, name = 'check_interval' }) => {
  return (
    <TextField
      className={className}
      register={register}
      label="Interval (sec)"
      name={name}
      errors={errors}
      type="number"
      defaultValue="60"
      disabled={disabled}
      variant="outlined"
      InputLabelProps={{
        shrink: true
      }}
      required="true"
    />
  );
};

export default IntervalField;
