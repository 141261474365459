import { Box, Button, Typography } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router';
import { useQuery as useQueryGraphQl } from 'urql';

import useUser from 'hooks/queriesRest/useUser';

const useStyles = makeStyles(theme => ({
  root: {},
  main: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  h2: {
    marginBottom: 30
  },
  text: {
    lineHeight: 1.5,
    fontSize: 18
  },
  button: {
    width: '520px',
    height: '67px',
    fontSize: 23,
    color: 'white',
    background: 'linear-gradient(90deg, #286B6B 0%, #37B877 100%); border-radius: 3px'
  }
}));

const Blocked = () => {
  const classes = useStyles();
  const { data: user } = useUser();

  const GetPlansOrgStatus = `#graphql
  query {
    orgStatus {
      show
      status
      message
      days                
    }
  }
`;

  const [resultOrgStatus] = useQueryGraphQl({
    query: GetPlansOrgStatus,
    pause: !user
  });

  const dataFromGraphQl = resultOrgStatus.data?.orgStatus;

  if (user?.logged?.org?.signatureStatus === 'active') {
    return <Redirect to="/" />;
  }

  return (
    <Box display="flex" className={classes.root}>
      <Box flexBasis="50%" pr={4} className={classes.main}>
        <Typography variant="h2" className={classes.h2}>
          Evaluation period ended
        </Typography>
        <Typography className={classes.text}>Hello {user?.displayName}, how are you?</Typography>
        <br></br>
        <Typography className={classes.text}>
          If {dataFromGraphQl?.days} day(s) have passed since the day you started to try One
          Platform, we hope you have enjoyed it so far, but as the trial period has ended, it is
          necessary to regularize your subscription to continue using it.
        </Typography>
        <br></br>
        <br></br>
        <Typography className={classes.text}>
          Rest assured, everything you've done on the platform will be saved for the moment you use
          it again.
        </Typography>
        <br></br>
        <br></br>
        <Typography className={classes.text}>
          Contact one of our service channels to regularize your subscription and enjoy all the
          monitoring and observability benefits that the platform offers.
        </Typography>
        <br></br>
        <br></br>
        <Typography className={classes.text}>
          Send a message in the chat on the side, or if you prefer, call or send a message to the
          service channel.
        </Typography>
        <br></br>
        <a
          href="https://api.whatsapp.com/send?phone=5511940702988"
          title="Open whatsapp"
          target="_blank"
          rel="noreferrer">
          <Button variant="contained" className={classes.button}>
            Subscribe now!
            <WhatsApp
              style={{
                color: 'white',
                marginLeft: '10',
                marginRight: '10'
              }}
            />
            (11) 94070-2988
          </Button>
        </a>
      </Box>
      <Box id="chat" name="chat" flexBasis="50%" className={classes.chat}></Box>
    </Box>
  );
};

export default Blocked;
