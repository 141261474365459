import { RefObject } from 'react';

import postMortemTemplate from 'constants/postMortemTemplate';

import { Box, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UseMutationResponse } from 'urql';

import { ButtonDefault } from 'components/Button';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import MarkdownEditor from 'componentsV4/MarkdownEditor';
import MarkdownIcon from 'icons/Markdown';
import actions from 'redux/actions';

import useStyles from '../styles';

type FormValues = { postMortemId: string; postMortem: string };

function PostMortemForm({
  incident,
  postMortemRef,
  onPostMortemSave,
  useUpdateMutation,
  useCreateMutation
}: {
  incident: {
    postMortem?: { id: number; postMortem: string };
    incidentId: number;
    applicationId: number;
    applicationUid: string;
    addonId: number;
    serviceId: number;
  };
  postMortemRef: RefObject<HTMLDivElement>;
  onPostMortemSave: () => void;
  useUpdateMutation: () => UseMutationResponse;
  useCreateMutation: () => UseMutationResponse;
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    control,
    register,
    formState: { isDirty },
    handleSubmit,
    reset
  } = useForm<FormValues>({
    defaultValues: {
      postMortemId: incident.postMortem?.id ? String(incident.postMortem?.id) : undefined,
      postMortem: incident.postMortem?.postMortem
    }
  });

  const [{ fetching: isUpdatingPostMortem }, updatePostMortem] = useUpdateMutation();

  const [{ fetching: isCreatingPostMortem }, createPostMortem] = useCreateMutation();

  async function handleSubmitPostMortem(formData: FormValues) {
    if (formData.postMortemId) {
      const { data, error } = await updatePostMortem({
        id: Number(formData?.postMortemId),
        incidentId: Number(incident.incidentId),
        postMortem: formData.postMortem,
        serviceId: incident.serviceId || undefined,
        title: 'Untitled document'
      });

      if (error) {
        return dispatch({
          type: actions.ENTITY_ERROR,
          payload: new Error('Something went wrong while updating post mortem. Please try again.')
        });
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Post mortem updated successfully.'
      });

      onPostMortemSave();

      return reset({
        postMortem: data?.updatePostMortem?.postMortem || data?.updatePostMortemV2.postMortem
      });
    }

    const { data, error } = await createPostMortem({
      incidentId: incident.incidentId,
      postMortem: formData.postMortem,
      serviceId: incident.serviceId || undefined,
      title: 'Untitled document'
    });

    if (error) {
      return dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error('Something went wrong while creating post mortem. Please try again.')
      });
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Post mortem created successfully.'
    });

    onPostMortemSave();

    return reset(
      data?.createPostMortem
        ? {
            postMortemId: String(data?.createPostMortem?.id),
            postMortem: data?.createPostMortem?.postMortem
          }
        : {
            postMortemId: String(data?.createPostMortemV2?.id),
            postMortem: data?.createPostMortemV2?.postMortem
          }
    );
  }

  return (
    <Box mt={4} position="relative" clone>
      <div ref={postMortemRef}>
        {(isUpdatingPostMortem || isCreatingPostMortem) && <LoadingOverlay />}

        <Typography variant="h3" color="secondary" gutterBottom>
          Post mortem
        </Typography>

        <Typography variant="caption" color="textPrimary" gutterBottom>
          <span className={classes.markdownTipText}>
            Tip: Markdown is supported <MarkdownIcon fontSize="small" />
          </span>
        </Typography>

        <form onSubmit={handleSubmit(handleSubmitPostMortem)}>
          <input type="hidden" ref={register} name="postMortemId" />

          <MarkdownEditor
            name="postMortem"
            control={control}
            defaultValue={incident?.postMortem?.postMortem || postMortemTemplate}
          />

          <Box mt={3}>
            <ButtonDefault
              type="submit"
              size="medium"
              children="Save changes"
              variant="contained"
              disabled={!isDirty || isUpdatingPostMortem}
            />
          </Box>
        </form>
      </div>
    </Box>
  );
}

export default PostMortemForm;
