import React from 'react';

import { IconButton, Typography, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationUrql } from 'urql';

import actions from 'redux/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  input: {
    fontSize: 20,
    maxWidth: 600
  },
  icon: {
    marginLeft: theme.spacing(1)
  }
}));

const CrudToolbar = props => {
  const {
    className,
    buttonNewName,
    searchPlaceholder,
    entity,
    view,
    handleOpen,
    autoCompleteHandleChange,
    newUserDisabled,
    editDisabled,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const [name, setName] = React.useState(view.name);
  const [isNameFocused, setIsNameFocused] = React.useState(false);

  const UpdateTeamV2 = `#graphql
  mutation($name: String!, $teamId: Float!) {
  updateTeam(name: $name, teamId: $teamId) {
    name
    id
  }
}
`;

  const [, updateTeamV2] = useMutationUrql(UpdateTeamV2);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Avatar alt="Avatar" src={view.logo} className={classes.avatar} />

        {!isNameFocused ? (
          <Typography variant="h4">{name}</Typography>
        ) : (
          <TextField
            autoFocus
            inputProps={{ className: classes.input }}
            value={name}
            onChange={event => setName(event.target.value)}
            onBlur={() => {
              if (!name.length) {
                return null;
              }

              setIsNameFocused(false);
              updateTeamV2({ name: name, teamId: view.id }).then(() => {
                dispatch({
                  type: actions.GLOBAL_SUCCESS,
                  payload: 'Updated successfully'
                });
              });
            }}
            error={!name.length ? true : false}
            helperText={!name.length ? 'Field cannot be empty' : null}
          />
        )}

        <IconButton
          className={isNameFocused ? null : classes.icon}
          disabled={editDisabled}
          onClick={() => {
            setIsNameFocused(true);
          }}>
          <EditIcon />
        </IconButton>
      </div>
    </div>
  );
};

CrudToolbar.propTypes = {
  className: PropTypes.string
};

export default CrudToolbar;
