import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import ProductEditView from 'views/Products/Edit';
import ProductListView from 'views/Products/List';
import ProductView from 'views/Products/View';
import ProductAvailabilityView from 'views/Products/View/Availability/View';
import ExternalWithAddons from 'views/V3/Products/New/ExternalWithAddons';
import ExternalWithoutAddons from 'views/V3/Products/New/ExternalWithoutAddons';
import InternalApplication from 'views/V3/Products/New/InternalApplication';
import MultiHttp from 'views/V3/Products/New/MultiHttp';
import WebhookApplication from 'views/V3/Products/New/WebhookApplication';

export const productsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={ProductListView}
    exact
    layout={MainLayoutV4}
    key="/products"
    path="/products"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ProductView}
    exact
    layout={MainLayoutV4}
    key="/products/:id"
    path="/products/:id"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ProductEditView}
    exact
    layout={MainLayoutV4}
    key="/products/:id/edit"
    path="/products/:id/edit"
    planFeature="Monitoring"
    featureTarget="ProductController-put-/products/:id"
  />,
  <AuthorizedRoute
    component={ProductAvailabilityView}
    exact
    layout={MainLayoutV4}
    key="/products/:productId/availability/applications/:applicationId"
    path="/products/:productId/availability/applications/:applicationId"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    exact
    component={ExternalWithoutAddons}
    layout={MainLayoutV4}
    key="/products/v3/new/externalWithoutAddons"
    path="/products/v3/new/externalWithoutAddons"
    planFeature="Monitoring"
    featureTarget="ApplicationController-post-/products/:productId/applications"
  />,
  <AuthorizedRoute
    exact
    component={ExternalWithAddons}
    layout={MainLayoutV4}
    key="/products/v3/new/externalWithAddons"
    path="/products/v3/new/externalWithAddons"
    planFeature="Monitoring"
    featureTarget="ApplicationController-post-/products/:productId/applications"
  />,
  <AuthorizedRoute
    exact
    component={InternalApplication}
    layout={MainLayoutV4}
    key="/products/v3/new/internalApplication"
    path="/products/v3/new/internalApplication"
    planFeature="Monitoring"
    featureTarget="ApplicationController-post-/products/:productId/applications"
  />,
  <AuthorizedRoute
    exact
    component={WebhookApplication}
    layout={MainLayoutV4}
    key="/products/v3/new/webhookApplication"
    path="/products/v3/new/webhookApplication"
    planFeature="Monitoring"
    featureTarget="ApplicationController-post-/products/:productId/applications"
  />,
  <AuthorizedRoute
    exact
    component={MultiHttp}
    layout={MainLayoutV4}
    key="/products/v3/new/multiHttp"
    path="/products/v3/new/multiHttp"
    planFeature="Monitoring"
    featureTarget="ApplicationController-post-/products/:productId/applications"
  />
];
