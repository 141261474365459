import React from 'react';

import { Grid, MenuItem } from '@material-ui/core';

import Select from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

import providers from '../provider';

import AwsFields from './AwsFields';
import AzureFields from './AzureFields';

const CloudsForm = ({ control, register, errors, setValue, watch, isValid }: any) => {
  const provider = watch('provider');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Select control={control} errors={errors} name="provider" label="Cloud Provider" required>
            {providers.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            errors={errors}
            register={register}
            name="name"
            label="Name"
            type="text"
            defaultHelperText=""
          />
        </Grid>

        {provider === 'aws' && (
          <AwsFields
            register={register}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
        {provider === 'azure' && (
          <AzureFields
            register={register}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
        )}
      </Grid>
    </>
  );
};

export default CloudsForm;
