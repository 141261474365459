import React from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation as useMutationURQL } from 'urql';
import { v4 as uuidv4 } from 'uuid';

import { ButtonSimple } from 'componentsV4/Button';
import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';

import { Recurrence, EndOfRecurrence } from '../../../../../constants/callRotationRecurrence';

import FormCreate from './FormCreate';

const createScheduleRecurrenceMutation = `#graphql
mutation($data: CreateScheduleRecurrenceInput!) {
  createScheduleRecurrence(data: $data
  ) {
    id
  }
}
`;

const DialogCreate = ({
  permissions,
  onClose,
  dataSelected,
  setDataSelected,
  dataInitSelected,
  dataEndSelected,
  recurrence,
  setRecurrence,
  endOfRecurrence,
  setEndOfRecurrence,
  refetchSchedules
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id: teamId } = useParams();
  const [group, setGroup] = React.useState(null);
  const [color, setColor] = React.useState(theme.palette.primary.main);
  const [timeSelected, setTimeSelected] = React.useState({
    start: '00:00',
    end: '23:59'
  });
  const saveDisabled = !group;

  const [{ fetching }, executeMutation] = useMutationURQL(createScheduleRecurrenceMutation);

  const { data: user } = useUser();

  const orgUid = user?.logged?.org?.uid;

  const resetForm = () => {
    setGroup(null);
    setColor(theme.palette.primary.main);
    setDataSelected([]);
    setTimeSelected({ start: '00:00', end: '23:59' });
    setRecurrence(Recurrence.DoesNotRepeat);
    setEndOfRecurrence(EndOfRecurrence.AfterAMonth);
  };

  const [onCreateEvent] = useMutation(
    data => {
      if (fetching || !orgUid) return;

      return executeMutation({ data });
    },
    {
      onSuccess: () => {
        refetchSchedules();
        onClose();
        resetForm();
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Event saved successfully'
        });
      },
      onError: error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: error,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const handleCreateEvent = event => {
    event.preventDefault();
    const uid = uuidv4();

    onCreateEvent({
      uid,
      color,
      startDate: dayjs(
        dayjs(dataInitSelected).format('YYYY/MM/DD') + ' ' + timeSelected.start
      ).toDate(),
      endDate: dayjs(dayjs(dataEndSelected).format('YYYY/MM/DD') + ' ' + timeSelected.end)
        .add(
          new Date(`2021-01-01 ${timeSelected.end}`) <= new Date(`2021-01-01 ${timeSelected.start}`)
            ? 0
            : -1,
          'day'
        )
        .toDate(),
      groupId: group,
      recurrence,
      endOfRecurrence,
      team: null,
      teamIdFromTeams: Number(teamId)
    });
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3" gutterBottom>
          Create schedule
        </Typography>
      </Box>

      <FormCreate
        refetchSchedules={refetchSchedules}
        timeSelected={timeSelected}
        setTimeSelected={setTimeSelected}
        dataSelected={dataSelected}
        setDataSelected={setDataSelected}
        color={color}
        setColor={setColor}
        group={group}
        setGroup={setGroup}
        teamId={teamId}
        recurrence={recurrence}
        setRecurrence={setRecurrence}
        endOfRecurrence={endOfRecurrence}
        setEndOfRecurrence={setEndOfRecurrence}
        dataInitSelected={dataInitSelected}
        dataEndSelected={dataEndSelected}
        permissions={permissions}
      />
      <Box mt={1} display="flex" gridGap={22} justifyContent="flex-end">
        <ButtonSimple onClick={onClose} text="Cancel" variant="outlined" color="primary" />

        <ButtonSimple
          onClick={handleCreateEvent}
          disabled={saveDisabled || !permissions.newCallRotation}
          text="Create"
          variant="contained"
          color="primary"
        />
      </Box>
    </>
  );
};

export default DialogCreate;
