import React from 'react';

import { Grid } from '@material-ui/core';

import SecretsField from 'components/SecretsField';

const CloudAwsFields = ({ register, control, errors, watch, setValue }: any) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Access Key ID',
            name: `accessKeyId`,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Secret Access Key',
            name: `secretAccessKey`,
            type: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          defaultHelperText=""
          disabled={false}
          defaultValue=""
          disableOffset=""
        />
      </Grid>
    </>
  );
};

export default CloudAwsFields;
