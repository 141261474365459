import React from 'react';

import { Button, Dialog, DialogContentText, Link } from '@material-ui/core';

import { DialogTitle, DialogContent, DialogActions } from 'components/LegacyDialog';

const TeamsIntegrationInstructionsDialog = ({ field }) => {
  const [showInstructions, setShowInstructions] = React.useState(false);

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>
      <Dialog open={showInstructions} aria-labelledby="new-service-dialog" onClose={handleClose}>
        <DialogTitle id="new-service-dialog" onClose={handleClose}>
          Microsoft Teams integration instructions
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant="h5">
            1. Install the incoming webhook connector
          </DialogContentText>

          <DialogContentText>
            Go to{' '}
            <Link
              href="https://teams.microsoft.com/l/app/203a1e2c-26cc-47ca-83ae-be98f960b6b2?source=store-copy-link"
              target="_blank"
              rel="noopener noreferrer">
              incoming webhook connector details
            </Link>
            , click on <strong>Add to a team</strong>, then choose a channel to receive One Platform
            notifications.
          </DialogContentText>

          <DialogContentText variant="h5">
            2. Configure incoming webhook and get webhook url
          </DialogContentText>

          <DialogContentText>
            Set a name (e.g. One Platform) and image for the connector, then copy the webhook url
            and paste below.
          </DialogContentText>

          {field}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Link
        href="#"
        onClick={() => {
          setShowInstructions(true);
        }}>
        Integration instructions
      </Link>
    </>
  );
};

export default TeamsIntegrationInstructionsDialog;
