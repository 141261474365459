import StaticWelcome from 'layouts/V4/components/StaticWelcome';
import MinimalLayoutV4 from 'layouts/V4/Minimal/Minimal';
import AccountRecoveryView from 'views/Auth/AccountRecovery';
import AccountResetView from 'views/Auth/AccountReset';
import SignInViewV4 from 'views/Auth/SignIn/v4';
import SignUpViewV4 from 'views/Auth/SignUp/v4';
import SignUpGuestView from 'views/Auth/SignUpGuest';
import SelectOrg from 'views/V4/SelectOrg';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const authRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={SignUpGuestView}
    exact
    layout={MinimalLayoutV4}
    isPublicRoute={true}
    key="/guest-sign-up/:uid"
    path="/guest-sign-up/:uid"
  />,
  <RouteWithLayoutV2
    component={SignInViewV4}
    exact
    layout={MinimalLayoutV4}
    isPublicRoute={true}
    key="/sign-in"
    path="/sign-in"
  />,
  <RouteWithLayoutV2
    component={SelectOrg}
    exact
    layout={MinimalLayoutV4}
    key="/select-org"
    path="/select-org"
  />,
  <RouteWithLayoutV2
    component={AccountRecoveryView}
    exact
    layout={MinimalLayoutV4}
    isPublicRoute={true}
    key="/recovery"
    path="/recovery"
  />,
  <RouteWithLayoutV2
    component={AccountResetView}
    exact
    layout={MinimalLayoutV4}
    key="/reset"
    path="/reset"
  />,
  <RouteWithLayoutV2
    component={SignUpViewV4}
    exact
    layout={StaticWelcome}
    isPublicRoute={true}
    key="/sign-up"
    path="/sign-up"
  />
];
