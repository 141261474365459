import React from 'react';

import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Box, Grid, makeStyles, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { KeyValueFields, RadioButtons, RawJsonField, Select, TextField } from 'components/Inputs';
import ValidationStringField from 'components/MonitoringInputs/ValidationStringField';
import SkipSSLValidation from 'components/ResourceInputs/SkipSSLValidation';
import SecretsField from 'components/SecretsField';
import { useMsTeamsQuery } from 'views/V3/Products/View/Product/TeamsAssign';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3)
  },
  cancelButton: {
    marginRight: theme.spacing(2)
  },
  invisible: {
    display: 'none'
  }
}));

const HTTPStep = ({
  type,
  register,
  control,
  errors,
  defaultValues,
  setValue,
  watch,
  headersFields,
  headersAppend,
  headersRemove,
  postBodyXFormUrlEncodedFields,
  postBodyXFormUrlEncodedAppend,
  postBodyXFormUrlEncodedRemove,
  selectedMethod,
  selectedPostBodyType,
  children
}) => {
  const classes = useStyles();

  const postBodyField = (
    <React.Fragment>
      <Grid item xs={10}>
        <RawJsonField
          className={clsx(
            classes.formControl,
            selectedPostBodyType === postBodyTypeValues.urlencoded ? classes.invisible : null
          )}
          register={register}
          errors={errors}
          name={`${type}_post_body_raw`}
          label="Post body"
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValueFields
          className={clsx(
            classes.formControl,
            selectedPostBodyType === postBodyTypeValues.raw ? classes.invisible : null
          )}
          name={`${type}_post_body_urlencoded`}
          label="Post body"
          register={register}
          fields={postBodyXFormUrlEncodedFields}
          fieldAppend={postBodyXFormUrlEncodedAppend}
          fieldRemove={postBodyXFormUrlEncodedRemove}
          control={control}
          watch={watch}
          setValue={setValue}
        />
      </Grid>
    </React.Fragment>
  );

  const useQuery = useMsTeamsQuery;

  const [teamsQueryResult] = useQuery();

  const resultData =
    teamsQueryResult?.data?.teams?.data?.map(team => ({
      label: team.name,
      value: String(team.id)
    })) || [];

  return (
    <>
      {type === 'pre' ? (
        <TextField
          className={classes.formControl}
          errors={errors}
          register={register}
          label="Pre Request Name"
          name={`${type}_name`}
        />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              className={classes.formControl}
              errors={errors}
              register={register}
              label="Application Name"
              name={`${type}_name`}
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              className={classes.formControl}
              label="Team"
              name={`${type}_teamId`}
              control={control}
              setValue={setValue}
              watch={watch}>
              <MenuItem value={null}>No Team</MenuItem>
              {resultData.map(team => (
                <MenuItem value={`${team.value}`}>{team.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item md={5}>
          <Box display="flex">
            <SecretsField
              TextFieldProps={{
                label: 'Healthcheck URL',
                name: `${type}_healthcheckUrl`,
                required: true
              }}
              control={control}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
          </Box>
        </Grid>

        <Grid item md={2}>
          <Select
            className={classes.formControl}
            label="Method"
            name={`${type}_method`}
            control={control}>
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
          </Select>
        </Grid>
        <Grid style={{ marginTop: '7px' }} item xs={3}>
          <SkipSSLValidation
            register={register}
            defaultChecked={defaultValues?.skip_ssl_validation}
            name={`${type}_skip_ssl_validation`}
          />
        </Grid>
        <Grid item md={2}>
          <Select
            errors={errors}
            name={`${type}_tls_renegotiation`}
            control={control}
            label="TLS Renegotiation">
            <MenuItem value={0}>Never</MenuItem>
            <MenuItem value={1}>One Time</MenuItem>
            <MenuItem value={2}>Always</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <KeyValueFields
        className={classes.formControl}
        defaultValues={defaultValues?.headers}
        name={`${type}_headers`}
        label="Header"
        register={register}
        fields={headersFields}
        fieldAppend={headersAppend}
        fieldRemove={headersRemove}
        control={control}
        watch={watch}
        setValue={setValue}
      />

      {selectedMethod === 'POST' && (
        <Box mb={3} display="flex" alignItems="flex-end">
          <RadioButtons
            label="Post-body type"
            name={`${type}_post_body_type`}
            control={control}
            options={[
              { value: postBodyTypeValues.raw, label: 'raw' },
              { value: postBodyTypeValues.urlencoded, label: postBodyTypeValues.urlencoded }
            ]}
          />
          {selectedPostBodyType === postBodyTypeValues.raw && (
            <Box ml={2} clone>
              <Select
                style={{ minWidth: 120 }}
                label="Raw Type"
                name={`${type}_post_body_raw_type`}
                control={control}
                fullWidth={false}>
                <MenuItem value={postBodyRawTypeValues.json}>JSON</MenuItem>
                <MenuItem value={postBodyRawTypeValues.text}>Text</MenuItem>
                <MenuItem value={postBodyRawTypeValues.javaScript}>JavaScript</MenuItem>
                <MenuItem value={postBodyRawTypeValues.xml}>XML</MenuItem>
                <MenuItem value={postBodyRawTypeValues.html}>HTML</MenuItem>
              </Select>
            </Box>
          )}
        </Box>
      )}

      {selectedMethod === 'POST' && postBodyField}

      {type !== 'pre' && (
        <ValidationStringField
          className={classes.formControl}
          register={register}
          name={`${type}_validationString`}
        />
      )}

      {children}
    </>
  );
};

export default HTTPStep;
