import {
  Rule,
  RuleAction,
  RuleActionType,
  RuleOriginEntityType,
  UpdateRulePayload
} from 'types/external/AlertCenter';

import { TimeoutRuleFormData } from 'views/ServicesHub/forms/monitoring/commom/Timeout';

export function rulesAdapter(
  data: TimeoutRuleFormData & { entityUid: string; entity: RuleOriginEntityType }
) {
  const { timeout: rule } = data;

  const teams = rule.teams.map(team => {
    return { teamId: Number(team.value) };
  });

  return {
    title: 'Default',
    description: '',
    severity: rule.severity,
    type: rule.type,
    actions: [
      {
        action: RuleAction.Open,
        type: RuleActionType.Timeout,
        operation: '>=',
        occurrence: 1,
        interval: Number(rule.timeout)
      }
    ],
    teams,
    origins: [
      {
        entity: data.entity,
        entityUid: data.entityUid
      }
    ]
  };
}

export function updateRuleAdapter(data: {
  rule: Rule;
  ruleFormData: TimeoutRuleFormData['timeout'] & {
    entityUid: string;
    entity: RuleOriginEntityType;
  };
}): UpdateRulePayload {
  const { ruleFormData: ruleData, rule } = data;

  const closeActionId = rule.actions.find(action => action.action === RuleAction.Close)?.id!;

  const teamIds = ruleData.teams.map(team => Number(team.value));

  return {
    title: 'Default',
    description: '',
    uid: rule.uid,
    type: ruleData.type,
    teamIds,
    severity: ruleData.severity,
    actions: [
      {
        id: closeActionId,
        action: RuleAction.Open,
        type: RuleActionType.Timeout,
        operation: '>=',
        occurrence: 1,
        interval: Number(ruleData.timeout)
      }
    ],
    origins: [
      {
        entity: ruleData.entity,
        entityUid: ruleData.entityUid
      }
    ]
  };
}
