import { useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { ButtonDefault as Button } from 'components/Button';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import QueryStats from 'icons/QueryStats';

type ApplicationMetricsButtonProps = {
  name: string;
  sterasId?: number | null;
};

function ApplicationMetricsButton({ name, sterasId }: ApplicationMetricsButtonProps) {
  const theme = useTheme();

  const canShowMetricsButton = useFeaturePlan('Metrics.Dashboards');

  const redirectURL = `/metrics?tab=monitoring-performance&resource=${name}`;

  const isDisabled = !Boolean(sterasId);

  if (!canShowMetricsButton) return null;

  return (
    <Link to={redirectURL}>
      <Button
        variant="contained"
        size="medium"
        style={{ marginRight: theme.spacing() }}
        disabled={isDisabled}>
        <QueryStats style={{ marginRight: theme.spacing() }} />
        Metrics details
      </Button>
    </Link>
  );
}

export default ApplicationMetricsButton;
