import { useEffect, useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { editUserAdapter } from 'views/ServicesHub/adapters/edit/user';
import { newUserAdapter } from 'views/ServicesHub/adapters/new/user';
import { LayoutType } from 'views/ServicesHub/forms';
import { useDefaultUser } from 'views/ServicesHub/forms/User/utils/useDefaultUser';

import FormSkeleton from './components/FormSkeleton';
import { createUserSchema, editUserSchema } from './schemas';
import { useStyles } from './styles';
import { useStartValues } from './useStartValues';

const createAccountMutation = `#graphql
  mutation($createAccountInputs: [CreateAccountInputs!]!) {
    createAccounts(createAccountInputs: $createAccountInputs){
      message
    }
  }
`;

const UpdateAccountMutation = `#graphql
    mutation($updateAccountInputs: UpdateAccountInputs!) {
      updateAccount (updateAccountInputs: $updateAccountInputs){
        id
      }
    }
`;

export function UserLayout({ Form, isEdit }: { Form: any; isEdit: LayoutType['isEdit'] }) {
  const hasNewUserPermission = usePermission('UserController-post-/users');
  const hasUpdateUserPermission = usePermission('UserController-put-/users/:id/account');

  const [hasInvalidEmail, setHasInvalidEmail] = useState(true);

  const classes = useStyles();
  const { startValues, fetching: fetchingStartValues } = useStartValues();
  const { defaultUser, defaultRole, organizationRoles } = useDefaultUser();
  const { uid: userId } = useParams<{ uid: string }>();

  const form = useForm({
    mode: 'all',
    resolver: zodResolver(isEdit ? editUserSchema : createUserSchema),
    defaultValues: {
      users: [defaultUser] as any
    }
  });

  const { reset: formReset } = form;

  const dispatch = useDispatch();

  const history = useHistory();

  const [{ fetching: fetchingCreateUser }, createUser] = useMutation(createAccountMutation);

  const [{ fetching: fetchingUpdateUser }, updateUser] = useMutation(UpdateAccountMutation);

  const handleSubmit = async (data: any) => {
    if (isEdit && startValues) {
      const payload = editUserAdapter({ users: [{ ...data?.users?.[0] }] });

      const response = await updateUser({
        updateAccountInputs: { ...payload, userId: Number(userId) }
      });

      if (response.error || response.data?.updateAccount.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update user' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        if (response.error) apm.captureError(response.error);
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'User updated successfully'
      });

      history.push(`/users`);
      return;
    }

    if (!isEdit && defaultRole?.id) {
      const createAccountInputs = newUserAdapter(data, defaultRole?.id);

      const response = await createUser({ createAccountInputs });

      if (response.error || response.data?.createAccounts.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create user' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        if (response.error) apm.captureError(response.error);
        return;
      }

      dispatch({
        payload: 'User created successfully',
        type: actions.GLOBAL_SUCCESS
      });

      history.push(`/users`);
    }
  };

  const fetching = fetchingCreateUser || fetchingStartValues || fetchingUpdateUser;

  useEffect(() => {
    if ((isEdit && startValues) || (!isEdit && defaultUser.role >= 0))
      formReset({
        users: [isEdit ? startValues : defaultUser] as any
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset, isEdit, defaultRole, startValues, fetching]);

  const watchStatus = form.watch('users.0.status');
  const watchRole = form.watch('users.0.role');

  const shouldDisableSaveButton =
    startValues?.status === watchStatus && startValues?.role === watchRole;

  const disableButton = isEdit
    ? shouldDisableSaveButton || !form.formState.isValid
    : !form.formState.isValid || hasInvalidEmail;

  if (!isEdit && !hasNewUserPermission) {
    history.push(`/forbidden`);
  }

  if (isEdit && !hasUpdateUserPermission) {
    history.push(`/forbidden`);
  }

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {organizationRoles && fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4" color="secondary">
          Basic informations
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            {!!defaultUser?.role ? (
              <Form
                form={form}
                isEdit={isEdit}
                organizationRoles={organizationRoles}
                defaultUser={defaultUser}
                startValues={startValues}
                setHasInvalidEmail={setHasInvalidEmail}
              />
            ) : (
              <FormSkeleton />
            )}
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2" color="secondary">
              Manage User Info
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Provide the necessary details for each user you want to add, including their Email,
              Name, and Role. You can create one or multiple users in a single process. After
              setting up the users, you can edit their Role if their responsibilities change and
              update their status to indicate whether they are Active or Inactive within the
              organization. This setup allows for flexible and efficient user management.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem" mt="80px">
        <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" disabled={disableButton}>
          SAVE {isEdit ? 'EDITIONS' : 'USER'}
        </Button>
      </Box>
    </Box>
  );
}
