import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: theme.spacing(5.5),

    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.elvenGray[200],
      borderStyle: 'solid'
    }
  },
  caption: {
    color: '#9e9e9e'
  }
}));
