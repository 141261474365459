import { Rule, RuleActionType } from 'types/external/AlertCenter';

import { TimeoutRuleFormData } from 'views/ServicesHub/forms/monitoring/commom/Timeout';

export function rulesAdapter(
  rule?: Rule,
  teamsFieldOptions: { value: string; label: string }[] = []
): TimeoutRuleFormData['timeout'] {
  if (!rule)
    return {
      timeout: 60,
      type: 'alert',
      severity: 'not-classified',
      teams: [],
      enable: false
    };

  const timeout = rule.actions.find(action => action.type === RuleActionType.Timeout)?.interval!;

  const teams = rule.teams.map(team => {
    return {
      value: String(team.teamId),
      label: teamsFieldOptions.find(option => option.value === String(team.teamId))?.label ?? ''
    };
  });

  return {
    timeout,
    enable: true,
    severity: rule.severity,
    teams,
    type: rule.type
  };
}
