import { Box, Divider, Typography } from '@material-ui/core';
import { Service } from 'types/cms/ServicesHub';

import PageContainer from 'components/PageContainer';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useCms } from 'hooks/queriesGraphQL/useCms';

import { useStyles } from '../formStyles';
import { useLayoutFromServiceType } from '../lib/useLayoutFromServiceType';

function ServicesHubEditInner({ servicesDefinitions }: { servicesDefinitions: Service[] }) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const { Layout, title, description, breadcrumb, backLabel, backLink, baseBreadcrumb } = useLayoutFromServiceType(
    servicesDefinitions
  );

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={baseBreadcrumb ? [
          { label: baseBreadcrumb },
          { label: backLabel, link: backLink },
          { label: `Edit ${breadcrumb}` }
      ] : [
        { label: backLabel, link: backLink },
        { label: `Edit ${breadcrumb}` }
    ]}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
        <Typography variant="h4" color="secondary" className={classes.title}>
          Edit {title}
        </Typography>
      </Box>

      <Typography className={classes.description} variant="body1">
        {description}
      </Typography>

      <Divider className={classes.divider} />

      <Layout isEdit />
    </PageContainer>
  );
}

export function ServicesHubEdit() {
  const globalClasses = useGlobalStyles();

  const [{ fetching, data }] = useCms({
    page: 'servicesHub'
  });

  if (fetching) {
    return (
      <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
        <LoadingOverlay />
      </PageContainer>
    );
  }

  return <ServicesHubEditInner servicesDefinitions={data?.cms.content.services} />;
}
