import { Box, Typography } from '@material-ui/core';

import PageContainer from 'components/PageContainer';

import { OrganizationSettingsTabs } from './components/Tabs';
import { useStylesMaterial } from './styles';

function OrganizationView() {
  const classes = useStylesMaterial();

  return (
    <PageContainer className={classes.pageContainerList} maxWidth="xl">
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2.5} mb={7.5}>
        <Typography variant="h4" color="secondary" className={classes.titleList}>
          Organization Settings
        </Typography>
      </Box>

      <OrganizationSettingsTabs />
    </PageContainer>
  );
}

export default OrganizationView;
