import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertSentryGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Sentry',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/alerts/v1/sentry/${integrationUid}?token=${token}`,
    instructions: {
      isNumeric: false,
      steps: [
        `Use this url to create incidents via sentry
           You can complement the URL with &severity= and one of the following values:`,

        '• critical',
        '• high',
        '• moderate',
        '• low',
        '• informational',
        `If you do not want to classify the created incident, simply do not include this add-on`
      ]
    }
  };
};
