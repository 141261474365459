import { useRef, useState } from 'react';

import { Box, Grid, makeStyles, Typography, FormHelperText } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import Delete from '@material-ui/icons/Delete';
import { useMutation } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import ButtonWithIcon from 'componentsV3/ButtonWithIcon';
import InputDefault from 'componentsV3/InputDefault';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import ModalTeam from 'componentsV3/ModalTeam';
import ProductImageUpload from 'componentsV3/ProductImageUpload';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

import TeamsAssign, { useMsTeamsQuery } from './TeamsAssign';
import useForm from './useForm';

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    marginTop: spacing(2),
    color: palette.text.primary
  },
  form: {
    marginTop: spacing(4)
  },
  titleImage: {
    marginTop: -2,
    marginBottom: 2
  },
  button: {
    marginTop: spacing(4)
  }
}));

const startValuesByMSTeams = {
  name: '',
  description: '',
  cover: null,
  teams: []
};

const CreateProductWithMSTeamsMutation = `#graphql
  mutation ($productInput: ProductInputV2!) {
    createProductV2 (productInput: $productInput) {
      id
    }
  }
`;

function TabProduct({ firstAccess, onFinish, applicationType }) {
  const useTeamsQuery = useMsTeamsQuery;
  const uploadRef = useRef(null);
  const [showTeamsModal, setShowTeamsModal] = useState(false);

  const classes = useStyles();

  const [teamsQueryResult, reexecuteTeamsQuery] = useTeamsQuery();

  const { handleSubmit, register, errors, watch, setValue, control, formState } = useForm({
    defaultValues: startValuesByMSTeams
  });

  const [createProductResultWithMSTeams, createProductMutationWithMSTeams] = useMutation(
    CreateProductWithMSTeamsMutation
  );

  const createProductWrapperWithMSTeams = payload => {
    createProductMutationWithMSTeams({
      productInput: {
        ...payload,
        teams: payload.teams.map(team => {
          return Number(team.value);
        })
      }
    }).then(response => {
      analiticEventsMessage(response);
      if (onFinish) {
        onFinish(response.data.createProductV2.id);
      }
    });
  };

  const analiticEventsMessage = result => {
    const eventsMap = {
      success: {
        internalMultiHttp: firstAccess ? 'fa_success_product_multi' : 'success_product_multi',
        externalWithoutAddons: firstAccess ? 'fa_new_epwd_success' : 'new_epwd_success',
        webhook: firstAccess ? 'fa_success_product_hook' : 'success_product_hook',
        internal: firstAccess ? 'fa_new_iaea_success' : 'new_iaea_success',
        externalWithAddons: firstAccess ? 'fa_new_eaea_success' : 'new_eaea_success'
      },
      error: {
        internalMultiHttp: firstAccess ? 'fa_error_product_multi' : 'error_product_multi',
        externalWithoutAddons: firstAccess ? 'fa_new_epwd_error' : 'new_epwd_error',
        webhook: firstAccess ? 'fa_fail_product_hook' : 'fail_product_hook',
        internal: firstAccess ? 'fa_new_iaea_error' : 'new_iaea_error',
        externalWithAddons: firstAccess ? 'fa_new_eaea_error' : 'new_eaea_error'
      }
    };

    if (result.error) {
      if (eventsMap.error[applicationType]) {
        analyticsEvent(eventsMap.error[applicationType]);
      }
      return;
    }

    if (eventsMap.success[applicationType]) {
      analyticsEvent(eventsMap.success[applicationType]);
    }
  };

  const saveNewTeamAnaliticsEvent = () => {
    const eventsMap = {
      externalWithoutAddons: firstAccess
        ? 'fa_new_epdw_save_new_team_button'
        : 'new_epdw_save_new_team_button'
    };
    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }
  };

  const saveNewProductAnaliticEvent = () => {
    const eventsMap = {
      internalMultiHttp: firstAccess ? 'fa_save_product_multi' : 'save_product_multi',
      webhook: firstAccess ? 'fa_validate_product_hook' : 'validate_product_hook',
      externalWithoutAddons: firstAccess
        ? 'fa_save_and_create_new_epdw_button'
        : 'save_and_create_new_epdw_button',
      internal: firstAccess
        ? 'fa_save_and_create_new_iaea_button'
        : 'save_and_create_new_iaea_button',
      externalWithAddons: firstAccess
        ? 'fa_save_and_create_new_eaea_button'
        : 'save_and_create_new_eaea_button'
    };

    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }
  };

  const { fetching, error } = createProductResultWithMSTeams;

  const submitMutation = formData => {
    const productImage = formData.cover && formData.cover[0];

    if (productImage) {
      return createProductWrapperWithMSTeams({
        ...formData,
        productImage,
        cover: null
      });
    }

    return createProductWrapperWithMSTeams({ ...formData, productImage: null, cover: null });
  };

  function handleEdit() {
    // eslint-disable-next-line no-unused-expressions
    uploadRef.current?.changeImage();
  }

  function handleRemove() {
    setValue('cover', null, {
      shouldValidate: true
    });
    // eslint-disable-next-line no-unused-expressions
    uploadRef?.current.removeImage();
  }

  const appImage = watch('cover');
  const team = watch('teams');

  const serverErrorMessage = error?.graphQLErrors
    ? error.graphQLErrors[0]?.originalError?.message
    : null;

  const { isValid } = formState;

  return (
    <>
      <Typography className={classes.title} variant="h3">
        New Product
      </Typography>

      <form className={classes.form} onSubmit={handleSubmit(submitMutation)}>
        <Grid container spacing={4} style={{ position: 'relative' }}>
          <Grid item xs={6}>
            <InputDefault
              errors={errors}
              inputRef={register}
              id="product-name"
              name="name"
              label={'Product name'}
              placeholder={'Elvenworks - OnePlatform'}
              required
            />
          </Grid>

          <Grid item xs={5}>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.titleImage} variant="body2">
                Assign an image to your product (Max size: 2MB)
              </Typography>
              <Box display="flex">
                <ProductImageUpload
                  firstAccess={firstAccess}
                  ref={uploadRef}
                  register={register}
                  setValue={setValue}
                  applicationType={applicationType}
                />
                {Boolean(appImage?.length) && (
                  <Box marginLeft={2} display="flex" flexDirection="column">
                    <ButtonWithIcon text="Substituir" startIcon={<Create />} onClick={handleEdit} />
                    <ButtonWithIcon text="Remover" startIcon={<Delete />} onClick={handleRemove} />
                  </Box>
                )}
              </Box>

              {errors?.cover && <FormHelperText error>{errors.cover.message}</FormHelperText>}
            </Box>
          </Grid>

          <Grid style={{ marginTop: '-10px' }} item xs={6}>
            <InputDefault
              inputRef={register}
              name="description"
              id="product-description"
              label={'Description'}
              errors={errors}
              placeholder={
                'Enter a description that makes it easy to identify the product on the platform'
              }
              multiline
              linesNumber={5}
            />
          </Grid>

          <Grid style={{ position: 'relative' }} item xs={5}>
            <TeamsAssign
              name={'teams'}
              setValue={setValue}
              result={teamsQueryResult}
              control={control}
              setShowTeamsModal={setShowTeamsModal}
              firstAccess={firstAccess}
              team={team}
              applicationType={applicationType}
            />
          </Grid>

          <Grid className={classes.button} xs={12} item>
            {serverErrorMessage && (
              <Box>
                <FormHelperText error>{serverErrorMessage}</FormHelperText>
              </Box>
            )}
            <ButtonSimple
              type="submit"
              color="primary"
              onClick={() => {
                saveNewProductAnaliticEvent();
              }}
              text={'Save and create application'}
              disabled={!isValid}
            />
          </Grid>

          {fetching && <LoadingOverlay />}
        </Grid>
      </form>

      <ModalTeam
        onTeamCreate={newTeamCreated => {
          setValue('teams', [...team, newTeamCreated], { shouldValidate: true });
          saveNewTeamAnaliticsEvent();
          reexecuteTeamsQuery();
        }}
        isOpen={showTeamsModal}
        handleClose={() => {
          setShowTeamsModal(false);
        }}
        applicationType={applicationType}
      />
    </>
  );
}

export default withThemeV3Hoc(TabProduct);
