import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import actions from 'redux/actions';
import reducer from 'redux/reducer/users';

import SignUp from './SignUp';

export default withRouter(() => {
  const { signUpGuestError, guest_signup } = useSelector(state => state);
  const guest = guest_signup.values;
  const dispatch = useDispatch();
  const signUp = () => dispatch({ type: actions.USERS_SIGNUP });
  const onChange = payload =>
    dispatch({
      type: actions.USERS_POPULATE_ENTITY_SIGNUP,
      payload,
      meta: {
        reducer
      }
    });
  return (
    <SignUp guest={guest} onChange={onChange} signUp={signUp} signUpGuestError={signUpGuestError} />
  );
});
