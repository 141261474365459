import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertNewrelicGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Newrelic',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/alerts/v1/newrelic/${integrationUid}?token=${token}`
  };
};
