import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@material-ui/core';
import {
  makeStyles,
  Box,
  Typography,
  CircularProgress  
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import FailuresToIncident from 'components/MonitoringInputs/FailuresToIncident';
import IntervalField from 'components/MonitoringInputs/IntervalField';
import OpenIncidentField from 'components/MonitoringInputs/OpenIncidentField';
import { ButtonSimple } from 'componentsV4/Button';

import { EnvironmentSelect, TextField } from '../../../../../../../../components/Inputs';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3)
  },
  closeButton: {
    marginRight: theme.spacing(2)
  },
  agentStatus: {
    color: grey[500],
    marginLeft: theme.spacing()
  },
  sectionDivider: {
    border: `${theme.palette.grey[300]} 1px solid`,
    marginTop: theme.spacing(1)
  }
}));

const schema = z
  .object({
    name: z.string().nonempty({ message: 'Required' }),
    environmentId: z.union([z.string().nonempty({ message: 'Required' }), z.number()]),
    check_interval: z
      .string()
      .refine(
        check_interval => {
          try {
            if (
              Number(check_interval) >=
              Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
            ) {
              return true;
            }
          } catch {
            return false;
          }
        },
        {
          message: `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
        }
      )
      .optional(),
    failuresToIncident: z
      .string()
      .refine(
        failuresToIncident => {
          try {
            if (Number(failuresToIncident) >= 1) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Fails number should be a greater than zero' }
      )
      .optional(),
    openIncidentWithSeverity: z.string().optional(),
    alertConnectionHealth: z.boolean().optional(),
    alert: z
      .object({
        type: z.string().optional(),
        timeout: z.coerce.number().optional(),
        counter: z.coerce.number().optional(),
        teams: z.any().optional(),
        severity: z.string().optional()
      })
      .optional()
  })
  .refine(
    values => {
      if (Number(values.check_interval) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval number can only be up to six digits',
      path: ['check_interval']
    }
  );

const alertDefaultValues = {
  alertConnectionHealth: false,
  alert: {
    type: 'counter',
    counter: 2,
    timeout: 300,
    severity: '',
    teams: ''
  }
};

const defaultValues = {
  environmentId: '',
  openIncidentWithSeverity: 'not-classified',
  ...alertDefaultValues
};

const WebHookFormWrapper = ({
  startValues,
  onClose,
  onSubmit,
  isLoading,
  errorMessage  
}) => {
  const classes = useStyles();
  const { control, handleSubmit, register, errors } = useForm({
    defaultValues: startValues || defaultValues,
    resolver: zodResolver(schema)
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EnvironmentSelect className={classes.formControl} errors={errors} control={control} />

        <TextField
          className={classes.formControl}
          errors={errors}
          register={register}
          label="Application Name"
          name="name"
        />

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <IntervalField
              className={classes.formControl}
              register={register}
              errors={errors}
              name="check_interval"
            />
          </Grid>
          <Grid item xs={4}>
            <FailuresToIncident
              className={classes.formControl}
              register={register}
              errors={errors}
              name="FailuresToIncident"
            />
          </Grid>

          <Grid item xs={4}>
            <OpenIncidentField
              className={classes.formControl}
              register={register}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>

        {errorMessage && (
          <Typography color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}

        <Box display="flex" justifyContent="flex-end">
          <ButtonSimple
            className={classes.closeButton}
            variant="outlined"
            onClick={onClose}
            text="Cancel"
            color="primary"
          />
          <ButtonSimple
            disableRipple
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            text="Save"
          />
        </Box>

        {isLoading && (
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </>
  );
};

const WebHookForm = ({ onClose, startValues, submitMutation, isLoading, errorMessage }) => {
  
  if (isLoading) {
    return (
      <Box
        style={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(255 255 255 / 66%)',
          zIndex: 1000
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <WebHookFormWrapper
      startValues={startValues}
      onClose={onClose}
      onSubmit={submitMutation}
      isLoading={isLoading}
      errorMessage={errorMessage}      
    />
  );
};

export default WebHookForm;
