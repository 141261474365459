import { Dialog as MuiDialog, useTheme } from '@material-ui/core';

import { DialogTitle, DialogContent } from 'components/LegacyDialog';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useNewGetDependencies from 'hooks/queriesGraphQL/useNewGetDependencies';

import EditAddonForm from './EditAddonForm';

const EditDialog = ({
  open,
  handleToggle,
  addonId,
  productId
}: {
  open: boolean;
  handleToggle: () => void;
  addonId: number;
  productId: number;
}) => {
  const theme = useTheme();

  const { result: resultNewGetDependencies, fetching } = useNewGetDependencies({
    addonId,
    executeQuery: open
  });

  return (
    <>
      <MuiDialog
        fullWidth
        open={open}
        aria-labelledby="edit-dependency-dialog"
        onClose={handleToggle}
        maxWidth="lg"
        disableEnforceFocus
        PaperProps={{
          style: {
            padding: theme.spacing(2)
          }
        }}>
        <DialogTitle id="edit-dependency-dialog" onClose={handleToggle}>
          Edit Dependency
        </DialogTitle>

        <DialogContent style={{ overflowX: 'hidden' }}>
          {fetching ? (
            <LoadingOverlay />
          ) : (
            <EditAddonForm
              data={resultNewGetDependencies}
              handleClose={handleToggle}
              productId={productId}
            />
          )}
        </DialogContent>
      </MuiDialog>
    </>
  );
};

export default EditDialog;
