import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';

import FeaturesList from './FeaturesList';

class Features extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { history } = this.props;
    return <FeaturesList history={history} />;
  }
}

export default connect(null, dispatch => ({
  fetch: () =>
    dispatch({
      type: actions.FETCH_ENTITY,
      meta: {
        entity: 'features'
      }
    })
}))(Features);
