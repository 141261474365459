import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 400,
    marginBottom: theme.spacing(4)
  },
  stepsTitle: {
    fontSize: theme.typography.pxToRem(24)
  },
  infoSectionTitle: {
    fontWeight: 'bold'
  },
  infoSectionDescription: {
    color: theme.palette.elvenGray[500]
  },
  sectionDivider: {
    border: `${theme.palette.grey[300]} 1px solid`
  },
  text: {
    color: theme.palette.grey[700]
  },
  circle: {
    marginRight: theme.spacing(0.5)
  },
  item: {
    paddingLeft: 0
  },
  codeBlock: {
    marginTop: theme.spacing(1),
    borderRadius: '5px',
    border: '1px solid var(--Gray-300, #C2C2C2)',
    background: 'var(--gray-50-background, #F6F6F6)'
  },
  snippet: {
    cols: 100,
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[700]}`,
    background: theme.palette.grey[700],
    whiteSpace: 'pre-wrap',
    textOverflow: 'clip',
    color: '#FFFFFF',
    margin: theme.spacing(2)
  },
  copyButton: {
    margin: theme.spacing(3)
  }
}));
