import { Divider, useTheme } from '@material-ui/core';
import { GitHub } from '@material-ui/icons';
import clsx from 'clsx';
import { useParams } from 'react-router';

import PageContainer from 'components/PageContainer';
import { Typography } from 'components/Typography';
import Bitbucket from 'icons/Bitbucket';
import GitLab from 'icons/GitLab';
import { withThemeV4 } from 'theme/v4';

import { LeadTimeIntegrationForm } from './LeadTimeIntegrationForm';
import useStyles from './styles';

export type PlatformIntegration = 'github' | 'gitlab' | 'bitbucket';

const getCardIcon = (value: PlatformIntegration): JSX.Element => {
  const icons: { [key: string]: JSX.Element } = {
    bitbucket: <Bitbucket fontSize="large" />,
    github: <GitHub fontSize="large" />,
    gitlab: <GitLab fontSize="large" />
  };

  return icons[value];
};

const integrationDescription = {
  leadTime:
    'get the Lead Time for Change metric, which is an indicator that measures how quickly code makes it to production.'
};

function IntegrationsNew() {
  const classes = useStyles();

  const theme = useTheme();

  const platformIntegration = useParams<{
    type: PlatformIntegration;
  }>().type;

  const platformIntegrationNormalized = platformIntegration.replace('-', ' ');

  return (
    <>
      <PageContainer>
        <Typography className={clsx(classes.title, classes.capitalize)} variant="h3" gutterBottom>
          {getCardIcon(platformIntegration)}
          {platformIntegrationNormalized} Integration
        </Typography>

        <Typography className={classes.body} variant="body1" gutterBottom>
          By integrating with{' '}
          <span className={classes.capitalize}>{platformIntegrationNormalized}</span>, you can{' '}
          {integrationDescription.leadTime}
        </Typography>

        <Divider style={{ backgroundColor: theme.palette.grey[200] }} />

        <LeadTimeIntegrationForm platformIntegration={platformIntegration} />
      </PageContainer>
    </>
  );
}

export default withThemeV4(IntegrationsNew);
