import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud/';

import schema from '../components/schema';

import FeaturesNewForm from './FeaturesNewForm';

class FeaturesNew extends React.Component {
  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { features_new: formState, onChange, save } = this.props;
    return <FeaturesNewForm formState={formState} onChange={onChange} save={save} />;
  }
}

export default connect(
  ({ features_new }) => ({ features_new }),
  dispatch => ({
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'features_new'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'features_new',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    },
    save: payload =>
      dispatch({
        type: actions.SAVE_ENTITY,
        payload,
        meta: { entity: 'features', url: `/features` }
      })
  })
)(FeaturesNew);
