import React from 'react';

import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Box, MenuItem } from '@material-ui/core';

import { KeyValueFields, RadioButtons, Select } from 'components/Inputs';

import PostBodyField from './PostBodyField';

const HTTPFields = ({
  className = '',
  selectedCheckType,
  selectedMethod,
  defaultValues,
  register,
  headersFields,
  headersAppend,
  headersRemove,
  control,
  setValue,
  watch,
  selectedPostBodyType,
  errors,
  postBodyXFormUrlEncodedFields,
  postBodyXFormUrlEncodedAppend,
  postBodyXFormUrlEncodedRemove,
  disabled
}) => {
  return (
    selectedCheckType === 'http' &&
    selectedMethod && (
      <>
        {selectedMethod && (
          <KeyValueFields
            className={className}
            defaultValues={defaultValues.headers}
            name="headers"
            label="Header"
            register={register}
            fields={headersFields}
            fieldAppend={headersAppend}
            fieldRemove={headersRemove}
            control={control}
            setValue={setValue}
            watch={watch}
            disabled={disabled}
          />
        )}

        {selectedMethod === 'POST' && (
          <>
            <Box mb={3} display="flex" alignItems="flex-end">
              <RadioButtons
                label="Post-body type"
                name="post_body_type"
                control={control}
                options={[
                  { value: postBodyTypeValues.raw, label: 'raw' },
                  {
                    value: postBodyTypeValues.urlencoded,
                    label: postBodyTypeValues.urlencoded
                  }
                ]}
                disabled={disabled}
              />
              {selectedPostBodyType === postBodyTypeValues.raw && (
                <Box ml={2} clone>
                  <Select
                    style={{ minWidth: 120 }}
                    label="Raw Type"
                    name="post_body_raw_type"
                    control={control}
                    errors={errors}
                    fullWidth={false}
                    disabled={disabled}>
                    <MenuItem value={postBodyRawTypeValues.json}>JSON</MenuItem>
                    <MenuItem value={postBodyRawTypeValues.text}>Text</MenuItem>
                    <MenuItem value={postBodyRawTypeValues.javaScript}>JavaScript</MenuItem>
                    <MenuItem value={postBodyRawTypeValues.xml}>XML</MenuItem>
                    <MenuItem value={postBodyRawTypeValues.html}>HTML</MenuItem>
                  </Select>
                </Box>
              )}
            </Box>

            <PostBodyField
              className={className}
              selectedPostBodyType={selectedPostBodyType}
              register={register}
              postBodyXFormUrlEncodedFields={postBodyXFormUrlEncodedFields}
              postBodyXFormUrlEncodedAppend={postBodyXFormUrlEncodedAppend}
              postBodyXFormUrlEncodedRemove={postBodyXFormUrlEncodedRemove}
              errors={errors}
              control={control}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
            />
          </>
        )}
      </>
    )
  );
};

export default HTTPFields;
