import React from 'react';

import { connect } from 'react-redux';

import Breadcrumb from 'components/Breadcrumb';
import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud';

import schema from '../components/schema';

import EnvironmentsNewForm from './EnvironmentsNewForm';

class EnvironmentsNew extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { environments_new: formState, clouds, onChange } = this.props;

    return (
      <>
        <Breadcrumb items={[{ link: `/environments`, label: 'Environments' }, { label: 'New' }]} />
        <EnvironmentsNewForm formState={formState} onChange={onChange} clouds={clouds.data} />
      </>
    );
  }
}

export default connect(
  ({ environments_new, clouds }) => ({ environments_new, clouds }),
  dispatch => ({
    fetch: () =>
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'clouds',
          pagination: false
        }
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'environments_new'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'environments_new',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(EnvironmentsNew);
