import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles<Theme>(({ spacing, palette }) => ({
  paper: {
    backgroundColor: palette.background.paper,
    borderRadius: 8,
    padding: spacing(2, 2),
    height: '100%'
  },
  cardContent: {
    width: '100%',
    padding: 0,
    height: '100%'
  },
  description: {
    maxHeight: 100,
    overflowY: 'auto',
    scrollbarWidth: 'auto',
    scrollbarColor: `${palette.grey[300]} ${palette.grey[100]}`,
    '&::-webkit-scrollbar': {
      width: spacing(0.5)
    },
    '&::-webkit-scrollbar-track': {
      background: palette.grey[100]
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.grey[300],
      borderRadius: spacing(2)
    }
  },
  titleCard: {
    fontSize: 14,
    marginBottom: spacing(3)
  },
  icon: {
    width: 15,
    height: 15,
    marginBottom: -2
  },
  dividerSections: {
    backgroundColor: palette.text.hint,
    opacity: '30%',
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  spaceToSourceLink: {
    color: palette.elvenGreen[400],
    marginRight: spacing(1.2),
    marginTop: spacing(0.2),
    width: 16.5,
    height: 16.5
  },
  colorLink: {
    color: palette.elvenGreen[400],
    fontSize: 12
  },
  incidentTitle: { color: '#515151' },
  markdownTipText: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    gap: '0.5rem'
  }
}));
