import React from 'react';

import { Button, Link, TextField, Checkbox, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const SignUpForm = ({ guest, classes, onChange, signUp }) => {
  const orgName = guest.org ? guest.org.name : '';
  const inviteFrom = guest.inviteFrom ? guest.inviteFrom.email : '';

  const handleSignUp = event => {
    event.preventDefault();
    signUp();
  };

  const handleChange = event => {
    event.preventDefault();
    onChange({
      [event.target.name]: event.target.value
    });
  };

  const handleToggleChange = event => {
    event.preventDefault();
    onChange({
      [event.target.name]: event.target.checked
    });
  };

  const password = !guest.user ? (
    <TextField
      className={classes.textField}
      error={false}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      label="Senha"
      name="password"
      onChange={handleChange}
      required
      type="password"
      value={guest.password}
      variant="outlined"
    />
  ) : null;

  const displayName = !guest.user ? (
    <TextField
      className={classes.textField}
      error={false}
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      label="Nome"
      name="displayName"
      onChange={handleChange}
      type="text"
      value={guest.displayName}
      variant="outlined"
    />
  ) : null;

  return (
    <form className={classes.form} onSubmit={handleSignUp}>
      <Typography className={classes.title} variant="h2">
        Criar conta
      </Typography>

      <Typography className={classes.title} variant="subtitle1">
        Olá {guest.email}, voce foi convidado para a organização <b>{orgName}</b> por{' '}
        <b>{inviteFrom}</b>.
      </Typography>

      {displayName}
      {password}

      <div className={classes.policy}>
        <Checkbox
          checked={guest.policy}
          className={classes.policyCheckbox}
          color="primary"
          name="policy"
          onChange={handleToggleChange}
          required
        />
        <Typography className={classes.policyText} color="textSecondary" variant="body1">
          I have read and accepted{' '}
          <Link color="primary" component={RouterLink} to="/terms" underline="always" variant="h6">
            Terms and Conditions
          </Link>
        </Typography>
      </div>
      <Button
        className={classes.signUpButton}
        color="primary"
        disabled={false}
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        Assinar agora
      </Button>
    </form>
  );
};

export default SignUpForm;
