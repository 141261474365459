import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router';

import Breadcrumb from 'components/Breadcrumb';
import axios from 'redux/axios';

import DragAndDropBoxes from '../components/DragAndDropBoxes';

const StatupsPageEdit = ({
  match: {
    params: { id }
  }
}) => {
  const { data, isLoading } = useQuery(['statuspage', Number(id)], () => {
    return axios.get(`/statuspage/${id}`).then(response => response.data);
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <Redirect to="/statuspages" />;
  }

  return (
    <>
      <Breadcrumb items={[{ link: `/statuspages`, label: 'Status Pages' }, { label: 'Edit' }]} />
      <DragAndDropBoxes startValues={data} />
    </>
  );
};

export default StatupsPageEdit;
