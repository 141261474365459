const planFeaturesMapByPage: Record<string, string> = {
  statuspages: 'StatusPage',
  incidents: 'IncidentManagement',
  metrics: 'Metrics.Dashboards'
};

export function getPlanFeatureByPage(href: string): string {
  const [, startHref] = href.split('/');
  return planFeaturesMapByPage[startHref] ?? '';
}
