import React from 'react';

import { connect } from 'react-redux';

import Breadcrumb from 'components/Breadcrumb';
import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud';

import schema from '../components/schema';

import EnvironmentsEditForm from './EnvironmentsEditForm';

class EnvironmentsEdit extends React.Component {
  componentDidMount() {
    this.props.fetchEntity(this.props.match.params.id);
    this.props.fetchCloud();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { environments_edit: formState, clouds, onChange } = this.props;

    return (
      <>
        <Breadcrumb items={[{ link: '/environments', label: 'Environments' }, { label: 'Edit' }]} />
        <EnvironmentsEditForm formState={formState} onChange={onChange} clouds={clouds.data} />
      </>
    );
  }
}

export default connect(
  ({ environments_edit, clouds }) => ({ environments_edit, clouds }),
  dispatch => ({
    fetchCloud: () =>
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'clouds',
          pagination: false
        }
      }),
    fetchEntity: id =>
      dispatch({
        type: actions.EDIT_ENTITY,
        meta: {
          entity: 'environments',
          id: id
        }
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'environments_edit'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'environments_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(EnvironmentsEdit);
