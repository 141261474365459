import { useEffect } from 'react';

import { useFlagsStatus } from '@unleash/proxy-client-react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import Breadcrumb from 'components/Breadcrumb';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import reducer from 'redux/reducer/crud';

import Form from './Form';
import Toolbar from './Toolbar';

const schema = {
  name: {
    length: {
      maximum: 500
    }
  }
};

const ProductEdit = props => {
  const { fetch, match } = props;
  const productId = match.params.id;

  useEffect(() => {
    fetch(productId);
  }, [productId, fetch]);

  const { save, onChange, products_edit } = props;

  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <Breadcrumb items={[{ link: `/products`, label: 'Products' }, { label: 'Edit' }]} />
      <Toolbar product={products_edit.values} />
      <Form formState={products_edit} onChange={onChange} save={save} />
    </>
  );
};

export default connect(
  ({ products_edit }) => ({ products_edit }),
  dispatch => ({
    fetch: payload =>
      dispatch({
        type: actions.PRODUCT_EDIT_FETCH,
        payload
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'products_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    },
    save: payload =>
      dispatch({
        type: actions.SAVE_ENTITY,
        payload,
        meta: { entity: 'products', url: `/products/${payload.productId}` }
      })
  })
)(ProductEdit);
