import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Link,
  Box,
  Typography,
  Divider,
  FormHelperText,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { ButtonSimple } from 'componentsV4/Button';
import Input from 'componentsV4/Input';
import SuccessfulModal from 'componentsV4/SuccessfulModal';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { Theme, withThemeV4 } from 'theme/v4';

import schema from './schema';
import { FormData } from './schema';

interface StyleProps {
  isSmallScreen: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    minHeight: '100%'
  },
  content: {
    marginBottom: props => (props.isSmallScreen ? '5vh' : '')
  },
  title: {
    color: theme.palette.secondary.main
  },
  body: {
    color: theme.palette.text.primary
  },
  input: {
    height: theme.typography.pxToRem(40)
  },
  button: {
    height: theme.typography.pxToRem(52)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(0.4)
  }
}));

const AccountRecoveryV4 = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles({ isSmallScreen });

  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const [submitMutation, { isLoading }] = useMutation(
    (formData: FormData) => axios.post(`/auth/recovery`, { email: formData.email }),
    {
      onSuccess: () => {
        setOpen(true);
      },
      onError: (data: { response: { status: number } }) => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message:
              data.response?.status === 422
                ? 'Email not found'
                : 'The request failed. Please try again.'
          },
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const onSubmit = (formData: FormData) => {
    submitMutation(formData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
        <Box display="flex-start">
          <Box mb={3}>
            <Typography variant="h3" className={classes.title}>
              Reset Password
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="body2" className={classes.body}>
              Enter your email address below and we'll send you a link to reset your password.
            </Typography>
          </Box>

          <Box mb={4}>
            <Input
              name="email"
              id="input-email"
              defaultLabel="Email address"
              placeholder="Ex.: email@email.com"
              fullWidth
              className={classes.input}
              inputRef={register()}
              required={true}
              error={errors['email']?.message ? true : false}
            />
            <FormHelperText error>{errors['email']?.message}</FormHelperText>
          </Box>

          <Box mb={2.9}>
            <ButtonSimple
              type="submit"
              text="Reset Password"
              variant="contained"
              isLoading={isLoading}
              fullWidth
              color="primary"
              disabled={!isValid}
              className={classes.button}
            />
          </Box>

          <Box mb={3.7} display="flex" justifyContent="center" alignItems="center">
            <Link title="Go to login page" to="/login" component={RouterLink}>
              <Typography component="span" variant="h5" color="primary">
                Sign in with another account
              </Typography>
            </Link>
          </Box>

          <Box mb={3.1}>
            <Divider />
          </Box>

          <Box mb={1.8}>
            <Typography variant="h4" className={classes.title}>
              Don't have an account yet?
            </Typography>
          </Box>

          <Typography variant="body2" className={classes.body}>
            Start monitoring the health of your technology infrastructure.
            <Link
              title="Go to sign up page"
              to="/sign-up"
              component={RouterLink}
              className={classes.link}>
              Sign up now
            </Link>
          </Typography>
        </Box>
      </form>
      <SuccessfulModal
        open={open}
        setOpen={setOpen}
        title="Password reset link has been sent to your e-mail"
        bodyComponent={`
          Check your inbox for the next steps.
          If you don't receive an email, and it's not in your spam folder this could mean you signed up with a different address.
        `}
      />
    </>
  );
};

export default withThemeV4(AccountRecoveryV4);
