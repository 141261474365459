import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 4,
    minWidth: 235,
    marginTop: theme.spacing(),
    maxHeight: 500,
    zIndex: theme.zIndex.appBar + 1000,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)'
  },
  list: {
    padding: 0
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: 0,
    padding: theme.spacing(1.5, 2),
    color: theme.palette.elvenGray[700],
    '&:hover': {
      backgroundColor: theme.palette.elvenGreen[50],
      color: theme.palette.elvenGreen[500]
    }
  },
  listItemActive: {
    backgroundColor: theme.palette.elvenGreen[50],
    color: theme.palette.elvenGreen[500]
  },
  orgName: {
    marginTop: theme.spacing(),
    maxWidth: 170,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  text: {
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(3)
  },
  textWithIcon: {
    flexGrow: 1
  },
  button: {
    width: 185,
    height: 45,
    borderRadius: 7,
    whiteSpace: 'nowrap'
  },
  iconButton: {
    marginRight: theme.spacing()
  },
  divider: {
    backgroundColor: theme.palette.grey[300]
  }
}));
