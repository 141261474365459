import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertPrometheusGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Prometheus',
    errors: ['Select an api token'],
    useToken: true,
    snippets: [
      {
        key: 'Config snippet',
        value: `
        route:
            receivers:
            - name: 1p-webhook
                webhook_configs:
                - url: "https://${getEnvUrl()}/${orgUid}/alerts/v1/prometheus/${integrationUid}?token=${token}"
                    send_resolved: true
            `
      },
      {
        key:
          'It is also possible to insert a description that will be saved in the incident, in annotations.description, e.g.',
        value: `
        - name: Instances GoLang
            rules:
            - alert: Error Alert status 500 - GoLang
                expr: sum by(app_kubernetes_io_instance, code) (rate(gin_request_duration_seconds_bucket{code="500"}[5m])) > 1
                for: 5m
                annotations:
                description: "The application returned the Status x times."
                labels:
                severity: critical
            `
      }
    ]
  };
};
