import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import MetricsProduct from 'views/Metrics/Product';
import ResourceCheckExplorer from 'views/Metrics/Resource/CheckExplorer';
import ResourcesList from 'views/Metrics/Resource/List';

const RouteV4WithMainLayout = MainLayoutV4;

export const metricsResourcesRoutesWithLayoutV2 = [
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={MetricsProduct}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/products/:productId"
    key="/metrics/products/:productId"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={ResourcesList}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/resource/:resourceUid"
    key="/metrics/resource/:resourceUid"
  />,
  <AuthorizedRoute
    planFeature="Metrics.Dashboards"
    component={ResourceCheckExplorer}
    exact
    layout={RouteV4WithMainLayout}
    path="/metrics/resource/:resourceUid/checks"
    key="/metrics/resource/:resourceUid/checks"
  />
];
