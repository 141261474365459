import { Divider, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { useStyles as useComponentsStyles } from '../styles';

import { useStyles } from './styles';

type CallToActionProps = {
  event: EventType;
};

export function CallToAction({ event }: CallToActionProps) {
  const componentsClasses = useComponentsStyles();
  const classes = useStyles();

  switch (event.type) {
    case EventTypeEnum.Alarmed:
    case EventTypeEnum.Acknowledged:
    case EventTypeEnum.Resolved:
    case EventTypeEnum.IncidentStatusUpdate:
      return (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Typography variant="body1" className={classes.incidentId}>
            ID &nbsp;
          </Typography>
          <Link
            className={classes.incidentIdLink}
            component={RouterLink}
            to={`/incidents/${event.incidentId}`}
            variant="body1"
            color="secondary">
            #{event.incidentId}
          </Link>
        </>
      );
  }
  return <Divider />;
}
