import { useEffect } from 'react';

import { useQuery } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

const getMTTQuery = (query: string, periods: string[]) => `#graphql
 query(
    $originUid: String!
    $entity: String!
  ) {
    ${query}(
        originUid: $originUid
        entity: $entity
    ) {
      period {
        ${periods.join('\n')}
      }
    }
  }
`;

function getAvailablePeriods(enabledDataRetentions: {
  canFetchDataFromTheLast30Days: boolean | null;
}): string[] {
  return [
    {
      field: 'oneDay',
      enabled: true // always available in Free
    },
    {
      field: 'thirtyDays',
      enabled: enabledDataRetentions.canFetchDataFromTheLast30Days
    }
  ]
    .filter(option => option.enabled)
    .map(({ field }) => field);
}

const useMtta = ({
  originUid,
  entity,
  disabled = false
}: {
  originUid?: string;
  entity?: string;
  disabled?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !originUid;

  const [resultMtta, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMttaV2', periods),
    pause: pause || disabled,
    variables: { originUid, entity }
  });

  const { fetching } = resultMtta;

  const dataFromGraphQl = resultMtta.data?.getMttaV2;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 * 10);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  return dataFromGraphQl;
};

const useMtbf = ({
  originUid,
  entity,
  disabled = false
}: {
  originUid?: string;
  entity?: string;
  disabled?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !originUid;

  const [resultMtbf, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMtbfV2', periods),
    pause: pause || disabled,
    variables: { originUid, entity }
  });

  const { fetching } = resultMtbf;

  const dataFromGraphQl = resultMtbf.data?.getMtbfV2;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 * 10);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  return dataFromGraphQl;
};

const useMttrecovery = ({
  originUid,
  entity,
  disabled = false
}: {
  originUid?: string;
  entity?: string;
  disabled?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !originUid;

  const [resultMttrecovery, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMttrecoveryV2', periods),
    pause: pause || disabled,
    variables: { originUid, entity }
  });

  const { fetching } = resultMttrecovery;

  const dataFromGraphQl = resultMttrecovery.data?.getMttrecoveryV2;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 * 10);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  return dataFromGraphQl;
};

export { useMtta, useMtbf, useMttrecovery };
