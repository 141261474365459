import MainLayoutV4 from 'layouts/V4/Main';
import MinimalLayoutV4 from 'layouts/V4/Minimal/Minimal';
import { Settings as SettingsView, Terms as TermsView, Loading as LoadingView } from 'views';
import { ForbiddenView } from 'views/Forbidden';
import { LoginErrorView } from 'views/LoginError';
import { NotFoundView } from 'views/NotFound';
import SlackIntegrationDone from 'views/SlackIntegrationDone';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

const RouteV4WithMainLayout = MainLayoutV4;
const RouteV4WithMinimalLayout = MinimalLayoutV4;

export const utilityRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={SettingsView}
    exact
    layout={RouteV4WithMainLayout}
    path="/settings"
  />,
  <RouteWithLayoutV2 component={TermsView} exact layout={RouteV4WithMinimalLayout} path="/terms" />,
  <RouteWithLayoutV2
    component={LoadingView}
    exact
    layout={RouteV4WithMinimalLayout}
    path="/loading"
  />,
  <RouteWithLayoutV2
    component={NotFoundView}
    exact
    layout={RouteV4WithMainLayout}
    path="/not-found"
  />,
  <RouteWithLayoutV2
    component={ForbiddenView}
    exact
    layout={RouteV4WithMainLayout}
    path="/forbidden"
  />,
  <RouteWithLayoutV2
    component={SlackIntegrationDone}
    exact
    layout={RouteV4WithMinimalLayout}
    path="/slack-integration-done"
    isPublicRoute={true}
  />,
  <RouteWithLayoutV2
    component={LoginErrorView}
    exact
    layout={RouteV4WithMinimalLayout}
    path="/login-error"
    isPublicRoute={true}
  />
];
