import MainLayoutV4 from 'layouts/V4/Main';
import RolesEditView from 'views/Roles/Edit';
import RolesListView from 'views/Roles/List';
import RolesNewView from 'views/Roles/New';
import RolesPermissionsView from 'views/Roles/Permissions';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const rolesRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={RolesListView}
    exact
    layout={MainLayoutV4}
    key="/roles"
    path="/roles"
  />,
  <RouteWithLayoutV2
    component={RolesNewView}
    exact
    layout={MainLayoutV4}
    key="/roles/new"
    path="/roles/new"
  />,
  <RouteWithLayoutV2
    component={RolesEditView}
    exact
    layout={MainLayoutV4}
    key="/roles/:id/edit"
    path="/roles/:id/edit"
  />,
  <RouteWithLayoutV2
    component={RolesPermissionsView}
    exact
    layout={MainLayoutV4}
    key="/roles/:id/permissions"
    path="/roles/:id/permissions"
  />
];
