import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentPrometheusGuide = ({
  token,
  orgUid,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Prometheus',
    errors: ['Select an api token'],
    useToken: true,
    snippets: [
      {
        key: 'Config snippet',
        value: `
        route:
            receivers:
            - name: 1p-webhook
                webhook_configs:
                - url: "https://${getEnvUrl()}/${orgUid}/incidents/v1/incidents/prometheus/${integrationUid}?token=${token}"
                    send_resolved: true
            `
      },
      {
        key: 'Payload',
        value: `
            {
                "title": {ProblemTitle},
                "description": {ProblemDetailsText},
                "external_aggregate_key": {Pid},
                "action": {State},
                "severity": ""
              }
            `
      }
    ]
  };
};
