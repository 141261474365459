import React from 'react';

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import encrypt from 'helpers/encrypt';
import actions from 'redux/actions';
import axios from 'redux/axios';

interface SignInParams {
  email: string;
  password: string;
}

export const useSignIn = () => {
  const dispatch = useDispatch();

  const router = useHistory();

  const [loginError, setLoginError] = React.useState(false);

  const [signInMutation, { isLoading, isError, isIdle, isSuccess }] = useMutation(
    (data: string | null) => axios.post('/auth/login', { data }).then(response => response.data),
    {
      onSuccess: data => {
        dispatch({ type: actions.LOGGED_USER, data });
        router.push('/');
      },
      onError: (e: any) => {
        if (e.response.status === 422) {
          setLoginError(true);
        }

        if (e.response.status !== 422) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: 'The request failed. Please try again.',
            ga: { category: 'ERROR' }
          });
        }
      }
    }
  );

  const handleSignIn = ({ email, password }: SignInParams) => {
    const form = JSON.stringify({ email, password });

    const hash = encrypt(form);

    signInMutation(hash);
  };

  return {
    handleSignIn,
    loginError,
    isLoading,
    isError,
    isIdle,
    isSuccess
  };
};
