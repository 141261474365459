import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';
import SwipeableViews from 'react-swipeable-views';

import useQueryParams from 'helpers/useQueryParams';

import { CustomTab } from './components/CustomTab';
import { CustomTabs } from './components/CustomTabs';
import { TabPanel } from './components/TabPanel';

const SELECTED_TAB_QUERY_STRING_NAME = 'tab';

type TabDefinitionType = {
  label: ReactNode;
  slug: string;
  component: JSX.Element;
  disabled?: boolean;
};

export type TabsProps = {
  onChange?: (index: number) => void;
  tabs: TabDefinitionType[];
};

const useUpdateSelectedTabOnQueryString = (tabs: TabsProps['tabs']) => {
  const history = useHistory();
  const location = useLocation();

  return {
    updateSelectedTabOnQueryString: (index: number) => {
      const params = new URLSearchParams(window.location.search);

      params.set(SELECTED_TAB_QUERY_STRING_NAME, tabs[index]?.slug || '');

      history.replace({ pathname: location.pathname, search: params.toString() });
    }
  };
};

export function Tabs({ tabs }: TabsProps) {
  const selectedTab = useQueryParams().get(SELECTED_TAB_QUERY_STRING_NAME);

  const { updateSelectedTabOnQueryString } = useUpdateSelectedTabOnQueryString(tabs);

  const getSelectedTab = useCallback(() => {
    if (!selectedTab) {
      return 0;
    }

    const tabIndex = tabs.findIndex(tab => tab.slug === selectedTab && !tab.disabled);

    if (tabIndex === -1) {
      updateSelectedTabOnQueryString(0);
      return 0;
    }

    return tabIndex;
  }, [selectedTab, tabs, updateSelectedTabOnQueryString]);

  const [currentTabIndex, setTabCurrentIndex] = useState(getSelectedTab);

  const updateSelectedTab = (newIndex: number) => {
    if (tabs[newIndex]?.disabled) {
      return;
    }

    updateSelectedTabOnQueryString(newIndex);
    return setTabCurrentIndex(newIndex);
  };

  useEffect(() => {
    setTabCurrentIndex(getSelectedTab());
  }, [getSelectedTab, selectedTab]);

  return (
    <>
      <CustomTabs
        indicatorColor="primary"
        onChange={(_, newIndex) => {
          updateSelectedTab(newIndex);
        }}
        value={currentTabIndex}>
        {tabs.map((item, index) => (
          <CustomTab key={item.slug} label={item.label} value={index} />
        ))}
      </CustomTabs>

      <SwipeableViews index={currentTabIndex} onChangeIndex={updateSelectedTab}>
        {tabs.map((item, index) => (
          <TabPanel key={item.slug} index={index} value={currentTabIndex}>
            {!item.disabled && item.component}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
}
