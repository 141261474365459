import { useState, useCallback } from 'react';

import { Fade, Box, Grid, Button, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import { useProductPermission } from 'hooks/useProductPermission';
import MaintenanceDialog from 'views/Products/View/Availability/components/Maintenance/Dialog/MaintenanceDialog';
import MaintenanceForceFinishForm from 'views/Products/View/Availability/components/Maintenance/Form/MaintenanceForceFinishForm';

const useStyles = makeStyles(theme => ({
  alert: {
    color: 'white',
    height: theme.typography.pxToRem(30),
    width: '100%',
    background: '#F57D27'
  },
  message: {
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 'bold',
    lineHeight: 2,
    fontSize: 16
  },
  button: {
    border: 'none',
    outline: 'none',
    background: 'none',
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline',
    fontWeight: 'bold',
    lineHeight: 2,
    fontSize: 16
  }
}));

const MaintenanceMessage = ({ maintenance, message, onForceFinish, user }) => {
  const classes = useStyles();

  const { productId } = useParams();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const canPostMaintenanceWithProduct = useProductPermission({
    target: 'MaintenanceController-put-/maintenance/force_finish',
    productId
  });
  const canPostMaintenanceWithoutProduct = usePermission(
    'MaintenanceController-put-/maintenance/force_finish'
  );

  const canForceFinishMaintenance = shouldUseNewApplications
    ? canPostMaintenanceWithoutProduct
    : canPostMaintenanceWithProduct;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const ForceFinishButton = props => (
    <Button class={classes.button} title="End maintenance" type="submit" {...props}>
      End maintenance
    </Button>
  );

  const ForceFinishMaintenance = MaintenanceForceFinishForm;

  const FormForceFinishComponent = useCallback(
    props => (
      <ForceFinishMaintenance onForceFinish={onForceFinish} handleClose={handleClose} {...props} />
    ),
    [onForceFinish]
  );

  return (
    <Fade in timeout={{ enter: 1000 }}>
      <Box className={classes.alert}>
        <Grid className={classes.message}>
          {message}
          {canForceFinishMaintenance && (
            <Tooltip
              title={
                'The maintenance window signals a period when your app will be unavailable for scheduled maintenance, pausing monitoring, alerts, and related notifications.'
              }>
              <Help
                style={{
                  color: 'white',
                  marginTop: '2',
                  marginLeft: '8',
                  marginRight: '24'
                }}
              />
            </Tooltip>
          )}

          {canForceFinishMaintenance && (
            <>
              <ForceFinishButton onClick={() => setIsModalOpen(true)} />
              <MaintenanceDialog
                isOpen={isModalOpen}
                handleClose={handleClose}
                title={'End maintenance?'}
                startValues={maintenance}
                applicationName={'applicationName'}
                onForceFinish={onForceFinish}
                formComponent={FormForceFinishComponent}
              />
            </>
          )}
        </Grid>
      </Box>
    </Fade>
  );
};

export default MaintenanceMessage;
