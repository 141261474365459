import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>(({ palette, spacing, typography }: Theme) => ({
  title: {
    color: palette.elvenTeal[400],
    fontSize: typography.pxToRem(28),
    fontWeight: typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2)
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  body: {
    color: palette.grey[800],
    fontSize: typography.pxToRem(16)
  },
  input: {
    width: 400,
    '&[data-visible=false]': {
      display: 'none'
    },
    '& svg': {
      // end-adornment
      marginRight: spacing(1)
    }
  },
  displayNone: {
    display: 'none'
  }
}));

export default useStyles;
