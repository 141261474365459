import { Breadcrumbs, Grid, Link as MuiLink, Typography, useTheme } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import useStyles from './styles';

type BreadcrumbProps = {
  items: {
    label: string;
    link?: string;
  }[];
  custom?: {
    isLastItemGray?: boolean;
  };
};

function Breadcrumb({ items, custom }: BreadcrumbProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item xs={12} style={{ margin: theme.spacing(2, 2, 2, 0) }}>
      <Breadcrumbs
        separator={<ChevronRight />}
        aria-label="breadcrumb"
        className={classes.root}
        classes={{ li: classes.listItem, separator: classes.separator }}>
        {items.map((item, index) => {
          const key = `${item.link}_${item.label}`;

          const isLastItem = index === items.length - 1;

          if (isLastItem) {
            return (
              <Typography
                key={key}
                color="textSecondary"
                className={clsx(classes.item, custom?.isLastItemGray && classes.itemGray)}>
                {item.label}
              </Typography>
            );
          }

          if (!item.label) {
            return null;
          }

          if (item.link) {
            return (
              <MuiLink
                key={key}
                className={clsx(classes.breadcrumbBefore, classes.item)}
                to={item.link}
                component={Link}>
                {item.label}
              </MuiLink>
            );
          }

          return (
            <Typography key={key} className={clsx(classes.breadcrumbBefore, classes.item)}>
              {item.label}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
}

export default Breadcrumb;
