import ApplicationType from 'constants/ApplicationType';

import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect } from 'react-router';

import ApplicationMultiHttp from 'componentsV3/ApplicationMultiHttp';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import withThemeV3Hoc from 'theme/v3/withTheme';

import WithAddons from './WithAddons';

function MultiHttp() {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }
  return (
    <>
      <WithAddons
        applicationType={ApplicationType.InternalMultiHttp}
        renderApplicationTab={({
          firstAccess,
          environmentId,
          productId
        }: {
          firstAccess: boolean;
          environmentId: number;
          productId: number;
          applicationType: string;
        }) => (
          <ApplicationMultiHttp
            firstAccess={firstAccess}
            environmentId={environmentId}
            productId={productId}
          />
        )}
      />
    </>
  );
}

export default withThemeV3Hoc(MultiHttp);
