import { getPermissionForProduct } from 'helpers';

import { useHasPermission } from './permissions/hasPermission';
import useUser from './queriesRest/useUser';
import useFeatureFlagUnleash from './useFeatureFlagUnleash';
import { useGetProductTeamsForPermission } from './useGetProductTeamsForPermission';

type UseProductPermissionParams = {
  target: string;
  productId: string;
};

export function useProductPermission({ target, productId }: UseProductPermissionParams) {
  const { data: user } = useUser();

  const useNewPermission = useFeatureFlagUnleash('useNewPermission', { queryString: true });

  const teams = useGetProductTeamsForPermission(Number(productId)) ?? [];

  const hasPermission = useHasPermission({
    userId: user?.id,
    target,
    productId: Number(productId)
  });

  return useNewPermission
    ? hasPermission
    : getPermissionForProduct(user, { id: Number(productId), teams }, target);
}
