import { useEffect, useRef, useState } from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  Typography as MuiTypography,
  useTheme
} from '@material-ui/core';

import '@material-ui/core/styles';
import PageContainer from 'components/PageContainer';
import { Typography } from 'components/Typography';
import CardNewResource from 'componentsV3/CardNewResource';
import { ButtonSimple } from 'componentsV4/Button';
import useUser from 'hooks/queriesRest/useUser';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import { analyticsEvent } from 'services/firebase/analytics';

import applicationType from '../../../constants/ApplicationType';
import ProductsList from '../../V3/Products/List/ProductList';

const Products = () => {
  const [firstAccess, setFirstAccess] = useState(false);
  const [
    shouldUpdateHighlightNewResourcesCard,
    setShouldUpdateHighlightNewResourcesCard
  ] = useState(false);
  const [highlightNewResourcesCard, setHighlightNewResourcesCard] = useState(false);
  const createProductSectionRef = useRef(null);
  const theme = useTheme();

  const { data: user, isLoading } = useUser({});

  const shouldShowButtonToCreateProduct = useFeaturePlan('Monitoring');
  const hasNewProductPermission = usePermission('ProductController-post-/products');

  const handleNewProduct = () => {
    // eslint-disable-next-line no-unused-expressions
    createProductSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });

    if (setHighlightNewResourcesCard) {
      setHighlightNewResourcesCard(false);
      setShouldUpdateHighlightNewResourcesCard(true);
      return;
    }
    setHighlightNewResourcesCard(true);
  };

  useEffect(() => {
    if (shouldUpdateHighlightNewResourcesCard) {
      setHighlightNewResourcesCard(true);
      setShouldUpdateHighlightNewResourcesCard(false);
    }
  }, [shouldUpdateHighlightNewResourcesCard]);

  return (
    <PageContainer>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="h3" style={{ flexGrow: 1, color: theme.palette.elvenTeal[400] }}>
            Welcome, {user?.displayName}
          </Typography>
          {shouldShowButtonToCreateProduct && hasNewProductPermission && (
            <ButtonSimple
              style={{
                width: 'auto',
                background: theme.palette.primary.main,
                color: theme.palette.background.paper
              }}
              text="Monitor a New Product"
              onClick={handleNewProduct}
            />
          )}
        </Box>
      )}
      <Box marginTop={3} marginBottom={3}>
        <ProductsList firstAccess={firstAccess} setFirstAccess={setFirstAccess} />
      </Box>
      {hasNewProductPermission && (
        <>
          <MuiTypography ref={createProductSectionRef} variant="h3" gutterBottom>
            What do you want to monitor?
          </MuiTypography>
          <MuiTypography gutterBottom variant="body1">
            Select an option below and start monitoring your infrastructure in just a few steps.
          </MuiTypography>
          <MuiTypography gutterBottom variant="body1">
            Get quick information when your application crashes or is unstable.
          </MuiTypography>
          <Grid
            container
            spacing={3}
            className="newMonitoringShortcuts"
            style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>
            <Grid item lg={3} xs={12}>
              <CardNewResource
                styleChange={highlightNewResourcesCard}
                title={applicationType.ExternalWithoutAddons.title}
                body={applicationType.ExternalWithoutAddons.description}
                characteristics={[
                  applicationType.ExternalWithoutAddons.agentRequirement,
                  applicationType.ExternalWithoutAddons.addonsInfo
                ]}
                linkTo={
                  firstAccess
                    ? '/products/v3/new/externalWithoutAddons?firstAccess'
                    : '/products/v3/new/externalWithoutAddons'
                }
                analyticsEvent={() => {
                  analyticsEvent(firstAccess ? 'fa_new_epwd_button' : 'new_epwd_button');
                }}
              />
            </Grid>

            <Grid item lg={3} xs={12}>
              <CardNewResource
                styleChange={highlightNewResourcesCard}
                title={applicationType.ExternalWithAddons.title}
                body={applicationType.ExternalWithAddons.description}
                characteristics={[
                  applicationType.ExternalWithAddons.agentRequirement,
                  applicationType.ExternalWithAddons.addonsInfo
                ]}
                linkTo={'/products/v3/new/externalWithAddons'}
                analyticsEvent={() =>
                  analyticsEvent(firstAccess ? 'fa_new_eaea_button' : 'new_eaea_button')
                }
              />
            </Grid>

            <Grid item lg={3} xs={12}>
              <CardNewResource
                styleChange={highlightNewResourcesCard}
                title={applicationType.Internal.title}
                body={applicationType.Internal.description}
                characteristics={[
                  applicationType.Internal.agentRequirement,
                  applicationType.Internal.addonsInfo
                ]}
                linkTo={'/products/v3/new/internalApplication'}
                analyticsEvent={() =>
                  analyticsEvent(firstAccess ? 'fa_new_iaea_button' : 'new_iaea_button')
                }
              />
            </Grid>

            <Grid item lg={3} xs={12}>
              <CardNewResource
                styleChange={highlightNewResourcesCard}
                title={applicationType.Webhook.title}
                body={applicationType.Webhook.description}
                characteristics={[
                  applicationType.Webhook.agentRequirement,
                  applicationType.Webhook.addonsInfo
                ]}
                linkTo={'/products/v3/new/webhookApplication'}
                analyticsEvent={() => analyticsEvent(firstAccess ? 'fa_new_hook' : 'new_hook')}
              />
            </Grid>

            <Grid item lg={3} xs={12}>
              <CardNewResource
                styleChange={highlightNewResourcesCard}
                title={applicationType.InternalMultiHttp.title}
                body={applicationType.InternalMultiHttp.description}
                characteristics={[
                  applicationType.InternalMultiHttp.agentRequirement,
                  applicationType.InternalMultiHttp.addonsInfo
                ]}
                linkTo={`/products/v3/new/multiHttp${firstAccess ? '?firstAccess' : ''}`}
                analyticsEvent={() => analyticsEvent(firstAccess ? 'fa_new_multi' : 'new_multi')}
              />
            </Grid>
          </Grid>{' '}
        </>
      )}
    </PageContainer>
  );
};

export default Products;
