import { useEffect, useState } from 'react';

import applicationType from 'constants/ApplicationType';

import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import { useQuery, UseQueryState, OperationContext } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import DialogBox from 'componentsV3/DialogBox';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import ModalAllReady from 'componentsV3/ModalAllReady';
import { getEnvUrl, getAbreviatedEnv } from 'helpers/getEnvString';
import useUser from 'hooks/queriesRest/useUser';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';
import ModalToken from 'views/V3/Products/View/TabSetup/Componets/ModalToken';

import DialogBoxDouble from './Componets/DialogBoxDouble';
import GeneratorToken, { Token, useTokensQuery } from './Componets/GeneratorToken';
import useForm from './useForm';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  marginBottom8: {
    marginBottom: spacing(1)
  },
  marginBottom16: {
    marginBottom: spacing(2)
  },
  marginTop16: {
    marginTop: spacing(2)
  },
  marginTop24: {
    marginTop: spacing(3)
  },
  marginBottom24: {
    marginBottom: spacing(3)
  },
  marginTop32: {
    marginTop: spacing(4)
  },
  marginTop40: {
    marginTop: spacing(5)
  }
}));

const startValues = {
  token: ''
};

export type RefreshToken = {
  client_id: string;
  client_secret: string;
};

type GetCurl = {
  data: RefreshToken | undefined;
  org: any;
};

const getCurl = ({ data, org }: GetCurl) => {
  const groupName = `${org.id}-${org.name
    .normalize('NFD')
    .replace(/[\u0300-\u036f-\s]/g, '')
    .toLowerCase()}${getAbreviatedEnv()}`;

  if (data) {
    return `curl --request POST \\
          --url https://${getEnvUrl()}/external/auth/v1/client/${groupName} \\
          --header 'Content-Type: application/json' \\
          --header 'User-Agent: 1PwebhookAuth/1.0' \\
          --data '{
            "client_id": "${data.client_id}",
            "client_secret": "${data.client_secret}"
          }'`;
  }
};

const RegenerateAppTokenQuery = `#graphql
  mutation (
    $id: Float!
    $name: String!
  ) {
    regenerateAppToken(
      appTokenRegenerateInput: {
        id: $id,
        name: $name
      }
    ) {
      client_id
      client_secret
    }
  }
`;

function useRegenerateAppToken({
  id,
  name
}: Token): [
  UseQueryState<{ regenerateAppToken: RefreshToken }, object>,
  (opts?: Partial<OperationContext> | undefined) => void
] {
  const [result, reexecuteQuery] = useQuery<{ regenerateAppToken: RefreshToken }>({
    query: RegenerateAppTokenQuery,
    pause: [id, name].some(value => !value),
    variables: {
      id,
      name
    }
  });

  return [result, reexecuteQuery];
}

function TabSetup({
  sterasId,
  firstAccess = false,
  onFinish = () => {}
}: {
  sterasId: number | null;
  firstAccess: boolean;
  onFinish: () => void;
}) {
  const classes = useStyles();

  const [showTokensModal, setShowTokensModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [refreshToken, setRefreshToken] = useState<RefreshToken | null>(null);

  const { watch, setValue, control, formState } = useForm({
    defaultValues: startValues
  });

  const { data: userData } = useUser({});

  const orgUid = userData?.logged?.org?.uid;

  const [tokensQueryResult, reexecuteTokensQuery] = useTokensQuery();

  const { data: appTokensData } = tokensQueryResult;

  const tokenId = watch('token');

  const token = tokenId
    ? appTokensData?.appTokens?.data?.find(token => token.id === Number(tokenId))
    : null;

  const [regenerateTokenResult] = useRegenerateAppToken(token || ({} as Token));

  const { data: regenerateTokenData, fetching: regeneratingAppToken } = regenerateTokenResult;

  const { isValid } = formState;

  useEffect(
    () => {
      analyticsEvent(firstAccess ? 'fa_view_setup_pg_hook' : 'view_setup_pg_hook');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Typography variant="h3" className={classes.marginBottom16}>
        Configure communication between your system and the OnePlatform
      </Typography>
      <Typography variant="body1">
        Carefully follow the steps below to configure the agent. <br />
        If you need help, check out this FAQ article or ask one of our implementers for help.
      </Typography>

      <Typography variant="h6" className={classes.marginTop32}>
        Defines the integration token for hits/failures
      </Typography>
      <Typography variant="body1">
        Whenever an integration token is created, it will also be stored on your organization's
        page.
      </Typography>

      <Grid container className={classes.marginTop16}>
        <Grid item xs={6}>
          <GeneratorToken
            result={tokensQueryResult}
            control={control}
            setShowTokensModal={() => {
              setShowTokensModal(true);
            }}
            firstAccess={firstAccess}
            setValue={setValue}
          />
        </Grid>
      </Grid>
      {(refreshToken || tokenId) && (
        <>
          <Grid container className={classes.marginTop24}>
            <Grid item xs={6}>
              <Box position="relative">
                <DialogBox
                  title={'Insert this token into your system.'}
                  link={
                    'https://elvenworks.zendesk.com/hc/en-us/articles/17614454749844-Creating-Webhook-Credentials'
                  }
                  text={getCurl({
                    data: regenerateTokenData?.regenerateAppToken,
                    org: userData?.logged.org
                  })}
                  applicationType={applicationType.Webhook.type}
                  firstAccess={Boolean(firstAccess)}
                />
                {regeneratingAppToken && <LoadingOverlay />}
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h3" className={`${classes.marginTop32} `}>
            Now generate the curls needed for hits and failures.
          </Typography>

          <Grid container className={classes.marginTop24}>
            <Grid item>
              <DialogBoxDouble sterasId={sterasId} orgUid={orgUid} firstAccess={firstAccess} />
            </Grid>
          </Grid>

          <ButtonSimple
            onClick={() => {
              setShowSuccessModal(true);

              analyticsEvent(firstAccess ? 'fa_finish_monitor_hook' : 'finish_monitor_hook');
            }}
            className={classes.marginTop40}
            disabled={!isValid}
            color="primary"
            text="Finish Setup"
          />
        </>
      )}

      <ModalToken
        onTokenCreate={(token: RefreshToken | undefined) => {
          reexecuteTokensQuery();

          setRefreshToken(token || null);

          setValue('token', token?.client_id);
        }}
        isOpen={showTokensModal}
        handleClose={() => {
          setShowTokensModal(false);
        }}
        firstAccess={Boolean(firstAccess)}
      />
      <ModalAllReady
        title="All set"
        description="Go to the Application page"
        mainActionLabel="Go to Application page"
        isOpen={showSuccessModal}
        firstAccess={firstAccess}
        mainAction={onFinish}
        applicationType={applicationType.Webhook.type}
      />
    </>
  );
}

export default TabSetup;
