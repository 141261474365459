import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Edit as EditIcon, Visibility, VisibilityOff } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

import CheckBox from 'componentsV3/CheckBox';
import MarkdownEditor from 'componentsV4/MarkdownEditor';
import useExternalIntegration from 'hooks/queriesGraphQL/useExternalIntegration';
import { useMultiFlows } from 'hooks/queriesGraphQL/useMultiFlows';
import { useResources } from 'hooks/queriesGraphQL/useResources';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import MarkdownIcon from 'icons/Markdown';
import axios from 'redux/axios';

import Form from '../../../../components/Crud/Form/Form';

import addService from './addService';
import { EditServiceModal } from './EditServiceModal';
import Icon from './Icon';
import moveServiceToAnotherComponent from './moveServiceToAnotherComponent';
import removeService from './removeService';
import reorder from './reorder';
import { types } from './types';
import updateComponentName from './updateComponentName';
import UploadImage from './uploadImage';
import useFocusGroupName from './useFocusGroupName';
import useForm from './useForm';
import useMutation from './useMutation';

const GetResourcesQuery = `#graphql
  query(
    $from: Int!,
    $size: Int!,
    $query: String!
  ) {
    getResourcesFromIndexer(
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        id
        uid
        name
        serviceId
        origin
        domainSettings
        status {
          status
        }        
        environment {
          name
        }
      }
      total
    }
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    height: '78vh',
    overflow: 'auto'
  },
  droppable: {
    minHeight: theme.spacing(8)
  },
  listItemWithRemove: {
    '&:hover + .removeButton': {
      display: 'block'
    }
  },
  removeButton: {
    display: 'none',
    '&:hover': {
      display: 'block'
    }
  },
  listIcon: {
    minWidth: theme.spacing(4)
  },
  toolboxIcon: {
    fontSize: theme.typography.pxToRem(22)
  },
  addIcon: {
    marginLeft: theme.spacing(1)
  },
  markdownTipText: {
    margin: '0px 0px 14px 8px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    gap: 6
  },
  label: {
    color: theme.palette.secondary.main
  }
}));

const flatten = (final, item) => {
  if (Array.isArray(item)) {
    return [...final, ...item.reduce(flatten, [])];
  }

  return [...final, item];
};

const getPaddingLeftForType = type => {
  if (type === types.product) {
    return null;
  }

  if (type === types.environment) {
    return { paddingLeft: 4 * 8 };
  }

  if (type === types.application || type === types.external) {
    return { paddingLeft: 8 * 8 };
  }

  if (type === types.addon) {
    return { paddingLeft: 12 * 8 };
  }
};

const getVariantForType = type => {
  if (type === types.product) {
    return 'h5';
  }

  if (type === types.environment) {
    return 'h6';
  }

  if (type === types.application) {
    return null;
  }

  if (type === types.addon) {
    return null;
  }
};

const GetEnvironments = () => {
  return useQuery(
    'statuspage/resources',
    () => axios.get(`/statuspage/environments`).then(response => response.data),
    {
      refetchOnWindowFocus: false
    }
  );
};

const IsDeprecatedItem = item => {
  if (
    item.type === 'product' ||
    item.type === 'environment' ||
    item.type === 'external' ||
    item.type === 'synthetic' ||
    item.synthetic_uid ||
    item.useResource === true ||
    item.use_resource === true
  ) {
    return false;
  }

  return true;
};

const DragAndDropBoxes = ({ startValues }) => {
  const classes = useStyles();

  const [statusPageComponents, setStatusPageComponents] = React.useState(
    startValues?.groups.map(group => {
      return {
        ...group,
        services: group.services.map(service => {
          return {
            ...service,
            id: service.monitoringId
          };
        })
      };
    }) || []
  );

  const [shouldValidateServices, setShouldValidateServices] = React.useState(false);

  const { setShouldFocusLastGroupName, lastGroupTextFieldRef } = useFocusGroupName();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const statusPageComponentsFlatten = statusPageComponents
    .map(({ services }) => services)
    .reduce(flatten, []);

  const {
    control,
    register,
    errors,
    handleSubmit,
    values: { slug },
    reset
  } = useForm(startValues);

  const isEditing = Boolean(startValues);
  const isPrivate = Boolean(startValues?.isPrivate || false);

  const { save, isLoading } = useMutation({ startValues, statusPageComponents });

  const { data: statusPageResources } = !shouldUseNewApplications
    ? GetEnvironments()
    : { data: [] };

  const { externalList } = useExternalIntegration();

  const [{ data: syntheticsData = { multiflows: { data: [] } } }] = useMultiFlows({
    page: 1,
    perPage: 1000,
    query: ''
  });

  const [{ data: resourcesData = { getResourcesFromIndexer: { data: [] } } }] = useResources({
    graphqlQuery: GetResourcesQuery,
    page: 1,
    perPage: 1000,
    query: '',
    pause: !shouldUseNewApplications
  });

  const syntheticsInGroups = syntheticsData?.multiflows?.data.reduce((acc, synthetic) => {
    if (acc[synthetic.environment.name]) {
      acc[synthetic.environment.name].data.push({
        ...synthetic,
        sterasId: null,
        monitoringId: synthetic.uid,
        type: types.application,
        level: types.application,
        resource_uid: null,
        use_resource: null,
        synthetic_uid: synthetic.uid
      });
      return acc;
    }

    acc[synthetic.environment.name] = {
      origin: synthetic.origin,
      data: [
        {
          ...synthetic,
          sterasId: null,
          monitoringId: synthetic.uid,
          type: types.application,
          level: types.application,
          resource_uid: null,
          use_resource: null,
          synthetic_uid: synthetic.uid
        }
      ]
    };

    return acc;
  }, {});

  const getGroupName = resource => {
    const groupName = {
      agent: resource?.environment?.name,
      pool: 'Pool - N. Virginia',
      api: 'API',
      static: 'Static'
    };

    return groupName[resource.origin];
  };

  const resourceInGroups = resourcesData?.getResourcesFromIndexer?.data.reduce((acc, resource) => {
    const groupName = getGroupName(resource);

    if (acc[groupName]) {
      acc[groupName].data.push({
        ...resource,
        sterasId: null,
        monitoringId: String(resource.serviceId),
        type: types.application,
        level: types.application,
        resourceUid: resource.uid,
        useResource: true,
        synthetic_uid: null
      });
      return acc;
    }

    acc[groupName] = {
      origin: resource.origin,
      data: [
        {
          ...resource,
          sterasId: null,
          monitoringId: String(resource.serviceId),
          type: types.application,
          level: types.application,
          resourceUid: resource.uid,
          useResource: true,
          synthetic_uid: null
        }
      ]
    };

    return acc;
  }, {});

  const externalListInGroups = externalList?.reduce((acc, external) => {
    if (acc[external.origin]) {
      acc[external.origin].data.push({
        ...external,
        sterasId: external.uid,
        monitoringId: external.uid,
        type: types.external,
        level: types.external,
        resource_uid: null,
        use_resource: null
      });

      return acc;
    }

    acc[external.origin] = {
      origin: external.origin,
      data: [
        {
          ...external,
          sterasId: external.uid,
          monitoringId: external.uid,
          type: types.external,
          level: types.external,
          resource_uid: null,
          use_resource: null
        }
      ]
    };
    return acc;
  }, {});

  const externalIntegrations = Object.keys(externalListInGroups).map(origin => {
    const integration = externalListInGroups[origin];
    return [
      {
        name: integration.origin,
        type: types.environment,
        level: types.environment,
        id: -1
      },
      ...integration.data
    ];
  });

  const synthetics = Object.keys(syntheticsInGroups).map(environment => {
    const sythetics = syntheticsInGroups[environment];
    return [
      {
        name: environment,
        type: types.environment,
        level: types.environment
      },
      ...sythetics.data
    ];
  });

  const resources = Object.keys(resourceInGroups).map(environment => {
    const resources = resourceInGroups[environment];
    return [
      {
        name: environment,
        type: types.environment,
        level: types.environment
      },
      ...resources.data
    ];
  });

  const resourcesTreeFlat = statusPageResources
    ?.map(product => [
      {
        ...product,
        type: types.product,
        level: types.product
      },
      ...product.environments.map(environment => [
        { ...environment, type: types.environment, level: types.environment },
        ...environment.applications.map(application => [
          {
            ...application,
            type: types.application,
            level: types.application,
            monitoringId: String(application.sterasId)
          },
          ...application.addons.map(addon => ({
            ...addon,
            type: types.addon,
            level: types.addon,
            monitoringId: String(addon.sterasId)
          }))
        ])
      ])
    ])
    .concat(
      [
        {
          id: -1,
          name: 'External Service Monitoring',
          type: types.product,
          level: types.product
        },
        externalIntegrations
      ],
      [
        {
          id: -1,
          name: 'Synthetic Monitorings',
          type: types.product,
          level: types.product
        },
        synthetics
      ],
      shouldUseNewApplications
        ? [
            {
              id: -1,
              name: 'Resource Monitorings',
              type: types.product,
              level: types.product
            },
            resources
          ]
        : []
    )
    .reduce(flatten, [])
    .map((item, index) => ({ ...item, key: index }));

  const handleGroupNameChange = (event, index) => {
    const name = event.target.value;
    setStatusPageComponents(updateComponentName({ name, index }));
  };

  const handleAddGroup = index => {
    setStatusPageComponents([...statusPageComponents, { name: 'New Group', services: [] }]);
    setShouldFocusLastGroupName(true);
  };

  const handleRemoveGroup = index => {
    setStatusPageComponents(
      statusPageComponents.filter((component, componentIndex) => componentIndex !== index)
    );
  };

  const onDragEnd = event => {
    const { source, destination } = event;

    // do nothing if the user drop outiside a dropable area
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      setStatusPageComponents(
        reorder(
          statusPageComponents,
          event.source.index,
          event.destination.index,
          source.droppableId
        )
      );
    }

    // do nothing if the user drag and drop from the products column
    if (source.droppableId.includes('prod-') && destination.droppableId.includes('prod-')) {
      return;
    }
    // remove the item from the products column and put it on statuspage column
    if (source.droppableId.includes('prod-') && !destination.droppableId.includes('prod-')) {
      setStatusPageComponents(addService({ resources: resourcesTreeFlat, ...event }));
    }

    // change selected between groups
    if (
      !source.droppableId.includes('prod-') &&
      !destination.droppableId.includes('prod-') &&
      source.droppableId !== destination.droppableId
    ) {
      setStatusPageComponents(
        moveServiceToAnotherComponent({ resources: resourcesTreeFlat, ...event })
      );
    }
  };

  const handleRemoveService = ({ droppableId, draggableId }) => {
    setStatusPageComponents(
      removeService({ resources: resourcesTreeFlat, source: { droppableId }, draggableId })
    );
  };

  const onKeyPress = e => {
    e.key === 'Enter' && e.preventDefault();
  };

  const publicUrl = `${process.env.REACT_APP_URL_STATUS_PAGE_PUBLIC ||
    'https://status.elven.works'}/${startValues?.uid}/${slug}`;

  const onSubmit = data => {
    if (statusPageComponents.find(component => !component.services.length)) {
      setShouldValidateServices(true);
      return;
    }

    save(data).then(() => reset());
  };

  const [isEditServiceModalOpen, setEditModalServiceOpen] = useState(false);

  const [editModalData, setEditModalData] = useState({});

  const canUsePrivateStatusPage = useFeaturePlan('StatusPage.Private');

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <Form
        formState={{ isValid: statusPageComponents.find(component => component.services.length) }}
        title={'New Status Page'}
        onSave={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignContent="center">
          <Grid item xs={12}>
            <UploadImage cover={startValues?.cover} />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.label} variant="h5" component="label" gutterBottom>
                Name
              </Typography>
              <Box maxWidth="400px" clone>
                <TextField
                  variant="outlined"
                  size="small"
                  name="name"
                  inputRef={register}
                  onKeyPress={onKeyPress}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={Boolean(errors?.name?.message)}
                  helperText={errors?.name?.message}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography className={classes.label} variant="h5" component="label" gutterBottom>
                Slug
              </Typography>
              <Box maxWidth="400px" clone>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  name="slug"
                  inputRef={register}
                  onKeyPress={onKeyPress}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={Boolean(errors?.slug?.message)}
                  helperText={errors?.slug?.message}
                />
              </Box>
            </Box>
          </Grid>

          {canUsePrivateStatusPage && (
            <Grid item xs={12}>
              <>
                <Box>
                  <Typography variant="caption" color="textPrimary" gutterBottom>
                    <span className={classes.markdownTipText}>
                      Password{' '}
                      <CheckBox inputRef={register} defaultChecked={isPrivate} name="isPrivate" />
                    </span>
                  </Typography>
                </Box>
                <Box maxWidth="400px" clone>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    required
                    size="small"
                    label="Password"
                    name="passphrase"
                    inputRef={register}
                    onKeyPress={onKeyPress}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={Boolean(errors?.passphrase?.message)}
                    helperText={errors?.passphrase?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              </>
            </Grid>
          )}

          <Grid item xs={12}>
            <>
              <Typography className={classes.label} variant="h5" component="label" gutterBottom>
                Subtitle
              </Typography>
              <Box mb="-16px" mt="4px">
                <Typography variant="caption" color="textPrimary" gutterBottom>
                  <span className={classes.markdownTipText}>
                    Tip: Markdown is supported <MarkdownIcon fontSize="small" />
                  </span>
                </Typography>
              </Box>
              <Box maxWidth="400px">
                <MarkdownEditor
                  MdEditorCommands={['bold', 'italic', 'code', 'link', 'quote']}
                  name="subtitle"
                  control={control}
                />
              </Box>
            </>
          </Grid>

          <Grid item xs={12}>
            <Box maxWidth="400px">
              <Box display="flex" alignItems="center">
                <Controller
                  control={control}
                  name="enableSubscription"
                  render={({ value, onChange }) => (
                    <Checkbox checked={value} onChange={e => onChange(e.target.checked)} />
                  )}
                />
                <span>Enable Subscription</span>
              </Box>
            </Box>
          </Grid>

          {isEditing && (
            <Grid item xs={12}>
              <Typography>
                Your Status Page url will be:{' '}
                <Link href={publicUrl} target="_blank" rel="noopener noreferrer">
                  {publicUrl} <OpenInNewIcon style={{ fontSize: '1rem', marginBottom: '-2px' }} />
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>

        <Box
          display="inline-flex"
          alignItems="center"
          my={4}
          p={2}
          style={{ backgroundColor: grey[100] }}>
          <Box mr={1} clone>
            <InfoIcon style={{ color: grey[400] }} />
          </Box>
          <Typography>Drag and drop from your resources into your status page below.</Typography>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2} alignContent="center" style={{ width: '100%' }}>
            <Grid item xs={4}>
              <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                minHeight={36}>
                <Typography variant="h5">Your Resources</Typography>
              </Box>

              <Paper elevation={3} className={classes.paper}>
                <List component="nav" aria-labelledby="nested-list-subheader">
                  <Droppable key={`prod-`} droppableId={`prod-`} isDropDisabled={true}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {resourcesTreeFlat?.map((item, itemIndex) => {
                          const isDeprecatedItem = () => {
                            if (!shouldUseNewApplications) {
                              return '';
                            }
                            if (IsDeprecatedItem(item)) {
                              return '(old)';
                            }
                            return '';
                          };

                          const hasMonitoringId =
                            typeof item.monitoringId === 'string' && item.monitoringId.length > 0;

                          const isPresentOnStatusPage =
                            statusPageComponentsFlatten.findIndex(
                              service => String(service.monitoringId) === String(item.monitoringId)
                            ) >= 0;

                          const isDragDisabled = !hasMonitoringId || isPresentOnStatusPage;

                          const isDraggable =
                            item.type === types.application ||
                            item.type === types.addon ||
                            item.type === types.external;

                          const listItem = (
                            <ListItem
                              key={item.key}
                              button
                              disableRipple
                              style={getPaddingLeftForType(item.type)}
                              disabled={isDraggable && isDragDisabled}>
                              <ListItemIcon className={classes.listIcon}>
                                <Icon type={item.type} />
                              </ListItemIcon>
                              <Typography variant={getVariantForType(item.type)}>
                                {`${item.name} ${isDeprecatedItem()}`}
                              </Typography>
                            </ListItem>
                          );

                          return (
                            <Draggable
                              key={`app-${item.key}`}
                              draggableId={`${item.key}`}
                              index={itemIndex}
                              isDragDisabled={isDragDisabled}>
                              {(provided, snapshot) => (
                                <React.Fragment>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    // Solution to support "cloning a copy" of the element
                                    // https://github.com/atlassian/react-beautiful-dnd/issues/216#issuecomment-755864913
                                    style={{
                                      ...provided.draggableProps.style,
                                      transform: snapshot.isDragging
                                        ? provided.draggableProps.style?.transform
                                        : 'translate(0px, 0px)'
                                    }}>
                                    {listItem}
                                  </div>
                                  {/* Solution to support "cloning a copy" of the element
                                      https://github.com/atlassian/react-beautiful-dnd/issues/216#issuecomment-755864913 */}
                                  {snapshot.isDragging && (
                                    <div style={{ transform: 'none !important' }}>{listItem}</div>
                                  )}
                                </React.Fragment>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">What will be shown in your Status Page</Typography>

                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.addIcon}
                  onClick={handleAddGroup}>
                  Add Group
                </Button>
              </Box>

              <Paper elevation={3} className={classes.paper}>
                <List component="nav" aria-labelledby="nested-list-subheader">
                  {statusPageComponents.map((group, index) => {
                    const error = shouldValidateServices && !group.services.length;

                    return (
                      <div key={`group-${index}`}>
                        <ListItem className={classes.listItemWithRemove}>
                          <ListItemIcon>
                            <WebAssetIcon />
                          </ListItemIcon>
                          <TextField
                            inputRef={
                              index + 1 === statusPageComponents.length
                                ? lastGroupTextFieldRef
                                : undefined
                            }
                            variant="outlined"
                            size="small"
                            name={`${group.name}_${index}`}
                            label="Group"
                            value={group.name}
                            onChange={event => {
                              handleGroupNameChange(event, index);
                            }}
                            onKeyPress={onKeyPress}
                            InputLabelProps={{
                              shrink: true
                            }}
                            error={error}
                            helperText={error && 'Component has no services ↴'}
                          />
                          <ListItemSecondaryAction
                            className={clsx(classes.removeButton, 'removeButton')}>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleRemoveGroup(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>

                        <Droppable droppableId={`group-${index}`}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} className={classes.droppable}>
                              {group.services.map((service, index) => {
                                const isDeprecatedItem = () => {
                                  if (!shouldUseNewApplications) {
                                    return '';
                                  }
                                  if (IsDeprecatedItem(service)) {
                                    return '(old)';
                                  }
                                  return '';
                                };

                                return (
                                  <div key={service.id}>
                                    <Draggable draggableId={`service-${service.id}`} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <ListItem
                                            style={{ paddingLeft: 32 }}
                                            className={classes.listItemWithRemove}>
                                            <ListItemIcon className={classes.listIcon}>
                                              <TurnedInIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={`${service.name} ${isDeprecatedItem()}`}
                                              secondary={
                                                service.displayName ? (
                                                  <Typography variant="caption">
                                                    {service.displayName}
                                                  </Typography>
                                                ) : null
                                              }
                                            />
                                            <ListItemSecondaryAction
                                              className={clsx(
                                                classes.removeButton,
                                                'removeButton'
                                              )}>
                                              <IconButton
                                                edge="end"
                                                aria-label="hide"
                                                onClick={() => {
                                                  const updatedStatusPages = [
                                                    ...statusPageComponents
                                                  ];

                                                  const group = updatedStatusPages.find(page =>
                                                    page.services.some(
                                                      item => item.id === service.id
                                                    )
                                                  );

                                                  const serviceToUpdate = group.services.find(
                                                    item => item.id === service.id
                                                  );

                                                  if (serviceToUpdate) {
                                                    serviceToUpdate.displayEnabled = !service.displayEnabled;
                                                    setStatusPageComponents(updatedStatusPages);
                                                  }
                                                }}>
                                                {service.displayEnabled ? (
                                                  <Visibility />
                                                ) : (
                                                  <VisibilityOff />
                                                )}
                                              </IconButton>

                                              <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => {
                                                  setEditModalServiceOpen(true);
                                                  setEditModalData({ service });
                                                }}>
                                                <EditIcon />
                                              </IconButton>

                                              <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() =>
                                                  handleRemoveService({
                                                    droppableId: `group-${index}`,
                                                    draggableId: `service-${service.id}`
                                                  })
                                                }>
                                                <DeleteIcon />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          </ListItem>
                                        </div>
                                      )}
                                    </Draggable>
                                  </div>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </DragDropContext>

        {isEditServiceModalOpen && (
          <EditServiceModal
            handleClose={() => setEditModalServiceOpen(false)}
            data={editModalData}
            onUpdate={data => {
              const updatedStatusPages = [...statusPageComponents];

              const service = updatedStatusPages.find(page =>
                page.services.some(service => service.id === data.id)
              );

              const serviceToUpdate = service.services.find(service => service.id === data.id);

              if (serviceToUpdate) {
                serviceToUpdate.displayName = data.displayName;
                setStatusPageComponents(updatedStatusPages);
              }
            }}
          />
        )}
      </Form>
      {isLoading && (
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgb(255 255 255 / 66%)',
            zIndex: 1000
          }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default DragAndDropBoxes;
