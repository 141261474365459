import React from 'react';

import ApplicationType from 'constants/ApplicationType';

import { Container, Divider, Typography, makeStyles, Grid } from '@material-ui/core';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import TabComponent from 'componentsV3/Tab';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

import TabPanel from '../components/TabPanel';
import ApplicationTab from '../View/Application';
import TabProduct from '../View/Product';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    padding: spacing(5, 10)
  },
  title: {
    marginBottom: spacing(1)
  },
  subtitle: {
    fontSize: 18
  },
  divider: {
    background: palette.inputBorder.default,
    margin: spacing(4, 0),
    opacity: '30%'
  }
}));

function ExternalWithoutAddons() {
  const classes = useStyles();
  const applicationType = ApplicationType.ExternalWithoutAddons.type;

  const [productId, setProductId] = React.useState(null);

  const tabIndex = productId ? 1 : 0;

  const firstAccess = useQueryParams().has('firstAccess');

  React.useEffect(
    () => {
      analyticsEvent(firstAccess ? 'fa_new_epwd_screen' : 'new_epwd_screen');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          External Without Addons
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Monitor a web application without needing a cloud.
        </Typography>

        <Divider className={classes.divider} />
        <TabComponent
          tabsList={[
            { label: 'Product', slug: 'product' },
            { label: 'Application', slug: 'application' }
          ]}
          config={{ navigation: process.env.REACT_APP_ENVIRONMENT !== 'production' }}
          tabIndex={tabIndex}
        />
        <Grid display="flex">
          <TabPanel value={tabIndex} index={0}>
            <TabProduct
              firstAccess={firstAccess}
              onFinish={setProductId}
              applicationType={applicationType}
            />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <ApplicationTab
              firstAccess={firstAccess}
              productId={productId}
              applicationType={applicationType}
            />
          </TabPanel>
        </Grid>
      </Container>
    </>
  );
}

export default withThemeV3Hoc(ExternalWithoutAddons);
