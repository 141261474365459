import { Grid, Link } from '@material-ui/core';
import Alert from '@material-ui/lab-old/Alert';

import CheckBox from 'components/ResourceInputs/CheckBox';

export const LambdaForm = ({ errors, register, startValues, disabled = false }) => {
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CheckBox
            inputRef={register}
            errors={errors}
            name="flag_search_period"
            tooltipText="If checked, we're going to generate a failure when there isn't any invocations in the configured interval. If unchecked, we search the last invoke in last 24 hs"
            label="Check not invoke on interval"
            defaultChecked={startValues?.flag_search_period}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity="warning">
            We use AWS CloudWatch API for searching by Lambda logs and metrics
            <Link href="https://aws.amazon.com/pt/cloudwatch/pricing/">
              Click here to read more about it's pricing.
            </Link>
          </Alert>
        </Grid>
      </Grid>
    </Grid>
  );
};
