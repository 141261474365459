import { useRef } from 'react';

import { Box, Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { RawJsonField } from 'components/Inputs';

const JsonFunctionsUpload = ({ register, errors, control, setValue, watch, selectedMethod }) => {
  const uploadJson = useSelector(state => state.upload);
  const inputRef = useRef(null);

  const progress = uploadJson.status ? <CircularProgress /> : null;

  const onCleanJsonFunctionsUpload = reader => {
    var json = JSON.stringify(JSON.parse(reader.result)).trim();

    setValue('json_functions_body_raw', json);
  };

  const onInputJsonFunctionsChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => onCleanJsonFunctionsUpload(reader);
    reader.readAsText(file);
  };

  const handleUploadJson = () => {
    // eslint-disable-next-line no-unused-expressions
    inputRef?.current.click();
  };

  return (
    <Box display="flex">
      <Box flexGrow={1} marginRight={1}>
        <RawJsonField
          selectedMethod={selectedMethod}
          label="Credentials (.json)"
          register={register}
          name={'json_functions_body_raw'}
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
        />
      </Box>
      <Box>
        <div>
          <input
            accept="application/json"
            ref={inputRef}
            onChange={onInputJsonFunctionsChange}
            style={{ display: 'none' }}
            type="file"
          />
          <Button
            color="primary"
            disabled={uploadJson.status}
            onClick={handleUploadJson}
            size="small"
            variant="text">
            Upload
          </Button>
          {progress}
        </div>
      </Box>
    </Box>
  );
};

export default JsonFunctionsUpload;
