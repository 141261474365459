import { Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function LockChip() {
  return (
    <Chip
      style={{
        cursor: 'pointer',
        marginLeft: 'auto'
      }}
      to="/plans"
      variant="outlined"
      color="secondary"
      component={Link}
      label="Locked"
      size="small"
      title="See our pricing page"
      clickable
    />
  );
}
