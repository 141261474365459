import React from 'react';

import { makeStyles, Box, Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';

import withThemeV4Hoc from 'theme/v4/withTheme';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    minHeight: '100vh',
    background: `
      url(/images/logos/waterMarkWelcomeLogo.svg),
      linear-gradient(270deg, #67C897 -17.92%, #286A6B 118.47%)
    `,
    backgroundRepeat: 'no-repeat, repeat',
    backgroundPosition: 'bottom 60% left 250px, 0 0',
    backgroundSize: '590px 590px, auto auto',
    paddingTop: theme.spacing(20),
    paddingLeft: theme.spacing(14)
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '420px',
    maxHeight: '700px',
    height: '100%'
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(4)
  },
  description: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(8)
  },
  list: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2)
  },
  listItem: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2)
  },
  footerContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    '& img': {
      height: '100%'
    }
  },
  footerText: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1)
  }
}));

const StaticWelcome = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const classes = useStyles();

  const content = isLargeScreen ? (
    <>
      <Grid item xs={6}>
        {children}
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.root}>
          <Box className={classes.main}>
            <Box>
              <Typography variant="h1" className={classes.title}>
                Welcome to One Platform
              </Typography>
              <Typography variant="h4" className={classes.description}>
                The all-in-one Monitoring and Observability Platform for Digital Products
              </Typography>
              <Box component="ul" className={classes.list}>
                <Box component="li">
                  <Typography variant="body1" className={classes.listItem}>
                    Get informed of problems before it reaches your clients
                  </Typography>
                </Box>
                <Box component="li">
                  <Typography variant="body1" className={classes.listItem}>
                    Follow the most relevant metrics to help improve your team
                  </Typography>
                </Box>

                <Box component="li">
                  <Typography variant="body1" className={classes.listItem}>
                    Easily provide information for your business teams.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.footerContainer}>
              <Typography variant="subtitle1" className={classes.footerText}>
                A product by
              </Typography>
              <img src="/images/logos/footerWelcome.svg" alt="A product by Elven Works" />
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  ) : (
    <Grid item xs={12}>
      {children}
    </Grid>
  );

  return <Grid container>{content}</Grid>;
};

export default withThemeV4Hoc(StaticWelcome);
