import React from 'react';

import { Button, IconButton, Typography, Box, Link, Fade, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'clipboard-copy';

import Dialog from 'components/Dialog';

import { useStyles } from '../../styles';

export type HitsAndFailuresProps = {
  resorceUid: string;
  orgUid: string;
  openerComponent: any;
  setActivated: (enabled: boolean) => void;
};

const HitsAndFailuresModal = ({
  resorceUid,
  orgUid,
  openerComponent: OpenerComponent
}: HitsAndFailuresProps) => {
  const classes = useStyles();

  const [copiedHitsSuccessfully, setCopiedHitsSuccessfully] = React.useState(false);
  const [copiedFailuresSuccessfully, setCopiedFailuresSuccessfully] = React.useState(false);

  React.useEffect(() => {
    if (copiedHitsSuccessfully) {
      setTimeout(() => {
        setCopiedHitsSuccessfully(false);
      }, 300);
    }
    if (copiedFailuresSuccessfully) {
      setTimeout(() => {
        setCopiedFailuresSuccessfully(false);
      }, 300);
    }
  }, [copiedHitsSuccessfully, copiedFailuresSuccessfully]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const environmentType =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? '.dev'
      : process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? '.st'
      : process.env.REACT_APP_ENVIRONMENT === 'production'
      ? ''
      : '.dev';

  const curlHits = `curl -X POST \\
    -H 'Content-type: application/json' \\
    -H "Authorization: Bearer <token>" \\
    -H "User-Agent: 1PapiHits/1.0" \\
    https://apis${environmentType}.elven.works/${orgUid}/resources/events/v1/${resorceUid}/hits \\
    \`# Latency in microseconds\` \\
    --data '{
      "latency": 100000 
    }'`;

  const curlFailures = `curl -X POST \\
    -H 'Content-type: application/json' \\
    -H "Authorization: Bearer <token>" \\
    -H "User-Agent: 1PapiFailures/1.0" \\
    https://apis${environmentType}.elven.works/${orgUid}/resources/events/v1/${resorceUid}/failures \\
    --data '{
      "issue": "Error message"
    }'`;

  const CurlType = (curlType: any) => {
    return (
      <>
        <Box id={`boxWebhookSubtitleCopy${curlType}`} display="flex" justifyContent="space-between">
          <Typography
            id={`curlWebhookSubtitle${curlType}`}
            className={classes.subtitle}
            variant="subtitle2">
            {curlType}
          </Typography>

          <Box id={`boxWebhookCopy${curlType}`}>
            {
              <Fade
                in={curlType === 'Hits' ? copiedHitsSuccessfully : copiedFailuresSuccessfully}
                timeout={{ enter: 500, exit: 2000 }}>
                <small style={{ color: 'green' }}>Copied</small>
              </Fade>
            }
            {
              <Tooltip title="Copy to clipboard" aria-label="add">
                <IconButton
                  id={`curlWebhookCopy${curlType}`}
                  color="primary"
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    copy(curlType === 'Hits' ? curlHits : curlFailures)
                      .then(() => {
                        curlType === 'Hits'
                          ? setCopiedHitsSuccessfully(true)
                          : setCopiedFailuresSuccessfully(true);
                      })
                      .catch(() => {
                        curlType === 'Hits'
                          ? setCopiedHitsSuccessfully(false)
                          : setCopiedFailuresSuccessfully(false);
                      });
                  }}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            }
          </Box>
        </Box>
        {
          <pre
            id={`curlWebhookText${curlType}`}
            style={{
              border: '1px solid rgba(29,28,29,.04)',
              borderRadius: 4,
              background: 'rgba(29, 28, 29, 0.04)',
              whiteSpace: 'pre-wrap',
              textOverflow: 'clip',
              padding: 8
            }}
            onClick={(event: React.MouseEvent<HTMLPreElement, MouseEvent>) => {
              if (event.target instanceof Node) {
                const range = document.createRange();
                range.selectNodeContents(event.target);
                const sel = window.getSelection();
                if (sel !== null) {
                  sel.removeAllRanges();
                  sel.addRange(range);
                }
              }
            }}>
            {curlType === 'Hits' ? curlHits : curlFailures}
          </pre>
        }
      </>
    );
  };

  return (
    <>
      <OpenerComponent id={'HitsFailuresOpener'} onClick={handleOpen} />
      <Dialog
        onPrimaryAction={() => {}}
        showPrimaryAction={false}
        showSecondaryAction={false}
        id={`sendHitsFailuresDialog`}
        open={open}
        onClose={handleClose}
        title={'Hits and Failures'}
        fullWidth
        maxWidth="md">
        <Box>
          <Typography>
            Please copy the curl and edit with the correct datas to: token, latency for hits, and
            issue for failures. To get a token {''}
            <Link id={`tokenWebhookLink`} href="/organization" target="_blank" rel="noopener">
              click here.
            </Link>
          </Typography>

          {CurlType('Hits')}

          {CurlType('Failures')}

          {
            <Box
              id={`curlWebhookBoxButtons`}
              display="flex"
              justifyContent="flex-end"
              marginTop={3}>
              <Button id={`sendHitsFailuresCancel`} variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Box>
          }
        </Box>
      </Dialog>
    </>
  );
};

export default HitsAndFailuresModal;
