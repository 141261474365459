import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  select: {
    height: theme.typography.pxToRem(45),
    marginTop: theme.spacing(4)
  },
  button: {
    height: theme.typography.pxToRem(50),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  title: {
    color: theme.palette.secondary.main
  },
  question: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  progress: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(255 255 255 / 66%)',
    zIndex: 1000
  }
}));
