import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginTop: '1rem',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    flex: 1,
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    },
    '& a[data-disabled=true]': {
      pointerEvents: 'none'
    },
    '&:has(.card-content:hover)': {
      cursor: 'pointer',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.50)'
    }
  },
  title: {
    fontSize: theme.typography.pxToRem(24)
  },
  actions: {
    paddingTop: 0,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
    '& .chip': {
      backgroundColor: theme.palette.elvenGray[300],
      '& span': {
        color: theme.palette.elvenGray[50]
      },
      '&[data-connected=true]': {
        background: theme.palette.primary.main
      }
    }
  },
  divider: {
    marginTop: 0,
    margin: theme.spacing(2)
  },
  menuActionsButton: {
    borderRadius: 4,
    minWidth: 42,
    padding: theme.spacing(0.625, 1),
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  menu: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& li': {
      margin: 0,
      ...theme.typography.body2,
      '&:hover': {
        color: theme.palette.elvenGreen['400'],
        backgroundColor: theme.palette.elvenGreen['50']
      }
    }
  }
}));
