import { makeStyles } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { Theme } from 'theme/v4';

type TypeChipStyleProps = {
  event: EventType;
};

function getBackgroundColor(event: EventType, palette: Theme['palette']) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: palette.elvenRed[500],
    [EventTypeEnum.Acknowledged]: palette.elvenBlue[400],
    [EventTypeEnum.Resolved]: palette.elvenGreen[500],
    [EventTypeEnum.PostMortem]: palette.elvenGray[500],
    [EventTypeEnum.Deploy]: palette.elvenBlue[400],
    [EventTypeEnum.HotFix]: palette.elvenOrange[400],
    [EventTypeEnum.Rollback]: palette.elvenRed[500],
    [EventTypeEnum.IncidentStatusUpdate]: palette.elvenGray[500]
  };

  return labelMap[event?.type];
}

function getBorderColor(event: EventType, palette: Theme['palette']) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: palette.elvenRed[500],
    [EventTypeEnum.Acknowledged]: palette.elvenBlue[400],
    [EventTypeEnum.Resolved]: palette.elvenGreen[500],
    [EventTypeEnum.PostMortem]: palette.elvenGray[500],
    [EventTypeEnum.Deploy]: palette.elvenBlue[400],
    [EventTypeEnum.HotFix]: palette.elvenOrange[400],
    [EventTypeEnum.Rollback]: palette.elvenRed[500],
    [EventTypeEnum.IncidentStatusUpdate]: palette.elvenGray[500]
  };

  return labelMap[event?.type];
}

export const useStyles = makeStyles<Theme, TypeChipStyleProps>(theme => ({
  chip: {
    color: props => getBackgroundColor(props.event, theme.palette),
    backgroundColor: 'white',
    borderColor: props => getBorderColor(props.event, theme.palette),
    marginRight: theme.spacing(1)
  }
}));
