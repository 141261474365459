import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Divider, Link, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import useQueryParams from 'helpers/useQueryParams';
import { usePostmortem } from 'hooks/queriesGraphQL/usePostmortem';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { PostmortemDeleteDialog } from '../components/DeleteDialog';
import { PostmortemViewLoadingSkeleton } from '../components/Skeleton';
import { useStyles } from '../styles';

import { Details } from './Details';
import { Markdown } from './Markdown';

export function PostmortemView() {
  const { uid } = useParams<{ uid: string }>();

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const isPrinting = useQueryParams().get('print') === 'true';

  const { fetching, postmortem } = usePostmortem({ uid });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [menuOptionsAnchorEl, setMenuOptionsAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenuOptions = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(event.currentTarget);
  };
  const handleCloseMenuOptions = (_event: React.MouseEvent<HTMLElement>) => {
    setMenuOptionsAnchorEl(null);
  };

  const handlePrint = useCallback(() => {
    const originalTitle = document.title;
    document.title = postmortem!.title;

    window.addEventListener('afterprint', () => {
      document.title = originalTitle;

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Postmortem exported successfully'
      });

      if (isPrinting) {
        history.push('/postmortems');
      }
    });

    window.print();
  }, [dispatch, history, isPrinting, postmortem]);

  useEffect(() => {
    if (fetching || !postmortem) return;

    if (isPrinting) {
      handlePrint();
    }
  }, [fetching, handlePrint, isPrinting, postmortem]);

  const hasDeletePostmortemPermission = usePermission('PostMortemController-delete-/post-mortem');
  const hasUpdatePostmortemPermission = usePermission('PostMortemController-put-/post-mortem');

  if (fetching)
    return (
      <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
        <PostmortemViewLoadingSkeleton />
      </PageContainer>
    );

  if (!postmortem) {
    dispatch({
      type: actions.ENTITY_ERROR,
      payload: new Error('Not found postmortem or not exists.'),
      ga: { category: 'ERROR' }
    });
    return <Redirect to="/postmortems" />;
  }

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <img
        className="print"
        alt="Logo"
        src={`${process.env.PUBLIC_URL}/images/logos/one_platform_logo.png`}
        style={{ margin: '0 auto', height: 60 }}
      />
      <div className="no-print">
        <Breadcrumb
          items={[
            { label: 'Incident Management' },
            { label: 'Postmortem Center', link: '/postmortems' },
            { label: postmortem.title ?? 'Postmortem' }
          ]}
        />
      </div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
        <Typography variant="h4" color="secondary" className={globalClasses.title}>
          {postmortem.title}
        </Typography>

        <div className="no-print">
          <Tooltip title="More Options" arrow>
            <Button
              variant="outlined"
              onClick={handleOpenMenuOptions}
              className={classes.menuOptionButton}>
              <MoreVert />
            </Button>
          </Tooltip>
        </div>
      </Box>
      <PostmortemDeleteDialog
        open={openDeleteDialog}
        postmortemTitle={postmortem.title}
        postmortemUid={uid}
        handleClose={() => setOpenDeleteDialog(false)}
        onDelete={() => history.push('/postmortems')}
      />
      <Menu
        open={Boolean(menuOptionsAnchorEl)}
        elevation={1}
        anchorEl={menuOptionsAnchorEl}
        getContentAnchorEl={null}
        onClose={handleCloseMenuOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem className={classes.menuItem} disabled={!hasUpdatePostmortemPermission}>
          <Link component={RouterLink} to={`/postmortems/edit/${uid}`}>
            Edit postmortem
          </Link>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => setOpenDeleteDialog(true)}
          disabled={!hasDeletePostmortemPermission}>
          Delete postmortem
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handlePrint}>
          Export to PDF
        </MenuItem>
      </Menu>
      <Divider className={globalClasses.divider} />
      <Box className={classes.main}>
        <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
          <Markdown text={postmortem.postMortem ?? ''} />
        </Box>
        <Box>
          <Details postmortem={postmortem} />
        </Box>
      </Box>
    </PageContainer>
  );
}
