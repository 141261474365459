import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: { borderColor: theme.palette.grey[300] },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    height: '110px',
    width: '110px',
    marginBottom: theme.spacing(1)
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = props => {
  const { className, account, saveHandler, profileCompleteness, upload, ...rest } = props;
  const classes = useStyles();

  const profile = account.profile
    ? {
        ...account.profile,
        photoURL: account.photoURL,
        email: account.email
      }
    : {
        displayName: account.displayName,
        photoURL: account.photoURL,
        email: account.email
      };

  const user = {
    timezone: 'GTM-3'
  };

  const onFileLoad = (e, file) => {
    saveHandler(e.target.result, file.type, file.name);
  };

  const onInputChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => onFileLoad(e, file);
    reader.readAsDataURL(file);
  };

  const handleUpload = () => {
    const input = document.querySelector('#avatarAccount');
    input.click();
  };

  const progress = upload.status ? <CircularProgress size={22} /> : null;

  return (
    <Card {...rest} className={clsx(classes.root, className)} variant="outlined">
      <CardContent>
        <div className={classes.details}>
          <Avatar className={classes.avatar} src={profile.photoURL} />
          <div>
            <Typography gutterBottom variant="h4" align="center">
              {profile.displayName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textPrimary"
              variant="body1"
              align="center">
              {profile.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textPrimary"
              variant="body1"
              align="center">
              {moment().format('hh:mm A')} ({user.timezone})
            </Typography>
          </div>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          {progress}
          <input
            accept="image/*"
            id="avatarAccount"
            onChange={onInputChange}
            style={{ display: 'none' }}
            type="file"
          />
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={upload.status}
            onClick={handleUpload}
            variant="text">
            Upload Image
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
