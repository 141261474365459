import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import PageContainer from 'components/PageContainer';

import Toolbar from '../../../components/Crud/ToolbarQueryProps';
import DataGrid from '../components/Crud/DataGrid';

import columns from './columns';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1),
    position: 'absolute',
    color: theme.palette.primary.dark
  }
});

const ServicesList = ({ history, permissions, classes }) => {
  const [query, setQuery] = useState('');

  return (
    <PageContainer maxWidth={false}>
      <div className={classes.root}>
        <Toolbar
          title="Services"
          buttonNewName="New"
          entity="services"
          history={history}
          searchPlaceholder="Search services"
          disableNew={!permissions.new}
          setQuery={setQuery}
        />
        <div className={classes.content}>
          <DataGrid cols={columns} entity="services" permissions={permissions} query={query} />
        </div>
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(ServicesList);
