import { PropsWithChildren } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';

import PageContainer from 'components/PageContainer';

import useStyles from './styles';
import { MetabaseDashboardProps } from './types';

type MetabaseDashboardContainerProps = Pick<
  MetabaseDashboardProps,
  'title' | 'subtitle' | 'header' | 'iframePadding' | 'showSubtitle'
>;

function MetabaseDashboardContainer({
  title,
  subtitle,
  header = true,
  iframePadding = true,
  showSubtitle = true,
  children
}: PropsWithChildren<MetabaseDashboardContainerProps>) {
  const classes = useStyles();
  const theme = useTheme();

  const paddings = iframePadding ? {} : { padding: 0 };
  const iframeStyle = { ...paddings };

  return (
    <>
      <PageContainer className={classes.container} style={iframeStyle}>
        {header && (
          <Box>
            <Typography
              className={classes.title}
              style={{ color: theme.palette.elvenTeal[400] }}
              variant="h4"
              gutterBottom>
              {title}
            </Typography>
            {showSubtitle && (
              <Typography className={classes.body} variant="body1" gutterBottom>
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </PageContainer>
    </>
  );
}

export default MetabaseDashboardContainer;
