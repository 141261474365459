import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const Form = ({ formState, onChange }) => {
  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          error={hasError('name')}
          fullWidth
          helperText={hasError('name') ? formState.errors.name[0] : null}
          InputLabelProps={{
            shrink: true
          }}
          label="Name"
          margin="dense"
          name="name"
          onChange={handleChange}
          required
          value={formState.values.name || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={hasError('description')}
          fullWidth
          helperText={hasError('description') ? formState.errors.description[0] : null}
          InputLabelProps={{
            shrink: true
          }}
          label="Description"
          margin="dense"
          multiline
          name="description"
          onChange={handleChange}
          rows={3}
          value={formState.values.description || ''}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default Form;
