import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {
  isSmallScreen: boolean;
}

export default makeStyles<Theme, StyleProps>(theme => ({
  content: {
    marginBottom: props => (props.isSmallScreen ? '5vh' : '')
  },
  title: {
    color: theme.palette.secondary.main
  },
  body: {
    color: theme.palette.text.primary
  },
  button: {
    height: theme.typography.pxToRem(52)
  },
  listItem: {
    padding: 0,
    marginTop: '-5px',
    marginLeft: '10px'
  },
  listItemIcon: {
    marginRight: '-20px'
  },
  icon: {
    fontSize: theme.typography.pxToRem(5)
  },
  dialogButton: {
    width: theme.typography.pxToRem(370)
  },
  input: {
    height: theme.typography.pxToRem(40)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: theme.spacing(0.4)
  }
}));
