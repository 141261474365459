import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { MonitoringList } from 'views/Monitoring/List';
import { ResourceMonitoring } from 'views/Monitoring/View';

const RouteV4WithMainLayout = MainLayoutV4;

export const monitoringRoutes = [
  <AuthorizedRoute
    component={MonitoringList}
    exact
    layout={RouteV4WithMainLayout}
    path="/monitoring"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ResourceMonitoring}
    exact
    layout={RouteV4WithMainLayout}
    path="/monitoring/:resourceUid"
    planFeature="Monitoring"
  />
];
