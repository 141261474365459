import React from 'react';

import Form from '../components/Form';

class FeaturesNewForm extends React.Component {
  render() {
    const { formState, onChange, save } = this.props;
    return <Form title="New Feature" formState={formState} onChange={onChange} save={save} />;
  }
}
export default FeaturesNewForm;
