import React from 'react';

import { TextField } from 'components/Inputs';

const FailuresToIncident = ({ className = '', register, errors, disabled }) => {
  return (
    <TextField
      className={className}
      register={register}
      label="Fails to open automatic incident"
      name="failuresToIncident"
      errors={errors}
      type="number"
      defaultValue="2"
      disabled={disabled}
      required="true"
    />
  );
};

export default FailuresToIncident;
