import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'components/MetabaseDashboard';

interface ResourcesProps {
  service: number;
}

function Resources(props: ResourcesProps) {
  const service = props.service;

  const dashboardName = MetabaseDashboards.LatenciesPercentiles;

  const hideParams = ['service'];

  const params = {
    service: service
  };

  return (
    <MetabaseDashboard
      title="Percentiles"
      subtitle="P50, P90 & P95"
      dashboardName={dashboardName}
      dashboardParams={params}
      header={false}
      hideParams={hideParams}
      iframeHeight={520}
      iframePadding={false}
      iframeRefresh={300}
      iframeTitle={false}
    />
  );
}

export default Resources;
