import withTheme from 'theme/withTheme';

interface ContentMainProps {
  children: React.ReactNode;
}

const ContentMain = ({ children }: ContentMainProps) => {
  return <main style={{ flex: 1 }}>{children}</main>;
};

export const Container = withTheme(ContentMain);
