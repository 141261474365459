import { Box, Drawer, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import usePlans from 'hooks/queriesGraphQL/usePlans';
import { SidebarDrawer } from 'layouts/V4/components/Sidebar/components/SidebarDrawer';
import SidebarList from 'layouts/V4/components/Sidebar/components/SidebarList';
import { withThemeV4 } from 'theme/v4';

import useStyles from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
};

const SidebarV4 = ({ open, onClose, variant }: Props) => {
  const { orgStatus } = usePlans();

  const classes = useStyles({
    showPlansMessage: orgStatus?.show
  });

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  return !isDesktop ? (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      variant={variant}
      classes={{ paper: classes.drawer }}>
      <SidebarDrawer />
    </Drawer>
  ) : (
    <Box className={clsx(classes.root, 'no-print')}>
      <SidebarList />
    </Box>
  );
};

export default withThemeV4(SidebarV4);
