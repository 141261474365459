import * as z from 'zod';

const schema = z.object({
  email: z
    .string()
    .email()
    .nonempty({ message: "Can't be empty" })
});

export type FormData = z.infer<typeof schema>;

export default schema;
