import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useUser from 'hooks/queriesRest/useUser';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import WithThemeV4Hoc from 'theme/v4/withTheme';

import RouteWithLayoutV2, { RouteWithLayoutV2Props } from './RouteWithLayoutV2';

type AuthorizedRouteProps = {
  planFeature?: string;
  featureTarget?: string;
} & Omit<RouteWithLayoutV2Props, 'isPublicRoute'>;

/**
 * ### AuthorizedRoute
 *
 * This component is used to check if a plan feature is enabled or/and current
 * user has permission to provided featureTarget
 */
function AuthorizedRouteComponent({
  planFeature = '',
  featureTarget = '',
  ...rest
}: AuthorizedRouteProps) {
  const hasPermission = usePermission(featureTarget);

  const isPageAccessibleByPlan = useFeaturePlan(planFeature);

  const { data: user, isLoading: isLoadingUser } = useUser();

  const isLoading = isPageAccessibleByPlan === null || hasPermission === null || isLoadingUser;
  const shouldValidate = Boolean(planFeature || featureTarget);

  /**
   * If the user is authenticated but not logged into an organization,
   * RouteWithLayoutV2 will be called to apply private route rules
   */
  if (!isLoadingUser && !user?.logged) {
    return <RouteWithLayoutV2 {...rest} isPublicRoute={false} />;
  }

  if (shouldValidate && isLoading) {
    return <LoadingOverlay />;
  }

  if (planFeature && !isPageAccessibleByPlan) {
    return <Redirect to="/plans" />;
  }

  if (featureTarget && !hasPermission) {
    return <Redirect to="/forbidden" />;
  }

  return <RouteWithLayoutV2 {...rest} isPublicRoute={false} />;
}

export const AuthorizedRoute = WithThemeV4Hoc(AuthorizedRouteComponent);
