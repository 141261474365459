const columns = [
  {
    name: 'ID',
    selector: 'id',
    sortable: true
  },
  {
    name: 'Parent ID',
    selector: menuItem => (menuItem.parent ? menuItem.parent.id : ''),
    sortable: true
  },
  {
    name: 'Title',
    selector: menuItem => (menuItem.parent ? `↳ ${menuItem.title}` : menuItem.title),
    sortable: true
  },
  {
    name: 'Icon',
    selector: 'icon',
    sortable: false
  },
  {
    name: 'href',
    selector: 'href',
    sortable: true
  },
  {
    name: 'Order',
    selector: 'order',
    sortable: true
  }
];

export default columns;
