import React, { useEffect } from 'react';

import { Typography, Box, LinearProgress, Dialog, DialogContent } from '@material-ui/core';
import { CreateOrgMutation } from 'graphqlQueries/createOrg';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useMutation as useMutationUrql } from 'urql';

import useUser from 'hooks/queriesRest/useUser';

import Form from './Form';

function sleep(duration: number): Promise<void> {
  return new Promise<void>(resolve =>
    setTimeout(() => {
      resolve();
    }, duration)
  );
}

type LoadImageProps = {
  type: 'error' | 'loading';
  title: string;
  text: string;
};

const LoadImage = ({ type, title, text }: LoadImageProps) => {
  const sourceImage = type === 'error' ? '/images/warning.svg' : '/images/working_in_progress.svg';

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <img alt="progress" src={sourceImage} height={300} />
      <Box marginTop={6} textAlign="center" height={70}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6">{text}</Typography>
        {type === 'loading' ? (
          <Box p={2} width="100%">
            <LinearProgress />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

interface DataProps {
  name: string;
  policy: boolean;
}

const Orgs = () => {
  const router = useHistory();

  const [dialog, setDialog] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [createdSuccessfully, setCreatedSuccessfully] = React.useState<null | boolean>(null);

  const { data: user, isLoading: userIsLoading } = useUser();

  const queryCache = useQueryCache();
  const [, createOrg] = useMutationUrql(CreateOrgMutation);

  useEffect(() => {
    if (user?.logged?.org?.id && createdSuccessfully) {
      router.push('/');
    }
  }, [createdSuccessfully, router, user?.logged?.org?.id]);

  const handleToggle = React.useCallback(() => {
    setDialog(!dialog);
  }, [setDialog, dialog]);

  const submitHandler = async (data: DataProps) => {
    const payload = {
      ...data,
      email: user.email
    };

    setDialog(true);

    await sleep(2000);

    const response = await createOrg({ orgInput: payload });

    if (response.error) {
      setError(true);
      setCreatedSuccessfully(false);
      return response;
    }

    setCreatedSuccessfully(true);

    queryCache.invalidateQueries('auth/logged');

    return response;
  };

  const [submitMutation] = useMutation(submitHandler, {
    onError: () => {
      setError(true);
    }
  });

  return (
    <>
      <Form submitMutation={submitMutation} isLoading={userIsLoading} />
      <Dialog
        open={dialog}
        aria-labelledby="loading-create-org-dialog"
        onClose={handleToggle}
        fullWidth>
        <DialogContent>
          <Box p={6}>
            {error ? (
              <LoadImage type="error" title="Ops!" text="Please contact us." />
            ) : (
              <LoadImage type="loading" title="Wait..." text="We are building your org setup." />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Orgs;
