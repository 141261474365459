import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Link,
  Box,
  Typography,
  Divider,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { Brightness1 } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { ButtonSimple } from 'componentsV4/Button';
import Input from 'componentsV4/Input';
import SuccessfulModal from 'componentsV4/SuccessfulModal';
import useQueryParams from 'helpers/useQueryParams';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { withThemeV4 } from 'theme/v4';

import schema, { FormData } from './schema';
import useStyles from './styles';

const AccountResetV4 = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles({ isSmallScreen });

  const queryParams = useQueryParams();

  const [open, setOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState: { isValid }
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
      code: `${queryParams.get('oobCode')}`
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const [submitMutation, { isLoading }] = useMutation(
    (formData: FormData) =>
      axios.post(`/auth/reset`, { code: formData.code, newPassword: formData.newPassword }),
    {
      onSuccess: () => {
        setOpen(true);
      },
      onError: () => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: 'The request failed. Please try again.',
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const onSubmit = (formData: FormData) => {
    submitMutation(formData);
  };

  const newPassword = watch('newPassword');
  const confirmNewPassword = watch('confirmNewPassword');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
      <Box display="flex-start">
        <Box mb={3}>
          <Typography variant="h3" className={classes.title}>
            Reset Password
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="body2" className={classes.body}>
            Your password must be:
          </Typography>

          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <Brightness1 className={classes.icon} />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="body2" className={classes.body}>
                    At least 6 characters
                  </Typography>
                }
              />
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <Brightness1 className={classes.icon} />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="body2" className={classes.body}>
                    Containing letters and numbers
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>

        <Box mb={1}>
          <Input
            name="newPassword"
            id="input-new-password"
            defaultLabel="New password"
            placeholder="******"
            type="password"
            inputRef={register()}
            required={true}
            fullWidth
            className={classes.input}
            error={errors['newPassword']?.message ? true : false}
          />
          <FormHelperText error>{errors['newPassword']?.message}</FormHelperText>
        </Box>
        <Box mb={3}>
          <Input
            name="confirmNewPassword"
            id="input-confirm-new-password"
            defaultLabel="Confirm new password"
            placeholder="******"
            type="password"
            inputRef={register()}
            required={true}
            fullWidth
            className={classes.input}
            error={
              errors['confirmNewPassword']?.message || newPassword !== confirmNewPassword
                ? true
                : false
            }
          />
          <FormHelperText error>
            {newPassword !== confirmNewPassword ? "Passwords don't match" : ''}
          </FormHelperText>
        </Box>

        <input type="hidden" ref={register} name="code" />

        <Box mb={3}>
          <ButtonSimple
            type="submit"
            text="Reset Password"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            fullWidth
            disabled={!isValid || newPassword !== confirmNewPassword}
            className={classes.button}
          />
        </Box>

        <Box mb={4} display="flex" justifyContent="center" alignItems="center">
          <Link title="Go to login page" to="/login" component={RouterLink}>
            <Typography component="span" variant="h5" color="primary">
              Sign in with another account
            </Typography>
          </Link>
        </Box>

        <Box mb={3}>
          <Divider />
        </Box>

        <Box mb={2}>
          <Typography variant="h4" className={classes.title}>
            Don't have an account yet?
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.body}>
          Start monitoring the health of your technology infrastructure.
          <Link
            title="Go to sign up page"
            to="/sign-up"
            component={RouterLink}
            className={classes.link}>
            Sign up now
          </Link>
        </Typography>
      </Box>
      <SuccessfulModal
        open={open}
        setOpen={setOpen}
        title="Password reset successfully"
        bodyComponent={
          <ButtonSimple
            type="button"
            href="/login"
            title="Go to Login Page"
            text="Access the One Platform"
            variant="contained"
            color="primary"
            className={classes.dialogButton}
          />
        }
      />
    </form>
  );
};

export default withThemeV4(AccountResetV4);
