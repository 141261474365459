import React, { ReactElement } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { MenuItem } from '@material-ui/core';
import { default as axiosDefault } from 'axios';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useSelectAccount from 'hooks/queriesGraphQL/useSelectAccount';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useGetAccounts } from 'hooks/useGetAccounts';
import { Account, Org } from 'layouts/V4/components/Toolbar/types';
import actions from 'redux/actions';
import reducer from 'redux/reducer/orgs';

const ChangeOrg = () => {
  const dispatch = useDispatch();

  const { data: user } = useUser();

  const { logout: logoutAuth0 } = useAuth0();

  const newAccountsList = useFeatureFlagUnleash('newAccountsList', {
    queryString: true
  });

  const newSelectAccount = useFeatureFlagUnleash('newSelectAccount', {
    queryString: true
  });

  const { data: accounts } = useGetAccounts();

  const { selectUserAccount } = useSelectAccount();

  const options: ReactElement[] = user?.logged
    ? user.accounts
        .filter((account: Account) => Boolean(account.org))
        .map((account: Account) => {
          return (
            <MenuItem key={`option-org-${account.org.id}`} value={account.org.id}>
              {account.org.name}
            </MenuItem>
          );
        })
    : [];

  const newOptions = accounts?.map(account => {
    return (
      <MenuItem key={`option-org-${account.org.id}`} value={account.org.id}>
        {account.org.name}
      </MenuItem>
    );
  });

  const queryCache = useQueryCache();

  const [logout] = useMutation(
    () => {
      return axiosDefault({
        url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
        method: 'post',
        withCredentials: true,
        data: {
          query: `
              mutation {
                logout
              }
            `
        }
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('auth/logged');
      }
    }
  );

  const handleLogout = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    logoutAuth0({
      returnTo: window.location.origin
    });
    logout();
  };

  const changeSelectedAccount = (payload: Org['id']) =>
    dispatch({
      type: actions.FETCH_ENTITY_SUCCESSFUL,
      payload: {
        selectedAccount: payload
      },
      meta: { entity: 'selectedAccount', reducer }
    });

  const history = useHistory();

  const [selectAccountOrg] = useMutation(
    (orgId: number) => {
      return axiosDefault({
        url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
        method: 'post',
        withCredentials: true,
        data: {
          query: `
              query {
                selectAccount (orgId: ${orgId})
              } 
            `
        }
      });
    },
    {
      onSuccess: () => onSuccessSelectAccount()
    }
  );

  const onSuccessSelectAccount = () => {
    queryCache.invalidateQueries('auth/logged');
    history.push('/');
  };

  const handleSelectAccount = async (orgId: Org['id']) => {
    if (!newSelectAccount) {
      return selectAccountOrg(orgId);
    }

    return selectUserAccount({ orgId }).then(async () => onSuccessSelectAccount());
  };

  const handleChangeOrg = (orgId: Org['id']) => {
    changeSelectedAccount(orgId);
    handleSelectAccount(orgId);
  };

  return { options: !newAccountsList ? options : newOptions, handleLogout, handleChangeOrg };
};

export default ChangeOrg;
