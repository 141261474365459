import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  root: {
    background: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    zIndex: 800
  },
  content: {
    flexGrow: 1
  },
  logo: {
    height: '100%',
    width: 'auto'
  },
  menuText: {
    textAlign: 'right'
  },
  iconButton: {
    color: theme.palette.common.white,
    padding: 0
  },
  expandIcon: {
    fontSize: 30
  },
  divider: {
    backgroundColor: theme.palette.divider,
    opacity: '0.7',
    height: '35px',
    margin: 'auto 0'
  },
  select: {
    height: theme.typography.pxToRem(45),
    marginTop: theme.spacing(4)
  },
  orgName: {
    maxWidth: '400px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  menuOrgName: {
    maxWidth: '145px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: theme.palette.elvenGray[50],
    color: theme.palette.elvenGreen[400]
  }
}));
