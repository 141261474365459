import { Box, Typography, Link, Checkbox } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import Button from 'componentsV4/Button';
import Input from 'componentsV4/Input';
import useUser from 'hooks/queriesRest/useUser';
import WithThemeV4Hoc from 'theme/v4/withTheme';

import useStyles from './styles';
import useForm from './useForm/useForm';

interface FormProps {
  submitMutation: () => void;
  isLoading: boolean;
}

const Form = ({ submitMutation, isLoading }: FormProps) => {
  const classes = useStyles();

  const { data: user } = useUser({});

  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues: {
      name: '',
      policy: false
    }
  });

  const name = watch('name');
  const isChecked = watch('policy');

  const accounts = user?.accounts;

  return (
    <>
      <Typography variant="h2" className={classes.title}>
        {accounts && Object.keys(accounts).length
          ? 'Choose the organization name'
          : 'Registration done! Start monitoring now'}
      </Typography>
      <Typography variant="body1" className={classes.body}>
        {accounts && Object.keys(accounts).length
          ? 'Give your organization a name'
          : 'First, give your organization a name'}
      </Typography>
      <form onSubmit={handleSubmit(submitMutation)}>
        <Input
          placeholder="Ex: Elvenworks - One Platform"
          fullWidth
          className={classes.input}
          name="name"
          type="text"
          inputRef={register()}
          error={errors['name']?.message ? true : false}
        />
        <Box className={classes.policy}>
          <Checkbox inputRef={register} className={classes.policyCheckbox} name="policy" />
          <Typography variant="caption" className={classes.policyText}>
            I have read and accepted
            <Link component={RouterLink} to="/terms" className={classes.linkPolicy}>
              Terms and Conditions
            </Link>
          </Typography>
        </Box>
        <Button
          type="submit"
          text="Start Now"
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth
          disabled={!name || !isChecked || isLoading}
        />
      </form>
    </>
  );
};

export default WithThemeV4Hoc(Form);
