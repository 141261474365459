import * as z from 'zod';

const schema = z.object({
  email: z
    .string()
    .email()
    .nonempty({ message: "Can't be empty" }),
  name: z.string().nonempty({ message: "Can't be empty" }),
  password: z
    .string()
    .refine(
      value => {
        if (value.length < 6) return false;

        return true;
      },
      { message: 'Minimum of 6 characters' }
    )
    .refine(
      value => {
        if (!value.match(/[0-9]/)) return false;

        return true;
      },
      { message: 'Password must have numbers' }
    )
    .refine(
      value => {
        if (value.match(/^[0-9]+$/)) return false;

        return true;
      },
      { message: 'Password must have letters' }
    ),
  passwordConfirmation: z.string()
});

export type FormData = z.infer<typeof schema>;

export default schema;
