import { MouseEvent, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  Link,
  CircularProgress,
  Icon
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useMutation } from 'urql';

import DeleteEntity from 'components/DeleteEntityDialog';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import {
  GetNewJourneyMatrixQuery,
  useNewJourneyMatrix
} from 'hooks/queriesGraphQL/useNewJourneyMatrix';
import { usePermission } from 'hooks/usePermission';

import { Matrix } from './components/Matrix';
import { useStyles } from './styles';

const DeleteProductMutation = `#graphql
    mutation($productId: Float!){
      deleteProduct (productId: $productId) {
        success
      }
    }
`;

export const ProductView = () => {
  const params = useParams<{
    id: string;
  }>();

  const productId = Number(params.id);

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const history = useHistory();

  const [{ data, fetching: isFetchingMatrix }, refetch] = useNewJourneyMatrix({
    productId,
    query: GetNewJourneyMatrixQuery
  });

  const matrix = data?.newJourneyMatrix;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleToggleMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const hasPermissionCreateApplication = usePermission('ApplicationController-post-/applications');
  const hasPermissionEdit = usePermission('ProductController-put-/products/:id');
  const hasPermissionDelete = usePermission('ProductController-delete-/products/:id');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOpenDeleteDialog = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const [{ fetching: fetchingDelete }, deleteProductMutation] = useMutation(DeleteProductMutation);

  const handleDeleteDialog = () => {
    return deleteProductMutation({
      productId
    }).then(() => {
      history.push('/products');
    });
  };

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={[
          { label: 'Monitoring Aggregator' },
          { label: 'Product Center', link: '/products' },
          { label: matrix?.name || 'Product' }
        ]}
      />

      <Box display="flex" mt={6} alignItems="center">
        <Typography variant="h4" color="secondary" className={globalClasses.title}>
          {matrix?.name || 'Product'}
        </Typography>

        <Box ml="auto">
          <Tooltip
            title="View metrics"
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow
            }}>
            <Link component={RouterLink} to="/metrics?tab=monitoring-performance">
              <Button className={classes.icons} variant="outlined">
                <Icon fontSize="small">query_stats</Icon>
              </Button>
            </Link>
          </Tooltip>

          <Tooltip
            title="More Options"
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow
            }}>
            <Button
              disabled={isFetchingMatrix}
              className={classes.icons}
              variant="outlined"
              onClick={handleToggleMenu}>
              <MoreVert />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        open={isMenuOpen}
        elevation={1}
        anchorEl={menuAnchorEl}
        getContentAnchorEl={null}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.paperProps }}>
        <MenuItem className={classes.menuItem} disabled={!hasPermissionCreateApplication}>
          <Link component={RouterLink} to="/services-hub/new?type=application">
            New Application
          </Link>
        </MenuItem>
        <MenuItem className={classes.menuItem} disabled={!hasPermissionEdit}>
          <Link component={RouterLink} to={`/services-hub/edit/${productId}?type=product`}>
            Edit product
          </Link>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleOpenDeleteDialog}
          disabled={!hasPermissionDelete}>
          Delete product
        </MenuItem>
      </Menu>
      <Divider className={globalClasses.divider} />
      <Box>
        {matrix ? (
          <Matrix matrix={matrix} fetching={isFetchingMatrix} reexecuteQuery={refetch} />
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </Box>

      <DeleteEntity
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleRemove={handleDeleteDialog}
        entityName="Product"
        disabled={matrix?.applications.length}
        isLoading={fetchingDelete}
        customTitle={`You need to unlink all applications from this product`}
        disassociate={false}
        children=""
        dependencyName=""
        agentInstalled={false}
      />
    </PageContainer>
  );
};
