import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, DialogActions, DialogContent, Divider, Link, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as z from 'zod';

import { LoadingOverlay } from 'componentsV4/Loading';
import { usePermission } from 'hooks/usePermission';
import SlackColouredIcon from 'icons/SlackColoured';
import actions from 'redux/actions';
import { Theme } from 'theme/v4';
import palette from 'theme/v4/palette';

import { useStyles as styleDeleteDalog } from '../../../../../../../../../componentsV4/DeleteDialog/styles';

import { useSlackCreateWarRoomChannel } from './useSlackCreateWarRoomChannel';
import { useSlackGetLinkChannelById } from './useSlackGetLinkChannelById';

const useStyles = makeStyles<Theme>(theme => ({
  iconFooter: {
    color: palette.primary.main,
    marginRight: theme.spacing(1)
  }
}));

export const SlackCreateChannelV2 = ({
  channelNameSuggestion,
  incidentId,
  warRoomProvider,
  warRoomIntegrationId
}: {
  channelNameSuggestion?: string;
  incidentId: number;
  warRoomProvider: string;
  warRoomIntegrationId: number;
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [linkSlackChannel, setlinkSlackChannel] = useState<string>('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [{ data, fetching, error }] = useSlackGetLinkChannelById(
    warRoomProvider || '',
    warRoomIntegrationId || 0
  );

  useEffect(() => {
    if (!fetching && !error) {
      setlinkSlackChannel(data.getLinkChannelById.link || '');
    }
  }, [data, fetching, error]);

  const hasPermissionCreateWarRoom = usePermission(
    'CommunicationIntegrationController-post-/slack_create_channel_with_incident'
  );

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        title="Create Slack Channel"
        disabled={fetching || !hasPermissionCreateWarRoom}
        onClick={
          linkSlackChannel.length === 0
            ? handleClickOpen
            : () => window.open(linkSlackChannel, '_blank')
        }
        startIcon={<SlackColouredIcon className={classes.iconFooter} style={{ width: 20 }} />}>
        {linkSlackChannel.length === 0 ? 'Create Slack Channel' : 'View Channel'}
      </Button>

      <SimpleDialog
        open={open}
        onClose={handleClose}
        channelNameSuggestion={channelNameSuggestion}
        incidentId={incidentId}
        setLinkSlackChannel={setlinkSlackChannel}
      />
    </>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  channelNameSuggestion?: string;
  incidentId: number;
  setLinkSlackChannel: (link: string) => void;
}

const schema = z.object({
  roomName: z.string().min(3)
});

function SimpleDialog(props: SimpleDialogProps) {
  const classes = styleDeleteDalog();
  const dispatch = useDispatch();

  const { onClose, open, setLinkSlackChannel } = props;

  const handleClose = () => {
    onClose();
  };

  const {
    register,
    handleSubmit
    // TODO: Validate input
    // errors,
    // formState: {
    // isValid
    // }
  } = useForm({
    defaultValues: {
      roomName: props.channelNameSuggestion || ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const {
    authorizeSlack,
    slackCreateChannel,
    slackCreateChannelResult
  } = useSlackCreateWarRoomChannel();

  const isSlackIntegration =
    slackCreateChannelResult.data?.slackCreateChannelWithIncident.integrated;

  const onSubmit = handleSubmit(async data => {
    const newData = {
      ...data,
      incidentId: props.incidentId
    };

    const result = await slackCreateChannel(newData);

    if (result?.slackCreateChannelWithIncident.success) {
      setLinkSlackChannel(result.slackCreateChannelWithIncident.link);

      handleClose();
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Channel created successfully'
      });
    }
  });

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box padding={3}>
        <DialogTitle
          color="secondary"
          id="simple-dialog-title"
          className={classes.dialogTitle}
          disableTypography>
          <Typography variant="h4">Create Slack Channel</Typography>

          <Divider className={classes.divider} />
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {slackCreateChannelResult.fetching ? <LoadingOverlay /> : null}

          <>
            <Typography>
              Channel names may only contain lowercase letters, numbers, hyphens, and underscores,
              and must be 80 characters or less.{' '}
            </Typography>
            <Box marginTop={3}>
              <form id="slackCreateChannelForm">
                <TextField
                  inputRef={register()}
                  required
                  autoFocus
                  margin="dense"
                  id="roomName"
                  name="roomName"
                  label="Channel name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  // TODO: Show validation errors
                  // error
                  // helperText="teste"
                />
              </form>

              {isSlackIntegration === false ? (
                <>
                  <Typography color="error">
                    Looks like you need to integrate slack with our app.
                  </Typography>
                  <Typography>
                    <Link href="#" onClick={authorizeSlack}>
                      Click here
                    </Link>{' '}
                    do integrate.
                  </Typography>
                </>
              ) : null}

              {(slackCreateChannelResult.error ||
                slackCreateChannelResult.data?.slackCreateChannelWithIncident.success ===
                  false) && <Typography color="error">Failed to create Slack channel.</Typography>}
            </Box>
          </>
        </DialogContent>

        <DialogActions style={{ padding: 0 }}>
          <Box mt={5} display="flex" justifyContent="flex-end" className={classes.buttons}>
            <Button
              variant="outlined"
              onClick={handleClose}
              color="primary"
              className={classes.cancelButton}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={onSubmit}
              color="primary"
              type="submit"
              form="slackCreateChannelForm">
              CREATE
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
