import { getPermission } from 'helpers';

import { useHasPermission } from './permissions/hasPermission';
import useUser from './queriesRest/useUser';
import useFeatureFlagUnleash from './useFeatureFlagUnleash';

export function usePermission(target: string) {
  const { data: user } = useUser();

  const useNewPermission = useFeatureFlagUnleash('useNewPermission', { queryString: true });

  const hasPermission = useHasPermission({
    roleId: user?.logged?.roleId,
    userId: user?.id,
    target
  });

  return useNewPermission ? hasPermission : getPermission(user, target);
}
