import React from 'react';

import { Button, Hidden, Paper, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import actions from 'redux/actions';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      // marginRight: 2,
      margin: theme.spacing(1)
    }
  },
  spacer: {
    flexGrow: 1
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10
  },
  paper: {
    // padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
    boxShadow: 'none',
    fontSize: 'xx-large',
    fontWeight: 'bold'
  },
  excluir: {
    backgroundColor: colors.red[700],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  }
}));

const EnvironmentToolbar = props => {
  const { className, environment, entity } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const newHandler = () => dispatch(push(`/${entity}/new`));
  const editHandler = () => dispatch(push(`/${entity}/${environment.id}/edit`));
  const removeHandler = ({ id }) =>
    dispatch({
      type: actions.DELETE_ENTITY,
      payload: { id },
      meta: {
        entity
      }
    });

  function disabledButton(environment) {
    if (environment.clusters?.length) {
      return true;
    }
    if ([0, 16, 32].includes(environment?.agent?.status_code)) {
      return true;
    }
    return false;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Hidden smDown>
          <Paper className={classes.paper}>{environment.name}</Paper>
        </Hidden>
        <span className={classes.spacer} />
        <Button
          className={classes.excluir}
          color="secondary"
          disabled={disabledButton(environment)}
          onClick={event => {
            event.preventDefault();
            const id = { id: environment.id };
            removeHandler(id);
          }}
          variant="contained">
          Remove
        </Button>

        <Button
          color="primary"
          disabled={disabledButton(environment)}
          onClick={editHandler}
          variant="contained">
          Edit
        </Button>

        <Button color="primary" onClick={newHandler} variant="contained">
          New
        </Button>
      </div>
    </div>
  );
};

EnvironmentToolbar.propTypes = {
  className: PropTypes.string
};

export default EnvironmentToolbar;
