import { TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, UseFormMethods } from 'react-hook-form';

import { Input } from '../Input';

type SelectProps = {
  control: UseFormMethods['control'];
  options: {
    label: string;
    value: string;
    type?: string;
  }[];
} & TextFieldProps;

export function MultiSelect({ options, control, ...textFieldProps }: SelectProps) {
  const { name, ...inputProps } = textFieldProps;

  return (
    <Controller
      control={control}
      name={name!}
      defaultValue={textFieldProps.defaultValue}
      render={({ value, onChange }) => {
        const orderedOptions = options.filter(
          option =>
            !value?.find((responder: { value: string }) => responder?.value === option.value)
        );

        return (
          <Autocomplete
            multiple
            value={value}
            onChange={(_, value) => onChange(value)}
            getOptionLabel={option => option.label}
            options={orderedOptions}
            size="small"
            fullWidth
            ChipProps={{
              color: 'secondary'
            }}
            renderInput={params => <Input {...params} {...inputProps} />}
          />
        );
      }}
    />
  );
}
