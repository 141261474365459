import React from 'react';

import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';

import Form from './Form';

const PermissionForm = ({ formState, features, permissions, save, remove }) => {
  return (
    <Card>
      <form name="RolePermissionsForm">
        <CardHeader title={`${formState.values.name} permissions`} />
        <Divider />
        <CardContent>
          <Form
            formState={formState}
            features={features}
            permissions={permissions}
            save={save}
            remove={remove}
          />
        </CardContent>
        <Divider />
      </form>
    </Card>
  );
};

export default PermissionForm;
