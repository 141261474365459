export type ExternalServiceBaseForm = {
  id: number;
  uid: string;
  name: string;
  responders: {
    value: string;
    label: string;
  }[];
};

export type ExternalIntegration = {
  id: number;
  uid: string;
  name: string;
  origin: ExternalIntegrationsOrigins;
  cusumerId: number;
  keyId: number;
  orgUid: string;
};

export type ExternalIntegrationMutationResult = {
  createExternalIntegration: ExternalIntegration;
};

export type ExternalIntegrationsFromIndexer = {
  getExternalIntegrationsFromIndexer: { data: ExternalIntegrationFromIndexer[]; total: number };
};

export type ExternalIntegrationFromIndexer = {
  uid: string;
  name: string;
  origin: ExternalIntegrationsOrigins;
  status: ExternalIntegrationStatusEnum;
  orgUid: string;
};

export enum ExternalIntegrationStatusEnum {
  Outage = 'outage',
  Available = 'available',
  Pending = 'pending'
}

export enum ExternalIntegrationsOrigins {
  ZapierEmail = 'zapier-email',
  Datadog = 'datadog',
  Prometheus = 'prometheus',
  Cloudwatch = 'cloudwatch',
  Zabbix = 'zabbix',
  Custom = 'custom',
  Newrelic = 'newrelic',
  Sentry = 'sentry',
  Grafana = 'grafana',
  Googlecloud = 'googlecloud',
  Dynatrace = 'dynatrace'
}

export const externalIntegrationsOriginsList = [
  { value: 'cloudwatch', label: 'Cloudwatch' },
  { value: 'custom', label: 'Custom' },
  { value: 'datadog', label: 'Datadog' },
  { value: 'dynatrace', label: 'Dynatrace' },
  { value: 'grafana', label: 'Grafana' },
  { value: 'googlecloud', label: 'Googlecloud' },
  { value: 'newrelic', label: 'Newrelic' },
  { value: 'prometheus', label: 'Prometheus' },
  { value: 'sentry', label: 'Sentry' },
  { value: 'zapier-email', label: 'Zapier-email' },
  { value: 'zabbix', label: 'Zabbix' }
];

export type GuideType = {
  name: string;
  errors?: string[];
  useToken: boolean;
  externalServiceUrl?: string;
  externalServiceUrls?: {
    key: string;
    url: string;
  }[];
  snippets?: {
    key: string;
    value: string;
  }[];
  instructions?: {
    isNumeric: boolean;
    steps: string[];
  };
};

export type GuideParams = {
  orgId: number;
  orgUid: string;
  orgName: string;
  token: string;
  integrationUid: string;
};

export type CreateExternalServicePayload = {
  name: string;
  responders: Responder[];
};

export type UpdateExternalServicePayload = {
  id: number;
  uid: string;
  name: string;
  responders: Responder[];
};

export type Responder = {
  responderId: number;
};
