import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { CreateApplicationV2Mutation } from 'graphqlQueries/createApplicationV2';
import { UpdateApplicationV2 } from 'graphqlQueries/updateApplicationsV2';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Application } from 'types/external/ApplicationManager';
import { Dependency } from 'types/external/Dependency';
import { useMutation } from 'urql';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { LoadingOverlay } from 'componentsV4/Loading';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useMultiOrigins } from 'hooks/queriesGraphQL/useMultiOriginsMonitorings';
import { useProducts } from 'hooks/queriesGraphQL/useProducts';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { Input } from 'views/ServicesHub/components/Input';

import { useStyles } from '../../ServicesHub/formStyles';
import { DependenciesSelect } from '../components/DependenciesSelect';
import { MonitoringSelect } from '../components/MonitoringSelect';
import { ProductSelect } from '../components/ProductSelect';

import { schema } from './schema';

type FormValues = {
  name: string;
  dependencies: { entityUid: string; dependencyUid: string }[];
  entityUid: string;
  productId?: string;
};

type FormEditValues = {
  applicationId: number;
  applicationUid: string;
  name: string;
  dependencies?: Dependency[];
  entityUid: string;
  entity: string;
  productId?: string;
};

export function ApplicationsForm({
  isEdit,
  isFetchingApp,
  refetch,
  breadcrumbItens,
  title,
  description,
  startValues
}: {
  isEdit: boolean;
  isFetchingApp?: boolean;
  refetch?: any;
  breadcrumbItens: any;
  title: string;
  description: string;
  startValues?: FormEditValues | undefined;
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const history = useHistory();

  const dispatch = useDispatch();

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      dependencies: [{ entityUid: '', dependencyUid: '' }],
      entityUid: '',
      productId: ''
    },
    resolver: zodResolver(schema)
  });
  const { register, errors, reset } = form;

  React.useEffect(() => {
    reset(startValues);
  }, [reset, startValues]);

  const hasPermissionCreate = usePermission('ApplicationController-post-/applications');
  const hasPermissionEdit = usePermission('ApplicationController-put-/applications');

  const [{ data, fetching: isFetching }] = useMultiOrigins();
  const monitoringsList = data?.getMultiOriginsMonitorings?.data;
  const monitoringOptions =
    monitoringsList?.map((item: { uid: string; name: string; entity: string }) => {
      return {
        value: item.uid,
        label: item.name,
        helperText: item.entity === 'Multiflow' ? 'Synthetic' : item.entity
      };
    }) ?? [];

  const monitoringOptionsWithDefault = [
    {
      value: '',
      label: 'Select one',
      helperText: ''
    },
    ...monitoringOptions
  ];

  const [{ data: productsList, fetching: isFetchingProducts }] = useProducts();
  const productOptions =
    productsList?.products?.data?.map((product: { id: number; name: string }) => {
      return { value: product.id.toString(), label: product.name };
    }) ?? [];
  const productOptionsWithDefault = [
    {
      value: '',
      label: 'None'
    },
    ...productOptions
  ];

  const [{ fetching: isCreating }, createApplicationV2] = useMutation<{
    createApplicationV2: Application;
  }>(CreateApplicationV2Mutation);

  const [{ fetching: isUpdating }, updateApplicationV2] = useMutation<{
    updateApplicationV2: Application;
  }>(UpdateApplicationV2);

  const prepareDependencies = ({
    startValues,
    filteredDependencies
  }: {
    startValues?: FormEditValues | undefined;
    filteredDependencies: { entityUid: string; dependencyUid: string }[];
  }) => {
    if (!filteredDependencies) return [];
    return filteredDependencies?.map(dependency => {
      const foundEntityForDependency = monitoringsList?.find(entity => {
        return entity.uid === dependency.entityUid;
      });

      const commonDependencyData = {
        entityUid: foundEntityForDependency?.uid,
        entity: foundEntityForDependency?.entity
      };

      if (startValues) {
        const foundStartValueDependency = startValues.dependencies
          ? startValues.dependencies.find(entity => {
              return entity.dependencyUid === dependency.dependencyUid;
            })
          : undefined;

        if (!foundStartValueDependency) return commonDependencyData;

        return {
          ...commonDependencyData,
          id: foundStartValueDependency.dependencyId,
          uid: foundStartValueDependency.dependencyUid
        };
      }

      return commonDependencyData;
    });
  };

  const handleSubmit = async (data: FormValues) => {
    const foundEntityForApplication = monitoringsList?.find(entity => {
      return entity.uid === data.entityUid;
    });

    const filteredDependencies = data.dependencies.filter(dependency => {
      return Boolean(dependency.entityUid);
    });

    const application = {
      name: data.name,
      entityUid: foundEntityForApplication?.uid,
      entity: foundEntityForApplication?.entity,
      productId: data.productId === '' ? null : Number(data.productId)
    };

    if (isEdit && startValues) {
      const dependenciesToDelete = startValues.dependencies
        ? startValues.dependencies
            .filter(oldDependency => {
              const found = filteredDependencies.find(newDependency => {
                return newDependency.dependencyUid === oldDependency.dependencyUid;
              });
              if (found) return false;
              return true;
            })
            .map(dependendency => dependendency.dependencyUid)
        : [];

      const applicationToUpdate = {
        ...application,
        applicationUid: startValues.applicationUid,
        id: startValues.applicationId,
        dependencies: prepareDependencies({ filteredDependencies, startValues }),
        dependenciesToDelete
      };

      const applicationResponse = await updateApplicationV2({ data: applicationToUpdate });

      if (applicationResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on Edit application' }
        });
        // eslint-disable-next-line no-console
        return;
      }
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application edit successfully'
      });

      history.push(`/applications/${startValues.applicationUid}`);
    }

    if (!isEdit) {
      const applicationResponse = await createApplicationV2({
        data: { ...application, dependencies: prepareDependencies({ filteredDependencies }) }
      });
      if (applicationResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create application' }
        });
        // eslint-disable-next-line no-console
        console.error(applicationResponse.error);
        return;
      }
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application created successfully'
      });
      history.push(`/applications/${applicationResponse.data?.createApplicationV2.uid}`);
    }
  };

  const isLoading = isFetchingApp || isCreating || isUpdating;

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <Breadcrumb items={breadcrumbItens} />

      <Box component="form" onSubmit={form.handleSubmit(handleSubmit)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
          <Typography variant="h4" color="secondary" className={globalClasses.title}>
            {title}
          </Typography>
        </Box>

        <Typography className={classes.description} variant="body1">
          {description}
        </Typography>

        <Divider className={globalClasses.divider} />

        <Box display="flex" gridGap="2rem" flexDirection="column" flex={1}>
          <Typography className={classes.sectionTitle} variant="h4" color="secondary">
            Basic Informations
          </Typography>
          <Box display="flex" gridGap="6rem">
            <Box flex={1}>
              <Box display="flex" gridGap="2rem" flexDirection="column">
                <Input
                  label="Display name"
                  name="name"
                  inputRef={register({
                    required: true
                  })}
                  required={true}
                  errors={errors}
                />

                <ProductSelect
                  form={form}
                  required={false}
                  options={productOptionsWithDefault}
                  fetching={isFetchingProducts}
                  refetch={() => refetch()}
                />
                <MonitoringSelect
                  form={form}
                  required={true}
                  options={monitoringOptionsWithDefault}
                  fetching={isFetching}
                  refetch={() => refetch()}
                />
              </Box>
            </Box>
            <Box flex={1}>
              <Typography
                className={classes.infoSectionTitle}
                variant="subtitle2"
                color="secondary">
                Cluster information
              </Typography>
              <Typography className={classes.infoSectionDescription} variant="subtitle1">
                Name your application and select the main monitoring for the group. If you don't
                have any registered monitoring, click on '+ Monitoring' to add new ones. After
                adding, click 'Update' and then select the created monitoring.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider className={globalClasses.divider} />

        <Box display="flex" gridGap="2rem" flexDirection="column" flex={1} paddingBottom="2rem">
          <Typography className={classes.sectionTitle} variant="h4" color="secondary">
            Link dependencies to this application
          </Typography>
          <Box display="flex" gridGap="6rem">
            <Box flex={1}>
              <DependenciesSelect
                form={form}
                options={monitoringOptionsWithDefault}
                required={false}
                fetching={isFetching}
                refetch={() => refetch()}
              />
            </Box>
            <Box flex={1}>
              <Typography
                className={classes.infoSectionTitle}
                variant="subtitle2"
                color="secondary">
                Link monitoring
              </Typography>
              <Typography className={classes.infoSectionDescription} variant="subtitle1">
                Include the desired monitors in the application group. Upon selection, the
                monitoring will be established as a dependency within the previously configured
                application.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" gridGap="1rem">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
            disabled={isCreating}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!(isEdit ? hasPermissionEdit : hasPermissionCreate) || isCreating}>
            {isEdit ? 'Save' : 'Create'} application
          </Button>
        </Box>
        {isLoading && <LoadingOverlay />}
      </Box>
    </PageContainer>
  );
}
