import { useEffect, useState } from 'react';

import { Box, Typography, List, ListItem, Button, Fade } from '@material-ui/core';
import copy from 'clipboard-copy';
import { GuideParams, GuideType } from 'types/external/ExternalService';

import useUser from 'hooks/queriesRest/useUser';

import { ApiToken } from './ApiToken';
import { InputCopyUrl } from './InputCopyUrl';
import { useStyles } from './styles';

export function Guide({
  guide,
  integrationUid
}: {
  guide: ({ orgId, orgUid, orgName, integrationUid }: GuideParams) => GuideType;
  integrationUid: string;
}) {
  const classes = useStyles();

  const { data: user } = useUser();

  const [token, setToken] = useState('');
  const [copiedSnippetIndex, setCopiedSnippetIndex] = useState<null | number>(null);

  const guideInfos = guide({
    orgId: user?.logged?.org?.id,
    orgUid: user?.logged?.org?.uid,
    orgName: user?.logged?.org?.name,
    token,
    integrationUid
  });

  useEffect(() => {
    if (copiedSnippetIndex) {
      setTimeout(() => {
        setCopiedSnippetIndex(null);
      }, 300);
    }
  }, [copiedSnippetIndex, setCopiedSnippetIndex]);

  return (
    <Box>
      <Typography className={classes.sectionTitle} variant="h4" color="secondary">
        {guideInfos.name} integration guide
      </Typography>

      {!token && guideInfos.errors && (
        <Box mb={4}>
          <Typography variant="subtitle1" color="secondary">
            Errors:
          </Typography>

          <List>
            {guideInfos.errors.map((error: string) => {
              return (
                <ListItem className={classes.item}>
                  <Typography variant="subtitle1" className={classes.text}>
                    <span className={classes.circle}>&#8226;</span> {error}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}

      {guideInfos.useToken && (
        <>
          <ApiToken onChangeToken={setToken} url={guideInfos?.externalServiceUrl} />
          {guideInfos?.externalServiceUrls &&
            guideInfos.externalServiceUrls?.map((item, index) => {
              return <InputCopyUrl key={index} title={item.key} url={item.url} />;
            })}
        </>
      )}

      {guideInfos?.snippets &&
        guideInfos.snippets.map((snippet, index) => {
          return (
            <Box key={index} mb={2}>
              <Typography variant="subtitle1" color="secondary">
                {snippet?.key}:
              </Typography>

              <Box className={classes.codeBlock}>
                <Box
                  className={classes.copyButton}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Box mr={1} display="flex">
                    <Fade in={copiedSnippetIndex === index} timeout={{ enter: 500, exit: 2000 }}>
                      <small style={{ color: 'green' }}>Copied</small>
                    </Fade>
                  </Box>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={event => {
                      event.preventDefault();
                      copy(String(snippet?.value)).then(() => {
                        setCopiedSnippetIndex(index);
                      });
                    }}>
                    COPY
                  </Button>
                </Box>

                <Box>
                  <pre className={classes.snippet}>
                    <Box p={2}>{snippet?.value}</Box>
                  </pre>
                </Box>
              </Box>
            </Box>
          );
        })}

      {guideInfos.instructions && (
        <Box>
          <Typography variant="subtitle1" color="secondary">
            Instructions:
          </Typography>

          <List>
            {guideInfos.instructions.steps.map((instruction: string, index: number) => {
              const isNumeric = guideInfos.instructions?.isNumeric;

              return (
                <ListItem className={classes.item}>
                  <Typography className={classes.text}>
                    {isNumeric ? `${index + 1} - ` : null}
                    {instruction}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
}
