import React from 'react';

import Form from '../components/Form';

class RolesNewForm extends React.Component {
  render() {
    const { formState, onChange, save } = this.props;
    return <Form formState={formState} onChange={onChange} save={save} title="New Role" />;
  }
}
export default RolesNewForm;
