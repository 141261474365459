import React from 'react';

import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';

import DataGrid from '../../../components/Crud/DataGrid/v1';
import Toolbar from '../../../components/Crud/Toolbar';

import columns from './columns';

const styles = theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
});

class RolesList extends React.Component {
  render() {
    const { classes, history, settingsHandler } = this.props;
    return (
      <div className={classes.root}>
        <Toolbar
          buttonNewName="New"
          entity="roles"
          history={history}
          searchPlaceholder="Search roles"
        />
        <div className={classes.content}>
          <DataGrid
            cols={[
              ...columns,
              {
                grow: 0,
                center: true,
                compact: true,
                cell: row => (
                  <IconButton
                    onClick={event => {
                      event.preventDefault();
                      settingsHandler(row);
                    }}
                    variant="outlined">
                    <SettingsIcon />
                  </IconButton>
                )
              }
            ]}
            entity="roles"
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RolesList);
