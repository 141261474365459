import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>(theme => ({
  form: {
    minHeight: 150
  },
  container: {
    minHeight: 110,
    padding: 0,
    margin: 0
  },
  input: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  text: {
    fontSize: theme.typography.pxToRem(14)
  },
  borderBody: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0)'
  },
  button: {
    padding: theme.spacing(1),
    width: '88%',
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(3.9),
    height: 40,
    marginBottom: theme.spacing(1),
    marginLeft: 16
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.elvenTeal[400]
  },
  checkboxText: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));
