import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

import { useStyles } from './styles';

interface Props {
  entity: any;
  helperText: string;
  featurePlan: string;
}

export const Metric = ({ helperText, entity, featurePlan }: Props) => {
  const classes = useStyles();

  const canUseMetrics = useFeaturePlan(featurePlan);
  const isLoading = canUseMetrics === null;

  if (isLoading) return null;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2">
              {entity.name}
            </Typography>
            {canUseMetrics ? (
              <Typography variant="h4">{entity.value}</Typography>
            ) : (
              <Link to="/plans">
                <Typography variant="h5" color="primary">
                  Upgrade your plan
                </Typography>
              </Link>
            )}
          </Grid>
        </Grid>
        {canUseMetrics && (
          <div className={classes.helperText}>
            {helperText && <Typography variant="caption">{helperText}</Typography>}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
