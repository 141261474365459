import { IconButton, Typography } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import slugify from 'slugify';
import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';

import { MarkdownHelperText } from 'components/MarkdownHelperText';
import useUser from 'hooks/queriesRest/useUser';
import useCopy from 'hooks/useCopy';
import actions from 'redux/actions';
import { Input } from 'views/ServicesHub/components/Input';

import useStyles from './styles';
import { useSSOForm } from './useSSOForm';

type SAMLConfigurationSectionType = {
  type: SSOIntegration['type'];
};

export function SAMLConfigurationSection({ type }: SAMLConfigurationSectionType) {
  const classes = useStyles();
  const { data: user } = useUser({});

  const dispatch = useDispatch();

  const organizationName: string = user.logged.org.name;

  // Auth0 connection name can only contain alphanumeric and hyphens (must start and and with an alphanumeric character)
  const auth0ConnectionName = slugify(organizationName, { strict: true });

  const ACSUrl = `https://auth.elven.works/login/callback?connection=${auth0ConnectionName}`;
  const entityID = `urn:auth0:elven:${auth0ConnectionName}`;

  const { handleCopy: copy } = useCopy();

  function handleCopy({ text, copiedAlertText }: { text: string; copiedAlertText: string }) {
    copy(text);

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: copiedAlertText
    });
  }

  const { data: ssoFormData, isLoading } = useSSOForm();

  if (type === 'google')
    return (
      <>
        <Typography variant="h5" component="label" color="secondary">
          Service Provider Details
        </Typography>

        <Input
          className={classes.input}
          label="ACS URL"
          value={ACSUrl}
          disabled
          helperText={
            isLoading ? (
              '...'
            ) : (
              <MarkdownHelperText>{ssoFormData?.acs_url_helper_text || ''}</MarkdownHelperText>
            )
          }
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ background: 'none', padding: 0 }}
                title="Copy ACS URL"
                disableRipple
                onClick={() =>
                  handleCopy({
                    text: ACSUrl,
                    copiedAlertText: 'ACS Url copied!'
                  })
                }>
                <FileCopy />
              </IconButton>
            )
          }}
        />

        <Input
          className={classes.input}
          label="Entity ID"
          value={entityID}
          disabled
          helperText={
            isLoading ? (
              '...'
            ) : (
              <MarkdownHelperText>{ssoFormData?.entity_id_helper_text || ''}</MarkdownHelperText>
            )
          }
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ background: 'none', padding: 0 }}
                title="Copy Entity ID"
                disableRipple
                onClick={() =>
                  handleCopy({
                    text: entityID,
                    copiedAlertText: 'Entity ID copied!'
                  })
                }>
                <FileCopy />
              </IconButton>
            )
          }}
        />
      </>
    );

  if (type === 'microsoft')
    return (
      <>
        <Typography variant="h5" color="secondary">
          Basic SAML Configuration
        </Typography>

        <Input
          className={classes.input}
          label="Identifier (Entity ID)"
          value={entityID}
          disabled
          helperText={
            isLoading ? (
              '...'
            ) : (
              <MarkdownHelperText>{ssoFormData?.identifier_helper_text || ''}</MarkdownHelperText>
            )
          }
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ background: 'none', padding: 0 }}
                title="Copy Identifier"
                disableRipple
                onClick={() =>
                  handleCopy({
                    text: entityID,
                    copiedAlertText: 'Identifier copied!'
                  })
                }>
                <FileCopy />
              </IconButton>
            )
          }}
        />

        <Input
          className={classes.input}
          label="Reply URL (Assertion Consumer Service URL)"
          value={ACSUrl}
          disabled
          helperText={
            isLoading ? (
              '...'
            ) : (
              <MarkdownHelperText>{ssoFormData?.reply_url_helper_text || ''}</MarkdownHelperText>
            )
          }
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ background: 'none', padding: 0 }}
                title="Copy Reply URL"
                disableRipple
                onClick={() =>
                  handleCopy({
                    text: ACSUrl,
                    copiedAlertText: 'Reply URL copied!'
                  })
                }>
                <FileCopy />
              </IconButton>
            )
          }}
        />
      </>
    );

  return null;
}
