import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { usePermission } from 'hooks/usePermission';

export const CreateSyntheticButton = () => {
  const history = useHistory();
  const permission = usePermission('ServicesHubController-post-/services');

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      startIcon={<Add />}
      disabled={!permission}
      onClick={() => history.push(`/services-hub/new?type=synthetic`)}>
      Synthetic
    </Button>
  );
};
