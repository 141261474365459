import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { DeleteEntity as NewDeleteEntity } from 'components/DeleteDialog';
import ShowDependencies from 'components/DeleteEntityDialog/components/ShowDependencies';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const DataGrid = ({
  entity,
  cols,
  title,
  mapEntity,
  permissions,
  data,
  total,
  handleSort,
  handlePageChange,
  handlePerRowsChange,
  removeHandler
}) => {
  const [deleteDialogState, setDeleteDialogState] = React.useState({ isOpen: false, entity: null });
  const dispatch = useDispatch();
  const history = useHistory();
  const shouldUseNewUserFormEdit = useFeatureFlagUnleash('useNewUserFormEdit');

  const editHandler = ({ id }) => dispatch(push(`/${entity}/${id}/edit`));

  const deleteIcon = row => {
    return (
      <IconButton
        title="Remove"
        data-name={row.name}
        onClick={() => {
          const entity = mapEntity ? mapEntity(row) : row;
          setDeleteDialogState({ isOpen: true, entity });
        }}>
        <DeleteIcon />
      </IconButton>
    );
  };

  const editIcon = row => {
    if (!permissions?.update) {
      return (
        <div title="You are not allowed to update">
          <IconButton disabled={true}>
            <EditIcon />
          </IconButton>
        </div>
      );
    }

    return (
      <IconButton
        title="Edit"
        data-name={row?.name}
        data-isactive={row?.isActive}
        onClick={event => {
          event.preventDefault();
          const dto = mapEntity ? mapEntity(row) : row;
          if (shouldUseNewUserFormEdit) {
            history.push(`/services-hub/edit/${dto?.id}?type=users`);
            return;
          }
          editHandler(dto);
        }}
        variant="outlined">
        <EditIcon />
      </IconButton>
    );
  };

  const columns = [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => editIcon(row)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => deleteIcon(row)
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false
  };

  const dataGridTheme = {
    tableWrapper: {
      style: {
        display: 'block'
      }
    },
    title: {
      height: title ? 56 : 0
    }
  };

  return (
    <>
      <DataTable
        clicked
        columns={columns}
        customTheme={dataGridTheme}
        data={data}
        highlightOnHover
        noDataComponent="No data"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={total}
        sortServer
        striped
      />
      {deleteDialogState.isOpen && (
        <NewDeleteEntity
          open={true}
          handleClose={() => setDeleteDialogState({ isOpen: false, entity: null })}
          entityName="User"
          disabled={!permissions.remove}
          handleRemove={() => {
            const { entity } = deleteDialogState;
            removeHandler(entity);
            setDeleteDialogState({ isOpen: false, entity: null });
          }}>
          <ShowDependencies title="If you remove this user from the organization, he will be excluded from all relationships." />
        </NewDeleteEntity>
      )}
    </>
  );
};

export default DataGrid;
