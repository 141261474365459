import { useQuery } from 'urql';

import useFeatureFlagUnleash from './useFeatureFlagUnleash';

const GetAccountsQuery = `#graphql
  query {
    getAccounts {
      id
      org {
        id
        name
      }
    }
  }
`;

type Accounts = {
  id: number;
  org: {
    id: number;
    name: string;
  };
};

type GetAccounts = {
  getAccounts: Accounts[];
};

export function useGetAccounts() {
  const newAccountsList = useFeatureFlagUnleash('newAccountsList', {
    queryString: true
  });

  const [{ data, fetching }] = useQuery<GetAccounts>({
    query: GetAccountsQuery,
    pause: !newAccountsList
  });

  return {
    data: data?.getAccounts,
    fetching
  };
}
