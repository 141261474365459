// eslint-disable-next-line import/order
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { Generator } from './Recurrence';

export const getDaysArray = (data, recurrence, endOfRecurrence) => {
  const generator = new Generator();
  const arr = generator.create({
    startDate: data.start,
    endDate: data.end,
    recurrence: recurrence,
    endOfRecurrence: endOfRecurrence
  });

  return arr;
};

const Calendar = ({
  setDataSelected,
  events,
  handleOpenDialogCreateEvent,
  handleOpenDialogEditEvent,
  setDataInitSelected,
  setDataEndSelected,
  recurrence,
  endOfRecurrence,
  setCurrentDates,
}) => {
  const handleSelectDate = data => {
    const days = getDaysArray(data, recurrence, endOfRecurrence);
    setDataSelected(days);
    setDataInitSelected(data.start);
    setDataEndSelected(data.end);
    handleOpenDialogCreateEvent();
  };

  return (
    <FullCalendar
      datesSet={setCurrentDates}
      plugins={[interactionPlugin, dayGridPlugin]}
      initialView="dayGridMonth"
      selectable
      select={handleSelectDate}
      eventClick={handleOpenDialogEditEvent}
      events={events}
    />
  );
};

export default Calendar;
