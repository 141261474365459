import { ReactNode } from 'react';

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ButtonDefault } from 'components/Button';
import { Typography } from 'components/Typography';

type Props = DialogProps & {
  open: boolean;
  title: ReactNode;
  onClose: () => void;
  showCloseButton?: boolean;
  children: ReactNode;
  primaryActionLabel?: string;
  onPrimaryAction: () => void;
  secondaryActionLabel?: string;
  showPrimaryAction?: boolean;
  showSecondaryAction?: boolean;
  customTitle?: string;
  customDialogTitle?: string;
  customButton?: string;
  buttonDisabled?: boolean;
};

const Dialog = ({
  open,
  title,
  onClose,
  showCloseButton = false,
  children,
  primaryActionLabel = 'Done',
  onPrimaryAction,
  secondaryActionLabel = 'Cancel',
  showPrimaryAction = true,
  showSecondaryAction = false,
  customTitle = '',
  customDialogTitle = '',
  customButton = '',
  buttonDisabled = false,
  ...props
}: Props) => {
  const theme = useTheme();

  return (
    <MuiDialog open={open} onClose={onClose} {...props}>
      <DialogTitle
        className={customDialogTitle}
        style={{
          padding: `${theme.spacing(4)}px 20px ${theme.spacing(2)}px`,
          display: 'flex',
          justifyContent: 'space-between'
        }}
        disableTypography>
        <Typography variant="h5" className={customTitle}>
          {title}
        </Typography>

        {showCloseButton && (
          <IconButton
            style={{ marginTop: -12, marginRight: -12, marginLeft: theme.spacing(2) }}
            aria-label="close"
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions
        style={{
          padding: `${theme.spacing(3)}px 20px ${theme.spacing(4)}px`
        }}>
        {showSecondaryAction && (
          <ButtonDefault size="medium" variant="text" onClick={onClose}>
            {secondaryActionLabel}
          </ButtonDefault>
        )}

        {showPrimaryAction && (
          <ButtonDefault
            style={{ marginLeft: theme.spacing(3) }}
            disabled={buttonDisabled}
            className={customButton}
            size="medium"
            variant="contained"
            onClick={onPrimaryAction}
            autoFocus>
            {primaryActionLabel}
          </ButtonDefault>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
