export type Resource = {
  id: number;
  uid: string;
  name: string;
  domain: string;
  interval: number;
  timeout: number;
  origin: ResourceOrigin;
  type: string;
  method: string;
  environmentId: number;
  orgUid: string;
  domainSettings: Record<string, any>;
  actived: string;
  serviceId: number;
  createdAt: string;
  updatedAt: string;
  status: ResourceStatus;
  environment: { name: string };
};

export type ResourceQuery = {
  id: number;
  name: string;
  domain: string;
  interval: number;
  timeout: number;
  origin: ResourceOrigin;
  type: string;
  method: string;
  domainSettings: Record<string, any>;
  serviceId: number;
  status: ResourceStatus;
  environmentId?: number;
  environment: { name: string; statusCode: number };
};

export type ResourceStatus = {
  status: ResourceStatusEnum;
  lastCheck: string;
};

export enum ResourceOrigin {
  Agent = 'agent',
  Pool = 'pool',
  API = 'api',
  Static = 'static'
}

export enum ResourceStatusEnum {
  Off = 'off',
  Available = 'available',
  Unavailable = 'unavailable',
  Maintenance = 'maintenance',
  Pending = 'pending'
}

export type ResourceList = {
  resources: {
    data: {
      uid: string;
      name: string;
      status: {
        status: ResourceStatus;
      };
    }[];
    total: number;
  };
};
