import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function getLastWeek() {
  const today = new Date();
  const lastDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  const firstDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

  const formattedFirstDate = firstDate.toISOString().slice(0, 10);
  const formattedLastDate = lastDate.toISOString().slice(0, 10);

  return formattedLastDate + '~' + formattedFirstDate;
}

function UptimeServiceMetrics() {
  const dashboardName = MetabaseDashboards.UptimeByApplication;

  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const params = {
    app_id: applicationId,
    date_range: getLastWeek()
  };

  const hideParams = ['app_id'];

  return (
    <MetabaseDashboard
      title="Application uptime metrics"
      subtitle="AVG Application + Dependencies Uptime hour by hour"
      dashboardName={dashboardName}
      dashboardParams={params}
      hideParams={hideParams}
    />
  );
}

export default withThemeV4(UptimeServiceMetrics);
