import { useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';

import '@material-ui/core/styles';
import { IntegrationCard } from 'components/IntegrationCard';
import useQueryParams from 'helpers/useQueryParams';
import useCommunicationIntegrations from 'hooks/queriesGraphQL/useCommunicationIntegrations';
import useItsmIntegration from 'hooks/queriesGraphQL/useItsmIntegration';
import useLeadTimeChanges, { IntegrationPlatforms } from 'hooks/queriesGraphQL/useLeadTimeChanges';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { useIntegrationData } from './lib/createInstallation';
import useStyles from './styles';

export function IntegrationsList() {
  const classes = useStyles();

  const queryParams = useQueryParams();

  const { createIntegration, getIntegrations } = useLeadTimeChanges();
  const { getItsmIntegrations } = useItsmIntegration();

  const { getCommunicationIntegrations } = useCommunicationIntegrations();

  const [{ fetching: isCreatingIntegration }, createIntegrationMutation] = createIntegration;
  const [{ fetching: isFetchingIntegrations, data }, refetchIntegrations] = getIntegrations({
    pause: isCreatingIntegration
  });

  const [
    { fetching: isFetchingItsmIntegrations, data: itsmIntegrations },
    refetchItsmIntegrations
  ] = getItsmIntegrations({
    pause: isCreatingIntegration
  });

  const [
    { fetching: isFetchingCommunicationIntegrations, data: communicationIntegrations },
    refetchCommunicationIntegrations
  ] = getCommunicationIntegrations({
    pause: isCreatingIntegration
  });

  const type = queryParams.get('type') as IntegrationPlatforms;

  const getIntegrationData = useIntegrationData(type);

  const shouldShowBitbucketIntegration = useFeatureFlagUnleash('bitbucketIntegration', {
    queryString: true
  });
  const shouldUseJiraIntegration = useFeatureFlagUnleash('useJiraIntegration', {
    queryString: true
  });
  const shouldShowSlackIntegration = useFeatureFlagUnleash('useSlackIntegration', {
    queryString: true
  });

  useEffect(() => {
    async function saveInstallation() {
      const data = getIntegrationData();

      if (!data) return;

      const { error } = await createIntegrationMutation({
        createIntegrationInput: {
          type,
          data
        }
      });

      if (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving integration', { error, type });
        return;
      }

      queryParams.clear();

      window.location.reload();
    }

    saveInstallation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const githubIntegrations = data?.integrations?.filter(
    integration => integration.type === 'github'
  );

  const bitbucketIntegrations = data?.integrations?.filter(
    integration => integration.type === 'bitbucket'
  );

  const jiraIntegrations = itsmIntegrations?.itsmIntegrations?.filter(
    (integration: any) => integration.origin === 'jira'
  );

  const serviceNowIntegrations = itsmIntegrations?.itsmIntegrations?.filter(
    (integration: any) => integration.origin === 'service-now'
  );

  return (
    <>
      <Typography className={classes.body} variant="body1">
        Maximize incident resolution efficiency with our platform. Our integrations enable
        calculating lead time for changes (DORA metrics) and seamless collaboration with response
        players such as Slack and ServiceNow.
      </Typography>
      <Grid container spacing={3} style={{ marginTop: '2rem' }}>
        {shouldShowSlackIntegration && (
          <Grid item>
            <IntegrationCard
              platform="Slack"
              data={communicationIntegrations?.communicationIntegrations}
              isCreatingIntegration={isFetchingCommunicationIntegrations}
              isFetchingIntegrations={isFetchingCommunicationIntegrations}
              onDeleteIntegration={() => refetchCommunicationIntegrations()}
            />
          </Grid>
        )}

        <Grid item>
          <IntegrationCard
            platform="ServiceNow"
            data={serviceNowIntegrations || []}
            isItsmIntegration={true}
            isCreatingIntegration={isCreatingIntegration}
            isFetchingIntegrations={isFetchingItsmIntegrations}
            onDeleteIntegration={() => refetchItsmIntegrations()}
          />
        </Grid>

        {shouldUseJiraIntegration && (
          <Grid item>
            <IntegrationCard
              platform="Jira"
              data={jiraIntegrations || []}
              isItsmIntegration={true}
              isCreatingIntegration={isCreatingIntegration}
              isFetchingIntegrations={isFetchingItsmIntegrations}
              onDeleteIntegration={() => refetchItsmIntegrations()}
            />
          </Grid>
        )}
        <Grid item>
          <IntegrationCard
            platform="Github"
            data={githubIntegrations || []}
            isCreatingIntegration={isCreatingIntegration}
            isFetchingIntegrations={isFetchingIntegrations}
            onDeleteIntegration={() => refetchIntegrations()}
          />
        </Grid>
        {shouldShowBitbucketIntegration && (
          <Grid item>
            <IntegrationCard
              platform="Bitbucket"
              data={bitbucketIntegrations || []}
              isCreatingIntegration={isCreatingIntegration}
              isFetchingIntegrations={isFetchingIntegrations}
              onDeleteIntegration={() => refetchIntegrations()}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
