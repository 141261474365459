import { Box, Card, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { LockChip } from 'components/LockChip';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import DirectionalRight from 'icons/DirectionalRight';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => {
  return {
    root: {
      minHeight: 250,
      padding: theme.spacing(3),
      borderRadius: 8,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    animateBorder: {
      animation: '$cardBorder 1s linear 0s 3 normal forwards'
    },
    title: {
      color: theme.palette.elvenTeal[400],
      marginBottom: theme.spacing(2)
    },
    body: {
      marginBottom: theme.spacing(4)
    },
    characteristics: {
      color: theme.palette.text.primary
    },
    '@keyframes cardBorder': {
      '0%': {
        outlineWidth: '3px',
        outlineStyle: 'solid',
        outlineColor: theme.palette.elvenGreen[400]
      },
      '100%': {
        outlineWidth: '3px',
        outlineStyle: 'none',
        outlineColor: theme.palette.elvenGreen[400]
      }
    }
  };
});

const CardNewResource = ({
  title,
  body,
  characteristics,
  linkTo,
  hover,
  analyticsEvent,
  styleChange
}) => {
  const theme = useTheme();
  const classes = useStyles({ hover, styleChange });

  const canCreateMonitoring = useFeaturePlan('Monitoring');

  const link = canCreateMonitoring ? linkTo : '/plans';

  const icon = canCreateMonitoring ? (
    <DirectionalRight style={{ fill: theme.palette.elvenGreen[400] }} />
  ) : (
    <LockChip />
  );

  return (
    <Link to={link}>
      <Card
        title={canCreateMonitoring ? '' : "Your plan don't have Monitoring. See our pricing page"}
        className={clsx(classes.root, { [classes.animateBorder]: styleChange })}
        onClick={analyticsEvent}>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Typography className={classes.body} variant="body2">
          {body}
        </Typography>
        <Box display="flex" alignItems="flex-end" marginTop="auto">
          <Box marginRight="auto">
            {characteristics.map(characteristic => {
              return (
                <Typography
                  key={characteristic}
                  variant="body2"
                  className={classes.characteristics}>
                  {characteristic}
                </Typography>
              );
            })}
          </Box>
          {icon}
        </Box>
      </Card>
    </Link>
  );
};

export default withThemeV3Hoc(CardNewResource);
