import { Grid, Typography, useTheme } from '@material-ui/core';

import useIncidentUpdate from 'hooks/queriesGraphQL/useIncidentUpdate';
import { usePermission } from 'hooks/usePermission';

import IncidentStatusUpdateForm from './Form';
import IncidentUpdateList from './List';
import { useStyles } from './styles';

function IncidentUpdate({ incident }: { incident: any }) {
  const classes = useStyles();
  const theme = useTheme();

  const [resultIncidentUpdate, fetchingIncidentUpdate, reexecuteQuery] = useIncidentUpdate({
    incidentId: Number(incident.incidentId)
  });

  const hasPermissionCreateStatusUpdate = usePermission(
    'IncidentStatusUpdateController-post-/status_updates'
  );

  return (
    <Grid container xs={12}>
      <Typography style={{ marginTop: theme.spacing(2) }} variant="h3" color="secondary">
        Incident Status Update
      </Typography>
      {hasPermissionCreateStatusUpdate && (
        <Grid item xs={12} className={classes.root}>
          <IncidentStatusUpdateForm onCreate={reexecuteQuery} />
        </Grid>
      )}
      <Grid item xs={12} className={classes.root}>
        <IncidentUpdateList
          onDelete={reexecuteQuery}
          onUpdate={reexecuteQuery}
          incident={incident}
          isLoading={fetchingIncidentUpdate}
          data={resultIncidentUpdate?.incidentUpdates?.data ?? []}
        />
      </Grid>
    </Grid>
  );
}

export default IncidentUpdate;
