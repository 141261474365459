import { ReactNode } from 'react';

import { Box, Paper, Typography } from '@material-ui/core';

import { useStyles } from 'views/Monitoring/View/styles';

export function MetricCard({ name, children }: { name: string; children: ReactNode }) {
  const classes = useStyles();

  return (
    <Box component={Paper} className={classes.paperTop}>
      <Typography variant="subtitle2" color="secondary" className={classes.paperTitle}>
        {name}
      </Typography>
      {children}
    </Box>
  );
}
