import { TextField, TextFieldProps } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

type InputProps = {
  errors?: UseFormMethods['errors'];
} & TextFieldProps;

export function Input({ errors, ...props }: InputProps) {
  const hasError = Boolean(props.name && errors?.[props.name]);
  const errorMessage = hasError ? errors?.[props.name!]?.message : '';

  return (
    <TextField
      style={{ flex: 1 }}
      variant="outlined"
      size="small"
      InputLabelProps={{
        shrink: true,
        color: 'secondary'
      }}
      error={hasError}
      fullWidth
      required
      {...props}
      helperText={hasError ? errorMessage : props?.helperText}
    />
  );
}
