import { severityOptions } from 'constants/severityOptions';

import { Box, MenuItem, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Controller } from 'react-hook-form';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import { useServiceMonitors } from 'hooks/queriesGraphQL/useServiceMonitors';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { MultiSelect } from 'views/ServicesHub/components/MultiSelect';
import { Select } from 'views/ServicesHub/components/Select';
import { TeamsSelect } from 'views/ServicesHub/components/TeamsSelect';

import { FormProps } from '../';
import { Input } from '../../components/Input';

export function IncidentManualForm({ form }: FormProps) {
  const { register, control } = form;

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });
  const shouldUseIncidentManualWithAlerts = useFeatureFlagUnleash('useIncidentManualWithAlerts', {
    queryString: true
  });

  const [{ data, fetching }] = useServiceMonitors({
    query: shouldUseNewApplications
      ? `(type: integration) OR (type: multiflow) OR (type: resource)`
      : `(type: integration) OR (type: multiflow)`
  });

  const monitorsOptions =
    data?.getMonitorsService?.data?.map(monitor => ({
      value: monitor.type === 'service' ? String(monitor.id) : String(monitor.uid),
      label: monitor.name,
      type: monitor.type
    })) ?? [];

  const { data: dataAlerts, fetching: fetchingAlerts } = useAlertsState({
    pause: !shouldUseIncidentManualWithAlerts
  });

  const alertsOptions =
    dataAlerts?.alertsState?.data?.map(alert => ({
      value: alert.uid,
      label: alert.title
    })) ?? [];

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {fetching && fetchingAlerts && <LoadingOverlay size="2rem" />}
      <Input label="Incident name" name="incidentName" inputRef={register} />

      <Input label="Cause" name="cause" inputRef={register} minRows={4} multiline />

      <Input label="Started At" name="startedAt" type="datetime-local" inputRef={register} />

      <Controller
        control={control}
        name="incidentStatus"
        defaultValue="alarmed"
        render={({ value, onChange }) => {
          return (
            <Input
              label="Choose the incident status"
              select
              fullWidth
              value={value}
              onChange={event => onChange(event.target.value)}>
              <MenuItem value="alarmed">Alarmed</MenuItem>
              <MenuItem value="acknowledged">Acknowledged</MenuItem>
              <MenuItem value="resolved">Resolved</MenuItem>
            </Input>
          );
        }}
      />
      <Box
        display="flex"
        style={{ backgroundColor: '#FFEFD0', padding: '1rem' }}
        alignItems="center">
        <Warning style={{ color: '#FAAD14' }} />
        <Typography variant="body2" style={{ marginLeft: 10 }}>
          When the incident is opened with the "Resolved" status, the "Time to Acknowledged" and
          "Time to Resolve" metrics will be set to zero. Additionally, a notification will be sent
          to the channels of the involved teams, informing them about the incident's opening.
        </Typography>
      </Box>
      <Select
        options={severityOptions}
        control={control}
        name="severity"
        label="Severity"
        defaultValue="sev-1-critical"
        required={true}
      />
      <MultiSelect
        options={monitorsOptions}
        control={control}
        name="origins"
        label="Choose the vinculated origins"
        required={false}
      />

      {shouldUseIncidentManualWithAlerts && (
        <MultiSelect
          options={alertsOptions}
          control={control}
          name="alerts"
          label="Choose the vinculated alerts"
          required={false}
        />
      )}
      <TeamsSelect control={control} name="teams" required={false} multiSelect={true} />
    </Box>
  );
}
