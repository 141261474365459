import React from 'react';

import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab-old/Skeleton';

import { useStyles } from 'helpers/globalStyles';

const LoadingSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.pageContainer}>
      <Skeleton height={40} width={200} />
      <Skeleton />
      <Skeleton width="80%" />
      <Skeleton width="90%" />
      <Skeleton height={40} width={200} />
      <Skeleton width="50%" />
      <Skeleton />
      <Skeleton width="60%" />
      <Skeleton height={40} width={200} />
      <Skeleton width="50%" />
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
  );
};

export default LoadingSkeleton;
