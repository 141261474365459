import { Fragment, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Collapse,
  Icon,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';
import { MenuSidebar } from 'types/directus';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { analyticsEvent } from 'services/firebase/analytics';
import { withThemeV4 } from 'theme/v4';

import { LockChip } from '../../../../../../components/LockChip';

import { getPlanFeatureByPage } from './getPlanFeatureByPage';
import useStyles from './styles';

interface MenuItemProps {
  page: MenuSidebar;
  key: string;
}

function getMenuItemId(href: string) {
  const hrefWithoutSlash = href.replaceAll('/', '-');

  return `MenuItem${hrefWithoutSlash}`;
}

const MenuItemChildrenButton = ({
  menuItem,
  target,
  display
}: {
  menuItem: MenuSidebar['children'][number];
  target: string;
  display: string;
}) => {
  const theme = useTheme();

  const isItemFlagEnabled = useFeatureFlagUnleash(menuItem.featureFlag ?? '');

  const isPageFeatureEnabled = useFeaturePlan(menuItem.planFeature, { placeholder: true });
  const isPageAccessibleByPlan = menuItem.planFeature ? isPageFeatureEnabled : true;

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const classes = useStyles({
    isDesktop: Boolean(isDesktop),
    hasChildren: false
  });

  if (menuItem.featureFlag && !isItemFlagEnabled) return null;

  return (
    <Button
      activeClassName={classes.active}
      className={clsx(classes.childrenButton, !isPageAccessibleByPlan && classes.buttonDisabled)}
      component={NavLink}
      to={menuItem.href ?? ''}
      target={target}>
      <Box className={classes.childrenActiveIcon} display={display} />
      {menuItem.icon && <Icon>{menuItem.icon}</Icon>}
      <Typography variant="body2" className={classes.buttonText}>
        {menuItem.title}
      </Typography>
      {!isPageAccessibleByPlan && <LockChip />}
    </Button>
  );
};

const MenuItem = ({ page, key }: MenuItemProps) => {
  const theme = useTheme();

  const itemFeatureFlag = 'featureFlag' in page ? page.featureFlag : '';
  const isItemFlagEnabled = useFeatureFlagUnleash(itemFeatureFlag ?? '');

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const history = useHistory();

  const [open, setOpen] = useState(true);

  const hasChildren = Boolean(page.children && page.children.length);

  const classes = useStyles({
    isDesktop: Boolean(isDesktop),
    hasChildren
  });

  const pagePlanFeature = getPlanFeatureByPage(page.href ?? '');
  const isPageFeatureEnabled = useFeaturePlan(pagePlanFeature, { placeholder: true });
  const isPageAccessibleByPlan = pagePlanFeature ? isPageFeatureEnabled : true;

  const emitAnalyticsEvent = (title: string) => {
    const normalizedTitle = title.replaceAll(' ', '_').toLowerCase();
    analyticsEvent(`click_menu_item_${normalizedTitle}`);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const isAbsoluteURL = (url: string) => {
    const regex = /((\w+:\/\/)[-a-zA-Z0-9@:%_+.~#?&//=;\\]+)/g;

    const match = url.match(regex);

    return Boolean(match);
  };

  const currentLocation = window.location.href.replace(window.location.origin, '');

  const itemIsVisible = (url: string) => {
    if (currentLocation === url) return 'inline';

    return 'none';
  };

  const buttonLink = (() => {
    if (isAbsoluteURL(page.href ?? '')) {
      return {
        pathname: page.href
      };
    }

    return page.href;
  })();

  useEffect(() => {
    const pageChildren: Array<typeof page.children[number]> = page.children;

    if (
      hasChildren &&
      pageChildren.filter(children => history?.location?.pathname.startsWith(children.href ?? ''))
    ) {
      setOpen(true);
    }
  }, [page, history, hasChildren]);

  const MenuItemContentBase = (
    <>
      <Typography variant="body2" className={classes.buttonText}>
        <strong>{page.title}</strong>
      </Typography>
      {!isPageAccessibleByPlan && <LockChip />}
    </>
  );

  const MenuItemRoot = (
    <ListItem key={`nav-${page.title}`} disableGutters className={classes.listItem}>
      {hasChildren ? (
        <Button key={key} className={classes.button} onClick={handleClick}>
          {page.icon && <Icon>{page.icon}</Icon>}
          <Typography variant="body2" className={classes.buttonText}>
            <strong>{page.title}</strong>
          </Typography>

          {open ? (
            <ExpandLess className={classes.expandIcon} />
          ) : (
            <ExpandMore className={classes.expandIcon} />
          )}
        </Button>
      ) : (
        <Button
          id={getMenuItemId(page.href ?? '')}
          onClick={() => isPageAccessibleByPlan && emitAnalyticsEvent(page.title)}
          disableRipple={!isPageAccessibleByPlan}
          {...(isPageAccessibleByPlan
            ? {
                target: isAbsoluteURL(page.href ?? '') ? '_blank' : '_self',
                component: NavLink,
                to: buttonLink
              }
            : {})}
          className={clsx(classes.button, !isPageAccessibleByPlan && classes.buttonDisabled)}>
          <Box className={classes.activeIcon} display={itemIsVisible(page.href ?? '')} />
          {page.icon && <Icon>{page.icon}</Icon>}
          {MenuItemContentBase}
        </Button>
      )}
    </ListItem>
  );

  const MenuItemChildren = page.children ? (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="nav" disablePadding>
          {page.children.map(children => (
            <MenuItemChildrenButton
              key={`bb-nav-${children.title}`}
              menuItem={children}
              target={isAbsoluteURL(children.href ?? '') ? '_blank' : '_self'}
              display={itemIsVisible(children.href ?? '')}
            />
          ))}
        </List>
      </Collapse>
    </>
  ) : null;

  if (itemFeatureFlag && !isItemFlagEnabled) return null;

  return (
    <Fragment key={currentLocation}>
      {MenuItemRoot}
      {MenuItemChildren}
    </Fragment>
  );
};

export const SidebarItem = withThemeV4(MenuItem);
