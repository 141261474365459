import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme/v4';

type StylesProps = {
  disabled?: boolean;
};

export default makeStyles<Theme, StylesProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  helperText: {
    marginTop: theme.spacing(0.6),
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: '#898989'
  },
  label: {
    color: props => (props.disabled ? '#898989' : theme.palette.secondary.main)
  }
}));
