import React from 'react';

import Account from './Account';

class AccountView extends React.Component {
  render() {
    return <Account />;
  }
}

export default AccountView;
