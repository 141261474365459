import { MetabaseDashboards } from 'constants/metabaseDashboards';

import { useParams } from 'react-router-dom';

import MetabaseDashboard from 'components/MetabaseDashboard';
import { withThemeV4 } from 'theme/v4';

function MetricsLeadTimeForChangesByApplication() {
  const dashboardName = MetabaseDashboards.LeadTimeForChangesByApplication;

  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const params = {
    application_id: applicationId
  };

  const [, applicationName] = applicationId.split(' - ');

  return (
    <MetabaseDashboard
      title="Lead Time for Changes"
      subtitle={`Lead Time for Changes metrics of application ${applicationName}`}
      dashboardName={dashboardName}
      dashboardParams={params}
      hideParams={['application_id']}
    />
  );
}

export default withThemeV4(MetricsLeadTimeForChangesByApplication);
