import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: theme.spacing(5.5),

    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.elvenGray[200],
      borderStyle: 'solid'
    }
  },
  autocomplete: {
    minHeight: theme.spacing(5.5),
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.elvenGray[200],
      borderStyle: 'solid'
    }
  },
  buttons: {
    minWidth: 115
  },
  customRangeButton: {
    minWidth: 170,
    whiteSpace: 'nowrap'
  },
  badgeContent: {
    backgroundColor: theme.palette.elvenGreen[400],
    minWidth: 16,
    height: 16
  },
  backgroundButton: {
    backgroundColor: '#ECFDF5'
  }
}));
