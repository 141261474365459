import React from 'react';

import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import actions from 'redux/actions';

import RolesList from './RolesList';

class Roles extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { history, settingsHandler } = this.props;
    return <RolesList history={history} settingsHandler={settingsHandler} />;
  }
}

export default connect(null, dispatch => ({
  fetch: () =>
    dispatch({
      type: actions.FETCH_ENTITY,
      meta: {
        entity: 'roles'
      }
    }),
  settingsHandler: ({ id }) => dispatch(push(`/roles/${id}/permissions`))
}))(Roles);
