import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { AlertCenter } from 'views/AlertCenter/index';

export const alertsRoutes = [
  <AuthorizedRoute
    component={AlertCenter}
    exact
    layout={MainLayoutV4}
    path="/alert-center"
    planFeature="IncidentManagement"
  />
];
