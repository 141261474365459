import { ChangeEvent, useState, useEffect, useRef } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  TextField,
  useTheme
} from '@material-ui/core';
import { Add, FilterList, Search } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useApplications } from 'hooks/queriesGraphQL/useApplications';
import { useFullApplications } from 'hooks/queriesGraphQL/useFullApplications';
import useDebounce from 'hooks/useDebounce';
import { usePermission } from 'hooks/usePermission';

import { COLUMNS } from './columns';
import { Filters, PageFilters } from './components/Filters';

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export function ApplicationsList() {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();

  const history = useHistory();

  const [applications, setApplications] = useState<any>([]);
  const [fullApplications, setFullApplications] = useState<any>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | ResourceStatusEnum | MultiFlowStatusEnum>('all');
  const [productId, setProductId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const [{ fetching, data, error }] = useApplications({
    page,
    perPage,
    query: buildElasticQuery({
      search,
      status: status === 'all' ? '' : status,
      product_id: productId === 'all' ? '' : productId
    }),
    ignoreDate: 'true',
    functions: [
      { filter: { term: { status: 'unavailable' } }, weight: 10 },
      { filter: { term: { status: 'error' } }, weight: 10 },
      { filter: { term: { status: 'maintenance' } }, weight: 8 },
      { filter: { term: { status: 'pending' } }, weight: 6 },
      { filter: { term: { status: 'available' } }, weight: 4 },
      { filter: { term: { status: 'success' } }, weight: 4 },
      { filter: { term: { status: 'off' } }, weight: 2 }
    ],
    sortKey: 'updated_at',
    maxBoost: 10,
    scoreMode: 'sum',
    boostMode: 'replace',
    minScore: 1
  });

  const hasPermissionCreateApplication = usePermission('ApplicationController-post-/applications');

  const [{ fetching: fullApplicationsFetching, data: fullApplicationsData }] = useFullApplications({
    fullApplications: applications
  });

  useEffect(() => {
    if (data?.applicationsV2?.data) {
      setApplications(data?.applicationsV2.data);
    }
  }, [data?.applicationsV2.data]);

  useEffect(() => {
    if (fullApplicationsData?.fullApplications) {
      setFullApplications(fullApplicationsData?.fullApplications);
    }
  }, [fullApplicationsData?.fullApplications]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const handleApplyFilters = (filters: PageFilters) => {
    setStatus(filters.status);
    setProductId(filters.productId);
  };

  const handleClearFilters = () => {
    setStatus('all');
    setProductId('all');
  };

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const dataToDysplay = () => {
    if (!applications.length && !fetching) return [];

    if (!fullApplications.length || fullApplicationsFetching) return applications;

    return fullApplications;
  };

  const ApplicationsResult = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    return (
      <DataTable
        columns={COLUMNS}
        data={dataToDysplay()}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
        paginationPerPage={perPage}
        paginationTotalRows={data?.applicationsV2?.total}
        paginationServer
        pagination
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={fetching}
        highlightOnHover
        striped
        pointerOnHover={true}
        onRowClicked={(application, e) => {
          if (e.ctrlKey) {
            return window.open(`applications/${application.uid}`, '_blank');
          }
          history.push(`applications/${application.uid}`);
        }}
      />
    );
  };

  return (
    <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
      <Breadcrumb
        items={[
          { label: 'Monitoring Aggregator' },
          { link: `/external-services`, label: 'Application Center' }
        ]}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2.5}>
        <Typography variant="h4" color="secondary" className={globalClasses.title}>
          Applications
        </Typography>

        <Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            disabled={!hasPermissionCreateApplication}
            href="/services-hub/new?type=application">
            Application
          </Button>
        </Box>
      </Box>

      <Divider className={globalClasses.divider} />

      <Box
        display="flex"
        justifyContent="space-between"
        mb={7.5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchContainerRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Search by name"
          InputProps={{
            endAdornment: <Search />,
            className: globalClasses.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          fullWidth
        />

        <Button
          className={globalClasses.filterButton}
          variant="outlined"
          color="primary"
          onClick={handleToggleFilters}>
          <FilterList />
        </Button>
      </Box>

      {isFiltersOpen && (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      )}

      {ApplicationsResult()}
    </PageContainer>
  );
}
