import React from 'react';

import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SearchInput } from 'components';
import { ButtonSimple } from 'componentsV4/Button';
import actions from 'redux/actions';
import reducer from 'redux/reducer/crud';

import useDebounce from '../ToolbarQueryProps/useDebounce';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  spacer: {
    flexGrow: 1
  },
  buttonBack: {
    background: grey[200]
  }
}));

const CrudToolbar = props => {
  const {
    className,
    buttonNewName,
    searchPlaceholder,
    entity,
    newEntity,
    disableNew,
    noSearch,
    ...rest
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const goBack = payload =>
    dispatch({
      type: actions.GO_BACK,
      payload
    });

  const buttonNewHandler = () => dispatch(push(`/${newEntity || entity}/new`));

  const onChangeHandler = useDebounce(
    event => {
      dispatch({
        type: actions.PAGINATE_ENTITY,
        payload: {
          query: event.target.value.length >= 3 ? event.target.value : ''
        },
        meta: {
          entity,
          reducer
        }
      });
    },
    500 // 500 ms
  );

  const history = useHistory();

  const goBackHandler = event => {
    event.preventDefault();
    goBack(history);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <ButtonSimple
          text="New"
          variant="contained"
          color="primary"
          fullWidth
          onClick={buttonNewHandler}
          style={{ width: 'initial' }}
          disabled={disableNew}
        />

        <ButtonSimple
          onClick={goBackHandler}
          variant="outlined"
          color="primary"
          text="Back"
          style={{
            width: 'initial'
          }}
        />
      </div>
      {!noSearch && (
        <div className={classes.row}>
          <SearchInput onChange={onChangeHandler} placeholder={searchPlaceholder} />
        </div>
      )}
    </div>
  );
};

CrudToolbar.propTypes = {
  className: PropTypes.string
};

export default CrudToolbar;
