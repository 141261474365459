import React, { useState, useEffect } from 'react';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { formatDistanceStrict } from 'date-fns';
import { ForceFinishMaintenance } from 'graphqlQueries/forceFinishMaintenance';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useQuery, useMutation, useSubscription } from 'urql';

import DeleteDialogV2 from 'components/DeleteDialogV2';
import MaintenanceMessage from 'components/MaintenanceMessage';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'componentsV4/Breadcrumb';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { maintenanceMessage } from 'helpers/maintenanceMessage';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import {
  EventsHistory,
  eventHistoryCustomElasticQuery
} from 'views/Applications/View/components/EventsHistory';
import { MaintenanceButton } from 'views/Monitoring/View/components/MaintenanceButton';
import { ScheduledEventButton } from 'views/Monitoring/View/components/ScheduledEventButton';
import { maintenanceDefaultValue } from 'views/Monitoring/View/defaultValue';

import { EventsHistory as OldEventsHistory } from '../../components/EventsHistory';
import { StatusChip } from '../List/components/StatusChip';

import ColumnsSteps from './components/Steps/columns';
import { ToggleMonitoring } from './components/ToggleMonitoring';
import { multiflowDefaultValue } from './defaultValue';
import { useStyles } from './styles';

const GetMultiFlowQuery = `#graphql
  query(
    $multiFlowUid: String!
  ) {
    multiflow(
      multiFlowUid: $multiFlowUid
    ) {
      id
      name
      uid
      status
      environmentId
      environment {
        id
        name
        statusCode
      }
      steps {
        id
        step
        name
        status
        lastCheck
      }
    }
  }
`;

const DeleteSyntheticQuery = `#graphql
  mutation ($uid: String!) {
    deleteMultiflowMonitoring(uid: $uid){
      success
      message
    }
  }
`;

const eventsHistoryQuery = `#graphql
    query (
        $from: Int!
        $size: Int!
        $query: String!
        $aggregationRefs: String
        $aggregationKeys: String!
        $aggregationValues: String
        $termKey: String
        $termValue: [String!]
        $betweenKey: String
        $betweenValues: String
    ) {
        syntheticEvents(
          from: $from
          size: $size
          query: $query
          aggregationRefs: $aggregationRefs
          aggregationKeys: $aggregationKeys
          aggregationValues: $aggregationValues
          termKey: $termKey
          termValue: $termValue
          betweenKey: $betweenKey
          betweenValues: $betweenValues
        ) {
          data {
            id
            incidentId
            eventId
            content {
              name
              createdAt
              description
              version
              severity
              private
            }
            createdAt
            type
            event
            eventType
          }
          total
        }
    }
`;

const PER_PAGE = 10;

export function MultiFlowMonitoring() {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const shouldUseNewEventsHistory = useFeatureFlagUnleash('useNewEventHistorySynthetics');
  const shouldUseMaintenanceSynthetics = useFeatureFlagUnleash('useMaintenanceSynthetics');
  const shouldUseScheduledEventSynthetics = useFeatureFlagUnleash('useScheduledEventSynthetics');

  const [multiflow, setMultiflow] = React.useState(multiflowDefaultValue);
  const [lastCheck, setLastCheck] = React.useState(multiflow.steps[0]?.lastCheck);
  const [maintenance, setMaintenance] = React.useState(maintenanceDefaultValue);

  useEffect(() => {
    setLastCheck(multiflow?.steps[0]?.lastCheck);
  }, [multiflow]);

  const { multiFlowUid } = useParams<{ multiFlowUid: string }>();

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | StatusEnum>('all');
  const [severity, setSeverity] = useState<'all' | SeverityStatusEnum>('all');
  const [betweenValues, setBetweenValues] = useState('now-1y,now');
  const [page, setPage] = useState(1);

  const [resultEventsQuery, reexecuteEventsQuery] = useQuery({
    query: eventsHistoryQuery,
    pause: !multiFlowUid || !shouldUseNewEventsHistory,
    variables: {
      query: eventHistoryCustomElasticQuery({
        queryFilters: [
          {
            key: 'title',
            value: search,
            isRegex: true
          },
          {
            key: 'status',
            value: status,
            isRegex: false
          },
          {
            key: 'severity',
            value: severity,
            isRegex: false
          }
        ]
      }),
      from: Math.max(page - 1, 0) * PER_PAGE,
      size: PER_PAGE,
      aggregationRefs: 'incidents',
      aggregationKeys: 'value_count',
      aggregationValues: 'id',
      termKey: 'origins.uid',
      termValue: multiFlowUid,
      betweenKey: 'created_at',
      betweenValues
    }
  });

  const { fetching: fetchingEvents } = resultEventsQuery;

  const dataFromGraphQL = resultEventsQuery.data?.syntheticEvents || [];
  const events = dataFromGraphQL || [];
  const { data: user } = useUser({});

  const [{ fetching, data }, reexecuteMultiFlowQuery] = useQuery({
    query: GetMultiFlowQuery,
    variables: {
      multiFlowUid
    }
  });

  useSubscription<void>(
    {
      query: `#graphql
        subscription ($multiFlowUid: String!) {
          multiFlowsUpdated (multiFlowUid: $multiFlowUid) {
            status
            uid
            steps {
              id
              step
              status
            }
            __typename
          }
        }
      `,
      variables: { multiFlowUid }
    },
    (current, newData: any) => {
      setMultiflow(prev => {
        const steps = newData?.multiFlowsUpdated?.steps.map((newStepData: any) => {
          if (newStepData.status !== multiflow.steps[newStepData.step]?.status) {
            return {
              ...multiflow.steps[newStepData.step],
              status: newStepData.status
            };
          }

          return multiflow.steps[newStepData.step];
        });
        return {
          ...prev,
          status: newData?.multiFlowsUpdated?.status,
          steps
        };
      });
    }
  );
  useSubscription<void>(
    {
      query: `#graphql
        subscription ($multiFlowUid: String!) {
          multiFlowLastCheckUpdated (multiFlowUid: $multiFlowUid) {
            createdAt
            resourceUid
            __typename
          }
        }
      `,
      variables: { multiFlowUid }
    },
    (current, newData: any) => {
      setLastCheck(new Date(newData?.multiFlowLastCheckUpdated?.createdAt).toISOString());
    }
  );

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const [{ fetching: isDeletingSyntheticMonitoring }, DeleteSyntheticMutation] = useMutation<{
    deleteMultiflowMonitoring: { success: boolean; message: string };
  }>(DeleteSyntheticQuery);

  async function deleteSyntheticMonitoringConfirm() {
    const response = await DeleteSyntheticMutation({ uid: multiFlowUid });

    if (!response.data?.deleteMultiflowMonitoring?.success) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: {
          message: `${response.data?.deleteMultiflowMonitoring?.message ||
            'Could not delete Synthetic Monitoring'}`
        },
        ga: { category: 'ERROR' }
      });
      setOpenDeleteDialog(false);
      return;
    }

    history.push('/synthetic');
  }

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOptionsMenuOpen = Boolean(optionsMenuAnchorEl);

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOptionsMenuAnchorEl(null);
  };

  const [monitoringLoading, setMonitoringLoading] = React.useState(false);

  React.useEffect(() => {
    if (data?.multiflow) {
      setMultiflow(data.multiflow);
      data.multiflow = null;
    }
  }, [multiflow, setMultiflow, data]);

  const monitoringIsActive = React.useCallback(() => {
    if (
      multiflow.status === 'success' ||
      multiflow.status === 'pending' ||
      multiflow.status === 'error'
    ) {
      return true;
    }

    return false;
  }, [multiflow]);

  const previousDayDate = new Date();
  previousDayDate.setDate(previousDayDate.getDate() - 1);

  const canEdit = usePermission('ServicesHubController-put-/services');
  const canDelete = usePermission('MultiFlowController-delete-/delete_multiflow');
  const isAllowedToUseMaintenance = usePermission('MaintenanceController-post-/maintenance');
  const hasPermissionCreateScheduledEvent = usePermission(
    'ScheduledEventController-post-/scheduledEvent'
  );
  const hasPermissionEditScheduledEvent = usePermission(
    'ScheduledEventController-put-/scheduledEvent'
  );

  const maintenanceButtonEnabled =
    (!monitoringIsActive() || !isAllowedToUseMaintenance) && !maintenance?.id;

  const scheduledEventButtonEnabled = !(
    hasPermissionCreateScheduledEvent && hasPermissionEditScheduledEvent
  );

  const inMaintenance = Boolean(
    maintenance?.status === 'Initialized' && multiflow.status === MultiFlowStatusEnum['Maintenance']
  );

  const [, forceFinishMaintenance] = useMutation(ForceFinishMaintenance);

  const onForceFinish = React.useCallback(
    (data: { id: number; start: string; finish: string }) => {
      forceFinishMaintenance({
        maintenanceInput: {
          id: data?.id,
          serviceId: 0,
          applicationId: 0,
          start: data.start,
          finish: data.finish,
          uid: multiFlowUid
        }
      }).then(result => {
        if (!result.error?.graphQLErrors) {
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Finishing maintenance'
          });

          reexecuteMultiFlowQuery();
        }
      });
    },
    [reexecuteMultiFlowQuery, forceFinishMaintenance, dispatch, multiFlowUid]
  );

  const disableMonitoringButton = () => {
    if (inMaintenance || fetching) {
      return true;
    }

    if (multiflow.environment?.statusCode !== 16) {
      return true;
    }

    return false;
  };

  const allowMaintenance = shouldUseMaintenanceSynthetics;
  const allowScheduledEvent = shouldUseScheduledEventSynthetics;

  return (
    <>
      {inMaintenance && (
        <MaintenanceMessage
          maintenance={maintenance}
          message={maintenanceMessage({
            start: maintenance?.start,
            finish: maintenance?.finish,
            entity: 'synthetic'
          })}
          onForceFinish={onForceFinish}
          user={user}
        />
      )}
      <PageContainer className={globalClasses.pageContainer} maxWidth="xl">
        <Backdrop open={monitoringLoading} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>
        <Breadcrumb
          items={[
            { label: 'Monitoring' },
            { label: 'Synthetic Center', link: '/synthetic' },
            { label: multiflow.name }
          ]}
        />

        <Box display="flex" mt={6} alignItems="center">
          <Typography variant="h4" color="secondary" className={globalClasses.title}>
            {multiflow.name}
          </Typography>

          <Box ml="auto">
            {allowMaintenance && (
              <MaintenanceButton
                name={multiflow.name}
                uid={multiFlowUid}
                entity={'multiflow'}
                serviceId={0}
                maintenance={maintenance}
                setMaintenance={setMaintenance}
                reexecuteEntityQuery={reexecuteMultiFlowQuery}
                disabled={maintenanceButtonEnabled}
              />
            )}

            {allowScheduledEvent && (
              <ScheduledEventButton
                uid={multiFlowUid}
                entity={'multiflow'}
                serviceId={0}
                disabled={scheduledEventButtonEnabled}
              />
            )}

            <Tooltip
              title="More Options"
              arrow
              classes={{
                tooltip: classes.tooltipOptions,
                arrow: classes.tooltipOptionsArrow
              }}>
              <Button className={classes.iconOptions} variant="outlined" onClick={handleToggleMenu}>
                <MoreVert />
              </Button>
            </Tooltip>
          </Box>

          <Menu
            id="simple-menu"
            open={isOptionsMenuOpen}
            elevation={1}
            anchorEl={optionsMenuAnchorEl}
            getContentAnchorEl={null}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            classes={{ paper: classes.menu }}>
            <MenuItem className={classes.menuItem} disabled={!canEdit}>
              <Link component={RouterLink} to={`/services-hub/edit/${multiFlowUid}?type=synthetic`}>
                Edit Synthetic Monitoring
              </Link>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={handleDeleteDialog}
              disabled={!canDelete}>
              Delete Synthetic Monitoring
            </MenuItem>
            <DeleteDialogV2
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              onPrimaryAction={deleteSyntheticMonitoringConfirm}
              message=""
              isLoading={isDeletingSyntheticMonitoring}
              title="Delete Synthetic Monitoring?"
              primaryActionLabel="Delete Synthetic Monitoring"
            />
          </Menu>
        </Box>
        <Box display="flex" mt={5} alignItems="center" className={classes.topBox}>
          <StatusChip status={multiflow.status} />

          <Box display="flex" ml={2} alignItems="center">
            <ToggleMonitoring
              multiflowName={multiflow.name}
              inMaintenance={inMaintenance}
              disabled={disableMonitoringButton()}
              multiflowUid={multiFlowUid}
              monitoringIsActive={monitoringIsActive()}
              reexecuteMultiflowQuery={reexecuteMultiFlowQuery}
              setMonitoringLoading={setMonitoringLoading}
            />
          </Box>

          <Box display="flex" alignItems="center" className={classes.topBoxText}>
            <Typography variant="body1" className={classes.info}>
              <strong>Checkpoint: </strong>
              Agent - {multiflow.environment?.name}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" className={classes.topBoxText}>
            <Typography variant="body1" className={classes.info}>
              <strong>Last Check:</strong>{' '}
              {lastCheck && new Date(lastCheck).getTime() > previousDayDate.getTime()
                ? formatDistanceStrict(new Date(lastCheck), new Date())
                : 'No data'}
            </Typography>
          </Box>
        </Box>

        <Box mt={5}>
          <Typography variant="h4" color="secondary" className={classes.multiflowSteps}>
            Synthetic Steps
          </Typography>

          <DataTable
            striped
            columns={ColumnsSteps}
            noHeader
            data={multiflow.steps}
            noDataComponent="No data"
            highlightOnHover={true}
          />
        </Box>

        {shouldUseNewEventsHistory ? (
          <Box mt={6}>
            <EventsHistory
              page={page}
              setPage={setPage}
              setSearch={setSearch}
              setStatus={setStatus}
              setSeverity={setSeverity}
              setBetweenValues={setBetweenValues}
              fetching={fetchingEvents}
              reexecuteQuery={reexecuteEventsQuery}
              events={events}
              hideType={true}
            />
          </Box>
        ) : (
          <Box mt={7}>
            <OldEventsHistory entity="multiflow" uid={multiFlowUid} />
          </Box>
        )}
      </PageContainer>
    </>
  );
}
