import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  toggle: {
    margin: 0
  },
  info: {
    whiteSpace: 'nowrap'
  },
  topBox: {
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
    border: '1px solid #D5D5D5',
    padding: theme.spacing(2)
  },
  multiflowSteps: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(4)
  },
  tooltipOptions: {
    backgroundColor: theme.palette.elvenGray[700],
    marginBottom: theme.spacing(3),
    top: theme.spacing(-1)
  },
  tooltipOptionsArrow: {
    color: theme.palette.elvenGray[700]
  },
  iconOptions: {
    marginLeft: theme.spacing(2),
    minWidth: 56,
    height: 42,
    border: '1px solid #595959',
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.elvenGray[100]
    }
  },
  menu: {
    width: '290px'
  },
  menuItem: {
    '& a': {
      color: 'inherit',
      display: 'block',
      width: '100%'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.elvenGreen[50],
      color: theme.palette.elvenGreen[400]
    }
  },
  topBoxText: {
    marginLeft: theme.spacing(10),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(4)
    }
  },
  deleteDialogText: {
    fontSize: theme.typography.pxToRem(16)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  }
}));
