import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud/';

import schema from '../components/schema';

import MenuItemsNewForm from './MenuItemsNewForm';

class MenuItemsNew extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { menuItems_new: formState, menuItems, onChange } = this.props;

    return <MenuItemsNewForm formState={formState} menuItems={menuItems} onChange={onChange} />;
  }
}

export default connect(
  ({ menuItems_new, menuItems: { data: menuItems } }) => ({
    menuItems_new,
    menuItems
  }),
  dispatch => ({
    fetch: () =>
      dispatch({
        type: actions.FETCH_ENTITY,
        meta: {
          entity: 'menuItems',
          pagination: false
        }
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'menuItems_new'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'menuItems_new',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(MenuItemsNew);
