import MainLayoutV4 from 'layouts/V4/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import RouteWithLayoutV2 from 'routes/components/RouteWithLayoutV2';
import { ExternalIntegrationsList } from 'views/ExternalIntegrations/List';
import IntegrationsNew from 'views/Integrations/New';

export const integrationsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={IntegrationsNew}
    exact
    layout={MainLayoutV4}
    key="/integrations/new/:type"
    path="/integrations/new/:type"
    planFeature="Metrics.DORA"
    featureTarget="LeadTimeChangesController-post-/integration"
  />,
  <RouteWithLayoutV2
    component={ExternalIntegrationsList}
    exact
    layout={MainLayoutV4}
    key="/external-services"
    path="/external-services"
  />
];
