import { ButtonProps as ButtonPropsMUI, CircularProgress } from '@material-ui/core';

import withThemeV4 from 'theme/v4/withTheme';

import Button from './Button';

interface ButtonProps extends ButtonPropsMUI {
  text?: string;
  isLoading?: boolean;
}

export const TextButton = ({
  text,
  onClick = () => {},
  type = 'submit',
  isLoading = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  return (
    <Button type={type} onClick={onClick} disabled={disabled || isLoading} {...props}>
      {isLoading ? <CircularProgress color="primary" size={32} /> : text}
    </Button>
  );
};

export default withThemeV4(TextButton);
