/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';

import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { zodResolver } from '@hookform/resolvers/zod';
import { Card, CardContent, colors, Box, Grid, MenuItem } from '@material-ui/core';
import { lime } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { TextField, EnvironmentSelect, Select } from 'components/Inputs';
import JsonFunctionsUpload from 'components/JsonFunctionsUpload';
import JsonPubsubUpload from 'components/JsonPubsubUpload';
import { DialogActions } from 'components/LegacyDialog';
import Locations from 'components/Locations';
import FailuresToIncident from 'components/MonitoringInputs/FailuresToIncident';
import InputsGroup from 'components/MonitoringInputs/InputsGroup';
import IntervalField from 'components/MonitoringInputs/IntervalField';
import OpenIncidentField from 'components/MonitoringInputs/OpenIncidentField';
import ValidationStringField from 'components/MonitoringInputs/ValidationStringField';
import HealthcheckField from 'components/ResourceInputs/HealthcheckField';
import HTTPFields from 'components/ResourceInputs/HTTPFields';
import Methods from 'components/ResourceInputs/Methods';
import SkipSSLValidation from 'components/ResourceInputs/SkipSSLValidation';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { ButtonSimple } from 'componentsV4/Button';
import getObjectList, { objectToKeyValueList } from 'helpers/getObjectList';
import safeParseJSON from 'helpers/safeParseJSON';
import useNewAddons from 'hooks/queriesGraphQL/useNewAddons';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import axios from 'redux/axios';
import { Theme } from 'theme/v4';
import { FunctionsForm } from 'views/Services/components/Form/Functions/Form';
import { functionsStartValues } from 'views/Services/components/Form/Functions/startValues';
import { KafkaForm } from 'views/Services/components/Form/Kafka/Form';
import { kafkaStartValues } from 'views/Services/components/Form/Kafka/startValues';
import { LambdaForm } from 'views/Services/components/Form/LambdaV2/Form';
import { lambdaStartValues } from 'views/Services/components/Form/LambdaV2/startValues';
import { RedisForm } from 'views/Services/components/Form/RedisV2/Form';
import { redisStartValues } from 'views/Services/components/Form/RedisV2/startValues';
import { ServiceBusForm } from 'views/Services/components/Form/ServiceBus/Form';
import { serviceBusStartValues } from 'views/Services/components/Form/ServiceBus/startValues';

import SaveDialog from '../../FormRow/components/Dialog';
import schema from '../../FormRow/schema';
import useSaveNewOrUpdateAddon from '../../FormRow/useSaveNewOrUpdateAddon';

import defaultValues from './defaultValues';
import { mapToApi } from './mapToApi';

const useStyles = makeStyles((theme: Theme) => ({
  highlight: {
    border: `10px solid ${lime[200]}`
  },
  remove: {
    marginLeft: theme.spacing(2),
    backgroundColor: colors.red[700],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  environmentSelect: {
    marginTop: theme.spacing(1)
  }
}));

const EditAddonForm = ({
  data,
  handleClose,
  productId
}: {
  data: any;
  handleClose: () => void;
  productId: number;
}) => {
  const useSeverityOnApplication = useFeatureFlagUnleash('chooseSeverityOnApplication', {
    queryString: true
  });

  const { reexecuteQuery: refetchNewDependencies } = useNewAddons({
    applicationId: data.applicationId
  });

  const classes = useStyles();

  const [saveModal, setSaveModal] = useState(false);

  const handleToggleSaveModal = React.useCallback(() => {
    setSaveModal(!saveModal);
  }, [saveModal, setSaveModal]);

  /**
   * ! global variables to control the form
   */
  const startValues = data?.addonSettings;
  const service = data?.service;
  const isService = data.applicationDependency?.id ? false : true;

  /**
   *  ! useForm startValues
   */
  const redisStartVals = redisStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const functionsStartVals = functionsStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const lambdaStartVals = lambdaStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const kafkaStartVals = kafkaStartValues({
    method: startValues.method,
    domain_settings: startValues?.domain_settings
  });

  const serviceBusStartVals = serviceBusStartValues({
    method: startValues.method,
    domain_settings: startValues.domain_settings
  });

  const tlsRenegotiation =
    typeof startValues.domain_settings === 'string'
      ? safeParseJSON(startValues?.domain_settings)?.tls_renegotiation
      : startValues?.domain_settings?.tls_renegotiation
      ? startValues?.domain_settings?.tls_renegotiation
      : 0;

  const defaultFormValues = startValues
    ? {
        ...defaultValues,
        ...startValues,
        headers: (headers => {
          if (!headers) {
            return [{ key: '', value: '' }];
          }

          if (typeof headers === 'string') {
            return getObjectList(headers);
          }

          return objectToKeyValueList(headers);
        })(startValues.headers),
        post_body_type:
          startValues.post_body_type === postBodyTypeValues.urlencoded
            ? startValues.post_body_type
            : postBodyTypeValues.raw,
        post_body_raw_type:
          startValues.post_body_type === postBodyTypeValues.urlencoded ||
          !startValues.post_body_type
            ? postBodyRawTypeValues.json
            : startValues.post_body_type,
        post_body_raw: startValues.post_body || '',
        post_body_urlencoded: (({ postBody }) => {
          if (startValues.post_body_type !== postBodyTypeValues.urlencoded || !postBody) {
            return [{ key: '', value: '' }];
          }

          if (typeof postBody === 'string') {
            return getObjectList(postBody);
          }

          return objectToKeyValueList(postBody);
        })({ postBody: startValues.post_body, postBodyType: startValues.post_body_type }),
        busy_size: (() => {
          if (typeof startValues.domain_settings === 'string') {
            return safeParseJSON(startValues.domain_settings).busy_size;
          }
          if (startValues.method === 'sidekiq') {
            return startValues?.domain_settings.busy_size;
          }
          return null;
        })(),
        queue_size: startValues.queue_size,
        connected_clients: (() => {
          if (typeof startValues.domain_settings === 'string') {
            return safeParseJSON(startValues.domain_settings).connected_clients;
          }
          if (startValues.method === 'sidekiq') {
            return startValues?.domain_settings?.connected_clients;
          }
          return null;
        })(),
        scheduled_size: (() => {
          if (typeof startValues.domain_settings === 'string') {
            return safeParseJSON(startValues.domain_settings).scheduled_size;
          }
          if (startValues.method === 'sidekiq') {
            return startValues?.domain_settings.scheduled_size;
          }
          return null;
        })(),
        json_functions_body_raw: (() => {
          if (typeof startValues.domain_settings === 'string') {
            return safeParseJSON(startValues.domain_settings).credentials;
          }
          if (startValues.method === 'functions' || startValues.method === 'functionsgen2') {
            return startValues.domain_settings?.credentials;
          }
          return null;
        })(),
        json_pubsub_body_raw: (() => {
          if (startValues.method === 'pubsub') {
            return startValues.domain_settings;
          }
          return null;
        })(),
        check_interval: startValues.check_interval,
        failuresToIncident: startValues.failuresToIncident,
        openIncidentWithSeverity: startValues?.openIncidentWithSeverity || 'not-classified',
        tls_renegotiation: tlsRenegotiation,
        timeout: startValues.timeout,
        ...lambdaStartVals,
        ...redisStartVals,
        ...kafkaStartVals,
        ...functionsStartVals,
        ...serviceBusStartVals
      }
    : defaultValues;

  /**
   *  ! useForm Control
   */
  const {
    control,
    setValue,
    handleSubmit,
    register,
    getValues,
    errors,
    watch,
    formState: { isValid, isDirty },
    reset
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  /**
   *  ! variables to control fields on form
   */

  const selectedMethod = watch('method');
  const selectedCheckType = isService ? service.check_type : 'http';
  const selectedPostBodyType = watch('post_body_type');
  const canEdit = !data.multiFlow;

  const showSidekiq = selectedCheckType === 'queue' && selectedMethod === 'sidekiq';

  const showQueueSize = selectedCheckType === 'queue' && selectedMethod === 'sqs';

  const showPubsub = selectedCheckType === 'queue' && selectedMethod === 'pubsub';

  const showWebhook = selectedCheckType === 'others' && selectedMethod === 'webhook';

  const showRedisV2 = selectedCheckType === 'cache' && selectedMethod === 'redisV2';

  const showLambdaV2 = selectedCheckType === 'lambda' && selectedMethod === 'lambdaawsv2';

  const showFunctions = selectedCheckType === 'lambda' && selectedMethod.startsWith('functions');

  const showKafkaAdvancedFields =
    selectedCheckType === 'queue' && selectedMethod === 'kafka_advanced';

  const showServiceBus = selectedCheckType === 'queue' && selectedMethod === 'servicebus';

  const showHealthcheckField = !showWebhook && !showKafkaAdvancedFields && !showServiceBus;

  const {
    fields: kafkaBrokersFields,
    append: kafkaBrokersAppend,
    remove: kafkaBrokersRemove
  } = useFieldArray({
    control,
    name: 'brokers'
  });

  const { fields: headersFields, append: headersAppend, remove: headersRemove } = useFieldArray({
    control,
    name: 'headers'
  });

  const {
    fields: postBodyXFormUrlEncodedFields,
    append: postBodyXFormUrlEncodedAppend,
    remove: postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'post_body_urlencoded'
  });

  const addonId = data.id;
  const applicationId = data.applicationId;

  const { saveNewAddon, updateAddon, isLoading } = useSaveNewOrUpdateAddon({
    productId: String(productId),
    applicationId,
    addonId,
    onUpdateAddon: refetchNewDependencies
  });

  const { data: sameDependencies } = useQuery(
    ['servicesDependencies'],
    () =>
      axios
        .get(
          `services/${data?.serviceId}/dependencies/environment/${data?.addonSettings?.environmentId}`
        )
        .then(response => response.data.data),
    {
      enabled: isDirty
    }
  );

  const handleSave = (save: any) => (formData: any) => {
    if (isLoading) return;

    const formatedPayload = mapToApi(formData, startValues);

    if (formData.method === 'GET')
      return save({
        ...data,
        ...{ addonSettings: { ...formatedPayload, domain: formatedPayload.domain.trim() } }
      }).then(() => {
        reset(formData);
        handleClose();
      });

    save({
      ...data,
      ...{
        addonSettings: {
          ...formatedPayload,
          domain: formatedPayload.method !== 'webhook' ? formatedPayload.domain?.trim() : null,
          timeout:
            formatedPayload.method !== 'webhook'
              ? formatedPayload.timeout
              : formatedPayload.check_interval
        }
      }
    }).then(() => {
      reset(formData);
      handleClose();
    });
  };

  return (
    <Box marginBottom={1} clone>
      <Card elevation={0} style={{ position: 'relative', overflow: 'initial' }}>
        <form name="DependenciesEditForm" onSubmit={handleSubmit(handleSave(updateAddon))}>
          <CardContent>
            {showHealthcheckField && (
              <Grid container spacing={1}>
                <Grid item md={showFunctions ? 9 : 12} xs={12}>
                  <Box display="flex">
                    <Box flex="1">
                      <HealthcheckField
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        getValues={getValues}
                        disabled={!canEdit || !isService}
                        selectedMethod={selectedMethod}
                      />
                    </Box>
                    <Grid
                      style={{ marginTop: '6px' }}
                      md={selectedCheckType === 'http' && showHealthcheckField && 2}>
                      {selectedCheckType === 'http' && (
                        <SkipSSLValidation
                          register={register}
                          defaultChecked={startValues?.skip_ssl_validation}
                          disabled={!canEdit || !isService}
                        />
                      )}
                    </Grid>
                    <Grid md={selectedCheckType === 'http' && showHealthcheckField && 2}>
                      {selectedCheckType === 'http' && (
                        <Select
                          errors={errors}
                          name="tls_renegotiation"
                          control={control}
                          label="TLS Renegotiation"
                          disabled={!canEdit || !isService}
                          required={false}>
                          <MenuItem value={0}>Never</MenuItem>
                          <MenuItem value={1}>One Time</MenuItem>
                          <MenuItem value={2}>Always</MenuItem>
                        </Select>
                      )}
                    </Grid>
                  </Box>
                </Grid>

                <Grid item md={showFunctions && 3} xs={12}>
                  {showFunctions && (
                    <Locations control={control} disabled={!canEdit || !isService} errors={null} />
                  )}
                </Grid>
              </Grid>
            )}

            <Box display="flex">
              <Box flex="1">
                <EnvironmentSelect
                  className={classes.environmentSelect}
                  errors={errors}
                  control={control}
                  disabled={!canEdit || !isService}
                />
              </Box>
            </Box>

            <Box display="flex" my={1}>
              <Box flexBasis="auto" mr={1}>
                <Methods
                  control={control}
                  selectedCheckType={selectedCheckType}
                  disabled={!canEdit || !isService}
                  errors={errors}
                />
              </Box>
              {(showQueueSize || showSidekiq) && (
                <Box flexBasis="10%" mr={1}>
                  <TextField
                    register={register}
                    required={false}
                    label="Queue size"
                    errors={errors}
                    name="queue_size"
                    disabled={!canEdit || !isService}
                    type="number"
                  />
                </Box>
              )}
              {!showPubsub && !showWebhook && selectedCheckType === 'http' && (
                <Box flexBasis="25%" marginRight={3}>
                  <ValidationStringField
                    register={register}
                    disabled={!canEdit || !isService}
                    required={showFunctions}
                    className={undefined}
                  />
                </Box>
              )}
              <Box flexBasis="auto">
                {!showWebhook ? (
                  <InputsGroup
                    register={register}
                    disabled={!canEdit || !isService}
                    errors={errors}
                    control={control}
                  />
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <IntervalField
                        register={register}
                        errors={errors}
                        name="check_interval"
                        disabled={!canEdit || !isService}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FailuresToIncident
                        register={register}
                        errors={errors}
                        disabled={!canEdit || !isService}
                      />
                    </Grid>
                    {useSeverityOnApplication && (
                      <Grid item xs={4}>
                        <OpenIncidentField
                          register={register}
                          control={control}
                          disabled={!canEdit || !isService}
                          errors={errors}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            </Box>

            {showSidekiq && (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    register={register}
                    label="Busy Size"
                    errors={errors}
                    required={false}
                    name="busy_size"
                    disabled={!canEdit || !isService}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    required={false}
                    register={register}
                    errors={errors}
                    label="Scheduled Size"
                    name="scheduled_size"
                    disabled={!canEdit || !isService}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    register={register}
                    label="Connected Clients"
                    required={false}
                    disabled={!canEdit || !isService}
                    errors={errors}
                    type="number"
                    name="connected_clients"
                  />
                </Grid>
              </Grid>
            )}

            {showKafkaAdvancedFields && (
              <KafkaForm
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
                register={register}
                startValues={startValues?.domain_settings || {}}
                kafkaBrokersFields={kafkaBrokersFields}
                kafkaBrokersAppend={kafkaBrokersAppend}
                kafkaBrokersRemove={kafkaBrokersRemove}
                disabled={!canEdit || !isService}
              />
            )}

            {showPubsub && (
              <Box flexBasis="35%" marginRight={1}>
                <JsonPubsubUpload
                  register={register}
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  selectedMethod={selectedMethod}
                  disabled={!canEdit || !isService}
                />
              </Box>
            )}

            {showRedisV2 && (
              <RedisForm
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                startValues={redisStartVals}
                disabled={!canEdit || !isService}
              />
            )}

            {showLambdaV2 && (
              <LambdaForm
                errors={errors}
                register={register}
                startValues={lambdaStartVals}
                disabled={!canEdit || !isService}
              />
            )}

            {showFunctions && (
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <JsonFunctionsUpload
                    register={register}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    selectedMethod={selectedMethod}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FunctionsForm
                    errors={errors}
                    register={register}
                    startValues={functionsStartVals}
                    disabled={!canEdit || !isService}
                  />
                </Grid>
              </Grid>
            )}

            {showServiceBus && (
              <ServiceBusForm
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                disabled={!canEdit || !isService}
                startValues={serviceBusStartValues}
              />
            )}

            <HTTPFields
              selectedCheckType={selectedCheckType}
              selectedMethod={selectedMethod}
              defaultValues={defaultValues}
              register={register}
              headersAppend={headersAppend}
              headersFields={headersFields}
              control={control}
              headersRemove={headersRemove}
              watch={watch}
              setValue={setValue}
              errors={errors}
              selectedPostBodyType={selectedPostBodyType}
              postBodyXFormUrlEncodedFields={postBodyXFormUrlEncodedFields}
              postBodyXFormUrlEncodedRemove={postBodyXFormUrlEncodedRemove}
              postBodyXFormUrlEncodedAppend={postBodyXFormUrlEncodedAppend}
              disabled={!canEdit || !isService}
            />
          </CardContent>
          <DialogActions>
            <ButtonSimple onClick={handleClose} text="Cancel" variant="outlined" color="primary" />
            <ButtonSimple
              color="primary"
              variant="contained"
              type={sameDependencies?.length === 1 ? 'submit' : 'button'}
              text="Save"
              disabled={!isValid || !isDirty}
              onClick={() => {
                if (sameDependencies?.length !== 1) setSaveModal(true);
              }}
            />
          </DialogActions>
        </form>

        <SaveDialog
          isOpen={saveModal}
          handleOpenDialog={handleToggleSaveModal}
          dependencies={sameDependencies}
          saveNewAddon={handleSubmit(handleSave(saveNewAddon))}
          updateAddon={handleSubmit(handleSave(updateAddon))}
        />

        {isLoading && <LoadingOverlay isRelative />}
      </Card>
    </Box>
  );
};

export default EditAddonForm;
