import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { format } from 'date-fns';
import { EventsHistoryType, EventType } from 'types/external/EventsHistory';
import { useQuery } from 'urql';

import { PrimaryChip } from 'components/EventsHistory/components/PrimaryChip';
import { SecondaryChip } from 'components/EventsHistory/components/SecondaryChip';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { organizeEventsHistoryList } from 'helpers/organizeEventsHistoryList';

import { useStyles } from './styles';

const eventsHistoryQuery = `#graphql
query (
  $index: String!
  $from: Int!
  $size: Int!
  $gte: Int!
  $query: String!
) {
  getTimeline(
    index: $index
    from: $from
    gte: $gte
    size: $size
    query: $query
  ) {
    data {
      id
      applicationId
      applicationName
      addonId
      content {
        name
        version
        createdAt
        description
        severity
      }
      incidentId
      type
      originEntity
      originUid
      createdAt
    }
  }
}
`;

export const EventsHistory = ({ entity, uid }: { entity: string; uid: string }) => {
  const index = process.env.REACT_APP_ES_EVENTS_INDEX;

  const classes = useStyles();

  const renderNextEvent = (array: EventType[], item: EventType) => {
    const index = array.indexOf(item);

    const nextIncidentId = array[index + 1]?.incidentId;

    if (!nextIncidentId) {
      return;
    }

    if (nextIncidentId && nextIncidentId === item.incidentId) {
      return (
        <Box display="flex" ml={0.6} mt={1} mb={1}>
          <span className={classes.timelineConnector}></span>
        </Box>
      );
    }

    return <Box mb={8}></Box>;
  };

  const [resultTimelineQuery, reexecuteQuery] = useQuery({
    query: eventsHistoryQuery,
    pause: !index || !uid,
    variables: {
      index,
      query: buildElasticQuery({
        search: '',
        origin_entity: entity,
        origin_uid: uid
      }),
      from: 0,
      size: 500,
      gte: 5
    }
  });

  const { fetching } = resultTimelineQuery;

  React.useEffect(() => {
    if (fetching) return;
    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  const dataFromGraphQL = resultTimelineQuery.data?.getTimeline?.data || [];

  const eventsHistoryList = dataFromGraphQL.length
    ? organizeEventsHistoryList({ eventsHistoryList: dataFromGraphQL, showLastDays: true })
    : [];

  return (
    <Box>
      <Typography variant="h3" color="secondary" className={classes.eventsHistoryTitle}>
        Events History
      </Typography>

      {fetching && !eventsHistoryList.length && (
        <Box display="flex" mt={4} alignItems="center" justifyContent="center" pb={4}>
          <CircularProgress color="primary" size={50} thickness={4} />
        </Box>
      )}

      {!eventsHistoryList.length && !fetching ? (
        <Typography variant="h3" className={classes.noDataText}>
          No data
        </Typography>
      ) : (
        <Box mt={5}>
          {eventsHistoryList.map((dayEvents: EventsHistoryType) => (
            <Box mb={2}>
              <Accordion
                disabled={Boolean(!dayEvents?.events.length)}
                className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary}>
                  <Box pt={2} pb={2} minHeight={64} display="flex" alignItems="center">
                    <Box>
                      <Typography variant="h3" className={classes.eventsDate}>
                        {dayEvents?.date}
                      </Typography>

                      {!dayEvents?.events.length && (
                        <Typography variant="body2" className={classes.noIncidentsMessage}>
                          No incidents reported
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>

                <Divider className={classes.divider} />

                <AccordionDetails className={classes.accordionDetails}>
                  <Box mt={1}>
                    {dayEvents?.events.map((event: EventType) => (
                      <React.Fragment key={event?.id}>
                        <Box display="flex" mt={1} alignItems="center">
                          <span className={classes.ellipse}></span>
                          <PrimaryChip
                            event={event}
                            style={{ height: '24px', marginRight: '8px' }}
                          />
                          <SecondaryChip
                            event={event}
                            style={{ height: '24px', marginRight: '8px' }}
                          />

                          <span className={classes.descriptionEllipse}></span>
                          <Typography variant="body1" className={classes.eventDescription}>
                            {event?.content?.name}
                          </Typography>
                          <span className={classes.descriptionEllipse}></span>
                          <Typography variant="body1" className={classes.eventDate}>
                            {format(new Date(event?.createdAt), 'MMM d, H:mm O')}
                          </Typography>
                        </Box>

                        {renderNextEvent(dayEvents?.events, event)}
                      </React.Fragment>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
