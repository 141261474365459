import React from 'react';

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';

const Form = ({ formState, features, permissions, save, remove }) => {
  const handleSave = featureId => save({ roleId: formState.id, featureId });

  const handleRemove = permissionId => remove({ roleId: formState.id, id: permissionId });

  return (
    <Grid container spacing={3}>
      {features.data.map((feature, index) => {
        const permission = permissions.data.find(p => p.featureId === feature.id);
        return (
          <Grid item key={`feature_${index}`} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(permission)}
                  value={feature.id}
                  onChange={() =>
                    permission ? handleRemove(permission.id) : handleSave(feature.id)
                  }
                  name={feature.target}
                />
              }
              label={
                <>
                  <small>
                    {feature.requiresDependent ? 'Team Permissions' : 'Organization Permissions'}
                  </small>
                  <br />
                  <b>{feature.name}</b>
                  <br />
                  <small>{feature.description}</small>
                  <br />
                </>
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Form;
