import {
  Rule,
  RuleAction,
  RuleActionType,
  RuleOriginEntityType,
  RuleType,
  UpdateIncidentRulePayload
} from 'types/external/AlertCenter';

import { IncidentFormData } from 'views/ServicesHub/forms/monitoring/commom/Incident';

export function incidentsRulesAdapter(
  data: IncidentFormData & { entityUid: string; entity: RuleOriginEntityType }
) {
  const { incident } = data;

  const teams = incident.teams.map(team => {
    return { teamId: Number(team.value) };
  });

  return {
    title: '?',
    description: '?',
    severity: incident.severity,
    type: RuleType.Incident,
    actions: [
      {
        action: RuleAction.Close,
        type: RuleActionType.Hits,
        operation: '>=',
        occurrence: Number(incident.hitsToCloseIncident),
        interval: Number(incident.intervalHits)
      },
      {
        action: RuleAction.Open,
        type: RuleActionType.Failures,
        operation: '>=',
        occurrence: Number(incident.failuresToOpenIncident),
        interval: Number(incident.intervalFailures)
      }
    ],
    teams,
    origins: [
      {
        entity: data.entity,
        entityUid: data.entityUid,
        serviceId: 0
      }
    ]
  };
}

export function updateIncidentRuleAdapter(data: {
  incidentRule: Rule;
  incident: IncidentFormData['incident'];
}): UpdateIncidentRulePayload {
  const { incident, incidentRule } = data;

  const closeActionId = incidentRule.actions.find(action => action.type === RuleActionType.Hits)
    ?.id!;
  const openActionId = incidentRule.actions.find(action => action.type === RuleActionType.Failures)
    ?.id!;

  const teamIds = incident.teams.map(team => {
    return Number(team.value);
  });

  return {
    ruleUid: incidentRule.uid,
    teamIds,
    severity: incident.severity,
    actions: [
      {
        id: closeActionId,
        action: RuleAction.Close,
        type: RuleActionType.Hits,
        operation: '>=',
        occurrence: Number(incident.hitsToCloseIncident),
        interval: Number(incident.intervalHits)
      },
      {
        id: openActionId,
        action: RuleAction.Open,
        type: RuleActionType.Failures,
        operation: '>=',
        occurrence: Number(incident.failuresToOpenIncident),
        interval: Number(incident.intervalFailures)
      }
    ]
  };
}
