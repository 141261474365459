import { Box, List, makeStyles, useTheme } from '@material-ui/core';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useLoggedUserRole } from 'hooks/useLoggedUserRole';
import { SidebarItem } from 'layouts/V4/components/Sidebar/components/SidebarItem';
import { Theme, withThemeV4 } from 'theme/v4';

import { useMenuItems } from './useMenuItems';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column'
  }
}));
const SidebarList = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { menuItems, isLoading } = useMenuItems();

  const [result] = useLoggedUserRole({ fromCache: true });

  const role = result.data?.role.name;

  const menuItemsFilteredByRole = role
    ? menuItems.filter(item => item.allowedRoles.includes(role))
    : [];

  const sidebarItems = menuItemsFilteredByRole?.filter(item => !item.isStatic);

  const staticSidebarItems = menuItemsFilteredByRole?.filter(item => item.isStatic);

  return (
    <List component="nav" disablePadding className={classes.root}>
      {isLoading && <LoadingOverlay />}
      <Box>
        {sidebarItems?.map(page => {
          return <SidebarItem page={page} key={`bb-nav-${page.title}`} />;
        })}
      </Box>

      <Box display="flex" height="100%">
        <Box alignSelf="flex-end" position="sticky" bottom={theme.spacing(2)}>
          {staticSidebarItems?.map(page => {
            return <SidebarItem page={page} key={`bb-nav-${page.title}`} />;
          })}
        </Box>
      </Box>
    </List>
  );
};

export default withThemeV4(SidebarList);
