import React from 'react';

import { Grid, TextField, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  checkBoxDesabled: {
    color: theme.palette.grey[800]
  }
}));
const Form = ({ formState, onChange }) => {
  const classes = useStyles();

  const hasError = field => (formState.touched[field] && formState.errors[field] ? true : false);

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  const handleCheckboxChange = event => {
    event.persist();
    const isTrue = [true, 'true'].includes(event.target.value);
    onChange(event.target.name, isTrue);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          margin="dense"
          name="name"
          onChange={handleChange}
          required
          value={formState.values.name || ''}
          error={hasError('name')}
          helperText={hasError('name') ? formState.errors.name[0] : null}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Target"
          margin="dense"
          name="target"
          onChange={handleChange}
          required
          value={formState.values.target || ''}
          error={hasError('target')}
          helperText={hasError('target') ? formState.errors.target[0] : null}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="requiresDependent"
              value={!formState.values.requiresDependent}
              checked={Boolean(formState.values.requiresDependent)}
              onChange={handleCheckboxChange}
              className={classes.checkBoxDesabled}
            />
          }
          label="Requires dependent"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          fullWidth
          rows={3}
          label="Description"
          margin="dense"
          name="description"
          onChange={handleChange}
          value={formState.values.description || ''}
          error={hasError('description')}
          helperText={hasError('description') ? formState.errors.description[0] : null}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Form;
