import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  incidentTitle: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '123.5%',
    fontWeight: theme.typography.fontWeightRegular,
    color: '#515151'
  },
  incidentCauseTitle: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.elvenGray[700]
  },
  incidentCause: {
    margin: theme.spacing(2, 0, 2),
    color: theme.palette.elvenGray[500]
  },
  incidentDateTitle: {
    color: theme.palette.elvenGray[700]
  },
  incidentDate: {
    color: theme.palette.elvenGray[500]
  },
  incidentIdTitle: {
    color: theme.palette.elvenGray[700]
  },
  incidentIdLink: {
    color: theme.palette.elvenGray[500],
    fontSize: 14,
    fontWeight: 400
  },
  ellipse: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.elvenGray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  divider: {
    backgroundColor: theme.palette.elvenGray[100],
    margin: theme.spacing(2, 0, 6)
  },
  incidentDivider: {
    backgroundColor: theme.palette.elvenGray[100],
    margin: theme.spacing(3, 0, 3)
  },
  input: {
    height: theme.spacing(5.5),

    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`
    },

    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.elvenGray[200],
      borderStyle: 'solid'
    }
  },
  filterButton: {
    minWidth: 50,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  firstChild: {
    marginLeft: theme.spacing(1)
  },
  textNoResult: {
    fontWeight: 500,
    color: '#515151',
    fontSize: 20
  },
  menuButton: {
    marginLeft: 'auto',
    minWidth: 42,
    height: 34,
    border: '1px solid #595959',
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  menuItem: {
    '&:hover': {
      color: theme.palette.elvenGreen[400],
      backgroundColor: theme.palette.elvenGreen[50]
    }
  },
  paperProps: {
    width: '220px'
  },
  badgeContent: {
    backgroundColor: '#CDF4E2',
    color: '#515151',
    border: '1px solid #5FBC91',
    fontWeight: 500
  },
  textFilters: {
    fontWeight: 500,
    lineHeight: '26px',
    marginLeft: '3px'
  },
  backgroundButton: {
    backgroundColor: '#ECFDF5'
  },
  button: {
    border: '1px solid #5FBC91'
  }
}));
