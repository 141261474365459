import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  menuOptionButton: {
    width: 42,
    height: 42
  },
  content: {
    '& h1 + h1, & p': {
      marginTop: '1.5rem',
      marginBottom: '0.5rem'
    },
    '& h1': {
      ...theme.typography.h1,
      fontSize: '1.5rem',
      marginBottom: '0.5rem',
      color: theme.palette.elvenGray[700]
    },
    '& h2': {
      ...theme.typography.h2,
      fontSize: '1rem',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      color: theme.palette.elvenGray[700]
    },
    '& p': {
      ...theme.typography.body1,
      lineHeight: 1.5,
      fontSize: 18,
      marginTop: 8,
      marginBottom: 20,
      color: theme.palette.elvenGray[500]
    }
  },
  card: {
    minWidth: 300,
    maxWidth: 400
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  cardDivider: {
    backgroundColor: theme.palette.elvenGray[100],
    width: '100%',
    marginTop: theme.spacing(1)
  },
  orderedList: {
    listStyleType: 'decimal',
    listStylePosition: 'inside',

    '& li': { display: 'list-item' }
  },
  unorderedList: {
    listStyleType: 'disc',
    listStylePosition: 'inside'
  },
  informationTitle: {
    fontSize: theme.typography.pxToRem(24)
  },
  menuItem: {
    '& a': {
      color: 'inherit',
      display: 'block',
      width: '100%'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.elvenGreen[50],
      color: theme.palette.elvenGreen[400]
    }
  },
  main: {
    display: 'flex',
    gridGap: '5rem',
    '@media print': {
      flexDirection: 'column-reverse',
      gridGap: '0.5rem',
      paddingTop: '0.5rem'
    }
  }
}));
