import React from 'react';

import { Button, CircularProgress, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import DeleteEntity from 'components/DeleteEntityDialog';
import PageContainer from 'components/PageContainer';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

import Toolbar from '../../../components/Crud/ToolbarQueryProps';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  }
});

const StatusPageList = ({ classes }) => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = React.useState('name,ASC');

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'of',
    noRowsPerPage: false
  };

  const handleSort = ({ selector }, sortDirection) => {
    setSort(`${selector},${sortDirection.toUpperCase()}`);
  };

  const queryKey = ['statusPages', page, rowsPerPage, sort];

  const { data, isSuccess, isError, isLoading } = useQuery(queryKey, () =>
    axios
      .get(`/statuspage?page=${page}&per_page=${rowsPerPage}&sort=${sort}`)
      .then(response => response.data)
  );

  const queryCache = useQueryCache();

  const dispatch = useDispatch();

  const [handleRemove] = useMutation(async id => axios.delete(`/statuspage/${id}`), {
    onSuccess: data => {
      queryCache.invalidateQueries(queryKey);
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Status page removed successfully'
      });
      return data;
    },
    onError: error => {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: error
      });
    }
  });

  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, id: null });

  const permissions = {
    new: usePermission('StatusPageController-post-/statuspage'),
    update: usePermission('StatusPageController-put-/statuspage'),
    remove: usePermission('StatusPageController-delete-/statuspage/:id')
  };

  const history = useHistory();

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Slug',
      selector: 'slug',
      sortable: true
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: statusPage => (
        <Button
          startIcon={<OpenInNewIcon />}
          href={`${process.env.REACT_APP_URL_STATUS_PAGE_PUBLIC}/${statusPage.uid}/${statusPage.slug}`}
          title="Open this public status page in a new tab"
          target="_blank"
          rel="noopener noreferrer">
          View
        </Button>
      )
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: statusPage => (
        <IconButton
          onClick={() => history.push(`/statuspages/${statusPage.id}/edit`)}
          title="Edit"
          disabled={!permissions.update}>
          <EditIcon />
        </IconButton>
      )
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: statusPage => (
        <IconButton
          onClick={() => setDeleteModalState({ isOpen: true, id: statusPage.id })}
          title="Remove"
          disabled={!permissions.remove}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  return (
    <PageContainer maxWidth={false}>
      <Toolbar
        title="Status Page"
        buttonNewName="New"
        entity="statuspages"
        searchPlaceholder="Search status pages"
        noSearch
        disableNew={!permissions.new}
      />
      <div className={classes.content}>
        {isLoading && <CircularProgress />}

        {isError && "Couldn't load status pages :("}

        {isSuccess && (
          <DataTable
            clicked
            columns={columns}
            data={data.data || []}
            highlightOnHover
            noDataComponent="No results found"
            onChangePage={setPage}
            onChangeRowsPerPage={setRowsPerPage}
            onSort={handleSort}
            pagination
            paginationDefaultPage={page}
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[10, 25, 50]}
            paginationServer
            paginationTotalRows={data.total}
            sortServer
            striped
          />
        )}
      </div>
      <DeleteEntity
        open={deleteModalState.isOpen}
        handleClose={() => setDeleteModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          handleRemove(deleteModalState.id);
          setDeleteModalState({ isOpen: false, id: null });
        }}
        entityName="Status Page"
        isLoading={isLoading}
        disabled={!permissions.remove}
      />
    </PageContainer>
  );
};

export default withStyles(styles)(StatusPageList);
